import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { typesTarget, TYPES_COMPONENTS } from '../../Common';
import { linkDefault } from '../../Common/messages';

const prevent = (e) => e.preventDefault();

const ButtonGeneral = ({
  textUrl,
  textButton,
  icon,
  valueVariant,
  valueColor,
  url,
  styles,
  eventClick,
  direction,
  mobile = false,
  path,
  cardsItems,
  currentIndex,
  setIndex,
  contentCardsDesktop,
  contentCardsMobile,
  paginationCarousel,
}) => {
  return (
    <>
      {textUrl ? (
        !path ? (
          <Box
            sx={styles}
            component={TYPES_COMPONENTS.link}
            href={url}
            onClick={url === linkDefault ? prevent : undefined}
            target={
              url !== linkDefault
                ? typesTarget.TYPE_TARGET_BLANK
                : typesTarget.TYPE_TARGET_SELFT
            }
          >
            <Box component={TYPES_COMPONENTS.img} src={icon && icon} />
            {textUrl}
          </Box>
        ) : (
          <Link to={path || linkDefault}>
            <Box component={TYPES_COMPONENTS.img} src={icon && icon} />
            {textUrl}
          </Link>
        )
      ) : (
        <Button
          onClick={() => eventClick(
            direction,
            mobile,
            cardsItems,
            currentIndex,
            setIndex,
            contentCardsDesktop,
            contentCardsMobile,
            paginationCarousel,
          )}
          sx={styles}
          variant={valueVariant}
          color={valueColor}
        >
          {textButton}
        </Button>
      )}
    </>
  );
};

export default ButtonGeneral;
