import {
  Button,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

export const StyledModal = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'fixed',
  width: '100%',
  maxWidth: '375px',
  height: '199px',
  paddingTop: '1rem',
  top: '10.8%',
  left: '50%',
  transform: 'translate(-50%, -170%)',
  zIndex: '40',
  transition: 'transform .3s ease',
  backgroundColor: theme.palette.secondary.main,
  boxShadow: '0px 3px 6px #00000029',
  [theme.breakpoints.down('sm')]: {
    top: '0',
    left: '0',
    width: '100%',
    maxWidth: 'initial',
    transform: 'translate(0%, -170%)',
  },
}));

export const IconContainer = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '5px',
  right: '18px',
  color: theme.palette.webView.primary,
  padding: '0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ChasImageContainer = styled('div')(() => ({
  width: '48px',
  height: '75px',
}));
export const ChasImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

export const ModalText = styled(Typography)(({ theme }) => ({
  color: theme.palette.webView.text,
  fontSize: '.75rem',
  textAlign: 'center',
  marginTop: '1.25rem',
  fontWeight: '800',
}));

export const AcceptButton = styled(Button)(({ theme }) => ({
  width: '213px',
  hegiht: '40px',
  backgroundColor: theme.palette.webView.primary,
  color: theme.palette.secondary.main,
  borderRadius: '12px',
  marginTop: '1.25rem',
  textTransform: 'initial',
  fontSize: '0.875rem',
  '&:hover': {
    backgroundColor: theme.palette.webView.primary,
  },
}));

export const styledSuccess = {
  transform: {
    xs: 'translate(0%)',
    sm: 'translate(-50%, -10.7vh)',
    md: 'translate(-50%, -10.7vh)',
  },
};

export const styledClose = {
  transform: 'translate(-50%, -170%)',
};
