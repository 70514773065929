import { useParams } from 'react-router-dom';
/* eslint-disable no-undef */
import {
  useState,
  useEffect,
  useRef,
} from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
  bgImage,
  contentCaption,
  contentImageCaption,
  headingOne,
  headingTwo,
  styleAppStore,
  styleAppPlayStore,
  contentIconApps,
  progressContainer,
} from './SaveTimeStyles';
import { captionText, downloads } from '../../Common/messages';
import Captions from '../Captions/Captions';
import { TYPES_COMPONENTS } from '../../Common';
import './SaveTime.css';
import { DataLading, DownloadViewData } from '../../api';
import DownloadApp from '../DownloadApp/DownloadApp';

const {
  getDownloadData,
} = DownloadViewData;

const SaveTime = ({ isDownloadView }) => {
  const [dataSaveTime, setDataSaveTime] = useState({
    texts: {},
    imagePhone: {
      image: '',
      alternativeText: '',
    },
    backgroundImage: {},
    imageDownloadApp: {
      imageIOS: {
        image: '',
        alternativeText: '',
        url: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
        url: '',
      },
    },
    loading: true,
  });
  const { getSaveTime } = DataLading;
  const backgroundRef = useRef(null);

  const { landingId: landingTypeId } = useParams();

  let landingId;
  useEffect(() => {
    landingId = landingTypeId;
  }, [landingTypeId]);

  useEffect(() => {
    if (!isDownloadView) {
      getSaveTime(landingId).then(dataResponseSaveTime => {
        setDataSaveTime(prev => ({
          ...prev,
          texts: dataResponseSaveTime.texts,
          imagePhone: dataResponseSaveTime.imagePhone,
          imageDownloadApp: dataResponseSaveTime.imageDownloadApp,
          backgroundImage: dataResponseSaveTime.backgroundImage,
          loading: false,
        }));
      });
      return;
    }
    getDownloadData().then(dataResponseSaveTime => {
      setDataSaveTime(prev => ({
        ...prev,
        texts: dataResponseSaveTime.texts,
        imagePhone: dataResponseSaveTime.imagePhone,
        imageDownloadApp: dataResponseSaveTime.imageDownloadApp,
        backgroundImage: dataResponseSaveTime.backgroundImage,
        loading: false,
      }));
    });
  }, []);

  const {
    texts,
    backgroundImage,
    imageDownloadApp,
    imagePhone: imageIphone,
    loading,
  } = dataSaveTime;

  const { variants, color } = captionText;

  const circularProgessStyles = {
    ...bgImage(backgroundImage.desktop, backgroundImage.mobile), ...progressContainer,
  };

  return (
    <Box
      ref={backgroundRef}
      sx={loading
        ? circularProgessStyles
        : bgImage(backgroundImage.desktop, backgroundImage.mobile)}
    >
      {
        loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={contentCaption}>
              <Box>
                {texts.textH1 && (
                <Captions
                  variant={variants.variantH1}
                  style={headingOne}
                  color={color.colorSecondary}
                >
                  {texts.textH1}
                </Captions>
                )}

                {texts.textH3 && (
                <Captions
                  variant={variants.variantH2}
                  style={headingTwo}
                  color={color.colorSecondary}
                >
                  {texts.textH3}
                </Captions>
                )}
                {(imageDownloadApp.imageIOS || imageDownloadApp.imageAndroid) && (
                <Box sx={contentIconApps}>
                  <DownloadApp
                    appStore={imageDownloadApp.imageIOS}
                    playStore={imageDownloadApp.imageAndroid}
                    downloads={downloads}
                    style={{ styleAppStore, styleAppPlayStore }}
                  />
                </Box>
                )}
              </Box>
            </Box>
            {imageIphone.image && (
            <Box sx={contentImageCaption}>
              <Box
                component={TYPES_COMPONENTS.img}
                src={imageIphone.image}
                alt={imageIphone.alternativeText}
              />
            </Box>
            )}
          </>
        )
      }
    </Box>

  );
};

export default SaveTime;

SaveTime.defaultProps = {
  isDownloadView: false,
};
