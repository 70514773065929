export const boxTestimonials = (mobile, desktop) => {
  return {
    display: {
      xs: mobile,
      sm: mobile,
      md: desktop,
      lg: desktop,
    },
    height: '560px',
    '& h5': {
      maxWidth: '328px',
    },
    width: {
      xs: 'initial',
      sm: 'initial',
      md: '159%',
      lg: '159%',
    },
    position: {
      xs: 'static',
      sm: 'static',
      md: 'relative',
      lg: 'relative',
    },
    left: { md: '0%', lg: '-29.2%' },
  };
};

export const contentCardsTestimonials = {
  display: 'flex',
  gap: '32px',
  marginTop: '32px',
  overflowX: 'hidden',
  height: '68%',
  width: {
    xs: '100vw',
    phoneMd: '112vw',
    sm: '112vw',
    md: 'initial',
    lg: 'initial',
  },
  '& .swiper': {
    paddingRight: {
      xs: '0px !important',
      phoneMd: '61px !important',
      sm: '0px !important',
      md: '0px  !important',
      lg: '63%  !important',
      xl: '100% !important',
    },
    paddingLeft: {
      xs: '12px !important',
      phoneMd: '55px !important',
      sm: '55px !important',
      md: '14px !important',
      lg: '373px!important',
      laptopLarge: '373px!important',
      xl: '19px !important',
    },
    marginLeft: {
      laptopLarge: '-125px !important',
      xl: 'auto !important',
    },
    marginRight: {
      xs: '0px !important',
      sm: '0px !important',
      md: '-447px !important',
      laptop: '-231px !important',
      lg: '-44%!important',
      xl: '0px !important',
    },
    '& > .swiper-wrapper': {
      width: {
        laptopLarge: '98vw',
        xl: '103vw',
      },
    },
    '& > div > div > div': {
      boxShadow: '0px 0px 0px 0px transparent',
    },
  },
};

export const cardTestimonialsStyle = {
  width: '225px',
  borderRadius: '20px',
  backgroundColor: 'secondary.main',
  padding: '30px 24px 32px',
  '& div:first-of-type': {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px',
    alignItems: 'center',
    '& > div:first-of-type': {
      marginBottom: '0px',
      width: '43px',
      height: '43px',
    },
    '& h6': {
      fontSize: '1.125rem',
      fontWeight: '900',
      minWidth: '177px',
    },
  },
  '& > p:first-of-type': {
    fontSize: '1rem',
    overflowY: 'auto',
    height: '154px',
    width: '225px',
  },
  '& > p:last-of-type': {
    fontSize: '1rem',
    fontWeight: '800',
    position: 'relative',
    bottom: '-11px',
  },
};
