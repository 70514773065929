import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { ServicePreOrder } from '../Common/messages';
import { intialPreOrderState, preOrderReducer } from '../store/preOrder/store';

export const ServicePreOrderProvider = createContext(null);

const {
  localStorageData,
} = ServicePreOrder;

const ServicePreOrderContext = ({ children }) => {
  const [preOrderState, dispatch] = useReducer(preOrderReducer, [], intialPreOrderState);
  const [unmounted, setUnmounted] = useState(false);

  useEffect(() => {
    return () => {
      setUnmounted(true);
      if (unmounted) {
        const preOrderStateDefault = {
          configurationData: {},
          company: {},
          productShowCase: {},
          date: {
            dateView: '',
            defaultDate: '',
          },
          preOrderSummary: {},
          checkoutData: {},
          checkoutParamsData: {},
        };
        // eslint-disable-next-line no-undef
        localStorage.setItem(localStorageData.preOrder, JSON.stringify(preOrderStateDefault));
      }
    };
  }, [unmounted]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem(localStorageData.preOrder, JSON.stringify(preOrderState));
  }, [
    preOrderState?.configurationData?.serviceTypeUuid,
    preOrderState?.company,
    preOrderState?.productShowCase,
    preOrderState?.date?.dateView,
    preOrderState?.date?.dateView?.defaultDate,
    preOrderState?.preOrderSummary?.summaryData?.total,
    preOrderState?.checkoutData,
    preOrderState?.checkoutParamsData?.companyUuid,
    preOrderState?.checkoutParamsData?.customerData?.fullName,
    preOrderState?.checkoutParamsData?.customerData?.email,
    preOrderState?.checkoutParamsData?.customerData?.phoneNumber,
    preOrderState?.company?.score?.score,
    preOrderState?.company?.score?.count,
  ]);

  const preOrderStateMemo = useMemo(() => {
    return [preOrderState, dispatch];
  }, [
    preOrderState?.configurationData?.serviceTypeUuid,
    preOrderState?.company,
    preOrderState?.productShowCase,
    preOrderState?.productShowCase?.registryUuid,
    preOrderState?.date?.dateView,
    preOrderState?.date?.dateView?.defaultDate,
    preOrderState?.preOrderSummary?.summaryData?.total,
    preOrderState?.checkoutData,
    preOrderState?.checkoutParamsData?.companyUuid,
    preOrderState?.checkoutParamsData?.customerData?.fullName,
    preOrderState?.checkoutParamsData?.customerData?.email,
    preOrderState?.checkoutParamsData?.customerData?.phoneNumber,
    preOrderState?.company?.score?.score,
    preOrderState?.company?.score?.count,
  ]);

  return (
    <ServicePreOrderProvider.Provider value={preOrderStateMemo}>
      { children }
    </ServicePreOrderProvider.Provider>
  );
};

export default ServicePreOrderContext;
