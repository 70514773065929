import {
  Alert, Box, CircularProgress, Snackbar, useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceBillingData } from '../../api';
import { ERROS_TYPES, variantTypography } from '../../Common';
import { ServiceBilling } from '../../Common/messages';
import {
  Captions,
  ServiceBillingGroupRadio,
  ServiceBillingSelect,
  ServiceBillingTextField,
  WebViewButtonPrimary,
  WebViewModal,
} from '../../Components';
import { ErrorsMessages } from '../../shared/messages';
import { ErrosUtils, FormValidions } from '../../utils/validations';
import LayoutBannerTraking from '../LayoutTraking/LayoutBannerTraking';
import {
  Form,
  InputAndBannerContainer,
  InputContainer,
  styledBillingCompletedTitle,
  styledInputsAndButtonContainer,
  styledTitle,
  WarrantyBannerContainer,
} from './styles';
import ServiceBillingHelpSectionLayout from './ServiceBillingHelpSectionLayout';

const {
  inputData,
  selectors,
  billingCompletedTitle,
} = ServiceBilling;

const {
  errorMessages,
} = ErrorsMessages;

const data = {
  invoice: '',
  businessName: '',
  rfc: '',
  postalCode: '',
  email: '',
};

let isMxEmail = null;
let isNumeric = null;

const {
  validNumbers,
  validMxEmail,
} = FormValidions;

const {
  getCfdi,
  getFiscalRegimen,
  saveServiceBilling,
  getServiceBillingContent,
} = ServiceBillingData;

const {
  errorsValid,
} = ErrosUtils;

const {
  rfcNotFound,
} = ERROS_TYPES;

const {
  h1,
  h2,
} = variantTypography;

const ServiceBillingLayout = () => {
  const [selectorsData, setSelectorsData] = useState({
    cfdi: [],
    fiscalRegimen: [],
  });
  const [billingServiceData, setBillingServiceData] = useState({
    tite: '',
    buttonSendData: '',
    inputsData: {
      folioInput: {
        label: '',
        placeholder: '',
      },
      businessNameInput: {
        label: '',
        placeholder: '',
      },
      rfcInput: {
        label: '',
        placeholder: '',
      },
      fiscalRegimenInput: {
        label: '',
        placeholder: '',
      },
      cfdiInput: {
        label: '',
        placeholder: '',
      },
      postalCodeInput: {
        label: '',
        placeholder: '',
      },
      emailInput: {
        label: '',
        placeholder: '',
      },
    },
    radiosData: {
      physicalPersonRadio: {
        label: '',
      },
      moralPersonRadio: {
        label: '',
      },
    },
    modalData: {
      title: '',
      button: '',
    },
  });
  const [valueSelectors, setValueSelectors] = useState({
    cfdi: {
      code: '',
      description: '',
      fiscalSchema: [],
    },
    fiscalRegimen: {
      code: '',
      description: '',
      fiscalSchema: [],
    },
  });
  const [personType, setPersonType] = useState('Física');
  const [validInput, setValidInput] = useState({
    invoice: false,
    businessName: false,
    rfc: false,
    cfdi: false,
    fiscalRegimen: false,
    postalCode: false,
    email: false,
    personType: false,
  });
  const [saveError, setSaveError] = useState({
    error: false,
    message: '',
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const desktopBreakpoint = '(min-width:900px)';
  const desktop = useMediaQuery(desktopBreakpoint);
  const [disabled, setDisabled] = useState(false);
  const [rfcValid, setRfcValid] = useState(false);
  const [isResetValue, setIsResetValue] = useState(false);

  const navigate = useNavigate();
  const { folio: folioParam } = useParams();

  useEffect(() => {
    if (folioParam) {
      data.invoice = folioParam;
    }
  }, [folioParam]);

  useEffect(() => {
    setSelectLoading(true);
    Promise.all([getCfdi(), getFiscalRegimen()]).then((response) => {
      const [cfdiResponse, fiscalRegimenResponse] = response;

      const cfdiData = cfdiResponse?.data?.map(item => {
        return {
          id: item?.code,
          value: item?.code,
          name: item?.description,
          fiscalSchema: item['fiscal-schema'],
        };
      }).filter(obj => obj.fiscalSchema.includes(personType));

      const fiscalRegimenData = fiscalRegimenResponse?.data?.map(item => {
        return {
          id: item?.code,
          value: item?.code,
          name: item?.description,
          fiscalSchema: item['fiscal-schema'],
        };
      }).filter(select => {
        let fiscalValue = false;
        select?.fiscalSchema.forEach(item => {
          fiscalValue = item === personType;
        });
        return fiscalValue;
      });

      setValueSelectors((prev) => ({
        ...prev,
        cfdi: {
          code: '',
          description: '',
          fiscalSchema: [],
        },
        fiscalRegimen: {
          code: '',
          description: '',
          fiscalSchema: [],
        },
      }));

      setSelectorsData(prev => ({
        ...prev,
        cfdi: cfdiData,
        fiscalRegimen: fiscalRegimenData,
      }));

      setSelectLoading(false);
    }).catch(() => {
      setSelectLoading(false);
      setDataLoading(false);
      navigate('/*');
    });
  }, [personType]);

  useEffect(() => {
    setDataLoading(true);
    getServiceBillingContent().then(serviceBillingResponse => {
      setBillingServiceData(prev => ({
        ...prev,
        tite: serviceBillingResponse?.title,
        inputsData: { ...serviceBillingResponse?.inputsData },
        radiosData: { ...serviceBillingResponse?.radiosData },
        buttonSendData: serviceBillingResponse?.buttonSendData,
        modalData: { ...serviceBillingResponse.modalData },
      }));
      setDataLoading(false);
    }).catch(() => {
      setDataLoading(false);
      navigate('/*');
    });
  }, []);

  const validInputSetValue = (item, value) => {
    setValidInput(prev => ({
      ...prev,
      [item]: value,
    }));
  };

  useEffect(() => {
    Object.keys(valueSelectors).forEach(item => {
      if (valueSelectors[item].code) {
        validInputSetValue(item, false);
      }
    });
  }, [valueSelectors.cfdi, valueSelectors.fiscalRegimen]);

  const changeHandler = ({ value, name }) => {
    data[name] = value;
    isMxEmail = validMxEmail(data.email);
    isNumeric = validNumbers(data.postalCode);
    Object.keys(data).forEach(item => {
      if (data[item]) {
        validInputSetValue(item, false);
      }
    });
  };

  const selectChangeHandler = ({ value, name }) => {
    const valueSearch = selectorsData[name].find(
      select => select.name.toLowerCase() === value.toLowerCase(),
    );
    setValueSelectors(prev => ({
      ...prev,
      [name]: {
        code: valueSearch?.value,
        description: valueSearch?.name,
        fiscalSchema: valueSearch?.fiscalSchema,
      },
    }));
  };

  const radioChangeHandler = ({ value }) => {
    setPersonType(value);
  };

  const disabledAndLoadingSetValue = (value) => {
    setDisabled(value);
    setSendLoading(value);
  };

  const closeHandler = () => {
    setSaveError((prev) => ({ ...prev, error: false }));
  };
  const closeSuccessModalHandler = (value) => {
    setSaveSuccess(value);
  };

  const dataSendPost = async (dataSend) => {
    try {
      disabledAndLoadingSetValue(true);
      const response = await saveServiceBilling(dataSend);
      const createdStatus = 201;
      if (response.status === createdStatus) {
        setSaveSuccess(true);
        disabledAndLoadingSetValue(false);
        data.businessName = '';
        data.email = '';
        data.invoice = folioParam || '';
        data.postalCode = '';
        data.rfc = '';
        setRfcValid(false);
        setIsResetValue(!isResetValue);
        setValueSelectors({
          cfdi: {
            code: '',
            description: '',
            fiscalSchema: [],
          },
          fiscalRegimen: {
            code: '',
            description: '',
            fiscalSchema: [],
          },
        });
      }
    } catch ({ response: { data: errorResponse } }) {
      const validError = errorsValid(errorResponse?.response?.codeApi);
      if (
        errorResponse?.response?.message.toLocaleLowerCase() === rfcNotFound.toLocaleLowerCase()
      ) {
        setRfcValid(true);
        disabledAndLoadingSetValue(false);
        return;
      }
      disabledAndLoadingSetValue(false);
      setSaveError(prev => ({
        ...prev,
        error: true,
        message: validError,
      }));
    }
  };

  const handlerOnlyWithSubmitRfc = async (e) => {
    e.preventDefault();
    const dataWidthOnlyRfc = {
      invoice: folioParam || data.invoice,
      rfc: data.rfc,
      cfdi: valueSelectors.cfdi.code,
      email: data.email,
    };
    if (!Object.values(dataWidthOnlyRfc).every(input => input)) {
      Object.keys(dataWidthOnlyRfc).forEach(item => {
        if (!dataWidthOnlyRfc[item]) {
          validInputSetValue(item, true);
          return;
        }
        validInputSetValue(item, false);
      });
      return;
    }
    await dataSendPost({
      folio: data.invoice.toUpperCase(),
      rfc: data.rfc.toUpperCase(),
      cfdiUse: {
        code: valueSelectors.cfdi.code,
        description: valueSelectors.cfdi.description,
      },
      email: data.email,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const selectorsValidation = [
      Object.values(valueSelectors)[0].code, Object.values(valueSelectors)[1].code,
    ];
    data.invoice = folioParam || data.invoice;
    if (!Object.values(data).every(item => item) || !selectorsValidation.every(item => item)) {
      Object.keys(data).forEach(item => {
        if (!data[item]) {
          validInputSetValue(item, true);
          return;
        }
        validInputSetValue(item, false);
      });

      Object.keys(valueSelectors).forEach(item => {
        if (!valueSelectors[item].code) {
          validInputSetValue(item, true);
          return;
        }
        validInputSetValue(item.name, false);
      });
      return;
    }
    await dataSendPost({
      folio: data.invoice.toUpperCase(),
      businessName: data.businessName,
      fiscalSchema: personType,
      rfc: data.rfc.toUpperCase(),
      cfdiUse: {
        code: valueSelectors.cfdi.code,
        description: valueSelectors.cfdi.description,
      },
      fiscalRegime: {
        code: valueSelectors.fiscalRegimen.code,
        description: valueSelectors.fiscalRegimen.description,
      },
      postalCode: data.postalCode,
      email: data.email,
    });
  };

  const inputFirst = inputData()[0];
  const inputSecond = inputData(changeHandler)[1];
  const inputThird = inputData(changeHandler)[2];

  const inputIndexThird = 3;
  const inputLast = inputData().length - 1;

  const styledForm = {
    display: 'grid',
    placeItems: 'center',
    marginBottom: '1.4rem',
    width: {
      xs: '100%',
      md: '423px',
    },
  };

  const {
    inputsData: {
      folioInput,
      businessNameInput,
      rfcInput,
      fiscalRegimenInput,
      cfdiInput,
      postalCodeInput,
      emailInput,
    },
    radiosData: {
      moralPersonRadio,
      physicalPersonRadio,
    },
    modalData,
  } = billingServiceData;

  const radioDefaultValue = 'Física';
  const valueFirstRadio = radioDefaultValue;
  const valueSecondRadio = 'Moral';

  const disabledStyles = folioParam ? { backgroundColor: { xs: '#e8e8e87d', md: '#e8e8e87d' } } : {};

  const formStyles = dataLoading ? {
    height: '443px',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
  } : undefined;

  const styledFormValidation = !rfcValid ? styledInputsAndButtonContainer : undefined;
  return (
    <>
      <Form onSubmit={!rfcValid ? handlerOnlyWithSubmitRfc : submitHandler} sx={formStyles}>
        {
          dataLoading ? (
            <CircularProgress size='3rem' />
          ) : (
            <>
              <Captions
                variant={h1}
                style={rfcValid ? styledTitle : { ...styledTitle, marginBottom: { xs: '0.8rem', md: '2.67rem' } }}
              >
                {billingServiceData?.tite}
              </Captions>

              {rfcValid && (
                <Captions variant={h2} style={styledBillingCompletedTitle}>
                  {billingCompletedTitle}
                </Captions>
              )}

              <InputAndBannerContainer>

                <InputContainer sx={styledFormValidation}>
                  <Box>
                    <ServiceBillingTextField
                      onChange={changeHandler}
                      name={inputFirst.name}
                      props={{
                        placeholder: folioInput?.placeholder,
                        disabled: !!folioParam,
                      }}
                      labelText={folioInput.label}
                      messageError={errorMessages.requiredInput}
                      error={validInput.invoice}
                      styledInput={disabledStyles}
                      defaultValue={folioParam || data.invoice}
                      success={(rfcValid && data.invoice)}
                      isValuesReset={isResetValue}
                    />

                    {rfcValid && (
                      <ServiceBillingGroupRadio
                        onChange={radioChangeHandler}
                        labelFirst={physicalPersonRadio?.label}
                        labelSecond={moralPersonRadio?.label}
                        defaultValue={radioDefaultValue}
                        name='personType'
                        valueFirst={valueFirstRadio}
                        valueSecond={valueSecondRadio}
                        radioSize={desktop ? 'medium' : 'small'}
                      />
                    )}
                    {rfcValid && (
                      <ServiceBillingTextField
                        key={inputSecond.id}
                        onChange={inputSecond.cb}
                        name={inputSecond.name}
                        props={{
                          placeholder: businessNameInput?.placeholder,
                        }}
                        labelText={businessNameInput?.label}
                        messageError={errorMessages.requiredInput}
                        error={validInput.businessName}
                        isValuesReset={isResetValue}
                      />
                    )}

                    <ServiceBillingTextField
                      key={inputThird.id}
                      onChange={inputThird.cb}
                      name={inputThird.name}
                      props={{
                        placeholder: rfcInput?.placeholder,
                        inputProps: {
                          maxLength: 13,
                        },
                      }}
                      labelText={rfcInput?.label}
                      messageError={errorMessages.requiredInput}
                      error={validInput.rfc}
                      success={(rfcValid && data.rfc)}
                      isValuesReset={isResetValue}
                    />

                    {rfcValid && (
                      <ServiceBillingSelect
                        items={selectorsData.fiscalRegimen}
                        onChange={selectChangeHandler}
                        placeholder={fiscalRegimenInput?.placeholder}
                        name={selectors.fiscalregimen.name}
                        labelText={fiscalRegimenInput?.label}
                        error={validInput.fiscalRegimen}
                        messageError={errorMessages.requiredInput}
                        valueDefault={valueSelectors.fiscalRegimen.description}
                        loading={selectLoading}
                        formStyles={selectLoading ? styledForm : {}}
                      />
                    )}

                    <ServiceBillingSelect
                      items={selectorsData.cfdi}
                      onChange={selectChangeHandler}
                      placeholder={cfdiInput?.placeholder}
                      name={selectors.cfdi.name}
                      labelText={cfdiInput?.label}
                      error={validInput.cfdi}
                      messageError={errorMessages.requiredInput}
                      valueDefault={valueSelectors.cfdi.description}
                      loading={selectLoading}
                      formStyles={selectLoading ? styledForm : {}}
                      success={(rfcValid && valueSelectors.cfdi.code)}
                    />

                    {rfcValid && (
                      <ServiceBillingTextField
                        onChange={changeHandler}
                        name={inputData()[inputIndexThird].name}
                        props={{
                          placeholder: postalCodeInput?.placeholder,
                          inputProps: {
                            maxLength: 5,
                          },
                        }}
                        labelText={postalCodeInput?.label}
                        messageError={
                          isNumeric
                            ? errorMessages.isNumeric
                            : errorMessages.requiredInput
                        }
                        error={validInput.postalCode || isNumeric}
                        isValuesReset={isResetValue}
                      />
                    )}

                    <ServiceBillingTextField
                      onChange={changeHandler}
                      name={inputData()[inputLast].name}
                      props={{
                        placeholder: emailInput?.placeholder,
                      }}
                      labelText={emailInput?.label}
                      messageError={
                        isMxEmail
                          ? errorMessages.isEmail
                          : errorMessages.requiredInput
                      }
                      error={validInput.email || isMxEmail}
                      success={(rfcValid && data.email)}
                      isValuesReset={isResetValue}
                    />
                  </Box>

                  <Box>
                    <WebViewButtonPrimary
                      disabled={disabled || (isMxEmail || isNumeric)}
                      styles={disabled || (isMxEmail || isNumeric) ? { backgroundColor: '#B0B8C4' } : {}}
                      props={{ type: 'submit', disableRipple: true }}
                    >
                      {
                        sendLoading
                          ? <CircularProgress size='1.5rem' sx={{ color: 'secondary.main' }} />
                          : billingServiceData.buttonSendData
                      }
                    </WebViewButtonPrimary>
                  </Box>
                </InputContainer>
                <Snackbar
                  open={saveError.error}
                  autoHideDuration={6000}
                  onClose={closeHandler}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Alert
                    onClose={closeHandler}
                    severity='error'
                    sx={{ width: '100%' }}
                  >
                    {saveError.message}
                  </Alert>
                </Snackbar>
                <WebViewModal
                  open={saveSuccess}
                  buttonText={modalData?.button}
                  label={modalData?.title}
                  onClose={closeSuccessModalHandler}
                />
                {desktop && (
                  <WarrantyBannerContainer>
                    <LayoutBannerTraking />
                  </WarrantyBannerContainer>
                )}
              </InputAndBannerContainer>
            </>
          )
        }
      </Form>
      {desktop && (<ServiceBillingHelpSectionLayout />)}
    </>
  );
};

export default ServiceBillingLayout;
