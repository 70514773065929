export const serviceSearchContainer = {
  width: '100%',
  maxWidth: '1275px !important',
  marginTop: '5.29rem',
  marginBottom: '5rem',
  padding: '0px !important',
};

export const containerProduct = {
  display: {
    xs: 'block',
    md: 'flex',
  },
  gap: '4.6rem',
  maxWidth: '1300px !important',
  padding: {
    xs: '0rem 1rem',
    md: '0rem 0rem',
  },
  marginTop: '5.29rem',
};

export const containerSucces = {
  marginTop: {
    xs: '6rem',
    md: '9rem',
  },
};

export const preOrderContainer = {
  maxWidth: '1275px !important',
  display: 'block',
};
