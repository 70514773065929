import { useEffect, useState } from 'react';
import { CardMedia } from '@mui/material';
import { DownloadTextContainer } from '../../Components';
import { ImageContainer, SectionContainer, TextContainer } from '../Coverage/style';
import { TYPES_COMPONENTS } from '../../Common';
import { containerStyles } from './styles';

const LayoutSectionBenefits = () => {
  const [sectionBenefits, setSectionBenefits] = useState({
    image: {
      src: '',
      alternativeText: '',
    },
    textDownloader: {
      title: '',
      subTitle: '',
      contentText: '',
      button: '',
    },
  });

  useEffect(() => {
    setSectionBenefits(null);
  }, []);

  return (
    !sectionBenefits ? null : (
      <SectionContainer sx={containerStyles}>
        <ImageContainer>
          <CardMedia
            component={TYPES_COMPONENTS.img}
            image={sectionBenefits.image.src}
            alt={sectionBenefits.image.alternativeText}
          />
        </ImageContainer>
        <TextContainer>
          <DownloadTextContainer
            header={sectionBenefits.textDownloader.title}
            title={sectionBenefits.textDownloader.subTitle}
            text={sectionBenefits.textDownloader.contentText}
            downloadText={sectionBenefits.textDownloader.button}
          />
        </TextContainer>
      </SectionContainer>
    )
  );
};

export default LayoutSectionBenefits;
