export const titleMain = {
  fontSize: '2.5rem',
  fontWeight: '900',
  marginBottom: '16px',
  textAlign: 'center',
  color: 'primary.main',
};

export const subTitle = {
  fontSize: '1.4rem',
  color: 'primary.main',
  fontWeight: '800',
};

export const sectionGlobal = {
  color: 'text.secondary',
  marginBottom: '16px',
  '& > p': {
    fontSize: '1.3rem',
  },
};

export const contentList = {
  color: 'text.secondary',
  paddingLeft: '16px',
  fontSize: '1.1rem',
  '& > div': {
    display: 'flex',
    '& > span': {
      marginRight: '6px',
      fontWeight: '800',
    },
  },
};

export const contentFirstSection = {
  '& p': {
    marginBottom: '10px',
  },
};

export const contentSectionEighth = {
  '& > p': {
    paddingTop: '8px',
    paddingLeft: '16px',
    fontSize: '1.1rem',
  },
};
export const contentSectionNineth = {
  '& > div': {
    paddingLeft: '16px',
    fontSize: '1.18rem',
    '& > p': {
      fontSize: '1.1rem',
    },
  },
};

export const contentListTenthSection = {
  '& > div': {
    fontSize: '1.2rem',
    paddingLeft: '16px',
    '& > p': {
      fontSize: '1.2rem',
    },
  },
  '& > div:nth-of-type(3)': {
    marginTop: '10px',
    '& > ul': {
      paddingLeft: '22px',
      '& > li:before': {
        content: '"-"',
      },
    },
  },
  '& > div:nth-of-type(5)': {
    marginTop: '10px',
    '& > ul': {
      paddingLeft: '22px',
      '& > li:before': {
        content: '"-"',
      },
    },
  },
  '& > div:nth-of-type(6)': {
    marginTop: '10px',
    '& > ul': {
      paddingLeft: '22px',
      '& > li:before': {
        content: '"-"',
      },
    },
  },
};

export const contentSubListsTenthSection = {
  fontSizer: '1rem',
};
