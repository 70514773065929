/* eslint-disable no-undef */
import { Box } from '@mui/material';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link as NavLink, useLocation } from 'react-router-dom';
import iconBlog from '../../../assets/img/Blog/navbar/iconBlog.png';
import { TYPES_COMPONENTS, TYPES_ICON } from '../../Common';
import { MenuMobileContext } from '../../Contexts';
import { contentImageIcon, contentImageIconBlog } from './ImageIconStyles';
import { pathViews } from '../../Common/messages';
import { Validations } from '../../utils/validations';
import DarkWaddiLogo from '../../../assets/icons/DarkWaddiLogo';

const ImageIcon = ({ typeIcon }) => {
  const { toggleMenu, setToggleMenu } = useContext(MenuMobileContext);
  const breakPointMobile = '(max-width: 600px)';
  const [initialPath, setInitialPath] = useState(pathViews.home);
  const handleToggle = () => {
    let toggle = toggleMenu;
    const breackPoint = window.matchMedia(breakPointMobile).matches;
    if (!toggle && breackPoint) {
      toggle = true;
      setToggleMenu(toggle);
    }
  };
  const pathRef = useRef('');

  const { pathname } = useLocation();
  const { includePathLandingSub } = Validations;
  const landingSubPath = 'l';

  useEffect(() => {
    if (pathname === pathViews.notFoundPath) {
      setInitialPath(pathViews.home);
    }
  }, [pathname]);

  useEffect(() => {
    pathRef.current = initialPath;
    if (pathname !== pathViews.notFoundPath) {
      setInitialPath(
        includePathLandingSub(pathname, landingSubPath) ? pathname : pathViews.home,
      );
    }
  }, []);
  const { blog: { app, childrens } } = pathViews;

  const alternativeTextBlog = 'icon-blog.png';
  return (
    <Box sx={
      typeIcon === TYPES_ICON.landing
        ? contentImageIcon
        : { ...contentImageIcon, ...contentImageIconBlog }
    }
    >
      <NavLink
        onClick={handleToggle}
        to={typeIcon === TYPES_ICON.landing ? initialPath : app + childrens.home}
      >
        {typeIcon === TYPES_ICON.landing
          ? <DarkWaddiLogo width={141} height={45} />
          : <Box component={TYPES_COMPONENTS.img} src={iconBlog} alt={alternativeTextBlog} />}
      </NavLink>
    </Box>
  );
};

export default ImageIcon;
