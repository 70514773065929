import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { DataServiceTrackingContent } from '../../api';
import { variantTypography } from '../../Common';
import { downloads } from '../../Common/messages';
import { DownloadApp } from '../../Components';
import {
  ContainerBanner,
  TextWarranty,
  styleAppStore,
  styleAppPlayStore,
} from './styles';

const LayoutBannerTraking = () => {
  const [dataBanner, setDataBanner] = useState({
    text: '',
    imageDownloadApp: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
  });

  const navigate = useNavigate();
  const { getDataServiceTrackingContent } = DataServiceTrackingContent;
  useEffect(() => {
    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      setDataBanner(prevDataBanner => ({
        ...prevDataBanner,
        text: dataResponseServiceTrackingContent.banner.text,
        imageDownloadApp: { ...dataResponseServiceTrackingContent.banner.imagesApp },
      }));
    }).catch(() => navigate('/*'));
  }, []);
  const { imageDownloadApp } = dataBanner;
  return (
    <ContainerBanner>
      <TextWarranty
        variant={variantTypography.h2}
      >
        <ReactMarkdown>
          { dataBanner.text }
        </ReactMarkdown>
      </TextWarranty>
      <DownloadApp
        style={{ styleAppStore, styleAppPlayStore }}
        appStore={imageDownloadApp.imageIOS}
        playStore={imageDownloadApp.imageAndroid}
        downloads={downloads}
      />
    </ContainerBanner>
  );
};

export default LayoutBannerTraking;
