import { Box } from '@mui/material';
import {
  useRef, useState,
} from 'react';
import { typesColors, TYPES_CARDS, VARIANT_BUTTONS_TYPES } from '../../Common';
import { directionButttons } from '../../Common/messages';
import {
  ButtonGeneral, CardItem, HeaderCard, PointCarousel,
} from '../../Components';
import { Events } from '../../utils/events';
import {
  boxCardsCarousel,
  CardStyle,
  contentImage,
  contentTexts,
  contentButtonCarousel,
  contentCards,
} from './SuggestionsLayoutStyles';

const idElement = 'beneficios';

const SuggestionsLayout = ({
  textSuggestions, textHeader, nextButton, prevButton,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const contentCardsRef = useRef(null);
  const contentCardsMobileRef = useRef(null);
  const buttonsRef = useRef(null);
  buttonsRef.current = [];

  const getRefstButtons = (el) => {
    if (el && !buttonsRef.current.includes(el)) buttonsRef.current.push(el);
  };

  const {
    carouselMovement,
    endMovement,
    startMovement,
    startMovementMobile,
    carouselMovementMobile,
    endMovementMobile,
    eventCarousel,
  } = Events;

  const hendleCurrentIndex = (index) => {
    setCurrentIndex(index);
  };

  const xPositionDefaultDesktop = 0;
  const xPositiondEndDesktop = -1600;
  const xPositionDefaultMobile = 37;
  const xPositiondEndMobile = -970;

  return (
    <>
      <Box id={idElement} sx={boxCardsCarousel('block', 'none')}>
        <HeaderCard title={textHeader}>
          <ButtonGeneral
            textButton={prevButton}
            valueVariant={VARIANT_BUTTONS_TYPES.outlined}
            valueColor={typesColors.primary}
            direction={directionButttons.previous}
            eventClick={eventCarousel}
            cardsItems={textSuggestions}
            currentIndex={currentIndex}
            setIndex={setCurrentIndex}
            contentCardsDesktop={contentCardsRef}
            contentCardsMobile={contentCardsMobileRef}
            paginationCarousel={buttonsRef}
          />
          <ButtonGeneral
            textButton={nextButton}
            valueVariant={VARIANT_BUTTONS_TYPES.contained}
            valueColor={typesColors.primary}
            direction={directionButttons.next}
            eventClick={eventCarousel}
            cardsItems={textSuggestions}
            currentIndex={currentIndex}
            setIndex={setCurrentIndex}
            contentCardsDesktop={contentCardsRef}
            contentCardsMobile={contentCardsMobileRef}
            paginationCarousel={buttonsRef}
          />
        </HeaderCard>
        <Box
          sx={contentCards}
        >
          <Box
            onMouseDown={(e) => startMovement(
              e,
              contentCardsRef,
              xPositionDefaultDesktop,
              xPositiondEndDesktop,
            )}
            onMouseUp={() => endMovement(contentCardsRef)}
            onMouseLeave={() => endMovement(contentCardsRef)}
            onMouseMove={(e) => carouselMovement(
              e,
              contentCardsRef,
              xPositionDefaultDesktop,
              xPositiondEndDesktop,
            )}
            ref={contentCardsRef}
          >
            {textSuggestions.map((card) => (
              <CardItem
                key={card.title}
                {...card}
                cardStyle={CardStyle}
                contentImage={contentImage}
                contentTexts={contentTexts}
                typeCard={TYPES_CARDS.imageAndTextOrText}
              />
            ))}
          </Box>
        </Box>
        <PointCarousel
          contentCardsRef={contentCardsRef}
          lengthCard={textSuggestions}
          currentIndex={currentIndex}
          buttonsRef={buttonsRef}
          getRefstButtons={getRefstButtons}
          hendleCurrentIndex={hendleCurrentIndex}
          mobile={false}
        />
      </Box>
      <Box sx={boxCardsCarousel('none', 'block')}>
        <HeaderCard title={textHeader} />
        <Box sx={contentCards}>
          <Box
            onTouchStart={(e) => startMovementMobile(
              e,
              contentCardsMobileRef,
              xPositionDefaultMobile,
              xPositiondEndMobile,
            )}
            onTouchMove={(e) => carouselMovementMobile(
              e,
              contentCardsMobileRef,
              xPositionDefaultMobile,
              xPositiondEndMobile,
            )}
            onTouchEnd={() => endMovementMobile(contentCardsMobileRef)}
            ref={contentCardsMobileRef}
            onMouseDown={(e) => startMovement(
              e,
              contentCardsMobileRef,
              xPositionDefaultMobile,
              xPositiondEndMobile,
              xPositiondEndMobile,
            )}
            onMouseUp={() => endMovement(contentCardsMobileRef)}
            onMouseLeave={() => endMovement(contentCardsMobileRef)}
            onMouseMove={(e) => carouselMovement(
              e,
              contentCardsMobileRef,
              xPositionDefaultMobile,
              xPositiondEndMobile,
            )}
          >
            {textSuggestions.map((card) => (
              <CardItem
                key={card.title}
                {...card}
                cardStyle={CardStyle}
                contentImage={contentImage}
                contentTexts={contentTexts}
                typeCard={TYPES_CARDS.imageAndTextOrText}
              />
            ))}
          </Box>
          <Box sx={contentButtonCarousel}>
            <ButtonGeneral
              textButton={prevButton}
              valueVariant={VARIANT_BUTTONS_TYPES.outlined}
              valueColor={typesColors.primary}
              direction={directionButttons.previous}
              eventClick={eventCarousel}
              cardsItems={textSuggestions}
              currentIndex={currentIndex}
              setIndex={setCurrentIndex}
              contentCardsDesktop={contentCardsRef}
              contentCardsMobile={contentCardsMobileRef}
              paginationCarousel={buttonsRef}
              mobile={true}
            />
            <ButtonGeneral
              textButton={nextButton}
              valueVariant={VARIANT_BUTTONS_TYPES.contained}
              valueColor={typesColors.primary}
              direction={directionButttons.next}
              eventClick={eventCarousel}
              cardsItems={textSuggestions}
              currentIndex={currentIndex}
              setIndex={setCurrentIndex}
              contentCardsDesktop={contentCardsRef}
              contentCardsMobile={contentCardsMobileRef}
              paginationCarousel={buttonsRef}
              mobile={true}
            />
          </Box>
        </Box>
        <PointCarousel
          lengthCard={textSuggestions}
          currentIndex={currentIndex}
          contentCardsRef={contentCardsMobileRef}
          buttonsRef={buttonsRef}
          getRefstButtons={getRefstButtons}
          hendleCurrentIndex={hendleCurrentIndex}
          mobile={true}
        />
      </Box>
    </>
  );
};

export default SuggestionsLayout;
