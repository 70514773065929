import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Date, ModalSelect } from './styles';

const ModalDate = ({
  openModal,
  handleClose,
  modalName,
  dateValue,
  handleDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en'>
      <ModalSelect
        keepMounted
        open={openModal}
        onClose={(e) => handleClose(e, modalName, openModal)}
        aria-labelledby='keep-mounted-modal-title'
        aria-describedby='keep-mounted-modal-description'
      >
        <Date
          displayStaticWrapperAs='desktop'
          value={dateValue}
          onChange={(newValue) => handleDate(newValue, modalName, openModal)}
          renderInput={() => (
            <></>
          )}
        />
      </ModalSelect>
    </LocalizationProvider>
  );
};

export default ModalDate;
