import { styled } from '@mui/material';

export const SuccessPreOrderContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTempalteRows: 'repeat(4, max-content)',
  placeItems: 'center',
}));

export const captionStyles = {
  fontWeight: '900',
  fontSize: {
    xs: '1.5rem',
    md: '2rem',
  },
  lineHeight: {
    xs: '2.04875rem',
    md: '2.75rem',
  },
  color: '#000',
  marginBottom: {
    xs: '8rem',
    md: '3.625rem',
  },
  width: '100%',
  maxWidth: {
    xs: '315px',
    md: '726px',
  },
  textAlign: 'center',
};

export const buttonStyles = {
  width: '100%',
  maxWidth: {
    xs: '343px',
    md: '459px',
  },
  transform: 'scale(1)',
  transition: 'transform .3s ease',
  '&:hover': {
    transform: 'scale(1.03)',
  },
};

export const whatsAppButton = {
  backgroundColor: 'transparent',
  border: '1px solid #4433e0',
  marginTop: 'initial',
};

export const backButton = {
  marginTop: {
    xs: '1rem',
    md: '1.5rem',
  },
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'secondary.main',
    transform: 'scale(1.03)',
  },
};
