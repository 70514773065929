import { FormHelperText, InputBase, styled } from '@mui/material';

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '423px',
  height: '58px',
  border: `1px solid ${theme.palette.webView.fourth}`,
  paddingLeft: '0.8125rem',
  fontSize: '1rem',
  marginBottom: '1.25rem',
  borderRadius: '16px',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.webView.text,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '42px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    fontSize: '.75rem',
    marginBottom: '1.4rem',
    border: `1px solid ${theme.palette.webView.secondary}`,
    '& > input::placeholder': {
      color: theme.palette.webView.label,
    },
  },
  '& > input::placeholder': {
    color: theme.palette.webView.tertiary,
  },
}));

export const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  color: theme.palette.text.secondary,
  marginBottom: '.625rem',
  fontSize: '1rem',
  justifySelf: 'left',
  width: '423px',
  [theme.breakpoints.down('sm')]: {
    color: theme.palette.webView.text,
    marginBottom: '.3rem',
    fontSize: '.75rem',
    width: '100%',
  },
}));

export const HelperText = styled(FormHelperText)(({ theme, error }) => ({
  fontSize: '0.6875rem',
  marginBottom: '1.4rem',
  marginLeft: '0',
  '&, &.Mui-error': {
    color: error ? theme.palette.webView.error : '',
  },
}));
