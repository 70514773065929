import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMetaSEO } from '../../hooks';

const SEO = ({ getDataSeo }) => {
  const [seo, setSeo] = useState({
    metaTitle: '',
    metaDescription: '',
    keywords: '',
    metaRobots: '',
    structuredData: {},
  });
  const { landingId: landingTypeId } = useParams();

  let landingId;
  useEffect(() => {
    landingId = landingTypeId;
  }, [landingTypeId]);
  const navigate = useNavigate();
  useEffect(() => {
    getDataSeo(landingId).then(responseDataSeoLanding => {
      setSeo(prev => {
        return {
          ...prev,
          ...responseDataSeoLanding,
        };
      });
    }).catch(() => navigate('/*'));
  }, []);

  useMetaSEO(seo);

  return null;
};

export default SEO;
