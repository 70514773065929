import { typesTarget, TYPES_BUTTONS, TYPES_COMPONENTS } from '../../Common';
import { ButtonLink, ButtonPrimaryGeneral } from './styles';

const ButtonPrimary = ({
  styles,
  children,
  typeButton,
  path,
  variant,
  handleToggle,
  disabled,
  color,
  params,
  props,
}) => {
  if (typeButton === TYPES_BUTTONS.button) {
    return (
      <ButtonPrimaryGeneral
        onClick={handleToggle}
        variant={variant}
        color={color}
        sx={styles}
        disabled={disabled}
        {...props}
      >
        { children }
      </ButtonPrimaryGeneral>
    );
  }
  if (typeButton === TYPES_BUTTONS.buttonWithParams) {
    return (
      <ButtonPrimaryGeneral
        onClick={(e) => handleToggle(e, params)}
        variant={variant}
        color={color}
        sx={styles}
        disabled={disabled}
        {...props}
      >
        { children }
      </ButtonPrimaryGeneral>
    );
  }
  if (typeButton === TYPES_BUTTONS.link) {
    return (
      <ButtonPrimaryGeneral disableRipple={true} {...props} sx={styles}>
        <ButtonLink
          component={TYPES_COMPONENTS.link}
          target={typesTarget.TYPE_TARGET_BLANK}
          href={path}
        >
          { children }
        </ButtonLink>
      </ButtonPrimaryGeneral>
    );
  } else return null;
};

export default ButtonPrimary;

ButtonPrimary.defaultProps = {
  typeButton: 'button',
};
