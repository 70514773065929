import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { DataLading } from '../../api';
import { downloads } from '../../Common/messages';
import DownloadApp from '../DownloadApp/DownloadApp';
import {
  backgroundCoupon,
  ContainerBannerCoupon,
  NameCouponAndImageCoupon,
  TextAndImagesDownLoad,
  TextFooterCoupon,
  TextNameCoupon,
  Title,
  styleAppStore,
  styleAppPlayStore,
  directionContainerBannerCoupon,
} from './styles';

const BannerCoupon = () => {
  const [dataCoupon, setDataCoupon] = useState({
    title: '',
    nameCoupon: '',
    imageCoupon: '',
    footerCoupon: '',
    imageDownloadApp: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
  });

  const { getSaveTime } = DataLading;
  useEffect(() => {
    const dataResponseCoupon = {
      title: 'Te regalamos **$40** en tu primer servicio',
      nameCoupon: 'Cupón: **HOME40**',
      imageCoupon: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Frame_602_532ab54643.png',
      footerCoupon: '*Aplican Términos y Condiciones. Mínimo de compra $400. Solo primeros usuarios.',
    };
    setDataCoupon(prevDataCoupon => ({
      ...prevDataCoupon,
      ...dataResponseCoupon,
    }));
  }, []);

  useEffect(() => {
    getSaveTime().then(dataResponseLanding => {
      setDataCoupon(prevDataCoupon => ({
        ...prevDataCoupon,
        imageDownloadApp: { ...dataResponseLanding.imageDownloadApp },
      }));
    });
  }, []);

  const columnDesktop = 6;
  const columnPhone = 6;

  const { imageDownloadApp } = dataCoupon;

  return (
    <ContainerBannerCoupon direction={directionContainerBannerCoupon} container spacing={2}>
      <TextAndImagesDownLoad
        item
        md={columnDesktop}
        xs={columnPhone}
      >
        <Title>
          <ReactMarkdown>
            {dataCoupon.title}
          </ReactMarkdown>
        </Title>
        <DownloadApp
          style={{ styleAppStore, styleAppPlayStore }}
          appStore={imageDownloadApp.imageIOS}
          playStore={imageDownloadApp.imageAndroid}
          downloads={downloads}
        />
      </TextAndImagesDownLoad>
      <NameCouponAndImageCoupon
        item
        md={columnDesktop}
        xs={columnPhone}
        sx={backgroundCoupon(dataCoupon.imageCoupon)}
      >
        <TextNameCoupon>
          <ReactMarkdown>
            {dataCoupon.nameCoupon}
          </ReactMarkdown>
        </TextNameCoupon>
        <TextFooterCoupon>
          <ReactMarkdown>
            {dataCoupon.footerCoupon}
          </ReactMarkdown>
        </TextFooterCoupon>
      </NameCouponAndImageCoupon>
    </ContainerBannerCoupon>
  );
};

export default BannerCoupon;
