import {
  styled,
  Box,
  Card,
  CardMedia,
  Chip,
  CardContent,
  Typography,
  Button,
  Skeleton,
} from '@mui/material';

export const bgImage = (SaveTimeImageDesktop, SaveTimeImageMobile) => {
  return {
    display: 'flex',
    justifyContent: {
      xs: 'center',
      md: 'center',
    },
    alignItems: {
      xs: 'end',
      md: 'end',
    },
    width: '100%',
    height: {
      xs: '970px', sm: '970px', md: '100%', lg: '100%',
    },
    minHeight: {
      xs: 'initial',
      md: '618px',
    },
    paddingBottom: {
      xs: '6rem',
      md: '3.25rem',
    },
    borderRadius: {
      xs: 'initial',
      md: '40px',
    },
    position: {
      xs: 'relative', sm: 'relative', md: 'static', lg: 'static',
    },
    top: { xs: '69px', sm: '69px' },
    overflow: 'hidden',
    backgroundImage: {
      xs: `linear-gradient(0deg, rgba(32, 32, 34, 0.05), rgba(32, 32, 34, 0.05)), linear-gradient(0deg, rgba(68, 51, 224, 0.1), rgba(68, 51, 224, 0.1)), url(${SaveTimeImageMobile})`,
      sm: `linear-gradient(0deg, rgba(32, 32, 34, 0.05), rgba(32, 32, 34, 0.05)), linear-gradient(0deg, rgba(68, 51, 224, 0.1), rgba(68, 51, 224, 0.1)),   url(${SaveTimeImageMobile})`,
      md: `linear-gradient(0deg, rgba(32, 32, 34, 0.25), rgba(32, 32, 34, 0.25)), linear-gradient(0deg, rgba(68, 51, 224, 0.1), rgba(68, 51, 224, 0.1)), url(${SaveTimeImageDesktop})`,
      lg: `linear-gradient(0deg, rgba(32, 32, 34, 0.25), rgba(32, 32, 34, 0.25)), linear-gradient(0deg, rgba(68, 51, 224, 0.1), rgba(68, 51, 224, 0.1)), url(${SaveTimeImageDesktop})`,
    },
    backgroundSize: {
      xs: '100% 96%',
      sm: '100% 96%',
      md: '100%',
      lg: '100%',
    },
    backgroundPosition: {
      xs: 'center -8px',
      sm: 'center -8px',
      md: 'center center',
      lg: 'center center',
    },
    backgroundRepeat: 'no-repeat',
  };
};

export const ContentTexts = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto)',
  gridTemplateRows: 'repeat(7, auto)',
  placeItems: 'center',
  width: '668px',
}));

export const contentCaption = {
  display: 'flex',
  flexBasis: {
    xs: '100%', sm: '100%', md: '50%', lg: '50%',
  },
  justifyContent: {
    xs: 'start', phoneMd: 'start', sm: 'start', md: 'center', lg: 'center',
  },
  alignItems: {
    xs: 'flex-end', phoneMd: 'flex-end', sm: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end',
  },
  overflow: 'hidden',
  '& > div': {
    display: {
      xs: 'flex', sm: 'flex', md: 'block', lg: 'block',
    },
    flexDirection: { xs: 'column', md: 'column' },
    alignItems: { xs: 'center', md: 'center' },
    flexBasis: {
      xs: '100%', sm: '100%', md: '73%', lg: '73%',
    },
    height: {
      xs: '53%', phoneMd: '53%', sm: '62%', md: '50%',
    },
    marginBottom: {
      xs: '0', sm: '0', md: '103px', laptop: '100px', lg: '60px',
    },
  },
};

export const subTitle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gridColumn: '1 / 5',
  gridRow: '1 / 2',
  marginBottom: '1rem',
  '& > span': {
    display: 'block',
    width: '200px',
    fontWeight: '800',
    fontSize: '1.25rem',
    lineHeight: '1.7075rem',
    backgroundColor: 'rgba(120, 114, 242, 0.7)',
    borderRadius: '8px',
    padding: '0.25rem 0.5rem 0.25rem 0.375rem',
    textAlign: 'center',
  },
};

export const headingOne = {
  width: '83.7%',
  marginBottom: '1.5rem',
  gridColumn: '1 / 5',
  gridRow: '2 / 3',
  fontSize: {
    xs: '2.5rem', sm: '2.5rem', md: '3.125rem', lg: '3.125rem',
  },
  fontWeight: '900',
  lineHeight: {
    xs: '2.5rem', sm: '2.5rem', md: '3.125rem', lg: '3.125rem',
  },
  textAlign: 'center',
};

export const headingTwo = {
  gridColumn: '1 / 5',
  gridRow: '4 / 5',
  width: {
    xs: '100%', sm: '100%', md: '100%', lg: '100%',
  },
  maxWidth: {
    xs: '275px', sm: '275px', md: '540px', lg: '540px',
  },
  marginTop: {
    xs: '1.5rem',
    md: '2.5rem',
  },
  marginBottom: '1rem',
  textAlign: 'center',
  fontSize: {
    xs: '1.375rem', sm: '1.375rem', md: '2rem', lg: '2rem',
  },
  lineHeight: {
    xs: '2.5rem', sm: '2.5rem', md: '2.5rem', lg: '2.5rem',
  },
};

export const contentImageCaption = {
  display: {
    xs: 'none', sm: 'none', md: 'flex', lg: 'flex',
  },
  flexBasis: '50%',
  justifyContent: 'end',
  '& img': {
    objectFit: 'cover',
    width: '83%',
  },
};

export const contentIconApps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gridColumn: '1 / 5',
  gridRow: '5 / 6',
  maxWidth: {
    xs: '292px', sm: '292px', md: 'initial', lg: 'initial',
  },
  width: '100%',
  margin: {
    xs: '0 auto', ms: '0 auto', md: '0', lg: '0',
  },
};

export const styleAppStore = {
  width: {
    xs: '9.25rem', sm: '9.25rem', md: '10.375rem', lg: '10.375rem',
  },
  marginRight: {
    xs: '0px', sm: '0px', md: '16px', lg: '16px',
  },
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%', sm: '88%', md: '100%', lg: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: {
    xs: '9.25rem', sm: '9.25rem', md: '10.375rem', lg: '10.375rem',
  },
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%', sm: '88%', md: '100%', lg: '100%',
    },
  },
};

/* Service Card */

export const ServiceCardStyles = styled(Card)(({ theme }) => ({
  width: '290px',
  height: '362px',
  background: theme.palette.secondary.main,
  boxShadow: '20px 30px 100px rgba(0, 0, 0, 0.1)',
  borderRadius: '30px',
  position: 'relative',
  zIndex: '-1',
}));

export const serviceCardStyles = {
  maxWidth: 345,
  pointerEvents: 'none',
};

export const ImageContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '138px',
}));

export const CardBage = styled(Chip)(() => ({
  padding: '0.25rem 0.5rem',
  width: 'auto',
  height: '24px',
  borderRadius: '15px',
  fontWeight: '900',
  fontSize: '0.75rem',
  lineHeight: '16px',
}));

export const imageBadge = {
  position: 'absolute',
  top: '16px',
  left: '16px',
  backgroundColor: '#FFE3B4',
  color: '#FF8300',
};

export const spanReset = {
  '& span': {
    textOverflow: 'initial',
    paddingLeft: '0',
    paddingRight: '0',
  },
};

export const ImageCard = styled(CardMedia)(() => ({
  width: '100%',
  height: '100%',
}));

export const CardServicesContent = styled(CardContent)(() => ({
  padding: '0.98125rem 0.75rem 1.75rem 1rem',
}));

export const Title = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: theme.palette.text.secondary,
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.6875rem',
  marginBottom: '0.3125rem',
  '& > span:first-of-type': {
    height: '27px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },
}));

export const priceBadgeStyles = {
  backgroundColor: 'primary.main',
  color: 'secondary.main',
};

export const SubTitle = styled(Typography)(() => ({
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  color: 'rgba(32, 32, 34, 0.8)',
  WebkitLineClamp: '2',
  marginBottom: '1rem',
  height: '40px',
}));

export const ServicesBadgeContainer = styled(Box)(() => ({
  width: '110%',
  WebkitLineClamp: '1',
  marginBottom: '1rem',
}));

export const serviceBadgeStyles = {
  backgroundColor: 'rgba(218, 218, 218, 0.5)',
  color: 'text.authorBlogPost',
  marginBottom: '0.25rem',
  '&:not(&:last-of-type)': {
    marginRight: '0.25rem',
  },
};

export const LocationsBadgeContainer = styled(Box)(() => ({
  width: '100%',
  WebkitLineClamp: '2',
}));

export const loactionBadgeStyles = {
  backgroundColor: 'rgba(68, 51, 224, 0.1)',
  color: 'primary.main',
  marginBottom: '0.25rem',
  '&:not(&:last-of-type)': {
    marginRight: '0.25rem',
  },
};

export const hiddenText = {
  display: '-webkit-box !important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
};

export const nameContainerBadge = {
  width: '100%',
};

export const badgeOne = {
  width: '100%',
};

export const badgeTwo = {
  width: '49%',
};

export const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: '100%',
  transform: 'scale(1, 1)',
  borderRadius: '0',
  backgroundColor: theme.palette.primary.main,
}));

export const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '80%',
  backgroundColor: theme.palette.primary.main,
}));

export const AboutSkeleton = styled(Skeleton)(() => ({
  width: '60%',
  height: '100%',
}));

export const CompaniesSkeleton = styled(Skeleton)(() => ({
  width: '90%',
}));

export const StatesSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
}));

/* Carousel Provider */

export const ProviderCard = styled(Button)(({ theme }) => ({
  fontWeight: '900',
  fontSize: '0.875rem',
  lineHeight: '1.195rem',
  color: theme.palette.text.secondary,
  backgroundColor: 'rgba(196, 196, 196, 0.5)',
  borderRadius: '15px',
  padding: '0.875rem 1rem',
  textTransform: 'initial',
  '&:hover': {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
  },
}));
