import {
  Box, Container, Typography,
} from '@mui/material';
import { variantTypography } from '../../Common';
import { headerStyle } from './CardStyles';

const HeaderCard = ({ title, children }) => {
  return (
    <Box>
      <Container sx={headerStyle}>
        { title && <Typography variant={variantTypography.h3}>{title}</Typography> }
        <Box>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderCard;
