import { useContext, useEffect } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Loader, Product } from '../../Components';
import { ServicePreOrder } from '../../Common/messages';
import { loaderContainer, ProductsContainer } from './styles';
import { ServicePreOrderProvider, ServiceProductContext } from '../../Contexts';
import {
  getCompanyId,
  getProductShowCase,
  setConfigurationData,
  setDateData,
} from '../../store/preOrder/action';
import { DataPreOrderProudct } from '../../api';
import { PreOrderProductDetail } from '../../utils';
import { FORMAT_DATE_CALENDAR, FORMAT_SCHEDULE } from '../../Common';
import dummyImage from '../../../assets/img/okboy_default.png';

const {
  dateTitles,
} = ServicePreOrder;

const {
  // eslint-disable-next-line no-unused-vars
  getCompanyDetail,
} = DataPreOrderProudct;

const {
  scheduleFormatView,
} = PreOrderProductDetail;

const {
  twelveHours,
} = FORMAT_SCHEDULE;

const {
  dayMonthYear,
} = FORMAT_DATE_CALENDAR;

const ProductLayout = () => {
  const { state: configurationData } = useLocation();
  const [preOrderState, dispatch] = useContext(ServicePreOrderProvider);
  const [productServiceState] = useContext(ServiceProductContext);

  const product = productServiceState?.productDetail;
  useEffect(() => {
    dispatch(setConfigurationData(configurationData));

    const productShowCase = productServiceState.showCaseProduct.find(
      showCase => showCase.registryUuid === productServiceState.showCaseProductId,
    );
    // eslint-disable-next-line array-callback-return
    const scheduleData = productServiceState?.companieSchedules?.find(schedule => {
      const start = scheduleFormatView(schedule?.start);
      const end = scheduleFormatView(schedule?.end);
      return `${start} - ${end}` === preOrderState?.configurationData?.date?.schedule;
    });

    const scheduleEnd = scheduleFormatView(scheduleData?.end);
    const scheduleEndFormatted = moment(scheduleEnd, twelveHours).format(twelveHours);
    const dateFormatted = moment(preOrderState?.configurationData?.date?.date).format(dayMonthYear);

    dispatch(getProductShowCase(productShowCase));
    dispatch(setDateData({
      dateView: `${dateFormatted} ${scheduleEndFormatted}${scheduleData?.meridian}`,
      defaultDate: scheduleData?.end,
    }));
    getCompanyDetail(productServiceState?.companieId).then(({ data: companyDetailResponse }) => {
      dispatch(getCompanyId(companyDetailResponse));
    });
  }, [preOrderState?.company?.id]);

  const companyData = preOrderState?.company;
  const scoreData = companyData?.score;
  // eslint-disable-next-line radix
  const rating = scoreData?.score ? parseInt(scoreData?.score) : null;
  const calification = scoreData?.count ? `${scoreData?.score} (${scoreData?.count} calificaciones)` : '';

  const showCaseProductData = productServiceState?.showCaseProductId;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const unitsPriceTotal = showCaseProductData?.unitPrice * preOrderState?.configurationData?.units;

  return (
    <ProductsContainer sx={!preOrderState?.company?.id ? loaderContainer : undefined}>
      {
        !preOrderState?.company?.id ? (
          <Loader />
        ) : (
          <Product
            key={product?.id}
            image={product?.icon || dummyImage}
            serviceTitle={product.name}
            price={showCaseProductData?.unitPrice}
            avatar={companyData?.logoUrl}
            nameProvider={companyData?.name}
            rating={rating}
            califications={calification}
            productTitle={showCaseProductData?.name || null}
            units={preOrderState?.configurationData?.units}
            unitsPriceTotal={unitsPriceTotal}
            complements={showCaseProductData?.complements}
            dateTitle={dateTitles?.delivery}
            date={preOrderState?.date?.dateView}
          />
        )
      }
    </ProductsContainer>
  );
};

export default ProductLayout;
