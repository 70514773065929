import { Box } from '@mui/material';
import { TYPES_CARDS } from '../../Common';
import CardItem from '../../Components/CardCarousel/CardItem';
import { contentCardQA, contentText, styleCardQA } from './LayoutQAStyles';

const LayoutQA = ({ textsQa }) => {
  return (
    <>
      <Box sx={contentCardQA('none', 'flex')}>
        {textsQa.map((textCard) => (
          <CardItem
            key={Math.random()}
            cardStyle={styleCardQA}
            contentTexts={contentText}
            title={textCard.title}
            textContent={textCard.textContent}
            typeCard={TYPES_CARDS.imageAndTextOrText}
          />
        ))}
      </Box>
      <Box sx={contentCardQA('flex', 'none')}>
        {textsQa.map((textCard) => (
          <CardItem
            key={Math.random()}
            cardStyle={styleCardQA}
            contentTexts={contentText}
            title={textCard.title}
            textContent={textCard.textContent}
            typeCard={TYPES_CARDS.imageAndTextOrText}
          />
        ))}
      </Box>
    </>
  );
};

export default LayoutQA;
