import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './styles';

const Loader = ({ styles, progressSize }) => {
  return (
    <LoaderContainer sx={styles}>
      <CircularProgress size={progressSize || '3rem'} />
    </LoaderContainer>
  );
};

export default Loader;
