export const layoutFooterStyle = {
  height: 'auto',
  backgroundColor: 'rgb(38, 34, 66)',
  marginTop: {
    xs: '107px',
    phoneMd: '107px',
    sm: '107px',
    md: '0px',
    lg: '0px',
    xl: '0px',
  },
};

export const footerHeightBlog = {
  height: {
    xs: 'auto',
    phoneMd: 'auto',
    md: '508px',
    lg: '508px',
  },
  marginTop: '0px',
};

export const footerServiceGas = {
  marginBottom: {
    xs: '11rem',
    md: '11rem',
  },
};

export const containerFooter = {
  width: '100%',
  height: '100%',
  maxWidth: '1188px !important',
  padding: {
    xs: '0rem 1rem !important',
    phoneMd: '0rem 1rem !important',
    sm: '0rem 1rem !important',
    md: '0rem !important',
    lg: '0rem !important',
    xl: '0rem !important',
  },
};
