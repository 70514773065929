import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Box } from '@mui/material';
import { typesColors, TYPES_COMPONENTS, variantTypography } from '../../Common';
import { Captions } from '../../Components';
import {
  WrapperInformationOrder,
  captionStepper,
  styledOrderInformationTitle,
  SectionInformationOrderDate,
  SectionInformationOrder,
  AmountLabel,
  AmountLabelValue,
  Anchor,
  quantityStyles,
  marginTopStyles,
  styledTotal,
} from './styles';
import { ServiceTraking } from '../../Common/messages';
import { ServiceTracking } from '../../utils';
import MapMarkerOutline from '../../../assets/icons/MapMarkerOutline';

const {
  pricesMessage: { currency, totalText },
  messagesTracking: { serviceInformation, priceDefault },
} = ServiceTraking;

const {
  formatMoney,
  FormatCurrency,
} = ServiceTracking;

const LayoutInformationOrder = ({ orderInformationData }) => {
  const { dataInformationOrder } = orderInformationData;
  const detailData = dataInformationOrder.details[0] || {};

  const getBillingLink = (serviceFolio) => {
    return `https://www.okboy.app/service-billing/${serviceFolio}`;
  };

  const redirectBillingLink = (e) => {
    e.preventDefault();
    const billingUrl = dataInformationOrder.dataTracking.company?.billingUrl
      || getBillingLink(dataInformationOrder.dataTracking.folio);
    const billingLink = billingUrl;
    // eslint-disable-next-line no-undef
    window.open(billingLink, '_blank');
  };

  return (
    <WrapperInformationOrder>
      <Captions
        color={typesColors.textSecondary}
        variant={variantTypography.h2}
        style={{ ...captionStepper, ...styledOrderInformationTitle }}
      >
        {`${dataInformationOrder?.title}`}
        <span>{dataInformationOrder?.folio}</span>
      </Captions>
      <SectionInformationOrder sx={{ marginBottom: '0.5rem' }}>
        <AmountLabel>
          {`${serviceInformation.billingUrl}`}
          <Anchor href onClick={redirectBillingLink}>Facturación de pedido</Anchor>
        </AmountLabel>
      </SectionInformationOrder>
      <SectionInformationOrderDate>
        <Box component={TYPES_COMPONENTS.span}>
          {serviceInformation.date}
          <strong>{dataInformationOrder?.deliveryDate}</strong>
        </Box>
        <Box component={TYPES_COMPONENTS.span}>
          {serviceInformation.time}
          <strong>{`${dataInformationOrder?.deliveryTime} hrs`}</strong>
        </Box>
      </SectionInformationOrderDate>
      <SectionInformationOrderDate style={{ gap: '0.4rem' }}>
        <MapMarkerOutline width='17' />
        <Box component={TYPES_COMPONENTS.span} style={{ marginTop: '3px' }}>
          <strong>{dataInformationOrder.address}</strong>
        </Box>
      </SectionInformationOrderDate>
      <SectionInformationOrder sx={quantityStyles}>
        <AmountLabel sx={{ display: 'inline-flex', gap: '0.47rem' }}>
          <ShoppingBagOutlinedIcon fontSize='1rem' />
          {detailData?.category?.name || detailData?.serviceType?.description}
        </AmountLabel>
        <AmountLabelValue>
          {`${currency.pesos}${FormatCurrency(detailData.total, 100)}`}
        </AmountLabelValue>
        {
          detailData?.units ? (
            <AmountLabelValue sx={{ display: 'inline-block', paddingLeft: '1.4rem' }}>
              {`${serviceInformation.quantity} ${(Number(detailData?.units ?? '0') * (detailData?.isCurrency ? formatMoney(detailData?.unitPrice) : 1)).toFixed(2)} ${(detailData?.showcase?.measurementUnit?.description ?? 'u')}`}
            </AmountLabelValue>
          ) : null
        }
      </SectionInformationOrder>
      <SectionInformationOrder sx={{ marginTop: '1rem' }}>
        <AmountLabel>
          {serviceInformation.paymentMethod}
        </AmountLabel>
        <AmountLabelValue>
          {dataInformationOrder.paymentTypeText}
        </AmountLabelValue>
      </SectionInformationOrder>
      {detailData.unitPrice && detailData.units ? (
        <SectionInformationOrder sx={marginTopStyles}>
          <AmountLabel>
            {serviceInformation.amountSub}
          </AmountLabel>
          <AmountLabelValue>
            {`${currency.pesos}${FormatCurrency(dataInformationOrder.amount, 100)}`}
          </AmountLabelValue>
        </SectionInformationOrder>
      ) : null}
      {
        dataInformationOrder.serviceFee && dataInformationOrder.serviceFee !== 0 ? (
          <SectionInformationOrder sx={marginTopStyles}>
            <AmountLabel>
              {serviceInformation.serviceFee}
            </AmountLabel>
            <AmountLabelValue>
              {`${currency.pesos}${dataInformationOrder.serviceFee ? FormatCurrency(formatMoney(dataInformationOrder.serviceFee)) : priceDefault}`}
            </AmountLabelValue>
          </SectionInformationOrder>
        ) : null
      }
      {
        dataInformationOrder.discount && dataInformationOrder.discount !== 0 && dataInformationOrder.discount !== '0' ? (
          <SectionInformationOrder sx={marginTopStyles}>
            <AmountLabel>
              {serviceInformation.discount}
            </AmountLabel>
            <AmountLabelValue>
              {`${currency.pesos}${FormatCurrency(formatMoney(dataInformationOrder.discount))}`}
            </AmountLabelValue>
          </SectionInformationOrder>
        ) : null
      }
      {
        dataInformationOrder.tip && dataInformationOrder.tip !== 0 ? (
          <SectionInformationOrder sx={marginTopStyles}>
            <AmountLabel>
              {serviceInformation.tip}
            </AmountLabel>
            <AmountLabelValue>
              {`${currency.pesos}${FormatCurrency(formatMoney(dataInformationOrder.tip))}`}
            </AmountLabelValue>
          </SectionInformationOrder>
        ) : null
      }
      {detailData.unitPrice && detailData.units ? (
        <SectionInformationOrder sx={{ ...marginTopStyles, ...styledTotal }}>
          <AmountLabel>
            {totalText}
          </AmountLabel>
          <AmountLabelValue>
            {
              `${currency.pesos}${FormatCurrency((dataInformationOrder.total / 100))
              }`
            }
          </AmountLabelValue>
        </SectionInformationOrder>
      ) : null}
    </WrapperInformationOrder>
  );
};

export default LayoutInformationOrder;
