export const AlertStyleCustom = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'primary.main',
};

export const titleStyle = {
  fontSize: {
    xs: '0.9375rem',
    sm: '0.9375rem',
    md: '1.125rem',
    lg: '1.125rem',
  },
  fontWeight: '800',
  flexBasis: {
    xs: '41%',
    sm: '41%',
    md: '80%',
    lg: '80%',
  },
};

export const contentButtons = {
  display: 'flex',
  flexBasis: '16.3%',
  height: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  lineHeight: 'initial',
  '& a': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    padding: {
      xs: '4px 16px',
      sm: '4px 16px',
      md: '8px 20px 8px 20px',
      lg: '8px 20px 8px 20px',
    },
    width: {
      xs: '108px', sm: '108px', md: '146px', lg: '146px',
    },
    color: 'primary.main',
    fontSize: {
      xs: '1rem', sm: '1rem', md: '1.375rem', lg: '1.375rem',
    },
    fontWeight: '900',
    border: {
      xs: '1px solid',
      sm: '1px solid',
      md: '2px solid',
      lg: '2px solid',
    },
    borderColor: 'primary.main',
    borderRadius: '15px',
  },
  '& button': {
    position: 'relative',
    right: '-10px',
    height: '42px',
    width: '42px',
    backgroundColor: 'background.primary',
    color: 'primary.main',
    zIndex: '9',
  },
};

export const hiddenAlert = {
  opacity: '0',
  transtion: 'all .5s ease',
};

export const alertStyle = {
  width: '100%',
  backgroundColor: 'primary.main',
  color: 'secondary.main',
  fontSize: {
    xs: '0.82rem',
    md: '0.875rem',
  },
  '& .MuiSvgIcon-root': {
    fill: 'secondary.main',
  },
};
