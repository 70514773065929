import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import moment from 'moment';
import {
  ButtonPrimary,
  ButtonSelect,
  Captions,
  CounterButton,
  DateStatic,
  Loader,
  LocationInput,
  ProductConfigurationRadio,
  ProductConfigurationSelect,
  ProductPrices,
} from '../../../Components';
import {
  CaptionAndButtonContainer,
  CloseButton,
  containerButtonselectorsStyles,
  customLocationButtonText,
  customSelect,
  iconArrowStyle,
  labelButtonSelectStyle,
  loaderContainer,
  LocationButtonText,
  modalDateCustom,
  ModalProduct,
  OrderBox,
  orderConfigurationTitle,
  OrderContainer,
  productModalHeightCustom,
  saveButtonStyles,
  scheduleContainer,
  scheduleContinerCustom,
  sendButtonStyles,
  showModalBoxModal,
  showModalContainer,
  subTotalStyles,
  textButtonSelectStyle,
  TextWithoutSchedule,
  totalStyles,
} from './styles';
import { pathViews, ServiceProduct } from '../../../Common/messages';
import {
  captionModalButton,
  ContainerButtonSelect,
  modalTranslate,
} from '../../ServicesSearchLayout/styles';
import { PricesContainer } from '../styles';
import { useBreakpoint } from '../../../hooks';
import {
  breakPoints,
  variantTypography,
  TYPES_HANDLE,
  TYPES_BUTTONS,
  FORMAT_DATE_CALENDAR,
  scheduleFormat,
  typesColors,
  LOCALSTORAGE_NAME_TYPES,
} from '../../../Common';
import ModalsMobileLayout from '../../ServicesSearchLayout/ModalsMobileLayout';
import { ProductConfiguration, ServiceSearchUtils } from '../../../utils';
import { LoaderContext, ServiceProductContext } from '../../../Contexts';
import {
  getCompanieProductsData,
  getCompanieSchedules,
  getCompaniesData,
  getLocation,
  getShowcaseProduct,
  selectDate,
  selectProduct,
  selectProvider,
  selectSchedule,
  setCompanieId,
  setShowcaseProductId,
} from '../../../store/serviceProduct/action';
import { DataPreOrderProudct } from '../../../api';
import { tagetValues } from '../../../Common/messages/ServicesSearch';
import { getCompanieProducts, getCompanieSchedule } from '../../../api/pre-order-product-detail';

const {
  orderConfigurationData: {
    prices,
    title,
    locationButton,
    product,
    productLength,
    providerButton,
    continueButton,
  },
  modalTextButton,
  modalsNames,
  radioNameValues,
  withoutScheduleMessage,
  localStorageCounter,
} = ServiceProduct;

const {
  providerSelect,
} = TYPES_HANDLE;

const {
  handleModal,
  iconRotate,
} = ServiceSearchUtils;

const {
  dayMonthYear,
  yearMonthDay,
} = FORMAT_DATE_CALENDAR;

const {
  setLocation,
} = ProductConfiguration;

const {
  getCompanies,
} = DataPreOrderProudct;

const {
  serviceTypeData,
} = LOCALSTORAGE_NAME_TYPES;

const OrderConfigurationLayout = ({ showValue, handleNavbarShow }) => {
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const [productServiceState, dispatch] = useContext(ServiceProductContext);
  const [loading, setLoading] = useContext(LoaderContext);
  const [modalsData, setModalsData] = useState({
    productModal: false,
    providerModal: false,
    dateModal: false,
    product: productServiceState.product,
    provider: productServiceState.provider,
    date: productServiceState.dateData?.date,
    schedule: productServiceState.dateData?.schedule,
    companieId: '',
  });
  const [unmounted, setUnmounted] = useState(false);
  const [locationToggle, setLocationToggle] = useState(true);
  const unitOne = 1;
  const [count, setCount] = useState(
    // eslint-disable-next-line no-undef
    JSON.parse(localStorage.getItem(localStorageCounter)) || unitOne,
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  const { state: data } = useLocation();
  const navigate = useNavigate();

  const { dateData: dateDateState } = productServiceState;

  useEffect(() => {
    setUnmounted(true);
    return () => {
      if (unmounted) {
        dispatch(selectProvider(''));
        dispatch(selectProduct(''));
        dispatch(selectDate(''));
        dispatch(selectSchedule(''));
        dispatch(getLocation({}));
        setCount(unitOne);
        // eslint-disable-next-line no-undef
        sessionStorage.setItem(localStorageCounter, JSON.stringify(unitOne));
      }
    };
  }, [unmounted]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    sessionStorage.setItem(localStorageCounter, JSON.stringify(count));
  }, [count]);

  useEffect(() => {
    setModalsData(preModalsData => ({
      ...preModalsData,
      product: productServiceState.product,
      provider: productServiceState.provider,
      date: productServiceState.dateData?.date,
      schedule: productServiceState.dateData?.schedule,
    }));
  }, [
    productServiceState.product,
    productServiceState.provider,
    dateDateState?.date,
    dateDateState?.schedule,
  ]);

  const providerArrow = useRef(null);
  const productArrow = useRef(null);
  const dateArrow = useRef(null);
  useEffect(() => {
    if (toggleBreakpoint) {
      iconRotate(modalsData.providerModal, providerArrow.current);
      if (productArrow.current) {
        iconRotate(modalsData.productModal, productArrow.current);
      }
    }
    if (dateArrow.current) {
      iconRotate(modalsData.dateModal, dateArrow?.current);
    }
  }, [modalsData.providerModal, modalsData.productModal, modalsData.dateModal, toggleBreakpoint]);

  const inputRef = useRef(null);
  useEffect(() => {
    if (!locationToggle) {
      const inputElement = inputRef.current.childNodes[0] || {};
      inputElement.select();
    }
  }, [locationToggle]);

  // eslint-disable-next-line no-undef
  const serviceType = JSON.parse(sessionStorage.getItem(serviceTypeData));
  const serviceTypeValue = data.serviceType || serviceType?.registryUuid;
  useEffect(() => {
    getCompanies(serviceTypeValue).then(({ data: companiesResponse }) => {
      dispatch(getCompaniesData(companiesResponse));
    });
  }, []);

  useEffect(() => {
    if (productServiceState.companieId) {
      setLoading(true);
      getCompanieProducts(serviceTypeValue, productServiceState.companieId)
        .then(({ data: companieProductResponse }) => {
          const productShowcase = [];
          if (companieProductResponse.categories?.length) {
            companieProductResponse.categories.forEach(productData => {
              if (Array.isArray(productData.productShowCase)) {
                productData.productShowCase.forEach((productShowCaseData) => {
                  productShowcase.push({ ...productShowCaseData });
                });
              }
            });
            dispatch(getShowcaseProduct(productShowcase));
            return dispatch(getCompanieProductsData(companieProductResponse));
          }
          companieProductResponse.forEach(productData => {
            if (Array.isArray(productData.productShowCase)) {
              productData.productShowCase.forEach((productShowCaseData) => {
                productShowcase.push({ ...productShowCaseData });
              });
            }
          });
          dispatch(getShowcaseProduct(productShowcase));
          return dispatch(getCompanieProductsData(companieProductResponse));
        }).finally(() => setLoading(false));

      let day = moment().format(yearMonthDay);
      if (!modalsData.schedule) {
        dispatch(selectDate(''));
        dispatch(selectSchedule(''));
      }
      if (modalsData.date) {
        day = moment(modalsData.date).format(yearMonthDay);
      }
      getCompanieSchedule(productServiceState.companieId)
        .then(({ data: companieScheduleResponse }) => {
          const schedules = [];
          companieScheduleResponse.filter(schedule => {
            return schedule.date === day;
          })
            .forEach((date) => {
              schedules.push(date.schedules);
            });
          const totalSchedule = [];
          schedules.forEach((schedule) => {
            schedule.forEach((current, index) => {
              let start = schedule[index].start.split(':');
              start = start.slice(scheduleFormat.hours, scheduleFormat.minutes).join(':');
              let end = schedule[index].end.split(':');
              end = end.slice(scheduleFormat.hours, scheduleFormat.minutes).join(':');
              totalSchedule.push({
                ...schedule[index],
                name: `${start} - ${end}`,
                id: Math.random(),
              });
            });
          });
          dispatch(getCompanieSchedules(totalSchedule));
        }).catch(() => dispatch(getCompanieSchedules([])));
    }
  }, [productServiceState.companieId, modalsData.date]);

  const openModalHandle = (e, nameModal, openOrClose) => {
    handleModal(true, false, setModalsData, nameModal, openOrClose);
    handleNavbarShow();
  };

  const closeModalHandle = (e, nameModal, openOrClose) => {
    handleModal(true, false, setModalsData, nameModal, openOrClose);
    handleNavbarShow();
  };

  const radioHandleChange = (e) => {
    const { target } = e;
    setModalsData(prevModalsData => ({
      ...prevModalsData,
      [target.name]: target.value,
    }));
  };

  const setProductHandle = (e, nameModal, openOrClose) => {
    dispatch(selectProvider(modalsData.provider));
    dispatch(selectProduct(modalsData.product));
    dispatch(selectSchedule(modalsData.schedule));
    dispatch(selectDate(modalsData.date));
    handleModal(true, false, setModalsData, nameModal, openOrClose);
    handleNavbarShow();
  };

  const setShowcaseProductIdHandle = (e, showcaseProductData) => {
    const { target } = e;
    const showcaseProduct = showcaseProductData.find(
      companieItem => companieItem.name.trim() === target.value.trim(),
    );
    dispatch(setShowcaseProductId(showcaseProduct));
    dispatch(selectProduct(target.value));
    setModalsData(prevModalsData => ({
      ...prevModalsData,
      [target.name]: target.value,
    }));
  };

  const setProducthandleSelectors = (e, handleType) => {
    const { target } = e;
    if (handleType === providerSelect) {
      return dispatch(selectProvider(target.value));
    }
    return dispatch(selectProduct(target.value));
  };

  const setCompanieIdHandle = (e) => {
    const nameProperty = tagetValues.cards.description;
    const registryUuid = e.target.getAttribute(nameProperty);
    dispatch(setCompanieId(registryUuid));
    dispatch(selectProduct(''));
  };

  const setCompanieIdMobileHandle = (e, companiesData) => {
    const { target } = e;
    const companie = companiesData.find(
      companieItem => companieItem.name.trim() === target.value.trim(),
    );
    dispatch(setCompanieId(companie.registryUuid));
    setModalsData(prevModalsData => ({
      ...prevModalsData,
      [target.name]: target.value,
      product: '',
    }));
  };

  const handleDate = (dateValue) => {
    const { _d: day } = dateValue;
    setModalsData(prevModalsData => ({
      ...prevModalsData,
      date: day,
    }));
  };

  const locationHandleToggle = () => {
    setLocationToggle(!locationToggle);
  };

  const autocompleteHandle = (selected) => {
    if (!selected.formatted_address) return;
    const location = setLocation(selected.address_components);
    location.longitude = selected.geometry.location.lng();
    location.latitude = selected.geometry.location.lat();
    location.formatted_address = selected.formatted_address;
    dispatch(getLocation(location));
    setLocationToggle(!locationToggle);
  };

  const continueHandle = () => {
    const {
      servicesSearch: {
        app,
        chidren,
      },
    } = pathViews;
    const checkoutDataCart = {
      companyUuid: productServiceState?.companieId,
      showcaseUuid: productServiceState?.showCaseProductId?.registryUuid,
      serviceTypeUuid: data.serviceType,
      units: count,
      date: productServiceState?.dateData,
      location: productServiceState?.location,
    };
    navigate(`${app}${chidren.preOrder}`, {
      state: checkoutDataCart,
    });
  };

  const validDate = [modalsData?.schedule, modalsData?.date].every(item => !!item);
  let meridianData;
  if (validDate) {
    meridianData = productServiceState?.companieSchedules
      ?.find(schedule => schedule.name === modalsData?.schedule);
  }
  const valueDate = productServiceState?.companieSchedules.length && meridianData?.meridian ? `Entrega ${moment(modalsData.date).format(dayMonthYear)} ${modalsData?.schedule}${meridianData?.meridian || ''}` : productLength?.date;

  const items = 7;
  const validHeight = productServiceState.showCaseProduct.length > items
    ? { ...ModalProduct, ...modalTranslate, ...productModalHeightCustom }
    : { ...ModalProduct, ...modalTranslate };

  const isDisabled = [
    productServiceState.location?.formatted_address,
    modalsData.product,
    modalsData.provider,
    modalsData.date,
    modalsData.schedule,
    productServiceState.companieSchedules.length,
  ].every(modalData => !!modalData);

  const progressSize = '2rem';
  return (
    <OrderContainer sx={showValue ? showModalContainer : undefined}>
      <OrderBox sx={showValue ? showModalBoxModal : undefined}>
        <CaptionAndButtonContainer>
          <Captions
            variant={variantTypography.h2}
            style={orderConfigurationTitle}
          >
            { title }
          </Captions>

          <CloseButton
            onClick={() => handleNavbarShow()}
            aria-label='delete'
            color={typesColors.primary}
          >
            <CloseIcon />
          </CloseButton>
        </CaptionAndButtonContainer>

        <ContainerButtonSelect sx={containerButtonselectorsStyles}>
          <Captions
            variant={variantTypography.h5}
            style={{ ...captionModalButton, ...labelButtonSelectStyle }}
          >
            { locationButton?.label }
          </Captions>
          {
            locationToggle ? (
              <ButtonSelect
                styles={textButtonSelectStyle}
                handleClick={locationHandleToggle}
              >
                <LocationButtonText
                  sx={
                  !productServiceState.location?.formatted_address
                    ? customLocationButtonText
                    : undefined
                }
                >
                  {
                !productServiceState.location?.formatted_address
                  ? locationButton?.text
                  : productServiceState.location?.formatted_address
                }
                </LocationButtonText>
                <KeyboardArrowDownIcon />
              </ButtonSelect>
            ) : (
              <LocationInput
                inputRef={inputRef}
                locationHandleToggle={locationHandleToggle}
                autoCompleteHandle={autocompleteHandle}
                locationToggle={locationToggle}
              />
            )
          }
        </ContainerButtonSelect>

        <ContainerButtonSelect sx={containerButtonselectorsStyles}>
          <Captions
            variant={variantTypography.h5}
            style={{ ...captionModalButton, ...labelButtonSelectStyle }}
          >
            { providerButton?.label }
          </Captions>
          {
            toggleBreakpoint ? (
              <>
                <ButtonSelect
                  styles={textButtonSelectStyle}
                  handleClick={
                    (e) => openModalHandle(e, modalsNames.providerModal, modalsData.providerModal)
                  }
                >
                  {
                 !productServiceState.provider ? providerButton?.text : productServiceState.provider
              }
                  <KeyboardArrowDownIcon
                    sx={iconArrowStyle}
                    ref={(e) => { providerArrow.current = e; }}
                  />
                </ButtonSelect>
                <ModalsMobileLayout
                  title={providerButton.modalTitle}
                  openModal={modalsData.providerModal}
                  modalSelectStyle={
                  modalsData.providerModal
                    ? { ...ModalProduct, ...modalTranslate }
                    : ModalProduct
                }
                  handleClose={
                    (e) => closeModalHandle(e, modalsNames.providerModal, modalsData.providerModal)
                  }
                >
                  <ProductConfigurationRadio
                    data={productServiceState.companies}
                    handleChange={
                      (e) => setCompanieIdMobileHandle(e, productServiceState.companies)
                    }
                    value={modalsData.provider}
                    name={radioNameValues.provider}
                  />
                  <ButtonPrimary
                    typeButton={TYPES_BUTTONS.button}
                    styles={saveButtonStyles}
                    handleToggle={
                      (e) => setProductHandle(
                        e,
                        modalsNames.providerModal,
                        modalsData.providerModal,
                      )
                    }
                    disabled={!modalsData.provider}
                  >
                    { modalTextButton }
                  </ButtonPrimary>
                </ModalsMobileLayout>
              </>
            ) : (
              <ProductConfigurationSelect
                data={productServiceState.companies}
                value={productServiceState.provider}
                defaultValue={providerButton?.text}
                selectorsStyles={customSelect}
                handleSelectors={(e) => setProducthandleSelectors(e, providerSelect)}
                handleMenuItem={setCompanieIdHandle}
              />
            )
          }
        </ContainerButtonSelect>

        <ContainerButtonSelect sx={containerButtonselectorsStyles}>
          <Captions
            variant={variantTypography.h5}
            style={{ ...captionModalButton, ...labelButtonSelectStyle }}
          >
            { product?.label }
          </Captions>
          {
           toggleBreakpoint ? (
             <>
               {
              loading ? (
                <Loader styles={loaderContainer} progressSize={progressSize} />
              ) : (
                <ButtonSelect
                  styles={textButtonSelectStyle}
                  handleClick={
                  (e) => openModalHandle(e, modalsNames.productModal, modalsData.productModal)
                }
                >
                  {
                  !productServiceState.product ? product?.text : productServiceState.product
                }
                  <KeyboardArrowDownIcon
                    sx={iconArrowStyle}
                    ref={(e) => { productArrow.current = e; }}
                  />
                </ButtonSelect>
              )
             }
               <ModalsMobileLayout
                 title={product.modalTitle}
                 openModal={modalsData.productModal}
                 modalSelectStyle={
                  modalsData.productModal
                    ? validHeight
                    : validHeight
                }
                 handleClose={
                  (e) => closeModalHandle(e, modalsNames.productModal, modalsData.productModal)
                  }

               >
                 <ProductConfigurationRadio
                   data={productServiceState.showCaseProduct}
                   handleChange={
                    (e) => setShowcaseProductIdHandle(e, productServiceState.showCaseProduct)
                  }
                   value={modalsData.product}
                   name={radioNameValues.product}
                 />
                 <ButtonPrimary
                   typeButton={TYPES_BUTTONS.button}
                   styles={saveButtonStyles}
                   handleToggle={
                    (e) => setProductHandle(
                      e,
                      modalsNames.productModal,
                      modalsData.productModal,
                    )
                  }
                   disabled={!modalsData.product}
                 >
                   { modalTextButton }
                 </ButtonPrimary>
               </ModalsMobileLayout>
             </>

           ) : (
             loading ? (
               <Loader styles={loaderContainer} progressSize={progressSize} />
             ) : (
               <ProductConfigurationSelect
                 data={productServiceState.showCaseProduct}
                 value={productServiceState.product}
                 defaultValue={product?.text}
                 selectorsStyles={customSelect}
                 handleSelectors={
                  (e) => setShowcaseProductIdHandle(e, productServiceState.showCaseProduct)
                }
               />
             )
           )
          }
        </ContainerButtonSelect>

        <ContainerButtonSelect sx={containerButtonselectorsStyles}>
          <Captions
            variant={variantTypography.h5}
            style={{ ...captionModalButton, ...labelButtonSelectStyle }}
          >
            { productLength?.label }
          </Captions>

          <CounterButton setCount={setCount} count={count} />

          {
            loading ? (
              <Loader styles={loaderContainer} progressSize={progressSize} />
            ) : (
              <ButtonSelect
                styles={textButtonSelectStyle}
                handleClick={
                (e) => openModalHandle(e, modalsNames.dateModal, modalsData.dateModal)
              }
              >
                {
                 !validDate ? productLength?.date : valueDate
              }
                <KeyboardArrowDownIcon
                  sx={iconArrowStyle}
                  ref={(e) => { dateArrow.current = e; }}
                />
              </ButtonSelect>
            )
          }
          <ModalsMobileLayout
            title={productLength.modalTitle}
            openModal={modalsData.dateModal}
            modalSelectStyle={
                  modalsData.dateModal
                    ? { ...ModalProduct, ...modalTranslate, ...modalDateCustom }
                    : { ...ModalProduct, ...modalDateCustom }
                }
            handleClose={
                  (e) => closeModalHandle(e, modalsNames.dateModal, modalsData.dateModal)
                  }
          >
            <Box>
              <DateStatic
                value={modalsData?.date}
                handleDate={handleDate}
              />
            </Box>
            <Box sx={{ ...scheduleContainer, ...scheduleContinerCustom }}>
              {
                !productServiceState.companieSchedules.length ? (
                  <TextWithoutSchedule>{ withoutScheduleMessage }</TextWithoutSchedule>
                ) : (
                  <ProductConfigurationRadio
                    data={productServiceState.companieSchedules}
                    handleChange={radioHandleChange}
                    value={modalsData.schedule}
                    name={radioNameValues.schedule}
                    containerRadioStyles={scheduleContinerCustom}
                  />
                )
              }
            </Box>
            <ButtonPrimary
              typeButton={TYPES_BUTTONS.button}
              styles={saveButtonStyles}
              handleToggle={
                    (e) => setProductHandle(
                      e,
                      modalsNames.dateModal,
                      modalsData.dateModal,
                    )
                  }
              disabled={!validDate || !productServiceState.companieSchedules.length}
            >
              { modalTextButton }
            </ButtonPrimary>
          </ModalsMobileLayout>
        </ContainerButtonSelect>

        <PricesContainer>
          <ProductPrices
            stylesCustom={subTotalStyles}
            label={prices.subTotal.label}
            priceText={prices.subTotal.price}
          />
          <ProductPrices
            label={prices.serviceFee.label}
            priceText={prices.serviceFee.price}
          />
          <ProductPrices
            label={prices.dynamicRate.label}
            priceText={prices.dynamicRate.price}
          />
          <ProductPrices
            label={prices.iva.label}
            priceText={prices.iva.price}
          />
          <ProductPrices
            label={prices.total.label}
            priceText={prices.total.price}
            stylesCustom={totalStyles}
          />
        </PricesContainer>
        <ButtonPrimary
          styles={sendButtonStyles}
          typeButton={TYPES_BUTTONS.button}
          handleToggle={continueHandle}
          disabled={!isDisabled}
        >
          { continueButton }
        </ButtonPrimary>
      </OrderBox>
    </OrderContainer>
  );
};

export default OrderConfigurationLayout;
