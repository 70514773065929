import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, CircularProgress } from '@mui/material';
import { DataServiceTrackingContent } from '../../api';
import { ContextToken } from '../../Contexts';
import { AlertMessage, Map } from '../../Components';
import { FormValidions } from '../../utils/validations';
import LayoutScore from './LayoutScore';
import { scores, typesColors } from '../../Common';
import {
  AddressContainer,
  AddressSection,
  AddressText,
  AddressTitle,
  WhatsAppTitle,
  contentProgress,
  elementPseudo,
  styledCustomAddress,
  styledCustomAddressText,
  styledCustomMap,
  styledCustomWithoutAddress,
  whatsAppContainer,
} from './styles';
import { ServiceTraking } from '../../Common/messages';
import LayoutButtonWhatsApp from './LayoutButtonWhatsApp';

const {
  getDetailServiceTracking,
  scoreProvider,
  getDataServiceTrackingContent,
} = DataServiceTrackingContent;

const {
  finishedStatus,
} = FormValidions;

const {
  messagesTracking: {
    addresTitle,
    reference,
    whatsAppTitle,
    messageError,
    messageSuccess,
  },
} = ServiceTraking;

const LayoutMapOrScore = ({ company }) => {
  const [data, setData] = useState({
    lat: null,
    lng: null,
    status: {},
  });
  const [dataScore, setDataScore] = useState({
    serviceUuid: '',
    score: scores.starsFour,
    scoreOperator: scores.starsFour,
    comment: '',
    type: 'both',
    loading: false,
    isCalificated: false,
    error: false,
    messageOpen: false,
    isDisabled: false,
  });
  const [serviceTrackingData, setServiceTrackingData] = useState({
    calificatedButton: '',
    modalTitle: '',
    labelText: '',
    placeholderText: '',
    textareaTitle: '',
    sendButton: '',
    address: '',
    reference: '',
    detailScoreTracking: {},
  });
  const [loading, setLoading] = useState(true);
  const tokenContext = useContext(ContextToken);
  const navigate = useNavigate();
  const mounted = useRef();
  mounted.current = true;
  useEffect(() => {
    const getServiceTracking = async () => {
      try {
        const {
          address,
          status,
          registryUuid,
          addressCustom,
          score: scoreDetail,
          scoreService: scoreSeviceDetail,
          scoreComment: scoreCommentDetail,
        } = await getDetailServiceTracking(tokenContext);
        const {
          score,
        } = await getDataServiceTrackingContent();
        getDataServiceTrackingContent();
        const { longitude, latitude, reference: referenceData } = address;
        setData(prev => ({
          ...prev,
          lat: latitude,
          lng: longitude,
          status,
        }));
        setDataScore(prev => ({
          ...prev,
          serviceUuid: registryUuid,
        }));
        setServiceTrackingData(prev => ({
          ...prev,
          ...score,
          address: addressCustom,
          reference: referenceData,
          detailScoreTracking: {
            scoreDetail,
            scoreSeviceDetail,
            scoreCommentDetail,
          },
        }));
      } catch (error) {
        navigate('/*');
      } finally {
        setLoading(false);
      }
    };
    if (mounted.current) {
      getServiceTracking();
    }
    return () => {
      if (!mounted.current) {
        setData({
          lat: null,
          lng: null,
          status: {},
        });
      }
      mounted.current = false;
    };
  }, [tokenContext]);

  const handleScore = ({ target }, newValue) => {
    setDataScore(prevDataScore => ({
      ...prevDataScore,
      [target?.name]: newValue,
    }));
  };

  const handleComment = (e) => {
    setDataScore(prevDataScore => ({
      ...prevDataScore,
      comment: e.target.value,
    }));
  };

  const handleScoreSend = async () => {
    try {
      setDataScore(prevDataScore => ({
        ...prevDataScore,
        loading: true,
        isDisabled: true,
      }));
      await scoreProvider(dataScore);
      setDataScore(prevDataScore => ({
        ...prevDataScore,
        isCalificated: true,
        comment: '',
        error: false,
      }));
    } catch (error) {
      setDataScore(prevDataScore => ({
        ...prevDataScore,
        isCalificated: false,
        error: true,
        isDisabled: false,
      }));
    } finally {
      setDataScore(prev => ({
        ...prev,
        messageOpen: true,
        loading: false,
      }));
    }
  };

  const addressData = (
    <AddressSection>
      <AddressTitle>
        <RoomOutlinedIcon fontSize='1.3rem' />
        {addresTitle}
      </AddressTitle>
      <AddressText sx={elementPseudo}>
        {serviceTrackingData?.address}
      </AddressText>
      <AddressText sx={styledCustomAddressText}>
        <strong>
          {reference}
        </strong>
      </AddressText>
      <AddressText>
        {serviceTrackingData?.reference || 'N/A'}
      </AddressText>
    </AddressSection>
  );
  const { phoneNumber } = company;
  const whatsApp = phoneNumber && phoneNumber !== '' ? (
    <Box sx={whatsAppContainer}>
      <WhatsAppTitle>
        {whatsAppTitle}
      </WhatsAppTitle>
      <LayoutButtonWhatsApp phoneNumber={phoneNumber} />
    </Box>
  ) : null;

  return loading ? (
    <Box sx={contentProgress}>
      <CircularProgress color={typesColors.primary} />
    </Box>
  ) : (
    !finishedStatus(Number(data?.status?.id)) ? (
      <>
        <Map
          lat={+data.lat}
          lng={+data.lng}
          styles={styledCustomMap}
        />
        <AddressContainer sx={styledCustomAddress}>
          {addressData}
          {whatsApp}
        </AddressContainer>
      </>
    ) : (
      <>
        <LayoutScore
          {...serviceTrackingData}
          valueScore={dataScore.score}
          scoreOperator={dataScore.scoreOperator}
          handleScore={handleScore}
          handleComment={handleComment}
          handleScoreSend={handleScoreSend}
          valueComent={dataScore.comment}
          loading={dataScore.loading}
          isDisabled={dataScore.isDisabled}
          detailScoreTracking={serviceTrackingData.detailScoreTracking}
        />
        <AddressContainer sx={styledCustomWithoutAddress}>
          {whatsApp}
        </AddressContainer>
        <AlertMessage
          error={dataScore.error}
          message={dataScore.error ? messageError : messageSuccess}
          openProp={dataScore.messageOpen}
          handleClose={() => setDataScore(prev => ({ ...prev, messageOpen: false }))}
        />
      </>
    )
  );
};

export default LayoutMapOrScore;
