import { Select, styled } from '@mui/material';

export const styledForm = {
  width: {
    xs: '100%',
    md: '425px',
  },
};

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '423px',
  height: '58px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.webView.fourth}`,
  fontSize: '1rem',
  backgroundColor: theme.palette.secondary.main,
  marginBottom: '1.25rem',
  '& > svg': {
    color: `${theme.palette.webView.secondary}`,
  },
  '&.Mui-focused > .MuiOutlinedInput-notchedOutline, & > fieldset': {
    borderWidth: '0px',
    borderStyle: 'none',
    borderColor: 'transparent',
  },
  '&, & > .MuiSelect-outlined:focus-visible': {
    outline: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '42px',
    borderRadius: '8px',
    marginBottom: '1.4rem',
    fontSize: '.75rem',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.webView.secondary}`,
  },
}));

export const styledItems = {
  '&, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: 'webView.backgroundItem',
    borderBottom: '1px solid secondary.main',
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: 'webView.primary',
    color: 'secondary.main',
  },
  '&:hover': {
    backgroundColor: 'webView.backgroundItem',
  },
};
