import ProductImageDummy from '../../../assets/img/ServiceProduct/dummy-product.jpg';
import AvatarDummy from '../../../assets/img/ServicePreOrder/avatar-dummy.jpg';
import TerminalPay from '../../../assets/img/ServiceProduct/terminal.png';
import CashPay from '../../../assets/img/ServiceProduct/cash-pay.png';

const title = 'Preorden';

const addressTitle = 'Enviar a esta dirección';

const addressMessage = 'Benito Juárez 4 Cuévano, Plan de Abajo, México 43020';

const orderTitle = 'Tu pedido';

const productsData = [
  {
    id: 1,
    image: ProductImageDummy,
    serviceTitle: 'Servicio de lavandería',
    price: 135.00,
    avatar: AvatarDummy,
    nameProvider: 'Jacarándas',
    rating: 4,
    califications: '4.5 (93 calificaciones)',
    productTitle: 'Pieza de playera',
    units: 23,
    unitsPriceTotal: 7,
    complements: [
      {
        id: 1,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 2,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 3,
        product: 'Suavitel extra',
        price: 0.50,
      },
    ],
    date: '12/09/22  12:00 am',
    deliveryTitle: 'Fecha de entrega',
    delivery: '13/09/22  12:00 am',
  },
  {
    id: 2,
    image: ProductImageDummy,
    serviceTitle: 'Servicio de lavandería',
    price: 135.00,
    avatar: AvatarDummy,
    nameProvider: 'Jacarándas',
    rating: 4,
    califications: '4.5 (93 calificaciones)',
    productTitle: 'Pieza de playera',
    units: 23,
    unitsPriceTotal: 7,
    complements: [
      {
        id: 1,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 2,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 3,
        product: 'Suavitel extra',
        price: 0.50,
      },
    ],
    dateTitle: 'Fecha de recolección',
    date: '12/09/22  12:00 am',
    deliveryTitle: 'Fecha de entrega',
    delivery: '13/09/22  12:00 am',
  },
  {
    id: 3,
    image: ProductImageDummy,
    serviceTitle: 'Servicio de lavandería',
    price: 135.00,
    avatar: AvatarDummy,
    nameProvider: 'Jacarándas',
    rating: 4,
    califications: '4.5 (93 calificaciones)',
    productTitle: 'Pieza de playera',
    units: 23,
    unitsPriceTotal: 7,
    complements: [
      {
        id: 1,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 2,
        product: 'Suavitel extra',
        price: 0.50,
      },
      {
        id: 3,
        product: 'Suavitel extra',
        price: 0.50,
      },
    ],
    dateTitle: 'Fecha de recolección',
    date: '12/09/22  12:00 am',
    deliveryTitle: 'Fecha de entrega',
    delivery: '13/09/22  12:00 am',
  },
];

const preorderLabels = {
  userName: 'Ingresa tu nombre(s) y apellido(s)',
  phone: 'Ingresa tu número telefónico',
  email: 'Ingresa tu correo electrónico',
  pay: 'Forma de pago',
  coupon: '¿Tienes un cupón?',
};

const preorderPlaceholder = {
  userName: 'Nombre(s) y apellido(s)',
  phone: 'Número',
  email: '@Okboy',
  coupon: 'Ingresa tu cupón',
};

const buttonsData = [
  {
    id: 1,
    name: 'Efectivo',
    icon: CashPay,
  },
  {
    id: 2,
    name: 'Terminal',
    icon: TerminalPay,
  },
];

const payFooter = '*Aún no se te realizará ningún cobro';

const localStorageData = {
  payButton: 'payButton',
  preOrder: 'preOrder',
  customerInfo: 'customerInfo',
};

const ACTION_TYPES = {
  setConfigurationData: 'SET_CONFIGURATION_DATA',
  getCompanyId: 'GET_COMPANY_ID',
  getProductShowCase: 'GET_PRODUCT_SHOW_CASE',
  setDate: 'SET_DATE',
  getPreOrderSummary: 'GET_PRE_ORDER_SUMMARY',
  setCustomer: 'SET_CUSTOMER',
  getCheckout: 'GET_CHECKOUT',
  setCheckoutParamsData: 'SET_CHECKOUT_PARAMS_DATA',
};

const summaryTitle = 'Resumen de preorden';

const checkedMessage = {
  text: 'Recordar mi información para mi próximo servicio. Al dar clic aceptas nuestros',
  link: 'Términos y Condiciones',
};

const buttonText = 'Enviar';

const configurationPreOrderFooterMessage = '*Al finalizar se generará una preorden, en menos de 5 minutos nos comunicaremos contigo para confirmar tu compra vía WhatsApp.';

const dateTitles = {
  delivery: 'Fecha de entrega',
};

const errorMessage = {
  name: 'Caracter inválido',
  phoneNumber: 'Caracter inválido, sólo números por favor',
  email: 'Fomato Inválido, sólo formato en correo',
};

const customerForm = 'customerForm';

const preOrderErrorMessage = 'Ha habido un error en la creación de tu pre orden';

const checkoutErrorMessage = 'Ha ocurrido un error';

export {
  productsData,
  preorderLabels,
  preorderPlaceholder,
  payFooter,
  buttonsData,
  localStorageData,
  ACTION_TYPES,
  title,
  addressTitle,
  addressMessage,
  summaryTitle,
  checkedMessage,
  buttonText,
  configurationPreOrderFooterMessage,
  dateTitles,
  errorMessage,
  customerForm,
  preOrderErrorMessage,
  checkoutErrorMessage,
  orderTitle,
};
