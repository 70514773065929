import ButtonPrimary from '../../Button/ButtonPrimary';
import { styledButton } from './styles';

const WebViewButtonPrimary = ({
  children,
  color,
  disabled,
  styles,
  handleToggle,
  props,
}) => {
  return (
    <ButtonPrimary
      color={color}
      disabled={disabled}
      styles={{ ...styledButton, ...styles }}
      handleToggle={handleToggle}
      props={props}
    >
      { children }
    </ButtonPrimary>
  );
};

export default WebViewButtonPrimary;
