import TimeImage from '../../../assets/img/CardCarousel/TimeImage.png';
import TimeImageMobile from '../../../assets/img/CardCarousel/TimeImageMobile.png';
import ProgramsImage from '../../../assets/img/CardCarousel/ProgramsImage.png';
import ProgramsImageMobile from '../../../assets/img/CardCarousel/ProgramsImageMobile.png';
import InformationImage from '../../../assets/img/CardCarousel/InformationImage.png';
import InformationImageMobile from '../../../assets/img/CardCarousel/InformationImageMobile.png';
import ClaimsImage from '../../../assets/img/CardCarousel/ClaimsImage.png';
import ClaimsImageMobile from '../../../assets/img/CardCarousel/ClaimsImageMobile.png';

const textSuggestions = [
  {
    img: TimeImage,
    imgMobile: TimeImageMobile,
    title: 'No Pierdas tiempo buscando',
    textContent:
      'Encuentra todos los servicios que necesitas y pídelos en un clic.',
  },
  {
    img: ProgramsImage,
    imgMobile: ProgramsImageMobile,
    title: 'Programa lo que más necesitas',
    textContent:
      'Tu servicio más recurrente puedes programarlo para que nunca te falte.',
  },
  {
    img: ClaimsImage,
    imgMobile: ClaimsImageMobile,
    title: 'No pierdas tiempo en reclamos',
    textContent:
      'Nosotros nos aseguramos que recibas el servicio y un trato extraordinario.',
  },
  {
    img: InformationImage,
    imgMobile: InformationImageMobile,
    title: 'No pierdas tiempo haciendo cuentas',
    textContent:
      'Obtén informes y recomendaciones de tus gastos para hacer un mejor uso de tu dinero.',
  },
];

const textHeader = '¿Cómo te ayudamos a ahorrar tiempo?';

const directionButttons = {
  next: 'next',
  previous: 'previous',
};

export { textSuggestions, textHeader, directionButttons };
