export const styledButton = {
  display: 'grid',
  placeContent: 'center',
  width: {
    xs: '100%',
    md: '104px',
  },
  height: '40px',
  borderRadius: '12px',
  backgroundColor: 'webView.primary',
  color: 'secondary.main',
  fontSize: {
    xs: '0.875rem',
    md: '1.375rem',
  },
  marginBottom: {
    xs: 'initial',
    md: '4rem',
  },
  padding: {
    xs: 'initial',
    md: '.5rem 1.25rem',
  },
  '&:hover': {
    backgroundColor: 'webView.primary',
    color: 'secondary.main',
  },
};
