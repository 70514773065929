import { axiosService } from '../Configs/axios';
import { PreOrderProductDetail } from '../utils';

const {
  isNotEmpty,
} = PreOrderProductDetail;

const apiDefault = async () => {
  return {};
};

const getCardsSectionData = async () => {
  const data = await apiDefault();
  const cardsSectionData = data.attributes.cardsSection;

  return {
    title: !cardsSectionData.title ? null : cardsSectionData.title,
    cards: !cardsSectionData.cards.length ? null : cardsSectionData.cards,
  };
};

const getRecomandationSectionData = async () => {
  const data = await apiDefault();
  const recomendationsSectionData = data.attributes.recomendationsLists;
  const lists = data.attributes.recomendationsLists.listContent.sort((a, b) => a.id - b.id);

  let beforeList = lists[0] || {};
  beforeList = isNotEmpty(beforeList);

  let duringList = lists[1] || {};
  duringList = isNotEmpty(duringList);

  let afteList = lists[2] || {};
  afteList = isNotEmpty(afteList);

  return {
    title: recomendationsSectionData.title || null,
    beforeSectionList: {
      title: recomendationsSectionData.beforeList || null,
      list: !beforeList.length ? null : beforeList,
    },
    duringSectionList: {
      title: recomendationsSectionData.duringList || null,
      list: !duringList.length ? null : duringList,
    },
    afterSectionList: {
      title: recomendationsSectionData.afterList || null,
      list: !afteList.length ? null : afteList,
    },
    footer: recomendationsSectionData.footerLists || null,
  };
};

const getWarrantySectionData = async () => {
  const data = await apiDefault();
  const productWarrantyData = data.attributes.productWarranty;
  return {
    title: productWarrantyData.title || null,
    list: !productWarrantyData.warrantyLists.length
      ? null
      : productWarrantyData.warrantyLists,
  };
};

const getCheckInSectionData = async () => {
  const data = await apiDefault();
  const checkInSectionData = data.attributes.checkInSection;
  return {
    title: checkInSectionData.title || null,
    text: checkInSectionData.content || null,
  };
};

const getBannerSectionData = async () => {
  const data = await apiDefault();
  const bannerSectionnData = data.attributes.bannerSection;
  let imageData = bannerSectionnData.phoneImage.data[0] || {};
  imageData = imageData.attributes;
  const imageUrl = imageData.url;
  const alternativeTextData = imageData.alternativeText;
  return {
    image: {
      src: imageUrl,
      alt: alternativeTextData,
    },
    badge: bannerSectionnData.badge,
    title: bannerSectionnData.title,
    textContent: bannerSectionnData.content,
  };
};

const getFixedButtonData = async () => {
  const data = await apiDefault();
  const fixedButtonData = data.attributes.fixedButton;
  return {
    title: fixedButtonData.label,
    buttonText: fixedButtonData.button,
  };
};

/* Backend */

const getCompanies = async (serviceType) => {
  return axiosService.get(`company?active=true&format=asgmt&serviceTypeUuid=${serviceType}`);
};

const getCompanieProducts = async (serviceType, uuidCompanie) => {
  return axiosService.get(`services/categories/${serviceType}/${uuidCompanie}?showDetails=true&all=true`);
};

const getCompanieSchedule = (uuidCompanie) => {
  return axiosService.get(`ally/schedule/${uuidCompanie}`);
};

const postCheckoutCart = async (data) => {
  return axiosService.post('checkout/cart', data);
};

const getCompanyDetail = async (companyUuid) => {
  return axiosService.get(`/ally/company/${companyUuid}`);
};

const getPreOrderSummary = async (phoneNumber, shoppingCartUuid, addressUuid, serviceDate) => {
  const summaryParams = `${phoneNumber}?shoppingCartUuid=${shoppingCartUuid}&addressUuid=${addressUuid}&serviceDate=${serviceDate}`;
  return axiosService.get(`checkout/summary/${summaryParams}`);
};

const createPreOrder = (preOrderData) => {
  return axiosService.post('/preorder/web', preOrderData);
};

export {
  getCardsSectionData,
  getRecomandationSectionData,
  getWarrantySectionData,
  getCheckInSectionData,
  getBannerSectionData,
  getFixedButtonData,
  getCompanies,
  getCompanieProducts,
  getCompanieSchedule,
  postCheckoutCart,
  getCompanyDetail,
  getPreOrderSummary,
  createPreOrder,
};
