import { Box as Button } from '@mui/material';
import { typesTarget, TYPES_COMPONENTS } from '../../Common';
import './style.css';
import { ButtonSkeleton } from './styles';

const ButtonOrderService = ({
  children,
  link,
  loading,
  classStyle,
}) => {
  const buttonClass = 'button-order';
  const { TYPE_TARGET_BLANK } = typesTarget;
  return (
    <Button component={TYPES_COMPONENTS.link} target={TYPE_TARGET_BLANK} className={`${buttonClass} ${classStyle}`} href={link}>
      { loading ? <ButtonSkeleton /> : children }
    </Button>
  );
};

export default ButtonOrderService;
