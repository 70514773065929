/* eslint-disable no-undef */
import { List } from '@mui/material';
import { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { noBar } from '../../Common';
import { pathViews } from '../../Common/messages';
import { SectionScroll } from '../../Contexts';
import { MenuMobileContext } from '../../Contexts/MenuContext';
import ListItems from '../ListLinks/ListItems';
import { contentList, contentListMobileBlog } from './ListMenuMobileStyles';

const LINK_TYPE = 'link';
const ListMenuMobile = ({ listLinksMenu }) => {
  const listId = (Math.random() / Math.random()).toString();
  const { pathname } = useLocation();
  const { setScrollSectionHome } = useContext(SectionScroll);
  const { toggleMenu, setToggleMenu } = useContext(MenuMobileContext);
  const refLinks = useRef(null);
  refLinks.current = [];
  const typeScroll = 'smooth';
  const atribute = 'href';
  const lastLink = 2;
  const firstSection = 0;
  const firstSectionTop = 640 - 10;
  const scrollSubtraction = 155;

  const getLinks = (el) => {
    if (el && !refLinks.current.includes(el)) refLinks.current.push(el);
  };

  let toggle = toggleMenu;
  const handleEventScrollSection = (e) => {
    const { target } = e;
    const linkElement = !target.getAttribute(atribute)
      ? target.parentNode.parentNode.getAttribute(atribute)
      : target.getAttribute(atribute);
    e.preventDefault();
    refLinks.current.forEach((link, i) => {
      if (target === link && i <= lastLink) {
        const section = document.querySelector(linkElement).offsetTop === firstSection
          ? firstSectionTop
          : document.querySelector(linkElement).offsetTop - scrollSubtraction;
        toggle = true;
        window.scroll({
          top: i === lastLink ? section + scrollSubtraction : section,
          behavior: typeScroll,
        });
        setToggleMenu(toggle);
      }
    });
  };

  const handleLink = (e) => {
    const { target } = e;
    if (pathname !== pathViews.home) {
      toggle = true;
      setScrollSectionHome(`#${target.text.toLowerCase()}`);
      setToggleMenu(toggle);
    }
  };
  const handleLinkFrecuentQuetions = () => {
    toggle = true;
    setToggleMenu(toggle);
  };

  const activeViewCategory = true;
  return (
    <List sx={
      pathname.slice(0, noBar) !== pathViews.blog.app
        ? contentList
        : { ...contentList, ...contentListMobileBlog }
    }
    >
      {listLinksMenu.map((link) => (
        link.activeViewCategory !== activeViewCategory ? (
          <ListItems
            key={`${listId}${link.text}`}
            {...link}
            typeList={LINK_TYPE}
            event={handleEventScrollSection}
            refLinks={getLinks}
            eventLink={handleLink}
            eventFrecuentQuestions={handleLinkFrecuentQuetions}
          />
        ) : (
          <ListItems
            key={`${listId}${link.text}`}
            {...link}
            typeList={LINK_TYPE}
            eventFrecuentQuestions={handleLinkFrecuentQuetions}
          />
        )))}
    </List>
  );
};

export default ListMenuMobile;
