import { createContext, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const ContextToken = createContext('');
export const ContextImageAndToggle = createContext('');

const TokenServiceTracking = ({ children }) => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <ContextToken.Provider value={token || searchParams.get('token')}>
      { children }
    </ContextToken.Provider>
  );
};

export default TokenServiceTracking;

export const ToggleTrackingProvider = ({ children }) => {
  const [toggleAndImage, setToggleAndImage] = useState({
    image: '',
    toggle: false,
  });

  const handleToggle = (toggle, image) => {
    setToggleAndImage(prevToggleAndImage => ({
      ...prevToggleAndImage,
      image,
      toggle,
    }));
  };

  const ToggleMemo = useMemo(() => (
    { handleToggle, toggleAndImage, setToggleAndImage }
  ), [handleToggle]);

  return (
    <ContextImageAndToggle.Provider value={ToggleMemo}>
      { children }
    </ContextImageAndToggle.Provider>
  );
};
