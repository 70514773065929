import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import {
  FORMAT_DATE_CALENDAR,
  INPUTS_TYPES,
  LOCALSTORAGE_NAME_TYPES,
  variantTypography,
} from '../../Common';
import { pathViews, ServicePreOrder, ServicesSearchMessage } from '../../Common/messages';
import {
  ButtonPrimary,
  Captions,
  FormLabels,
  Loader,
  PaySelectButton,
  PreOrderInput,
  RememberChecked,
} from '../../Components';
import { ErrosUtils, FormValidions } from '../../utils/validations';
import {
  ButtonContainer,
  ConfigurationPreOrderFooter,
  labelCustom,
  FormContainer,
  IconButton,
  PayFooter,
  preorderButton,
  pricesTextCustom,
  summaryContainerStyles,
  summaryTitleStyles,
  summaryHidden,
  customLoader,
  customPaymentLoader,
  PreOrderAlert,
  emailInputCustom,
} from './styles';
import SummaryPreOrderLayout from './SummaryPreOrderLayout';
import { DataPreOrderProudct } from '../../api';
import { ServicePreOrderProvider, ServiceProductContext } from '../../Contexts';
import { getCheckoutData, getPreOrderSummaryData, setCheckoutParamsData } from '../../store/preOrder/action';
import { formatMoney } from '../../utils/service-tracking';
import { InputsMessages } from '../../shared/messages';

const {
  preorderLabels,
  preorderPlaceholder,
  payFooter,
  localStorageData,
  summaryTitle,
  checkedMessage,
  buttonText,
  configurationPreOrderFooterMessage,
  errorMessage,
  customerForm,
  preOrderErrorMessage,
  checkoutErrorMessage,
  buttonsData,
} = ServicePreOrder;

const {
  inputsNames,
} = InputsMessages;

const {
  userName,
  phone,
  email,
  pay,
  coupon,
} = preorderLabels;

const {
  userName: userNamePlaceHolder,
  phone: phonePlaceHolder,
  email: emailPlaceHolder,
  coupon: couponPlaceHolder,
} = preorderPlaceholder;

const {
  text,
  emailType,
} = INPUTS_TYPES;

const {
  validLetterCharater,
  validNumbers,
  validEmail,
} = FormValidions;

const {
  postCheckoutCart,
  getPreOrderSummary,
  createPreOrder,
} = DataPreOrderProudct;

const {
  yearMonthDay,
} = FORMAT_DATE_CALENDAR;

const {
  errorsValid,
} = ErrosUtils;

const characterLength = 10;

const {
  serviceTypeData,
} = LOCALSTORAGE_NAME_TYPES;

const {
  nameRegistryUUid,
} = ServicesSearchMessage;

const PreOrderForm = () => {
  // eslint-disable-next-line no-undef
  const dataPersistent = JSON.parse(localStorage.getItem(customerForm));
  // eslint-disable-next-line no-undef
  const customerDataPersitent = JSON.parse(localStorage.getItem(localStorageData.customerInfo));

  const namePersitentValid = customerDataPersitent?.name || dataPersistent?.name;
  const phoneNumberPersitentValid = customerDataPersitent?.phoneNumber
  || dataPersistent?.phoneNumber;

  const emailPersitentValid = customerDataPersitent?.email
  || dataPersistent?.email;

  const [formData, setFormData] = useState({
    name: namePersitentValid || '',
    phoneNumber: phoneNumberPersitentValid || '',
    email: emailPersitentValid || '',
    paymentType: dataPersistent?.paymentType || '',
    checked: false,
  });
  const [unmounted, setUnmounted] = useState(false);
  const [loaders, setLoaders] = useState({
    checkout: false,
    preOrder: false,
  });
  const [errors, setErrors] = useState({
    preOrder: false,
    checkout: false,
    message: '',
  });
  const [openModalError, setOpenModalError] = useState(false);
  const [preOrderbuttonDisabled, setPreOrderbuttonDisabled] = useState(false);

  const [preOrderState, dispatch] = useContext(ServicePreOrderProvider);
  const [productServiceState] = useContext(ServiceProductContext);
  const showCaseProductData = productServiceState?.showCaseProductId;
  const refButtons = useRef(null);
  refButtons.current = [];
  const queryValid = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    refButtons.current.filter(button => button).forEach(element => {
      const elementStyle = element.style;
      if (formData.paymentType === element.name) {
        elementStyle.border = '2px solid #4433E0';
      } else {
        elementStyle.border = '1px solid rgba(121, 121, 122, 0.2)';
      }
    });
  }, [formData?.paymentType, loaders.checkout]);

  const nameError = validLetterCharater(formData?.name);
  const numberError = validNumbers(formData.phoneNumber);
  const emailError = validEmail(formData.email);

  const buttonDesibled = [
    !formData?.name,
    nameError,
    !formData.phoneNumber,
    numberError,
    !formData.email,
    emailError,
    !formData?.paymentType,
  ].some(item => item);

  const validInputs = [
    !formData?.name,
    nameError,
    !formData?.phoneNumber,
    !(formData?.phoneNumber.length === characterLength),
    numberError,
    !formData?.email,
    emailError,
  ].some(item => item);

  const customer = {
    name: '',
    phoneNumber: '',
    email: '',
    paymentType: '',
    checked: false,
  };

  useEffect(() => {
    return () => {
      setUnmounted(true);
      if (unmounted) {
        // eslint-disable-next-line no-undef
        localStorage.setItem(customerForm, JSON.stringify(customer));
        queryValid.current = false;
      }
    };
  }, [unmounted]);

  useEffect(() => {
    const queryData = preOrderState?.configurationData;
    const locationData = preOrderState?.configurationData?.location;
    const checkoutDataCart = {
      customerUuid: '',
      optionUuid: null,
      reference: '',
      complements: [],
      notes: '',
      companyUuid: queryData?.companyUuid,
      showcaseUuid: showCaseProductData?.registryUuid,
      serviceTypeUuid: queryData?.serviceTypeUuid,
      units: queryData?.units,
      isPreorder: true,
      customerData: {
        fullName: formData?.name,
        phoneNumber: formData?.phoneNumber,
        email: formData?.email,
        address: {
          state: locationData?.state,
          municipality: locationData?.municipality,
          suburb: locationData?.suburb,
          street: locationData?.street,
          numExt: locationData?.numExt,
          zipcode: locationData?.zipcode,
          longitude: locationData?.longitude.toString(),
          latitude: locationData?.latitude.toString(),
        },
      },
    };
    if (!validInputs) {
      dispatch(setCheckoutParamsData(checkoutDataCart));
    }
  }, [
    preOrderState?.checkoutParamsData?.companyUuid,
    preOrderState?.configurationData?.serviceTypeUuid,
    preOrderState?.configurationData?.location?.state,
    formData?.name,
    formData?.phoneNumber,
    formData?.email,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem(customerForm, JSON.stringify(formData));

    const checkout = preOrderState?.checkoutData;
    if (!validInputs && queryValid.current) {
      if (preOrderState?.checkoutParamsData?.companyUuid && !checkout?.registryUuid) {
        setLoaders(loadersPrev => ({
          ...loadersPrev,
          checkout: true,
        }));
        postCheckoutCart(preOrderState?.checkoutParamsData).then(({ data }) => {
          dispatch(getCheckoutData(data));
        }).catch(error => {
          if (error.message && queryValid.current) {
            const codeApiData = error?.response?.data?.response?.codeApi;
            const message = errorsValid(codeApiData, showCaseProductData?.minPurchase);
            setErrors((errosPrev => ({
              ...errosPrev,
              checkout: true,
              message,
            })));
            setOpenModalError(true);
            setLoaders(loadersPrev => ({
              ...loadersPrev,
              checkout: false,
            }));
          }
        });
      }
    }
  }, [
    formData?.name,
    formData?.phoneNumber,
    formData?.email,
    preOrderState?.checkoutParamsData?.companyUuid,
  ]);

  useEffect(() => {
    const dateData = preOrderState?.configurationData?.date?.date;
    const schedule = preOrderState?.date?.defaultDate;
    const dateFormated = moment(dateData).format(yearMonthDay);
    const checkout = preOrderState?.checkoutData;
    if (checkout?.registryUuid) {
      setLoaders(loadersPrev => ({
        ...loadersPrev,
        checkout: true,
      }));
      getPreOrderSummary(
        checkout?.customer?.phoneNumber,
        checkout?.registryUuid,
        checkout?.addressUuid,
        `${dateFormated}${schedule}`,
      ).then(({ data: summaryData }) => {
        const pricesData = {
          subTotal: {
            label: 'Subtotal',
            price: `$${formatMoney(summaryData?.subtotal)} MXN`,
          },
          serviceFee: {
            label: 'Cuota de servicio',
            price: `$${formatMoney(summaryData?.serviceFee)} MXN`,
          },
          dynamicRate: {
            label: 'Tarifa dinámica',
            price: `$${formatMoney(summaryData?.saturationFee)} MXN`,
          },
          iva: {
            label: 'IVA',
            price: `$${formatMoney(summaryData?.iva)} MXN`,
          },
          total: {
            label: 'Total',
            price: `$${formatMoney(summaryData?.total)} MXN`,
          },
        };
        dispatch(getPreOrderSummaryData({ summaryData, pricesData }));
      })
        .catch(error => {
          if (error.message && queryValid.current) {
            const codeApiData = error?.response?.data?.codeApi;
            const message = errorsValid(codeApiData, showCaseProductData?.minPurchase);
            setErrors((errosPrev => ({
              ...errosPrev,
              checkout: true,
              message,
            })));
            setOpenModalError(true);
          }
        })
        .finally(() => {
          setLoaders(loadersPrev => ({
            ...loadersPrev,
            checkout: false,
          }));
        });
    }
  }, [preOrderState?.checkoutData?.registryUuid]);

  useEffect(() => {
    const customerData = {
      name: formData?.name,
      phoneNumber: formData?.phoneNumber,
      email: formData?.email,
      address: preOrderState?.configurationData?.location?.formatted_address,
      customerUuid: preOrderState?.checkout?.customer?.registryUuid,
      addressUuid: preOrderState?.checkout?.addressUuid,
    };
    if (formData.checked) {
      // eslint-disable-next-line no-undef
      localStorage.setItem(localStorageData?.customerInfo, JSON.stringify(customerData));
    } else {
      // eslint-disable-next-line no-undef
      localStorage.removeItem(localStorageData?.customerInfo);
    }
  }, [formData.checked]);

  useEffect(() => {
    if (customerDataPersitent?.name) {
      setFormData(prevFormData => ({
        ...prevFormData,
        name: customerDataPersitent?.name,
        phoneNumber: customerDataPersitent?.phoneNumber,
        email: customerDataPersitent?.email,
        checked: true,
      }));
    }
  }, []);

  const handlePay = (e) => {
    const { target } = e;
    const elements = refButtons.current.filter(button => button);
    elements.forEach(element => {
      const elementStyle = element.style;
      if (target.name === element.name) {
        elementStyle.border = '2px solid #4433E0';
      } else {
        elementStyle.border = '1px solid rgba(121, 121, 122, 0.2)';
      }
    });
    setFormData(prevFormData => ({
      ...prevFormData,
      paymentType: target.name,
    }));
    const customerFormData = {
      ...formData,
      paymentType: target.name,
    };
    // eslint-disable-next-line no-undef
    localStorage.setItem(customerForm, JSON.stringify(customerFormData));
  };

  const formHandler = ({ target }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [target.name]: target.value,
    }));
  };

  const createPreOrderHandler = () => {
    const checkoutUuid = preOrderState?.checkoutData?.registryUuid;
    const customerUuid = preOrderState?.checkoutData?.customer?.registryUuid;
    const dateData = preOrderState?.configurationData?.date?.date;
    const preOrderData = {
      shoppingCartUuid: checkoutUuid,
      customerUuid,
      serviceDate: dateData,
      preferredTime: dateData,
      paymentTypeId: formData?.paymentType,
      promocode: '',
    };
    setLoaders(loadersPrev => ({
      ...loadersPrev,
      preOrder: true,
    }));
    setPreOrderbuttonDisabled(true);
    createPreOrder(preOrderData).then(({ data: preOrderDataResponse }) => {
      const {
        servicesSearch,
      } = pathViews;

      const preOrderSuccessPath = `${
        servicesSearch.app}${servicesSearch.chidren.succesPreOrder
      }`;
      if (preOrderDataResponse.registryUuid) {
        setErrors((errosPrev => ({
          ...errosPrev,
          preOrder: false,
        })));

        setLoaders(loadersPrev => ({
          ...loadersPrev,
          preOrder: false,
        }));

        const searchServices = {
          service: '',
          serviceType: '',
          servicesData: [],
          servicesSelectors: [],
          location: '',
          locationSelectors: '',
          provider: '',
          date: '',
        };

        // eslint-disable-next-line no-undef
        sessionStorage.setItem(LOCALSTORAGE_NAME_TYPES.services, JSON.stringify(searchServices));

        const serviceTypeTargetData = {
          description: '',
          registryUuid: '',
        };
        // eslint-disable-next-line no-undef
        sessionStorage.setItem(serviceTypeData, JSON.stringify(serviceTypeTargetData));

        // eslint-disable-next-line no-undef
        sessionStorage.setItem(nameRegistryUUid, JSON.stringify(''));
        navigate(preOrderSuccessPath, { replace: true });
      }
    }).catch((err) => {
      if (err.message) {
        setErrors((errosPrev => ({
          ...errosPrev,
          preOrder: true,
        })));
        setLoaders(loadersPrev => ({
          ...loadersPrev,
          preOrder: false,
        }));
        setPreOrderbuttonDisabled(false);
      }
    });
  };

  const checkHandler = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      checked: e.target.checked,
    }));
  };

  const handleClose = () => {
    setOpenModalError(false);
  };

  const paymentsTypes = preOrderState?.preOrderSummary?.summaryData?.paymentTypes;
  const prices = preOrderState?.preOrderSummary?.pricesData;

  const secondsDuration = 6000;

  return (
    <FormContainer>
      <FormLabels title={userName} />
      <PreOrderInput
        placeholder={userNamePlaceHolder}
        type={text}
        handleChange={formHandler}
        name={inputsNames?.name}
        value={formData?.name || ''}
        error={nameError}
        helperText={errorMessage.name}
      />

      <FormLabels title={phone} />
      <PreOrderInput
        placeholder={phonePlaceHolder}
        type={text}
        handleChange={formHandler}
        name={inputsNames?.phoneNumber}
        value={formData?.phoneNumber || ''}
        error={numberError}
        helperText={errorMessage.phoneNumber}
        inputProps={{
          maxLength: characterLength,
        }}
      />

      <FormLabels title={email} styles={numberError ? labelCustom : undefined} />
      <PreOrderInput
        placeholder={emailPlaceHolder}
        type={emailType}
        handleChange={formHandler}
        name={inputsNames?.email}
        value={formData?.email || ''}
        error={emailError}
        helperText={errorMessage.email}
        styles={emailError ? emailInputCustom : undefined}
      />

      {
        loaders.checkout ? (
          <Loader styles={customPaymentLoader} />
        ) : (
          <>
            {!!paymentsTypes?.length && (
              <FormLabels title={pay} styles={numberError ? labelCustom : undefined} />
            )}
            <ButtonContainer>
              {
           !!paymentsTypes?.length && buttonsData?.map((button) => {
             return (
               <PaySelectButton
                 key={button.id}
                 targetRef={(e) => { refButtons.current = [...refButtons.current, e]; }}
                 value={button.name}
                 name={button.id}
                 handleEvent={handlePay}
               >
                 <IconButton src={button?.icon} />
                 { button.name }
               </PaySelectButton>
             );
           })
        }
            </ButtonContainer>
            {!!paymentsTypes?.length && (
              <PayFooter>{ payFooter }</PayFooter>
            )}
          </>
        )
      }

      <FormLabels title={coupon} />
      <PreOrderInput placeholder={couponPlaceHolder} type={text} />

      {
        loaders.checkout ? (
          <Loader styles={customLoader} />
        ) : prices?.total ? (
          <>
            <Captions variant={variantTypography.h2} style={summaryTitleStyles}>
              { summaryTitle }
            </Captions>
            <SummaryPreOrderLayout
              prices={prices}
              pricesContainerStyles={prices?.total ? summaryContainerStyles : summaryHidden}
              textStyles={pricesTextCustom}
            />
          </>
        ) : null
      }

      <RememberChecked
        text={checkedMessage.text}
        linkText={checkedMessage.link}
        path={pathViews.termsConditions}
        handleChange={checkHandler}
        checked={formData.checked}
        disabled={buttonDesibled}
      />

      <ButtonPrimary
        disabled={buttonDesibled || preOrderbuttonDisabled}
        styles={preorderButton}
        handleToggle={createPreOrderHandler}
      >
        { loaders.preOrder ? <CircularProgress color='text' /> : buttonText }
      </ButtonPrimary>

      { errors.checkout && (
      <Snackbar
        open={openModalError}
        autoHideDuration={secondsDuration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity='error'>
          { errors?.message || checkoutErrorMessage }
        </Alert>
      </Snackbar>
      ) }

      { errors.preOrder && (
      <>
        <PreOrderAlert severity='error'>
          { preOrderErrorMessage }
        </PreOrderAlert>
      </>
      ) }

      <ConfigurationPreOrderFooter>
        { configurationPreOrderFooterMessage }
      </ConfigurationPreOrderFooter>
    </FormContainer>
  );
};

export default PreOrderForm;
