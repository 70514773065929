import { contentTextParagraph } from '../../Common/messages';
import { LayoutGeneral, CancellationPoliciesLayout } from '../../Layouts';
import { contentSection } from './CancellationPoliciesStyles';

const CancellationPolicies = () => {
  return (
    <LayoutGeneral style={contentSection}>
      <CancellationPoliciesLayout contentTextParagraph={contentTextParagraph} />
    </LayoutGeneral>
  );
};

export default CancellationPolicies;
