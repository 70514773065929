import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
} from '@mui/material';

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  fontSize: 22,
  color: 'rgba(32, 32, 34, 0.6)',
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  fontSize: 40,
  color: '#4433E0',
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
  },
}));

export const TextContent = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 22,
  color: '#202022',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

export const DownloadApp = {
  marginTop: 20,
  backgroundColor: '#F0F1F5',
  borderRadius: 15,
  fontWeight: 900,
  fontSize: 22,
  textDecorationLine: 'underline',
  color: '#4433E0',
  textTransform: 'none',
};

export const Container = {
  width: '100%',
  margin: 0,
  padding: 0,
};
