import {
  Box,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

export const ProductContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '99%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '99%',
  },
  [theme.breakpoints.up('laptop')]: {
    width: '520px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '720px',
  },
}));

export const CardsContainers = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    width: '100vw',
    position: 'relative',
    left: '-16px',
    paddingLeft: '1rem',
    overflow: 'hidden',
  },
}));

export const captionDoesItWork = {
  width: '100%',
  maxWidth: {
    xs: '230px',
    md: 'initial',
  },
  fontWeight: 900,
  fontSize: {
    xs: '2rem',
    md: '1.875rem',
  },
  lineHeight: {
    xs: '2rem',
    md: '2.5rem',
  },
  marginBottom: '1.5rem',
  color: 'primary.main',
};

export const CaptionDoesItWorkSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'scale(1)',
  width: '368px',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    width: '258px',
  },
}));

export const CheckInCaptionSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'scale(1)',
  width: '60%',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const loaderContainerCustom = {
  display: 'grid',
  placeItems: 'center',
  width: '100%',
};

export const ListRecomendationSectionContainer = styled(Box)(({ theme }) => ({
  width: 'max-content',
  marginTop: '3.5rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '2.8rem',
  },
}));

export const ListRecomendationSectionContainerCustom = {
  display: 'grid',
  placeItems: 'center',
  height: '508px',
  width: '368px',
};

export const ListSection = styled('section')(() => ({
  width: 'max-content',
  height: 'max-content',
}));

export const ListRecomendationSectionFooter = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: '588px',
  fontSize: '0.75rem',
  fontWeight: '500',
  lineHeight: '1.25rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '350px',
  },
}));

export const WarrantySection = styled('section')(() => ({
  width: 'max-content',
  marginTop: '3rem',
}));

export const captionWarrantySection = {
  maxWidth: '287px',
};

export const CaptionWarrantySectionSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'scale(1)',
  width: '287px',
  backgroundColor: theme.palette.primary.main,
}));

export const WarrantyContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: '1fr',
  width: '442px',
  gap: '3.75rem',
  [theme.breakpoints.down('sm')]: {
    width: 'max-content',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(auto-fit, minmax(80px, 1fr))',
    gap: '.5rem',
  },
}));

export const loaderCustom = {
  display: 'grid',
  placeItems: 'center',
  height: '100px',
};

export const BillingSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  marginTop: '2.6rem',
  marginBottom: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '2.8rem',
  },
}));

export const PricesContainer = styled('div')(() => ({
  display: 'none',
  flexFlow: 'wrap column',
  gap: '0.375rem',
  width: '100%',
  padding: '0rem 3.6rem 0rem 1.7rem',
  marginTop: '2.5rem',
}));

export const showOrderButton = {
  display: 'none !important',
};

export const CaptionCardsSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '50%',
}));

export const ProgressContainer = styled('div')({
  display: 'grid',
  placeItems: 'center',
  height: '242px',
});

export const BannerSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '30px',
  transform: 'scale(1)',
  height: '379.4px',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    height: '689px',
  },
}));
