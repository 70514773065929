export const containerSkeletonCard = {
  marginTop: '7rem',
  marginBottom: '9rem',
};

export const contentImage = {
  height: '136px',
};

export const cardContentStyle = {
  paddingTop: '1rem',
  paddingBottom: '0rem !important',
};

export const cardMediaStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export const titleCardContent = {
  fontWeight: '900',
  fontSize: '1.75rem',
  lineHeight: '2.390625rem',
  color: 'text.secondary',
};

export const descriptionCardContent = {
  WebkitLineClamp: '5',
  fontWeight: '900',
  fontSize: '1.125rem',
  lineHeight: '1.5625rem',
  color: 'rgba(32, 32, 34, 0.8)',
};
