import { Button } from '@mui/material';
import { pointCarouselStyle } from '../CardCarousel/CardStyles';

const PointService = ({ getRefstButtons }) => {
  return (
    <>
      <Button
        ref={getRefstButtons}
        sx={pointCarouselStyle}
      />
    </>
  );
};

export default PointService;
