export const contentSection = {
  marginTop: {
    xs: '105px', phoneMd: '105px', sm: '105px', md: '162px', lg: '162px', xl: '162px',
  },
  color: 'text.secondary',
  '& > div': {
    marginBottom: '38px',
    fontSize: '1.375rem',
    fontWeight: '800',
    '& > p': {
      fontSize: 'inherit',
      fontWeight: '500',
    },
  },
  '& > div:last-of-type': {
    marginBottom: '62px',
    '& a': {
      fontWeight: '900',
      color: 'primary.main',
    },
  },
};
