import { scheduleFormat } from '../Common';

const isNotEmpty = (items) => {
  const indexAddition = 1;
  return Object.values(items).filter((list) => typeof list === 'string').map((list, index) => {
    return {
      id: index + indexAddition,
      text: list,
    };
  });
};

const getStatesOnly = (data) => {
  const indexAddition = 1;
  const statesItems = data.companies && data.companies.filter(
    companie => companie.state !== null,
  ).map(companieItem => companieItem.state);

  const unduplicatedState = statesItems?.flat().filter(
    (state, index) => statesItems?.flat().indexOf(state) === index,
  ).map((state, index) => {
    return {
      state,
      id: index + indexAddition,
    };
  });

  return unduplicatedState;
};

const scheduleFormatView = (schedule) => {
  let schduleFormat = schedule?.split(':');
  schduleFormat = schduleFormat?.slice(scheduleFormat.hours, scheduleFormat.minutes)?.join(':');
  return schduleFormat;
};

export {
  isNotEmpty,
  getStatesOnly,
  scheduleFormatView,
};
