import {
  Box,
  Modal,
  Skeleton,
  styled,
} from '@mui/material';

export const ModalMobile = styled(Modal)(() => ({
  backgroundCorlor: 'rgba(0, 0, 0, 0.25)',
}));

export const ModalMobileBox = styled(Box)(() => ({
  width: '100%',
  minHeight: '627px',
  position: 'fixed',
  bottom: '0',
  zIndex: '30',
  backgroundColor: '#F9F9F9',
  borderRadius: '20px 20px 0px 0px',
}));

export const ContainerCloseIcon = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '99%',
  margin: '0.7rem 0.5625px 1rem 0rem',
}));

export const ContainerListServices = styled(Box)(() => ({
  width: '100%',
  padding: '0rem 2rem',
}));

export const styleCaptionServiceList = {
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '2rem',
  marginBottom: '1rem',
  color: 'text.secondary',
};

export const CaptionSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  width: '80%',
}));

export const captionSkeletonDesktop = {
  width: '60%',
  margin: '0 auto',
};

export const ContainerCard = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const ModalDesktop = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'fixed',
  backgroundCorlor: 'rgba(0, 0, 0, 0.25)',
  zIndex: '30',
  top: '0',
}));

export const ModalDesktopBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '600px',
  minHeight: '600px',
  borderRadius: '30px',
  backgroundColor: theme.palette.menu.primary,
}));

export const styleCaptionServiceListDesktop = {
  fontSize: '1.80rem',
  textAlign: 'center',
};

export const cardDesktopSelectionOrder = {
  justifyContent: 'space-evenly',
  marginTop: '2rem',
};
