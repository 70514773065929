export const captionYouMightInterested = {
  color: 'text.secondary',
  fontSize: {
    xs: '2rem',
    sm: '2rem',
    md: '2.5rem',
    lg: '2.5rem',
  },
  fontWeight: '900',
  marginBottom: '2.5rem',
};

export const contentCardsBlogPost = {
  marginBottom: '5rem',
  flexWrap: 'wrap',
  '& > a': {
    textDecoration: 'none',
  },
  '& > a > div': {
    marginBottom: '1rem !important',
  },
  '& > a:nth-of-type(2n+1)': {
    marginLeft: '0px !important',
  },
  justifyContent: {
    xs: 'center',
    phoneMd: 'center',
    tablet: 'flex-start',
    md: 'flex-start',
    lg: 'flex-start',
  },
  alignItems: {
    xs: 'center',
    phoneMd: 'center',
    tablet: 'flex-start',
    md: 'flex-start',
    lg: 'flex-start',
  },
};

export const cardBlogPost = {
  width: '100%',
  maxWidth: {
    xs: '342px',
    sm: '342px',
    md: '369px',
    lg: '369px',
  },
  height: '424px',
  marginBottom: '5rem',
  borderRadius: '30px',
  boxShadow: '20px 30px 60px rgba(0, 0, 0, 0.05)',
  '& > img:first-of-type': {
    objectPosition: 'center center',
    backgroundColor: '#7872F2',
    height: '200px',
  },
  overflow: 'hidden',
};

export const contentTextCardBlogPost = {
  paddingTop: '.1rem',
  '& > *': {
    paddingTop: '0px !important',
    paddingLeft: '1.4375rem !important',
  },
  '& > div:first-of-type': {
    marginTop: '2.3rem',
    '& > div > span': {
      color: 'primary.timeRead',
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  '& > div:nth-of-type(2)': {
    marginTop: '-.5rem',
    fontSize: '1.375rem',
    '& > div > span': {
      display: '-webkit-box !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'text.secondary',
      fontWeight: '900',
      fontSize: 'inherit',
    },
  },
  '& > div:last-of-type > div > span': {
    fontSize: '1rem',
  },
  '& > div:last-of-type > div > span:first-of-type': {
    marginRight: '1.5rem',
  },
};

export const directionCards = {
  xs: 'column',
  phoneMd: 'column',
  sm: 'column',
  tablet: 'row',
  md: 'row',
};

export const spacingCards = {
  xs: 2,
  phoneMd: 2,
  sm: 2,
  tablet: 2,
  md: 5.3,
};
