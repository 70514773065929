export const containerGeneral = {
  marginTop: '95px',
  maxWidth: {
    xs: 'initial',
    md: '1271px',
  },
  paddingLeft: {
    xs: '1rem',
    md: '0rem',
  },
  paddingRight: {
    xs: '1rem',
    md: '0rem',
  },
};
