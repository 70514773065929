import { LOCALSTORAGE_NAME_TYPES } from '../../Common';
import { ServiceProduct } from '../../Common/messages';

const {
  ACTION_TYPES,
} = ServiceProduct;

const productServiceState = {
  provider: '',
  companies: [],
  companieProducts: [],
  companieId: '',
  product: '',
  showCaseProduct: [],
  showCaseProductId: {},
  dateData: {
    date: '',
    schedule: '',
  },
  companieSchedules: [],
  location: {},
  productDetail: {},
};

export const initialState = () => {
  return JSON.parse(
    // eslint-disable-next-line no-undef
    localStorage.getItem(LOCALSTORAGE_NAME_TYPES.productService),
  ) || productServiceState;
};

// eslint-disable-next-line default-param-last
export const productServiceReducer = (state = productServiceState, action) => {
  switch (action.type) {
    case ACTION_TYPES.selectProduct: {
      return { ...state, product: action.payload };
    }
    case ACTION_TYPES.selectProvider: {
      return { ...state, provider: action.payload };
    }
    case ACTION_TYPES.selectDate: {
      return { ...state, dateData: { ...state.dateData, date: action.payload } };
    }
    case ACTION_TYPES.selectSchedule: {
      return { ...state, dateData: { ...state.dateData, schedule: action.payload } };
    }
    case ACTION_TYPES.selectLocation: {
      return { ...state, location: action.payload };
    }
    case ACTION_TYPES.productDetail: {
      return { ...state, productDetail: action.payload };
    }
    case ACTION_TYPES.getCompanies: {
      return { ...state, companies: action.payload };
    }
    case ACTION_TYPES.setCompanieId: {
      return { ...state, companieId: action.payload };
    }
    case ACTION_TYPES.getCompanieProducts: {
      return { ...state, companieProducts: action.payload };
    }
    case ACTION_TYPES.getCompanieSchedules: {
      return { ...state, companieSchedules: action.payload };
    }
    case ACTION_TYPES.getShowCaseProduct: {
      return { ...state, showCaseProduct: action.payload };
    }
    case ACTION_TYPES.setShowcaseProductId: {
      return { ...state, showCaseProductId: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
