import {
  PriceLabel,
  PriceProductText,
  PricesContainer,
  priceTextStyled,
} from './styles';

const ProductPrices = ({
  label,
  priceText,
  stylesCustom,
}) => {
  return (
    <PricesContainer sx={stylesCustom}>
      <PriceLabel sx={priceTextStyled}>{ label }</PriceLabel>
      <PriceProductText sx={priceTextStyled}>{ priceText }</PriceProductText>
    </PricesContainer>
  );
};

export default ProductPrices;
