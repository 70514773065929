import { ContainerTraking } from './styles';

const LayoutTraking = ({ children, styles }) => {
  return (
    <ContainerTraking sx={styles}>
      { children }
    </ContainerTraking>
  );
};

export default LayoutTraking;
