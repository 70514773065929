import {
  Descriptioncontent,
  Image,
  ImageAndDescriptionContainer,
  Imagecontent,
} from './styles';

import {
  TYPES_COMPONENTS,
} from '../../Common';

const ImageAndDescription = ({
  image,
  nameProduct,
  description,
}) => {
  return (
    <ImageAndDescriptionContainer>
      <Imagecontent>
        <Image component={TYPES_COMPONENTS.img} src={image} alt={nameProduct} />
      </Imagecontent>
      {description && (
      <Descriptioncontent>
        { description }
      </Descriptioncontent>
      )}
    </ImageAndDescriptionContainer>
  );
};

export default ImageAndDescription;
