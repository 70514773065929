import { useEffect } from 'react';

const Root = ({ children }) => {
  useEffect(() => {
    const root = document.getElementById('root');
    const rootStyles = root?.style;
    rootStyles.overflow = 'hidden';

    return () => {
      rootStyles.overflow = 'initial';
    };
  }, []);

  return (
    <>{ children }</>
  );
};

export default Root;
