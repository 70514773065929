const pathViews = {
  home: '/home',
  subLanding: '/l/:landingId',
  frecuentQuestions: '/frecuent-questions/',
  faqs: '/faqs/',
  cancellationPolicies: '/cancellation-policies/',
  privacyPolicy: '/privacy-policy/',
  termsConditions: '/terms-conditions/',
  notFoundPath: '/*',
  download: '/descargas/',
  servicesSearch: {
    app: '/services-search/',
    chidren: {
      product: 'product',
      preOrder: 'pre-order/',
      succesPreOrder: 'success-pre-order',
    },
  },
  blog: {
    app: '/blog/',
    blogPost: '/blog/post',
    childrens: {
      home: 'posts',
      post: 'post/:id',
      categories: 'category/:id',
    },
  },
  categoriesBlogPost: {
    finances: 'blog/category/',
    Gas: 'blog/category/',
    cleaning: 'blog/category/',
    laundry: 'blog/category/',
    maintenance: 'blog/category/',
    water: 'blog/category/',
    gardening: 'blog/category/',
  },
  pathServices: {
    app: '/services/',
    services: {
      gas: 'pedir-gas/',
      order: 'pre-orden',
    },
  },
  pathTrackOrder: {
    path: '/tracking/:token',
  },
  pathTrackOrderWithQuery: {
    path: '/tracking',
  },
  webViews: {
    serviceBilling: '/service-billing/',
  },
};

const downloads = {
  downLoadApp: 'https://linktr.ee/okboyapp?_branch_match_id=956233290429815681&utm_source=All&utm_campaign=web&utm_medium=marketing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz89Oyq%2FUSywo0MvJzMvWL09NAgB7TUjJGgAAAA%3D%3D',
  iphone: 'https://apps.apple.com/mx/app/okboy/id1502734350',
  android: 'https://play.google.com/store/apps/details?id=app.okboy.cliente&hl=es_MX&gl=US',
};

const urls = {
  whatsApp: 'https://api.whatsapp.com/send?phone=+525610109864&text=%C2%A1Hola!%20Necesito%20ayuda%20en',
  whatsAppEmpresa: 'https://api.whatsapp.com/send?phone=+525610109864&text=%C2%A1Hola!%20Necesito%20ayuda%20en',
  facebook: 'https://www.facebook.com/okboyapp',
  instagram: 'https://www.instagram.com/okboy.app/',
  linkedIn: 'https://www.linkedin.com/company/okboy/',
  tiktok: 'https://www.tiktok.com/@okboy_app?_d=secCgYIASAHKAESPgo8u1DnXiRZC8XjjT%2FjyhcI6%2BNPkjGwzraGwWESJ8Wp6cubIvuRhyGVSTCosy6a7uYPZNDrwTh4eOcy8gfuGgA%3D&_r=1&checksum=e4f2b67dc8bdc095c1db5425ab83bfdd7e74c2e1d1101058f6e72dba9dd6efcf&language=es&sec_uid=MS4wLjABAAAAQJprVvfyg9XafmgDp32JUAb1ZMCginv1ZG7a7PzSGOVMY25ODFzEvZjPHs8atm6e&sec_user_id=MS4wLjABAAAAcklNRT0iTTc30vwxu8nesOlAn2QliYdMqcTNODOIAST4nvGgd5-MSSvqDRwESWiH&share_app_id=1233&share_author_id=6975916579330982918&share_link_id=6294B31F-75EC-480C-AFE4-C61D1013D803&source=h5_m&tt_from=copy&u_code=d981jjdc0mj71g&ug_btm=b9734%2Cb5836&user_id=6757403985491624965&utm_campaign=client_share&utm_medium=ios&utm_source=copy',
  orderGas: 'https://pedir.okboy.app/',
  telephone: 'tel:+523385261422',
  mail: 'mailto:hola@okboy.app',
  mailTracking: 'mailto:hola@waddi.io',
  mailSupport: 'mailto:soporte@okboy.app',
  termsConditions: 'https://pedir.okboy.app/terminos',
  trackingTermsConditions: 'https://www.waddi.io/terminosycondiciones',
  trackingPrivacyPolicy: 'https://www.waddi.io/terminosycondiciones',
  privacyPolicy: 'https://pedir.okboy.app/politicas',
  okBoy: 'https://www.okboy.app/',
  inai: 'https://www.inai.org.mx',
  okboyLinks: 'https://okboy.app.link/okboy-web',
  waddiTracking: 'https://www.waddi.io/srm',
  trackingFacebook: 'https://www.facebook.com/holawaddi/',
  trackingInstagram: 'https://www.instagram.com/holawaddi/',
  trackingLinkedIn: 'https://www.linkedin.com/company/holawaddi',
  trackingTwitter: 'https://twitter.com/HolaWaddi',
};

const linkDefault = '#';

export {
  pathViews,
  downloads,
  urls,
  linkDefault,
};
