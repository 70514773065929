import { variantTypography } from '../../../../Common';
import Captions from '../../../Captions/Captions';
import { stylesLabels } from './styles';

const FormLabels = ({ title, styles }) => {
  return (
    <Captions
      variant={variantTypography.h2}
      style={{ ...stylesLabels, ...styles }}
    >
      { title }
    </Captions>
  );
};

export default FormLabels;
