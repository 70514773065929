import { useEffect, useState } from 'react';
import {
  ContainerOrderButton,
  TextOrderButton,
  TextOrderButtonSkeleton,
  TitleOrderButton,
  TitleOrderButtonSkeleton,
} from './styles';

const OrderButton = ({
  handleEvent,
  containerButtonStyles,
}) => {
  const [fixedButtonData, setFixedButtonData] = useState({
    title: '',
    buttonText: '',
    loading: false,
  });
  useEffect(() => {
    const fixedButtonDataResponse = {
      title: 'Pide tu servicio con un clic',
      buttonText: 'Crear orden',
    };
    setFixedButtonData(prevFixedButtonData => ({
      ...prevFixedButtonData,
      ...fixedButtonDataResponse,
    }));
  }, []);

  return (
    <ContainerOrderButton sx={containerButtonStyles}>
      <TitleOrderButton>
        {
          fixedButtonData.loading
            ? <TitleOrderButtonSkeleton />
            : fixedButtonData.title
        }
      </TitleOrderButton>
      <TextOrderButton onClick={handleEvent}>
        {
          fixedButtonData.loading
            ? <TextOrderButtonSkeleton />
            : fixedButtonData.buttonText
        }
      </TextOrderButton>
    </ContainerOrderButton>
  );
};

export default OrderButton;
