import { PayButton } from './styles';

const PaySelectButton = ({
  children,
  handleEvent,
  value,
  targetRef,
  name,
}) => {
  return (
    <PayButton
      onClick={handleEvent}
      value={value}
      ref={targetRef}
      name={name}
    >
      {children}
    </PayButton>
  );
};

export default PaySelectButton;
