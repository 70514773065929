import { Checkbox } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { typesTarget } from '../../../Common';
import { CheckedContainer, CheckedLabel, checkedStyles } from './styles';

const RememberChecked = ({
  checked,
  handleChange,
  text,
  linkText,
  path,
  disabled,
}) => {
  return (
    <CheckedContainer>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={checkedStyles}
        disabled={disabled}
      />
      <CheckedLabel>
        { text }
        <NavLink to={path} target={typesTarget.TYPE_TARGET_BLANK}>
          { linkText }
        </NavLink>
      </CheckedLabel>
    </CheckedContainer>
  );
};

export default RememberChecked;
