import {
  WhatsApp, Telephone, Mail, Facebook, Instagram, Tiktok, Linkedin,
} from '../../../assets/icons';
import { pathViews, urls } from './paths';

const textHelpFooter = [
  {
    icon: <WhatsApp />,
    text: 'WhatsApp',
    typeNavLink: false,
    path: urls.whatsApp,
  },
  {
    icon: <Telephone />,
    text: '+52 33 8526 1422',
    typeNavLink: false,
    path: urls.telephone,
  },
  {
    icon: <Mail />,
    text: 'hola@okboy.app',
    typeNavLink: false,
    path: urls.mail,
  },
];

const textFollowFooter = [
  {
    icon: <Facebook />,
    text: 'Facebook',
    typeNavLink: false,
    path: urls.facebook,
  },
  {
    icon: <Instagram />,
    text: 'Instagram',
    typeNavLink: false,
    path: urls.instagram,
  },
  {
    icon: <Tiktok />,
    text: 'TikTok',
    typeNavLink: false,
    path: urls.tiktok,
    prevent: (e) => { e.preventDefault(); },
  },
  {
    icon: <Linkedin />,
    text: 'LinkedIn',
    typeNavLink: false,
    path: urls.linkedIn,
  },
];

const textNavigationFooter = [
  {
    text: 'Preguntas frecuentes',
    typeNavLink: true,
    path: '/frecuent-questions',
  },
  {
    text: 'Pedir Gas en Web',
    typeNavLink: false,
    path: urls.orderGas,
    prevent: (e) => { e.preventDefault(); },
  },
];

const textPrivacyFooter = [
  {
    text: 'Términos y condiciones',
    typeNavLink: true,
    path: pathViews.termsConditions,
    prevent: (e) => { e.preventDefault(); },
  },
  {
    text: 'Políticas de privacidad',
    typeNavLink: true,
    path: pathViews.privacyPolicy,
    prevent: (e) => { e.preventDefault(); },
  },
  {
    text: 'Políticas de cancelación',
    typeNavLink: true,
    path: pathViews.cancellationPolicies,
    prevent: (e) => { e.preventDefault(); },
  },
];

const titlesSectionParagraphs = {
  sectionApp: '¡Descarga la App, es gratis!',
  sectionHelp: 'Ayuda',
  sectionNavigation: 'Navegar',
  sectionRRSS: 'Síguenos',
  sectionPrivacy: 'Aviso de privacidad',
  sectionMoreContent: 'Para más contenido',
  sectionNavigationBlogPost: 'Navegar',
  sectionSubscribe: 'Subscríbete',
};

const contentTextSubscrite = {
  placeholder: 'Email',
  send: 'Enviar',
};

const directionOkboy = 'Zamora 187, Condesa Ciudad De México, 06140 México';

export {
  textHelpFooter,
  textFollowFooter,
  textNavigationFooter,
  textPrivacyFooter,
  directionOkboy,
  titlesSectionParagraphs,
  contentTextSubscrite,
};
