import { useEffect, useState } from 'react';
import HelpSectionLayout from '../HelpSectionLayout/HelpSectionLayout';

const ServiceBillingHelpSectionLayout = () => {
  const [landing, setLanding] = useState({
    bannerHelpSection: {
      titles: {
        titleMain: '',
        titleSecondary: '',
      },
      textsUrls: {
        whatsApp: '',
        quetions: '',
      },
      url: '',
    },
  });

  useEffect(() => {
    const dataHelpSectionResponse = {
      titles: {
        titleMain: '¿Necesitas ayuda?',
        titleSecondary: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
      },
      textsUrls: {
        whatsApp: 'Escríbenos',
        quetions: 'Preguntas frecuentes',
      },
      url: 'https://api.whatsapp.com/send?phone=+525610109864&text=%C2%A1Hola!%20Necesito%20ayuda%20en',
    };
    setLanding(prevDataLanding => ({
      ...prevDataLanding,
      bannerHelpSection: {
        titles: { ...dataHelpSectionResponse.titles },
        textsUrls: { ...dataHelpSectionResponse.textsUrls },
        url: dataHelpSectionResponse.url,
      },
    }));
  }, []);

  return (
    <HelpSectionLayout {...landing.bannerHelpSection} />
  );
};

export default ServiceBillingHelpSectionLayout;
