/* eslint-disable no-undef */
import { useState, useEffect } from 'react';

export const useBreakpoint = (breakpointExpected) => {
  const breakpointWindow = window.matchMedia(breakpointExpected);
  const [toggleBreakpoint, setToggleBreakpoint] = useState(breakpointWindow.matches);

  useEffect(() => {
    const handleBreakpoint = (e) => {
      if (e.matches) {
        setToggleBreakpoint(true);
      } else {
        setToggleBreakpoint(false);
      }
    };
    breakpointWindow.addEventListener('change', handleBreakpoint);
  }, [toggleBreakpoint]);

  return [toggleBreakpoint, setToggleBreakpoint, breakpointWindow];
};
