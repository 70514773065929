/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
import { Box } from '@mui/material';
import moment from 'moment';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  breakPoints,
  FORMAT_DATE_CALENDAR,
  SEARCH_SELECT_TYPES,
  SELECTORS_TYPES,
  typesColors,
  TYPES_BUTTONS,
  variantTypography,
} from '../../Common';
import {
  ButtonPrimary,
  ButtonSelect,
  Captions,
  ModalDate,
  SelectDate,
  SelectsFilters,
  DateStatic,
  ModalServices,
  ProviderCarousel,
} from '../../Components';
import { LoaderContext, ServiceSearchContext } from '../../Contexts';
import {
  dateFilter,
  getProvider,
  getServiceType,
  locationFilter,
  serviceFilter,
  setServiceType,
} from '../../store/action';
import { ServiceSearchUtils } from '../../utils';
import {
  ContainerSelects,
  servicesLabelStyles,
  locationLabelStyles,
  SelectContainer,
  selectCustomStyles,
  dateCustomStyles,
  locationsSelectCustomStyles,
  selectActive,
  ContainerSelectsMobile,
  filterButtonMobileStyles,
  modalSelectCustomStyles,
  ContainerButtonSelect,
  captionModalButton,
  styleArrowIcon,
  dateCustomStylesMobile,
  modalTranslate,
  labelHidden,
  providerSelectCustomStyles,
  hiddenDate,
  customServiceSelect,
  locationsSelectCustomWidth,
} from './styles';
import {
  arrowIcon,
  buttonSearchDescktop,
  buttonSearchMobile,
  locationButton,
  modalLocationStyles,
} from '../../Components/ContentSelectors/styles';
import { useBreakpoint } from '../../hooks';
import { FilterIcon } from '../../../assets/icons';
import ModalsMobileLayout from './ModalsMobileLayout';
import { DataServicesSearch } from '../../api';
import { ServicesSearchMessage } from '../../Common/messages';

const {
  handleModal,
  iconRotate,
  selectButtonChangeColor,
} = ServiceSearchUtils;

const {
  date,
  modalsContainer,
  service: nameServiceModal,
  location: modalLocation,
  provider,
} = SEARCH_SELECT_TYPES;

const {
  dayMonthYear,
} = FORMAT_DATE_CALENDAR;

const {
  getCategories,
} = DataServicesSearch;

const {
  nameRegistryUUid,
  providerData,
} = ServicesSearchMessage;

const {
  buttons,
} = typesColors;

const SelectorsLayout = ({
  customLabel,
  customSelect,
  titles,
  titlesSelectsMobile,
  textModals,
  textMobileModals,
}) => {
  const [searchServices, dispatch] = useContext(ServiceSearchContext);
  const [openModals, setOpenModals] = useState({
    modalService: false,
    modalLocation: false,
    modalDate: false,
    modalsContainer: false,
    valueService: searchServices.service,
    valueLocation: searchServices.location,
    serviceType: searchServices.serviceType,
    valueProvider: searchServices.provider,
  });
  const servicesRef = useRef(null);
  const locationRef = useRef(null);
  const refDateIcon = useRef(null);
  const providerRef = useRef(null);
  const refServiceIcon = useRef(null);
  servicesRef.current = [];
  locationRef.current = [];
  refDateIcon.current = [];
  providerRef.current = [];
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const [, setLoading] = useContext(LoaderContext);

  useEffect(() => {
    setOpenModals(prevOpenModals => ({
      ...prevOpenModals,
      valueService: searchServices?.service,
    }));
  }, [searchServices?.service]);

  useEffect(() => {
    const icon = refDateIcon.current;
    iconRotate(openModals.modalDate, icon);
  }, [openModals.modalDate, openModals.modalService, openModals.modalLocation]);

  useEffect(() => {
    if (toggleBreakpoint) {
      servicesRef.current = servicesRef.current.filter(service => service);
      servicesRef.current.forEach(serviceRef => {
        const element = serviceRef.style;
        if (searchServices.service === serviceRef.value) {
          element.backgroundColor = 'rgba(68, 51, 224, 0.2)';
        } else {
          element.backgroundColor = 'transparent';
        }
      });

      providerRef.current = providerRef.current.filter(service => service);
      providerRef.current.forEach(providerElement => {
        const element = providerElement.style;
        if (searchServices.provider === providerElement.value) {
          element.backgroundColor = buttons.currentProviderButton;
        } else {
          element.backgroundColor = buttons.defaultProviderButton;
        }
      });

      locationRef.current = locationRef.current.filter(service => service);
      locationRef.current.forEach(locationElement => {
        const element = locationElement.style;
        if (searchServices.location === locationElement.value) {
          element.backgroundColor = 'rgba(68, 51, 224, 0.2)';
        } else {
          element.backgroundColor = 'transparent';
        }
      });
    }
  }, [
    toggleBreakpoint,
    openModals.modalService,
    openModals.modalLocation,
    openModals.modalsContainer,
  ]);

  const registryUuidValue = JSON.parse(sessionStorage.getItem(nameRegistryUUid));

  const handleSelect = (e, selectType, items) => {
    const serviceType = items.find(item => e.target.value === item?.description);
    if (selectType === SELECTORS_TYPES.valueService) {
      dispatch(setServiceType(serviceType.description));
    }
    setOpenModals(prevOpenModals => {
      if (selectType === SELECTORS_TYPES.valueService) {
        return {
          ...prevOpenModals,
          [selectType]: e.target.value,
          serviceType: { ...serviceType, e },
        };
      } return {
        ...prevOpenModals,
        [selectType]: e.target.value,
      };
    });
  };
  // eslint-disable-next-line no-undef
  const handleSearch = () => {
    dispatch(serviceFilter({
      service: openModals.valueService,
      serviceType: openModals?.serviceType?.registryUuid || openModals?.serviceType,
    }));

    dispatch(locationFilter(openModals.valueLocation));

    dispatch(getProvider(openModals.valueProvider));
  };

  const handleSelectMobile = (e, modalName, items) => {
    const { target } = e;
    const serviceType = items.find(item => item.description === target.value);
    if (modalName === nameServiceModal) {
      selectButtonChangeColor(target, SELECTORS_TYPES.valueService, setOpenModals, servicesRef);
      setOpenModals(prevOpenModals => {
        return {
          ...prevOpenModals,
          serviceType,
        };
      });
    }
    if (modalName === modalLocation) {
      selectButtonChangeColor(target, SELECTORS_TYPES.valueLocation, setOpenModals, locationRef);
    }
  };

  const handleOpen = (e, modalName, openOrClose) => {
    handleModal(true, false, setOpenModals, modalName, openOrClose);
  };

  const handleOpenModalsContainer = (
    e,
    modalName,
    openOrClose,
    modalNameClose,
    openOrCloseModals,
  ) => {
    handleModal(true, false, setOpenModals, modalName, openOrClose);
    handleModal(false, true, setOpenModals, modalNameClose, openOrCloseModals);
  };

  const handleClose = (e, modalName, openOrClose) => {
    handleModal(false, true, setOpenModals, modalName, openOrClose);
  };

  const mobileHandleClose = (e, modalName, openOrClose) => {
    handleModal(false, true, setOpenModals, modalName, openOrClose);
    handleModal(true, false, setOpenModals, modalsContainer, openModals.modalsContainer);
  };

  const handleDate = (dateValue, typeModal, modalDate) => {
    const { _d: day } = dateValue;
    if (!typeModal) {
      return dispatch(dateFilter(day));
    }
    handleModal(false, true, setOpenModals, typeModal, modalDate);
    return dispatch(dateFilter(day));
  };

  const handleSearchMobile = (e, { modalName, openModal }) => {
    setOpenModals(prevModal => ({
      ...prevModal,
      [modalName]: !openModal,
    }));

    handleModal(true, false, setOpenModals, modalsContainer, openModals.modalsContainer);

    if (toggleBreakpoint && modalName === nameServiceModal) {
      dispatch(serviceFilter({
        service: openModals.valueService,
        serviceType: openModals?.serviceType?.registryUuid || openModals?.serviceType,
      }));
    }

    if (toggleBreakpoint && modalName === modalLocation) {
      dispatch(locationFilter(openModals.valueLocation));
      getCategories(registryUuidValue, openModals.valueLocation).then(servicesTypeResponse => {
        const { data } = servicesTypeResponse;
        dispatch(getServiceType(data));
      }).finally(() => setLoading(false));
    }
  };

  const handleModalsContainer = (e, { modalName, openModal }) => {
    handleModal(false, true, setOpenModals, modalName, openModal);
  };

  const handleProvider = (e, modalName) => {
    const { target } = e;
    if (toggleBreakpoint && modalName === provider) {
      selectButtonChangeColor(
        target,
        SELECTORS_TYPES.valueProvider,
        setOpenModals,
        providerRef,
        buttons.currentProviderButton,
        buttons.defaultProviderButton,
      );
      dispatch(getProvider(target.value));
    }
  };

  const servicesStyles = openModals.valueService
    ? { ...selectCustomStyles, ...selectActive, ...customServiceSelect }
    : selectCustomStyles;

  const locationsStyles = openModals.valueLocation
    ? {
      ...selectCustomStyles,
      ...locationsSelectCustomStyles,
      ...selectActive,
      ...locationsSelectCustomWidth,
    }
    : { ...selectCustomStyles, ...locationsSelectCustomStyles };

  const dateStyles = searchServices.date
    ? { ...dateCustomStyles, ...selectActive }
    : dateCustomStyles;

  const providerStyles = openModals.valueProvider
    ? { ...selectCustomStyles, ...locationsSelectCustomStyles, ...selectActive }
    : { ...selectCustomStyles, ...locationsSelectCustomStyles };

  const isLabelHidden = !openModals.valueService ? servicesLabelStyles : labelHidden;

  return (
    <>
      <ContainerSelects>
        <SelectContainer>
          <SelectsFilters
            items={searchServices.servicesSelectors}
            value={openModals.valueService}
            label={customLabel}
            selectId={customSelect}
            title={
              !searchServices.service
                ? textMobileModals?.service.label
                : searchServices.service
            }
            placeholder={titles.titleServiceLabel}
            selectorsStyles={servicesStyles}
            inputLabelStyle={isLabelHidden}
            refElements={servicesRef.current}
            handleSelectors={handleSelect}
            selectType={SELECTORS_TYPES.valueService}
          />
        </SelectContainer>
        <SelectContainer>
          <SelectsFilters
            items={searchServices.locationSelectors}
            value={openModals.valueLocation}
            label={customLabel}
            selectId={customSelect}
            title={titles.titleLocationLabel}
            placeholder={titles.titleLocationLabel}
            selectorsStyles={locationsStyles}
            inputLabelStyle={!openModals.valueLocation ? locationLabelStyles : labelHidden}
            refElements={locationRef.current}
            handleSelectors={handleSelect}
            selectType={SELECTORS_TYPES.valueLocation}
          />
        </SelectContainer>
        <SelectContainer sx={hiddenDate}>
          <SelectDate
            onClick={(e) => handleOpen(e, date, openModals.modalDate)}
            sx={dateStyles}
          >
            <Box>
              {
                searchServices.date
                  ? moment(searchServices.date).format(dayMonthYear)
                  : titlesSelectsMobile.date
            }
            </Box>
            <KeyboardArrowDownIcon
            // eslint-disable-next-line no-return-assign
              ref={(el) => refDateIcon.current = el}
            />
          </SelectDate>

          <ModalDate
            modalName={date}
            dateValue={searchServices.date}
            handleClose={handleClose}
            handleDate={handleDate}
            openModal={openModals.modalDate}
          />
        </SelectContainer>

        <SelectContainer sx={providerSelectCustomStyles}>
          <SelectsFilters
            items={providerData}
            value={openModals.valueProvider}
            label={customLabel}
            selectId={customSelect}
            title={titles.titleProvider}
            placeholder={titles.titleProviderv}
            selectorsStyles={providerStyles}
            inputLabelStyle={!openModals.valueProvider ? locationLabelStyles : labelHidden}
            refElements={providerRef.current}
            handleSelectors={handleSelect}
            selectType={SELECTORS_TYPES.valueProvider}
          />
        </SelectContainer>

        <SelectContainer>
          <ButtonPrimary
            styles={{ ...buttonSearchMobile, ...buttonSearchDescktop }}
            typeButton={TYPES_BUTTONS.button}
            handleToggle={handleSearch}
          >
            { titles.titleSerachButton }
          </ButtonPrimary>
        </SelectContainer>
      </ContainerSelects>

      <ContainerSelectsMobile>

        <ButtonPrimary
          styles={{ ...buttonSearchMobile, ...filterButtonMobileStyles }}
          typeButton={TYPES_BUTTONS.buttonWithParams}
          params={{ modalName: modalsContainer, openModal: openModals.modalsContainer }}
          handleToggle={handleModalsContainer}
        >
          <FilterIcon />
          { titles.titleSearchButtonMobile }
        </ButtonPrimary>

        <ModalsMobileLayout
          openModal={openModals.modalsContainer}
          handleClose={handleClose}
          modalName={modalsContainer}
          title={titles.titleModalsContainer}
          modalContainer={modalSelectCustomStyles}
          modalSelectStyle={openModals.modalsContainer ? modalTranslate : undefined}
          styleArrowIcon={styleArrowIcon}
        >
          <ContainerButtonSelect>
            <ContainerButtonSelect>
              <Captions
                variant={variantTypography.h5}
                style={captionModalButton}
              >
                { textMobileModals?.location.title }
              </Captions>
              <ButtonSelect
                handleClick={
                  (e) => handleOpenModalsContainer(
                    e,
                    modalLocation,
                    openModals.modalLocation,
                    modalsContainer,
                    openModals.modalsContainer,
                  )
                }
              >
                {
                !searchServices.location
                  ? textMobileModals?.location.label
                  : searchServices.location
                }
                <KeyboardArrowDownIcon ref={(el) => refServiceIcon.current = el} sx={arrowIcon} />
              </ButtonSelect>
            </ContainerButtonSelect>
          </ContainerButtonSelect>

          <ContainerButtonSelect>
            <ContainerButtonSelect>
              <Captions
                variant={variantTypography.h5}
                style={captionModalButton}
              >
                { textMobileModals?.service.title }
              </Captions>
              <ButtonSelect
                handleClick={
                  (e) => handleOpenModalsContainer(
                    e,
                    nameServiceModal,
                    openModals.modalService,
                    modalsContainer,
                    openModals.modalsContainer,
                  )
                }
              >
                {
                !searchServices.service
                  ? textMobileModals?.service.label
                  : searchServices.service
                }
                <KeyboardArrowDownIcon ref={(el) => refServiceIcon.current = el} sx={arrowIcon} />
              </ButtonSelect>
            </ContainerButtonSelect>
          </ContainerButtonSelect>

          <ContainerButtonSelect sx={providerSelectCustomStyles}>
            <Captions
              variant={variantTypography.h5}
              style={captionModalButton}
            >
              { textMobileModals?.provider.title }
            </Captions>
            <ProviderCarousel
              items={providerData}
              handleEvent={(e) => handleProvider(e, provider)}
              targetRef={(e) => {
                providerRef.current = [...providerRef.current, e];
              }}
            />
          </ContainerButtonSelect>

          <ContainerButtonSelect sx={hiddenDate}>
            <ContainerButtonSelect>
              <Captions
                variant={variantTypography.h5}
                style={captionModalButton}
              >
                { textMobileModals?.date.title }
              </Captions>
              <DateStatic
                handleDate={handleDate}
                value={searchServices.date}
                stylesCustom={dateCustomStylesMobile}
              />
            </ContainerButtonSelect>
          </ContainerButtonSelect>
        </ModalsMobileLayout>

        <ModalServices
          handleClose={mobileHandleClose}
          handleSearch={handleSearchMobile}
          handleSelect={handleSelectMobile}
          modal={openModals.modalLocation}
          nameServiceModal={modalLocation}
          searchServices={openModals.valueLocation}
          items={searchServices.locationSelectors}
          itemsRef={locationRef}
          textModals={textModals}
          titleModal={titles.titleLocationModal}
          styles={{ button: locationButton, modal: modalLocationStyles }}
        />

        <ModalServices
          handleClose={mobileHandleClose}
          handleSearch={handleSearchMobile}
          handleSelect={handleSelectMobile}
          modal={openModals.modalService}
          nameServiceModal={nameServiceModal}
          searchServices={openModals.valueService}
          items={searchServices.servicesSelectors}
          itemsRef={servicesRef}
          textModals={textModals}
          titleModal={titles.titleServiceModal}
        />

      </ContainerSelectsMobile>
    </>
  );
};

export default SelectorsLayout;
