import { Fragment, useState } from 'react';
import { Box } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { tabValues } from '../../Common/enums';
import {
  ContainerTitles,
  includeIcon,
  includeList,
  includeNotIcon,
  ItemTitle,
  ListContainerDesktop,
  ListContainerMobile,
  ListContent,
  notIncludeList,
  ServiceIncludedTitle,
  TextsContainer,
} from './styles';
import { TYPES_COMPONENTS } from '../../Common';

const ServiceIncluded = ({
  titles,
  listIncludeService,
  listNotIncludeService,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };
  const iconPosition = 'end';
  return (
    <>
      <ListContainerDesktop>
        <TextsContainer>
          {
                titles?.map((title, i) => {
                  return (
                    <Fragment key={title.id}>
                      <ServiceIncludedTitle>
                        <Box component={TYPES_COMPONENTS.span}>{ title.text }</Box>
                        {
                      i === tabValues.includeTitle
                        ? <CheckCircleOutlinedIcon sx={includeIcon} />
                        : <CancelOutlinedIcon sx={includeNotIcon} />
                      }
                      </ServiceIncludedTitle>
                      <ListContent sx={i === tabValues.includeTitle ? includeList : notIncludeList}>
                        { (
                          i === tabValues.includeTitle
                            ? listIncludeService
                            : i === tabValues.notIncludeTitle
                              ? listNotIncludeService
                              : []
                        )?.map((list) => {
                          return (
                            <li key={list.id}>
                              { list.text }
                            </li>
                          );
                        })}
                      </ListContent>
                    </Fragment>
                  );
                })
            }
        </TextsContainer>
      </ListContainerDesktop>

      {!!tabValue && (
      <ListContainerMobile>
        <ContainerTitles
          onChange={handleTabChange}
          value={tabValue}
        >
          {
                titles?.map((item, i) => {
                  return (
                    <ItemTitle
                      key={item.id}
                      label={item.text}
                      icon={
                        i === tabValues.includeTitle
                          ? <CheckCircleOutlinedIcon sx={includeIcon} />
                          : <CancelOutlinedIcon sx={includeNotIcon} />
                      }
                      iconPosition={iconPosition}
                    />
                  );
                })
          }
        </ContainerTitles>
        <ListContent>
          { (
            tabValue === tabValues.includeTitle
              ? listIncludeService
              : tabValue === tabValues.notIncludeTitle
                ? listNotIncludeService
                : []
          )?.map((list) => {
            return (
              <li key={list.id}>
                { list.text }
              </li>
            );
          })}
        </ListContent>
      </ListContainerMobile>
      )}
    </>
  );
};

export default ServiceIncluded;
