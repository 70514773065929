import { useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import { CircularProgress } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { breakPoints, lengthCardsSwiper, variantTypography } from '../../Common';
import { Captions, DoesItWorkCard } from '../../Components';
import {
  CaptionCardsSkeleton,
  captionDoesItWork,
  CardsContainers,
  ProgressContainer,
} from './styles';
import 'swiper/css';
import './styles.css';
import { useBreakpoint } from '../../hooks';

const CardsSpaceBetween = 16;

const CardLayout = () => {
  const [cardsSectionData, setCardsSectionData] = useState({
    title: '',
    cards: [],
    loading: false,
  });
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const cardClass = 'does-it-work-carousel';

  useEffect(() => {
    const cardsSectionDataResponse = {
      title: 'Cómo funciona Okboy',
      cards: [
        {
          id: 1,
          title: '1',
          cardsContent: 'Selecciona el servicio que necesitas.',
        },
        {
          id: 2,
          title: '2',
          cardsContent: 'Elige entre los cientos de proveedores que tenemos y elige la mejor opción para ti.',
        },
        {
          id: 3,
          title: '3',
          cardsContent: 'Sigue el trayecto de tu servicio',
        },
        {
          id: 4,
          title: '4',
          cardsContent: 'Disfruta de tu servicio con garantías exclusivas',
        },
      ],
    };
    return setCardsSectionData(prevCardsSectionData => ({
      ...prevCardsSectionData,
      title: cardsSectionDataResponse.title,
      cards: cardsSectionDataResponse.cards,
    }));
  }, []);

  return (
    <CardsContainers>
      <Captions
        variant={variantTypography.h2}
        style={captionDoesItWork}
      >
        {cardsSectionData.loading ? <CaptionCardsSkeleton /> : cardsSectionData.title}
      </Captions>
      {
        cardsSectionData.loading ? (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        ) : (
          !cardsSectionData.cards ? null : (
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode]}
              freeMode={true}
              slidesPerView={
                toggleBreakpoint ? lengthCardsSwiper.oneCard : lengthCardsSwiper.forCard
              }
              spaceBetween={CardsSpaceBetween}
              className={cardClass}
            >
              {
                cardsSectionData.cards?.map(item => {
                  return (
                    <SwiperSlide key={item.id}>
                      <DoesItWorkCard
                        contentText={item.cardsContent}
                        numberText={item.title}
                      />
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          )

        )
      }

    </CardsContainers>
  );
};

export default CardLayout;
