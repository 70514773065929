import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { zoom } from '../../Common';
import { MapContainer, mapStyles } from './styles';

const Map = ({ styles, lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (lat && lng) {
      setCoordinates({ lat, lng });
    }
  }, [lat, lng, coordinates?.lat, coordinates?.lng]);

  const onLoad = useCallback((mapData) => {
    // eslint-disable-next-line no-undef
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    mapData.fitBounds(bounds);
  }, [lat, lng, coordinates?.lat, coordinates?.lng]);

  const onUnmount = useCallback(() => {
    setCoordinates(null);
  }, []);
  return (
    isLoaded && (
    <MapContainer sx={styles}>
      <GoogleMap
        zoom={zoom}
        mapContainerStyle={mapStyles}
        center={coordinates}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {
        coordinates ? <Marker position={coordinates} /> : null
      }
      </GoogleMap>
    </MapContainer>
    )
  );
};

export default memo(Map);
