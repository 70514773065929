import {
  Box, FormControl, styled, Typography,
} from '@mui/material';

/* Caption */

export const captionStyles = {
  maxWidth: {
    xs: '330px',
    md: 'initial',
  },
  width: '100%',
  fontWeight: '900',
  fontSize: {
    xs: '2rem',
    md: '2.5rem',
  },
  lineHeight: '2.8125rem',
  marginBottom: {
    xs: '0.5rem',
    md: '1.125rem',
  },
  marginLeft: {
    xs: '1rem',
    md: 'initial',
  },
};

/* Selects */

export const ContainerSelects = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, max-content)',
    gridTemplateRows: '58px',
    placeItems: 'flex-start',
    width: '941px',
    height: '58px',
    gap: '1.5rem',
    marginBottom: '2.375rem',
  },
  display: 'none',
}));

export const SelectContainer = styled(FormControl)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export const selectCustomStyles = {
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  backgroundColor: 'background.selects',
  borderRadius: '15px',
  alignItems: 'center',
  borderRight: '0px solid transparent',

  '&.MuiInputBase-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '161px !important',
  },
  '.MuiInputBase-input': {
    width: 'auto !important',
    height: 'auto',
    padding: '2rem 2rem',
    paddingRight: '3rem !important',
    '& span': {
      display: 'block',
      minWidth: '79px',
    },
  },
};

export const customServiceSelect = {
  textAling: 'center',
  '&.MuiInputBase-root': {
    textAling: 'center',
    width: 'max-content !important',
  },
};

export const servicesLabelStyles = {
  transform: 'translate(41px, 17px) scale(1) !important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  width: '68px',
  '&.Mui-focused': {
    color: 'text.secondary',
  },
};

export const labelHidden = {
  display: 'none',
};

export const locationsSelectCustomStyles = {
  '&.MuiInputBase-root': {
    width: '175px !important',
  },
  '.MuiInputBase-input': {
    padding: '2rem 2rem',
    paddingRight: '3rem !important',
  },
};

export const locationsSelectCustomWidth = {
  textAlign: 'center',
  '&.MuiInputBase-root': {
    width: 'max-content !important',
    textAlign: 'center',
  },
};

export const providerSelectCustomStyles = {
  display: 'none !important',
};

export const selectActive = {
  backgroundColor: 'rgba(120, 114, 242, 0.2)',
};

export const locationLabelStyles = {
  transform: 'translate(28px, 17px) scale(1) !important',
  '&.Mui-focused': {
    color: 'text.secondary',
  },
};

export const dateCustomStyles = {
  width: '100%',
  height: '100%',
  padding: '1rem 2rem',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  backgroundColor: 'background.selects',
  borderRadius: '15px',
  marginTop: '0px',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '1.36625rem',
};

export const ContainerSelectsMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column wrap',
  width: '94%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '2.375rem',
  gap: '1rem',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

/* Cards Container */

export const CardContainer = styled('div')(() => ({
  width: 'max-content',
  height: 'max-content',
  cursor: 'pointer',
  transition: 'box-shadow .3s ease, top .3s ease',
  position: 'relative',
  top: '0',
  '&:hover': {
    top: '-10px',
    boxShadow: '25px 36px150px rgba(0, 0, 0, 0.5)',
  },
}));

export const cardsContainer = {
  flexFlow: 'row wrap',
  gap: '1.1rem',
  justifyContent: {
    xs: 'center',
    md: 'flex-start',
  },
  width: {
    xs: '85%',
    sm: '100%',
    md: 'initial',
  },
};

/* Modals Container  */
export const modalSelectCustomStyles = {
  alignItems: 'flex-end',
  display: {
    xs: 'flex',
    md: 'none',
  },
};

export const SelectsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '373px',
  backgroundColor: theme.palette.menu.primary,
  borderRadius: '30px 30px 0px 0px',
  padding: '1.625rem 2rem 0rem',
  transform: 'translateY(100%)',
  transition: 'transform .3s ease',
  transitionDelay: '.2s',
}));

export const modalTranslate = {
  transform: 'translateY(0%)',
};

export const IconAndTitleModalContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'max-content',
  marginBottom: '1.5rem',
}));

export const TitleModal = styled(Typography)(({ theme }) => ({
  fontWeight: '900',
  fontSize: '22px',
  lineHeight: '30px',
  color: theme.palette.text.secondary,
}));

export const styleArrowIcon = {
  position: 'relative',
  right: '0%',
  top: '0%',
};

export const filterButtonMobileStyles = {
  backgroundColor: 'rgba(68, 51, 224, 0.2)',
  color: 'primary.main',
  width: '139px',
  '& svg': {
    marginRight: '1rem',
  },
};

export const ContainerButtonSelect = styled(Box)(() => ({
  width: '100%',
  height: 'max-content',
  marginBottom: '1rem',
}));

export const captionModalButton = {
  widht: '100%',
  marginBottom: '0.5rem',
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.6875rem',
  color: 'text.secondary',
};

export const dateCustomStylesMobile = {
  '& .MuiPickerStaticWrapper-content': {
    width: '100%',
    minWidth: 'initial',
  },
  '& .MuiCalendarOrClockPicker-root': {
    '& > div, & .MuiCalendarPicker-root': {
      width: '100%',
    },
  },
};

export const captionServicesNofound = {
  width: {
    xs: '91%',
    md: '100%',
  },
  margin: {
    xs: '0 auto',
    md: 'initial',
  },
  fontSize: {
    xs: '1.5rem',
    md: '2.5rem',
  },
  fontWeight: '900',
};

export const hiddenDate = {
  display: 'none',
};
