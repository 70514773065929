/* eslint-disable no-undef */
import { ACTIONS_TYPES, LOCALSTORAGE_NAME_TYPES } from '../Common';

const {
  SERVICE_FILTER,
  LOCATION_FILTER,
  DATE_FILTER,
  GET_SERVICES_TYPE,
  GET_SERVICE_TYPE,
  GET_SERVICES_SELECTORS,
  GET_LOCATION_SELECTORS,
  GET_PROVIDER,
  SET_SERVICE_TYPE,
} = ACTIONS_TYPES;

const searchServices = {
  service: '',
  serviceType: '',
  servicesData: [],
  servicesSelectors: [],
  location: '',
  locationSelectors: '',
  provider: '',
  date: '',
};

export const initSearchServices = () => {
  return JSON.parse(sessionStorage.getItem(LOCALSTORAGE_NAME_TYPES.services)) || searchServices;
};

// eslint-disable-next-line default-param-last
export const serviceReducer = (state = searchServices, action) => {
  switch (action.type) {
    case SERVICE_FILTER: {
      return { ...state, service: action.payload.service, serviceType: action.payload.serviceType };
    }
    case SET_SERVICE_TYPE: {
      return { ...state, service: action.payload };
    }
    case LOCATION_FILTER: {
      return { ...state, location: action.payload };
    }
    case DATE_FILTER: {
      return { ...state, date: action.payload };
    }
    case GET_SERVICES_TYPE: {
      return { ...state, servicesData: action.payload, servicesSelectors: action.payload };
    }
    case GET_SERVICE_TYPE: {
      return { ...state, servicesData: action.payload };
    }
    case GET_LOCATION_SELECTORS: {
      return { ...state, locationSelectors: action.payload };
    }
    case GET_PROVIDER: {
      return { ...state, provider: action.payload };
    }
    case GET_SERVICES_SELECTORS: {
      return { ...state, servicesSelectors: action.payload };
    }
    default:
      return state;
  }
};
