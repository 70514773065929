import { useState, useEffect } from 'react';
import { Box, CardMedia } from '@mui/material';
import { DownloadTextContainer } from '../../Components';
import {
  Container,
  SectionContainer,
  TextContainer,
  ImageContainer,
  sectionSecondStyles,
  sectionContainerSecondStyles,
} from './style';
import { coverageEnums } from '../../Common';

const COMPONENT_TYPE = 'img';
const idElement = 'cobertura';

const CoverageLayout = () => {
  const [coverageData, setCoverageData] = useState([]);

  useEffect(() => {
    const dataCoverageResponse = {
      dataCoverageSectionData: [
        {
          id: 2,
          image: {
            data: [
              {
                id: 30,
                attributes: {
                  name: 'expense.png',
                  alternativeText: 'expense.png',
                  caption: 'expense.png',
                  width: 1220,
                  height: 938,
                  formats: {
                    large: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/large_expense_721d886737.png',
                      hash: 'large_expense_721d886737',
                      mime: 'image/png',
                      name: 'large_expense.png',
                      path: null,
                      size: 350.33,
                      width: 1000,
                      height: 769,
                    },
                    small: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_expense_721d886737.png',
                      hash: 'small_expense_721d886737',
                      mime: 'image/png',
                      name: 'small_expense.png',
                      path: null,
                      size: 107.75,
                      width: 500,
                      height: 384,
                    },
                    medium: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/medium_expense_721d886737.png',
                      hash: 'medium_expense_721d886737',
                      mime: 'image/png',
                      name: 'medium_expense.png',
                      path: null,
                      size: 213.71,
                      width: 750,
                      height: 577,
                    },
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_expense_721d886737.png',
                      hash: 'thumbnail_expense_721d886737',
                      mime: 'image/png',
                      name: 'thumbnail_expense.png',
                      path: null,
                      size: 26.3,
                      width: 203,
                      height: 156,
                    },
                  },
                  hash: 'expense_721d886737',
                  ext: '.png',
                  mime: 'image/png',
                  size: 98.37,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/expense_721d886737.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T23:33:11.547Z',
                  updatedAt: '2022-08-23T23:33:11.547Z',
                },
              },
            ],
          },
          textDownloader: {
            id: 3,
            title: 'Análisis',
            subTitle: 'Toma el control de tu hogar',
            contentText: '¿Sabes cuánto gastas mes a mes?,\n¿Quieres saber cuánto te rinde el gas?\nCon la selección de reportes es más fácil.',
            button: 'Descarga la App',
          },
        },
        {
          id: 1,
          image: {
            data: [
              {
                id: 29,
                attributes: {
                  name: 'address.png',
                  alternativeText: 'address.png',
                  caption: 'address.png',
                  width: 1220,
                  height: 956,
                  formats: {
                    large: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/large_address_36e381c243.png',
                      hash: 'large_address_36e381c243',
                      mime: 'image/png',
                      name: 'large_address.png',
                      path: null,
                      size: 295.45,
                      width: 1000,
                      height: 784,
                    },
                    small: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_address_36e381c243.png',
                      hash: 'small_address_36e381c243',
                      mime: 'image/png',
                      name: 'small_address.png',
                      path: null,
                      size: 87.76,
                      width: 500,
                      height: 392,
                    },
                    medium: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/medium_address_36e381c243.png',
                      hash: 'medium_address_36e381c243',
                      mime: 'image/png',
                      name: 'medium_address.png',
                      path: null,
                      size: 177.47,
                      width: 750,
                      height: 588,
                    },
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_address_36e381c243.png',
                      hash: 'thumbnail_address_36e381c243',
                      mime: 'image/png',
                      name: 'thumbnail_address.png',
                      path: null,
                      size: 19.98,
                      width: 199,
                      height: 156,
                    },
                  },
                  hash: 'address_36e381c243',
                  ext: '.png',
                  mime: 'image/png',
                  size: 94.84,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/address_36e381c243.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T23:32:22.651Z',
                  updatedAt: '2022-08-23T23:32:22.651Z',
                },
              },
            ],
          },
          textDownloader: {
            id: 2,
            title: 'Cobertura',
            subTitle: '¡Hola México!',
            contentText: 'Paga tus recibos y recargas en todo **México.**\nPide otros servicios en **Guadalajara, Colima,\nCiudad de México, Toluca, San Luis Potosí,\nAguascalientes y León.**\n¡Descarga la App y descubre qué servicios\nllegan a tu hogar!',
            button: 'Descarga la App',
          },
        },
      ],
      dataSectionBenefits: null,
    };
    setCoverageData(dataCoverageResponse.dataCoverageSectionData);
  }, []);

  const { sectionSecond } = coverageEnums;
  return (
    <Box id={idElement} sx={Container}>
      {
        coverageData.map((coverage, index) => {
          let image = coverage.image.data[0] || {};
          image = image.attributes;
          return (
            <SectionContainer
              key={coverage.id}
              sx={index === sectionSecond && sectionContainerSecondStyles}
            >
              <TextContainer sx={index === sectionSecond && sectionSecondStyles}>
                <DownloadTextContainer
                  header={coverage.textDownloader.title}
                  title={coverage.textDownloader.subTitle}
                  text={coverage.textDownloader.contentText}
                  downloadText={coverage.textDownloader.button}
                />
              </TextContainer>
              <ImageContainer>
                <CardMedia
                  component={COMPONENT_TYPE}
                  image={image.url}
                  alt={image.alternativeText}
                />
              </ImageContainer>
            </SectionContainer>
          );
        })
      }
    </Box>
  );
};

export default CoverageLayout;
