const FacebookCircle = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M11.6668 11.2501H13.7502L14.5835 7.91675H11.6668V6.25008C11.6668 5.39175 11.6668 4.58341 13.3335 4.58341H14.5835V1.78341C14.3118 1.74758 13.286 1.66675 12.2027 1.66675C9.94016 1.66675 8.3335 3.04758 8.3335 5.58341V7.91675H5.8335V11.2501H8.3335V18.3334H11.6668V11.2501Z' fill='white' />
    </svg>
  );
};

export default FacebookCircle;
