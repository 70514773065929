const Telephone = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.197 21.999a7.688 7.688 0 0 1-2.656-.48 16.652 16.652 0 0 1-5.713-4.12l-.161-.16a16.803 16.803 0 0 1-4.182-5.72c-.928-2.48-.324-5.96 2.452-8.719a2.292 2.292 0 0 1 1.61-.8c.885 0 1.61.68 2.576 1.64l.16.16c1.61 1.6 1.771 3.56.323 5l-.403.36c-.523.48-1.086.96-1.086 1.64a5.84 5.84 0 0 0 1.93 3.2c1.288 1.28 2.374 1.92 3.18 1.92.804 0 1.166-.56 1.65-1.08l.361-.4a3.181 3.181 0 0 1 2.375-.96 4.034 4.034 0 0 1 2.656 1.28l.16.16c.966.96 1.65 1.64 1.65 2.56a2.269 2.269 0 0 1-.804 1.6 8.519 8.519 0 0 1-2.736 2.082 8.568 8.568 0 0 1-3.342.837Zm-8.37-18.64c-.27.07-.79.185-.965.4-2.371 2.36-3.057 4.76-2.132 7.32a13.527 13.527 0 0 0 3.863 5.2l.161.16a15.81 15.81 0 0 0 5.232 3.838c2.173.8 4.989.24 7.363-2.12a1.28 1.28 0 0 0 .443-.68c0-.36-.604-.92-1.288-1.6l-.16-.16a2.741 2.741 0 0 0-1.731-.92 1.902 1.902 0 0 0-1.369.6l-.322.36a3.613 3.613 0 0 1-2.616 1.52c-1.287.04-2.575-.72-4.143-2.32h-.038c-1.61-1.6-2.334-2.92-2.334-4.118a3.592 3.592 0 0 1 1.529-2.6l.362-.32c.885-.88.765-2.04-.322-3.12l-.16-.16c-.685-.68-1.248-1.28-1.61-1.28'
        fill='#4433E0'
      />
      <path
        d='M6.828 3.359c-.27.07-.792.185-.966.4-2.371 2.36-3.057 4.76-2.132 7.32a13.527 13.527 0 0 0 3.863 5.2l.161.16a15.81 15.81 0 0 0 5.232 3.838c2.173.8 4.989.24 7.363-2.12a1.28 1.28 0 0 0 .443-.68c0-.36-.604-.92-1.288-1.6l-.16-.16a2.741 2.741 0 0 0-1.731-.92 1.902 1.902 0 0 0-1.369.6l-.322.36a3.613 3.613 0 0 1-2.616 1.52c-1.287.04-2.575-.72-4.143-2.32h-.038c-1.61-1.6-2.334-2.92-2.334-4.118a3.592 3.592 0 0 1 1.529-2.6l.362-.32c.885-.88.765-2.04-.322-3.12l-.16-.16c-.685-.68-1.248-1.28-1.61-1.28m8.607 18.64a7.688 7.688 0 0 1-2.656-.48 16.652 16.652 0 0 1-5.713-4.12l-.161-.16a16.803 16.803 0 0 1-4.182-5.72c-.928-2.48-.324-5.96 2.452-8.719a2.292 2.292 0 0 1 1.61-.8c.885 0 1.61.68 2.576 1.64l.16.16c1.61 1.6 1.771 3.56.323 5l-.403.36c-.523.48-1.086.96-1.086 1.64a5.84 5.84 0 0 0 1.93 3.2c1.288 1.28 2.374 1.92 3.18 1.92.804 0 1.166-.56 1.65-1.08l.361-.4a3.181 3.181 0 0 1 2.375-.96 4.034 4.034 0 0 1 2.656 1.28l.16.16c.966.96 1.65 1.64 1.65 2.56a2.269 2.269 0 0 1-.804 1.6 8.519 8.519 0 0 1-2.736 2.082 8.568 8.568 0 0 1-3.342.837Z'
        stroke='#4433E0'
        strokeWidth={0.4}
      />
    </svg>
  );
};

export default Telephone;
