import { urls } from '../../Common/messages';
import { FooterCopyRightsParagraph } from './FooterTrackingStyle';

const FooterCopyRightsTracking = () => {
  return (
    <>
      <FooterCopyRightsParagraph>
        © Todos los derechos reservados a Waddi ⏐ Powered by
        {' '}
        <a href={urls.okBoy} target='_blank' rel='noreferrer' className='tracking-link-2'>
          Okboy
        </a>
      </FooterCopyRightsParagraph>
    </>
  );
};

export default FooterCopyRightsTracking;
