import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Rating,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {
  typesColors,
  TYPES_CARDS,
  TYPES_COMPONENTS,
  variantTypography,
} from '../../Common';
import AuthorAndDateOfBlogPost from '../AuthorAndDAteOfBlogPost/AuthorAndDateOfBlogPost';

const CardItem = ({
  cardStyle,
  contentImage,
  contentTexts,
  img,
  title,
  textContent,
  imgMobile,
  typeCard,
  stylesTestimonials,
  valueRating,
  avatar,
  nameUserTestimonials,
  textTestimonials,
  textTypeService,
  refs,
  timeReadBlogPost,
  authorBlogPost,
  dateBlogPost,
  description,
  alternativeText,
}) => {
  return (
    <>
      {typeCard === TYPES_CARDS.imageAndTextOrText ? (
        <Card ref={refs} sx={cardStyle}>
          {img && imgMobile && (
            <CardMedia
              sx={contentImage(img, imgMobile)}
              component={TYPES_COMPONENTS.div}
            />
          )}
          <Box sx={contentTexts}>
            <CardHeader title={title} />
            <CardContent>{textContent}</CardContent>
          </Box>
        </Card>
      ) : typeCard === TYPES_CARDS.testimonials ? (
        <Card ref={refs} sx={stylesTestimonials}>
          <Box>
            <Avatar alt={nameUserTestimonials} src={avatar} />
            <Box>
              <Typography
                variant={variantTypography.h6}
                color={typesColors.primary}
              >
                {nameUserTestimonials}
              </Typography>
              <Rating
                name='text-feedback'
                value={valueRating}
                readOnly
                precision={0.5}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                }
              />
            </Box>
          </Box>
          <Typography
            variant={variantTypography.body1}
            color={typesColors.primaryNormal}
          >
            {textTestimonials}
          </Typography>
          <Typography
            variant={variantTypography.body2}
            color={typesColors.primaryNormal}
          >
            {textTypeService}
          </Typography>
        </Card>
      ) : typeCard === TYPES_CARDS.blogPost ? (
        <Card sx={cardStyle}>
          <CardMedia component={TYPES_COMPONENTS.img} image={img} alt={alternativeText} />
          <Box sx={contentTexts}>
            <CardHeader subheader={timeReadBlogPost} />
            <CardHeader className='content-header__title' title={title} />
            <CardContent>
              { description && <Typography className='content-header__title'>{ description }</Typography> }
              <AuthorAndDateOfBlogPost
                author={authorBlogPost}
                creationDate={dateBlogPost}
              />
            </CardContent>
          </Box>
        </Card>
      ) : null}
    </>
  );
};

CardItem.defaultProps = {
  typeCard: '',
  description: '',
};

export default CardItem;
