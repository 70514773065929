const Mail = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.154 6.085c.404 0 .792.159 1.078.44.286.281.447.663.447 1.06v8.828c0 .398-.161.78-.447 1.061-.286.281-.674.44-1.078.44H4.846c-.404 0-.791-.159-1.077-.44a1.49 1.49 0 0 1-.447-1.06V7.586c0-.398.16-.78.447-1.061.285-.282.673-.44 1.077-.44h14.308Zm0-1.3H4.846c-.755 0-1.479.295-2.012.82A2.78 2.78 0 0 0 2 7.588v8.828c0 .743.3 1.455.834 1.98a2.87 2.87 0 0 0 2.012.821h14.308a2.884 2.884 0 0 0 2.013-.82c.264-.261.474-.57.617-.91.143-.34.216-.704.216-1.073V7.587c0-.743-.3-1.456-.834-1.981a2.869 2.869 0 0 0-2.012-.821Z'
        fill='#4433E0'
        stroke='#4433E0'
        strokeWidth={0.4}
      />
      <path
        d='M12 13.773c-.333 0-.658-.099-.933-.285L4.785 9.206a.657.657 0 0 1-.293-.42.64.64 0 0 1 .295-.68.668.668 0 0 1 .75.03l6.282 4.283a.326.326 0 0 0 .362 0l6.281-4.281a.67.67 0 0 1 .898.179.643.643 0 0 1-.146.89l-6.28 4.282a1.66 1.66 0 0 1-.934.284Z'
        fill='#4433E0'
        stroke='#4433E0'
        strokeWidth={0.2}
      />
    </svg>
  );
};

export default Mail;
