import {
  AppBannerContainer,
  AppBannerSubTitle,
  AppBannerTitle,
  TextContent,
  TextAppBannerContainer,
  styleAppStore,
  styleAppPlayStore,
  AppBannerImage,
  ImageContainer,
  AppDownloadContainer,
} from './styles';
import Captions from '../Captions/Captions';
import {
  variantTypography,
} from '../../Common';
import DownloadApp from '../DownloadApp/DownloadApp';
import { downloads } from '../../Common/messages';
import { spanReset } from '../ServiceSearch/styles';

const AppBanner = ({
  badge,
  title,
  textContent,
  imageDownloadApp,
  image,
}) => {
  return (
    <AppBannerContainer>
      <TextAppBannerContainer>
        <AppBannerSubTitle sx={spanReset} label={badge} />
        <Captions
          variant={variantTypography.h4}
          style={AppBannerTitle}
        >
          { title }
        </Captions>
        <TextContent>{ textContent }</TextContent>
        <AppDownloadContainer>
          <DownloadApp
            style={{ styleAppStore, styleAppPlayStore }}
            appStore={imageDownloadApp.imageIOS}
            playStore={imageDownloadApp.imageAndroid}
            downloads={downloads}
          />
        </AppDownloadContainer>
      </TextAppBannerContainer>
      <ImageContainer>
        <AppBannerImage
          src={image?.src}
          alt={image?.alt}
        />
      </ImageContainer>
    </AppBannerContainer>
  );
};

export default AppBanner;
