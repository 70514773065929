import { Skeleton, Stack } from '@mui/material';
import { containerSkeletonContent } from './SkeletonBlogPostStyle';

const SkeletonContentBlogPost = () => {
  return (
    <Stack
      direction={containerSkeletonContent.direction}
      spacing={containerSkeletonContent.spacing}
      sx={containerSkeletonContent.style}
    >
      {Array.from(new Array(5)).map(() => (
        <Skeleton key={Math.random()} animation='wave' />
      ))}
    </Stack>
  );
};

export default SkeletonContentBlogPost;
