import { NavLink, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  CaptionSkeleton,
  ContainerCard,
  ContainerCloseIcon,
  ContainerListServices,
  ModalMobile,
  ModalMobileBox,
  styleCaptionServiceList,
} from './styles';
import LayoutPointServiceOrder from '../LayoutPointServiceOrder/LayoutPointServiceOrder';
import { ButtonOrderService, Captions, CardServiceOrder } from '../../Components';
import { typesColors, variantTypography } from '../../Common';
import { CardServiceSelectionSkeleton } from '../../Components/Services-OKBOY';
import { linkDefault, pathViews } from '../../Common/messages';
import './styles.css';

const classButtonNext = 'button-next';
const LayoutModalMobile = ({
  loading,
  steps,
  title,
  cardsServices,
  buttonText,
}) => {
  const { primary } = typesColors;
  const twoCards = 2;
  const { state } = useLocation();
  const {
    app,
    services,
  } = pathViews.pathServices;
  const pathServiceGas = `${app}${services.gas}`;
  return (
    <ModalMobile open={state.showModal}>
      <ModalMobileBox>
        <ContainerCloseIcon>
          <NavLink state={{ showModal: false }} to={pathServiceGas}>
            <IconButton color={primary}>
              <CloseIcon />
            </IconButton>
          </NavLink>
        </ContainerCloseIcon>
        <LayoutPointServiceOrder loading={loading} length={steps} />
        <ContainerListServices>
          <Captions
            style={styleCaptionServiceList}
            variant={variantTypography.h3}
          >
            { loading ? <CaptionSkeleton /> : title }
          </Captions>
          <ContainerCard>
            {
              loading ? (
                <CardServiceSelectionSkeleton length={twoCards} />
              ) : (
                cardsServices.map(card => (
                  <CardServiceOrder
                    key={card.nameTypeService}
                    image={card.imageService}
                    title={card.nameTypeService}
                    loading={loading}
                  />
                ))
              )
            }
          </ContainerCard>
        </ContainerListServices>
        <ButtonOrderService
          link={linkDefault}
          classStyle={classButtonNext}
          loading={loading}
        >
          { buttonText }
        </ButtonOrderService>
      </ModalMobileBox>
    </ModalMobile>
  );
};

export default LayoutModalMobile;
