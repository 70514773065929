import { useEffect } from 'react';
import { ServicePreOrder } from '../../Common/messages';
import { LayoutGeneral, ServiceProductLayout } from '../../Layouts';
import { containerProduct, serviceSearchContainer } from './styles';

const {
  customerForm,
  localStorageData,
} = ServicePreOrder;

const ServiceProduct = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.removeItem(customerForm);
    // eslint-disable-next-line no-undef
    localStorage.removeItem(localStorageData.preOrder);
  }, []);

  return (
    <LayoutGeneral style={{ ...serviceSearchContainer, ...containerProduct }}>
      <ServiceProductLayout />
    </LayoutGeneral>
  );
};

export default ServiceProduct;
