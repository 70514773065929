import {
  CardNumber,
  CardText,
  ContainerCard,
  NumberContainer,
} from './styles';

const DoesItWorkCard = ({
  numberText,
  contentText,
}) => {
  return (
    <ContainerCard>
      <NumberContainer>
        <CardNumber>{ numberText }</CardNumber>
      </NumberContainer>
      <CardText>{ contentText }</CardText>
    </ContainerCard>
  );
};

export default DoesItWorkCard;
