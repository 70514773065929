import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { SISEZ_TYPES, typesColors, TYPES_COMPONENTS } from '../../Common';
import { ModalSelect } from '../../Components';
import { closeIconStyles } from '../../Components/ContentSelectors/styles';
import { IconAndTitleModalContent, SelectsContainer, TitleModal } from './styles';

const ModalsMobileLayout = ({
  title,
  handleClose,
  modalName,
  openModal,
  children,
  modalContainer,
  modalSelectStyle,
  styleArrowIcon,
  captionStyles,
}) => {
  return (
    <ModalSelect
      sx={modalContainer}
      keepMounted
      open={openModal}
      onClose={(e) => handleClose(e, modalName, openModal)}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <SelectsContainer sx={modalSelectStyle}>
        <IconAndTitleModalContent>
          <TitleModal sx={captionStyles}>
            <Box component={TYPES_COMPONENTS.span}>
              { title }
            </Box>
          </TitleModal>
          <IconButton
            onClick={(e) => handleClose(e, modalName, openModal)}
            size={SISEZ_TYPES.sm}
            aria-label='delete'
            color={typesColors.primary}
            sx={{ ...closeIconStyles, ...styleArrowIcon }}
          >
            <CloseIcon />
          </IconButton>
        </IconAndTitleModalContent>
        { children }
      </SelectsContainer>
    </ModalSelect>
  );
};

export default ModalsMobileLayout;
