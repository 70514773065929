import { ACTIONS_TYPES } from '../Common';

const {
  SERVICE_FILTER,
  LOCATION_FILTER,
  DATE_FILTER,
  GET_SERVICES_TYPE,
  GET_SERVICE_TYPE,
  GET_PROVIDER,
  GET_SERVICES_SELECTORS,
  GET_LOCATION_SELECTORS,
  SET_SERVICE_TYPE,
} = ACTIONS_TYPES;

export const serviceFilter = (payload) => {
  return {
    type: SERVICE_FILTER,
    payload,
  };
};

export const setServiceType = (payload) => {
  return {
    type: SET_SERVICE_TYPE,
    payload,
  };
};

export const locationFilter = (payload) => {
  return {
    type: LOCATION_FILTER,
    payload,
  };
};

export const dateFilter = (payload) => {
  return {
    type: DATE_FILTER,
    payload,
  };
};

export const getServicesData = (payload) => {
  return {
    type: GET_SERVICES_TYPE,
    payload,
  };
};

export const getServiceType = (payload) => {
  return {
    type: GET_SERVICE_TYPE,
    payload,
  };
};

export const getSelectorsData = (payload) => {
  return {
    type: GET_SERVICES_SELECTORS,
    payload,
  };
};

export const getSelectorsLocation = (payload) => {
  return {
    type: GET_LOCATION_SELECTORS,
    payload,
  };
};

export const getProvider = (payload) => {
  return {
    type: GET_PROVIDER,
    payload,
  };
};
