const isDateServiceTracking = (day, time) => {
  const pluralHour = Number(time.replace('pm' || 'am', '').trim()) === 1 ? 'la' : 'las';
  const todayOrTomorrow = ['HOY', 'MAÑANA'];
  const daryArticle = todayOrTomorrow.includes(day) ? '' : 'el';
  return `Tu servicio llegará a tu dirección ${daryArticle} ${day} a ${pluralHour} ${time}`;
};

const getDay = (dateDeliveryData) => {
  const date = new Date(dateDeliveryData);
  return new Intl.DateTimeFormat('MEX', {
    weekday: 'long',
    day: '2-digit',
    month: 'narrow',
  }).format(date).split(',')[0] || {};
};

const getHours = (date) => {
  return new Intl.DateTimeFormat('en', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const getDateDelivery = (date) => {
  const dateDeliveryData = new Date(date);
  const dateCurrent = new Date();
  const dayCurrent = dateCurrent.getDay();
  const tomorrow = 1;
  const dayCurrentDelivery = dateDeliveryData.getDay();
  const isTomorrow = dayCurrent + tomorrow === dayCurrentDelivery;
  let dateDelivery = getDay(dateDeliveryData);
  const dayCurrentMessage = 'HOY';
  dateDelivery = dateDelivery === getDay(dateCurrent) ? dayCurrentMessage : dateDelivery;
  const messageTomorrowDelivary = 'mañana';
  return isTomorrow ? messageTomorrowDelivary.toUpperCase() : dateDelivery.toUpperCase();
};

const formatMoney = (price) => {
  const fixedPrice = price * 10;
  let formatPrice = fixedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  formatPrice = Number(formatPrice) ? Number(formatPrice) : Number(formatPrice.replace('.', ''));
  const decimalTwo = 2;
  return formatPrice.toFixed(decimalTwo);
};

const FormatCurrency = (total, divide = 1) => {
  let amount = total;
  if (typeof amount !== 'number') {
    amount = Number(amount);
  }
  amount /= divide;
  const format = new Intl.NumberFormat('es-MX');
  return format.format(amount);
};

export {
  isDateServiceTracking,
  getDateDelivery,
  getHours,
  formatMoney,
  FormatCurrency,
};
