import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ListItem, Box } from '@mui/material';
import './linksStyles.css';
import { pathViews } from '../../Common/messages';
import { Validations } from '../../utils/validations';
import { LadingIdContext } from '../../Contexts';

const typeComponent = 'a';
const link = 'link';

const ListItems = ({
  pathName,
  text,
  typeList,
  event,
  refLinks,
  eventLink,
  navLink,
  eventFrecuentQuestions,
  idStrapi,
}) => {
  const [pathToFrecuentQuestions, setPathToFrecuentQuestions] = useState('');
  const { pathname } = useLocation();
  const { dataLandingId } = useContext(LadingIdContext);
  const { validPathDistinc, validPathEqual, includePathLandingSub } = Validations;
  const {
    blog: { childrens },
    frecuentQuestions,
    cancellationPolicies,
    privacyPolicy,
    termsConditions,
    pathServices: { app, services },
  } = pathViews;
  const subLandingPath = 'l';
  const views = [frecuentQuestions, cancellationPolicies, privacyPolicy, termsConditions, `${app}${services.gas}`];
  const viewsValid = includePathLandingSub(pathname, views);
  useEffect(() => {
    if (viewsValid && dataLandingId.path) {
      setPathToFrecuentQuestions(dataLandingId.path);
    } else {
      setPathToFrecuentQuestions(pathViews.home);
    }
  }, [dataLandingId.path, pathname]);

  return (
    <>
      {typeList === link ? (
        <ListItem>
          {pathname === pathViews.home
          || includePathLandingSub(pathname, subLandingPath) ? (
              !navLink ? (
                <Box
                  ref={refLinks}
                  onClick={event}
                  component={typeComponent}
                  href={pathName}
                >
                  {text}
                </Box>
              ) : (
                <NavLink to={pathName} onClick={eventFrecuentQuestions}>
                  {text}
                </NavLink>
              )
            ) : (
              <NavLink
                to={!navLink ? pathToFrecuentQuestions : pathName}
                onClick={!navLink ? eventLink : eventFrecuentQuestions}
                className={!validPathEqual(pathname, pathViews.blog.app) ? ({ isActive }) => (
                  isActive ? 'activeCategorie' : 'inactiveCategorie'
                ) : undefined}
                state={!validPathDistinc(pathname, childrens.categories) && { idStrapi }}
              >
                {text}
              </NavLink>
            )}
        </ListItem>
      ) : (
        <ListItem>{text}</ListItem>
      )}
    </>
  );
};

export default ListItems;
