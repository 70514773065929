import {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { captionMenuMobile } from '../../AppStyles';
import {
  linkOrderGas,
  noBar,
  typesColors,
  // TYPES_PARAMS_STRAPI,
  variantTypography,
} from '../Common';
import {
  linkDefault,
  pathViews,
  textLinks,
  textsCaptionMenuMobile,
  urls,
} from '../Common/messages';
import {
  ButtonGeneral,
  Captions,
  // Header,
  ListMenuLogosMobile,
  ListMenuMobile,
} from '../Components';
// import axiosStrapi from '../Configs/axios';
import { MenuMobileContext } from './MenuContext';
import WhatsAppLogo from '../../assets/img/WhatsAppLogo.png';
import { LayoutHeader, LayoutMenuMobile } from '../Layouts';

// const { category: { fields, sort } } = TYPES_PARAMS_STRAPI;

export const CategoriesContext = createContext(null);

export const CategoriesContextData = ({ children }) => {
  const [dataNavbar, setDataNavbar] = useState({
    categoriesBlog: [],
    listLinks: [],
    listLinksMenu: [],
  });

  const [toggleMenu, setToggleMenu] = useState(true);
  const categoriesPostMemoAndListLinks = useMemo(
    () => ({ dataNavbar, setDataNavbar }),
    [dataNavbar.categoriesBlog, dataNavbar.listLinks, dataNavbar.listLinksMenu, setDataNavbar],
  );
  const { pathServices } = pathViews;
  const toogleMenuMemo = useMemo(
    () => ({ toggleMenu, setToggleMenu }),
    [toggleMenu],
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const getCategories = async () => {
      try {
        const categories = [];
        const listLinksDesktop = [];
        const listLinksMobileData = [];
        const dataMenuMobile = [
          ...listLinksMobileData,
          {
            text: linkOrderGas,
            pathName: `${pathServices.app}${pathServices.services.gas}`,
            navLink: true,
            activeViewCategory: false,
          },
          {
            text: 'Preguntas frecuentes',
            pathName: pathViews.frecuentQuestions,
            navLink: true,
            activeViewCategory: false,
          },
        ];
        setDataNavbar((prev) => ({
          ...prev,
          listLinks: listLinksDesktop,
          listLinksMenu: dataMenuMobile,
          categoriesBlog: categories,
        }));
      } catch (error) {
        navigate('/*');
      }
    };
    getCategories();
  }, [pathname]);

  const { categoriesBlogPost } = pathViews;

  let categoriesPostNavBar = dataNavbar.categoriesBlog.map(({ attributes, id }, i) => ({
    idStrapi: id,
    text: attributes.name,
    pathName: Object.values(categoriesBlogPost)[i] + attributes.name.split(' ').join('-').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
    navLink: true,
    activeViewCategory: true,
  }));

  categoriesPostNavBar = [
    ...categoriesPostNavBar,
    {
      text: <SearchIcon />,
      pathName: linkDefault,
      navLink: true,
      activeViewCategory: true,
    },
    {
      text: linkOrderGas,
      pathName: `${pathServices.app}${pathServices.services.gas}`,
      navLink: true,
      activeViewCategory: true,
    },
  ];
  const isViewTracking = !(pathname.indexOf(pathViews.pathTrackOrderWithQuery.path) !== -1);
  const navbar = (
    <>
      <LayoutHeader>
        <Link to='/home' title='Home'>
          <img src='https://assets-global.website-files.com/63dca0a1242fd10a4612d6cc/63dca4ffe1e0ce6be3bedbf3_Waddi-logo-morado.svg' alt='Waddi' />
        </Link>
      </LayoutHeader>
      <LayoutMenuMobile>
        <ListMenuMobile
          listLinksMenu={pathname.slice(0, noBar) !== pathViews.blog.app
            ? dataNavbar.listLinksMenu
            : categoriesPostNavBar}
        />
        <Captions
          variant={variantTypography.h3}
          color={typesColors.main}
          style={captionMenuMobile}
        >
          {textsCaptionMenuMobile.titleMain}
        </Captions>
        <ButtonGeneral icon={WhatsAppLogo} textUrl={textLinks.whatsApp} url={urls.whatsApp} />
        <ListMenuLogosMobile />
      </LayoutMenuMobile>
    </>
  );
  return (
    <CategoriesContext.Provider value={categoriesPostMemoAndListLinks}>
      <MenuMobileContext.Provider value={toogleMenuMemo}>
        {isViewTracking ? navbar : null}
        {children}
      </MenuMobileContext.Provider>
    </CategoriesContext.Provider>
  );
};
