const contentTitle = {
  mainTitle: 'Políticas de Cancelación',
  firstSubtitle: 'Nuestras políticas consideran los siguientes casos:',
  secondSubtitle: 'Esperamos que no pase, pero en caso que tengas una queja de tu servicio o proveedor, queremos ayudarte y resolver cualquier controversia de manera fácil y rápida.',
};

const contentTextParagraph = {
  firtsSection: [
    {
      text: 'En <strong> OKBOY </strong> estamos obsesionados con ofrecer la mejor experiencia de consumo de servicios para el hogar. Es por esto, que dedicamos mucho tiempo a seleccionar y actualizar constantemente a nuestros proveedores. Y aunque no somos perfectos, lo intentamos 😀',
      section: 1,
    },
    {
      text: 'Tenemos una política de cancelación y devolución que comunicamos en nuestros Términos y Condiciones, todos nuestros clientes y proveedores deben aceptarlas antes de hacer su solicitud de servicios a través de OKBOY.',
      section: 2,
    },
  ],
  firstList: [
    {
      text: 'En caso de no confirmar tu pedido hasta 2 horas después de haber lo realizado con el protocolo antifraude, tu pedido será cancelado con un cargo del 3.5% + 3 pesos sobre el monto total. El cual es nuestro costo del procesador de pagos.',
      section: 1,
    },
    {
      text: 'En caso de solicitar una devolución de una orden, una vez que el proveedor asistió al domicilio y no fue atendido o recibido, tendrás un cargo de $100 pesos por costo de cancelación de pedido no atendido.',
      section: 2,
    },
    {
      text: 'En caso de cancelar una orden, cuando el proveedor ya te confirmó que va en camino, tendrás un cargo de $70 pesos por costo de cancelación de pedido en tránsito.',
      section: 3,
    },
    {
      text: 'En caso de cancelar una orden, dentro del horario de entrega calendarizado, tendrás un cargo de $20 pesos por costo de cancelación de pedido confirmado.',
      section: 4,
    },
    {
      text: 'En caso de cancelar una orden, fuera del horario de entrega calendarizado, con al menos 1 min de retraso no existirá ningún costo de cancelación de pedido tardío.',
      section: 5,
    },
  ],
  secondSection: 'Todas nuestras devoluciones se reflejarán en tu banco entre 48 horas y hasta 7 días a partir de ser solicitadas. Para que una devolución sea válida debe ser solicitada el mismo día que solicitaste tu pedido. Usualmente respondemos en menos de 24 horas',
  secondList: [
    {
      text: 'Compártenos tu Folio de pedido',
      section: 1,
    },
    {
      text: 'Tu queja (en caso de ser gas L.P. no olvides compartirnos cuánto cargaste y cuánto te cargaron). <a href=# target="_blank">www.okboy.app</a> Última Actualización: 08.07.21. Es importante recordar que nosotros no somos los únicos responsables de ofrecerte la mejor experiencia y dependemos de la calidad del servicio de nuestros proveedores.',
      section: 2,
    },
    {
      text: 'En caso de presentar una queja, en la cual el proveedor no subió la evidencia del servicio, en menos de 24hrs te agregaremos el valor en dinero digital proporcional al valor de tu queja en tu cuenta OKBOY. Dicho valor, no puede ser utilizado en 48 hrs, que es el tiempo de respuesta para que un proveedor resuelva cualquier queja.',
      section: 3,
    },
    {
      text: 'En caso de presentar una queja, en la cual el proveedor subió una evidencia y no concuerda, nosotros solicitaremos al proveedor en menos de 48 horas, asistir al domicilio para completar el servicio de forma óptima.',
      section: 4,
    },
    {
      text: 'En caso de presentar una queja, en la cual no hay evidencia de tu parte o hay sospecha de alguna fuga o mal interpretación deberemos enviar al proveedor para levantar un caso de queja directamente, dicho proceso suele tomar de 24 horas a 5 días.',
      section: 5,
    },
  ],
  thirdSection: 'Queremos ofrecerte la mejor experiencia así que siempre puedes comunicarnos cualquier queja o sugerencia a <a href=#>ayuda@okboy.app.</a>',
};

export {
  contentTextParagraph,
  contentTitle,
};
