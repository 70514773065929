/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';

export const useResetScrollTop = (scrollDocument) => {
  const [scroll, setScroll] = useState(scrollDocument);

  const typeScroll = 'smooth';
  useEffect(() => {
    window.scroll({
      behavior: typeScroll,
      top: scroll,
    });
  }, [scroll]);

  return [setScroll];
};
