import { createTheme } from '@mui/material';
import AvenirBlack from '../../assets/font/AvenirLTStd-Black.otf';
import AvenirBook from '../../assets/font/AvenirLTStd-Book.otf';
import AvenirRoman from '../../assets/font/AvenirLTStd-Roman.otf';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4433E0',
      secondary: '#403acc',
      normal: '#000',
      timeRead: '#B938FC',
      purple: '#5843E0',
      purple2: '#BCB4F3',
      black2: '#151229',
      gray: '#615D78',
    },
    secondary: {
      main: '#fff',
      bannerDownload: '#E2F7F5',
      bannerSubscribe: '#7872F2',
    },
    text: {
      primary: '#202022CC',
      secondary: '#202022',
      authorBlogPost: '#4D4D4E',
      select: '#79797A',
    },
    menu: {
      primary: '#F9F9F9',
    },
    alert: {
      primary: '#E2F7F5',
    },
    background: {
      primary: '#F9F9F9',
      secondary: '#F0F1F5',
      textFieldSubscribe: '#BFF5F1',
      selects: '#F0F1F5',
      tertiary: '#E0DEEC',
    },
    badge: {
      main: '#FFB03E',
    },
    webView: {
      primary: '#503FF3',
      secondary: '#B0B8C4',
      tertiary: '#D9D9D9',
      fourth: '#C4C4C4',
      label: '#878795',
      text: '#404040',
      backgroundItem: '#F1F3F6',
      radio: '#6856F1',
      error: '#ec225e',
      success: '#08FFDB',
    },
  },
  typography: {
    fontFamily: 'Avenir',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Avenir';
            font-style: normal;
            font-weight: 900;
            src: url(${AvenirBlack});
          }
          @font-face {
            font-family: 'Avenir';
            font-style: normal;
            font-weight: 200;
            src: url(${AvenirBook});
          }
          @font-face {
            font-family: 'Avenir';
            font-style: normal;
            font-weight: 400;
            src: url(${AvenirRoman});
          }
          `,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      phoneMd: 375,
      sm: 600,
      md: 931,
      tablet: 768,
      laptop: 1024,
      lg: 1200,
      laptopLarge: 1440,
      xl: 1650,
    },
  },
});
