import { Backdrop, CircularProgress } from '@mui/material';
import { typesColors } from '../../Common/types';
import { stylesLoader } from './styles';

const ViewLoader = ({ loading }) => {
  const isOpen = true;
  return (
    <Backdrop
      open={loading || isOpen}
      sx={stylesLoader}
    >
      <CircularProgress color={typesColors.primary} />
    </Backdrop>
  );
};

export default ViewLoader;
