export const containerSkeletonHeader = {
  style: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    tablet: 'row',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  spacing: {
    xs: 1,
    phoneMd: 1,
    tablet: 3.3,
    md: 14.3,
    lg: 14.3,
    xl: 14.3,
  },
};

export const skeletonImage = {
  width: {
    xs: '100%',
    phoneMd: '100%',
    tablet: 'initial',
  },
  marginTop: {
    xs: '1rem',
    phoneMd: '1rem',
    sm: '1rem',
    tablet: 'initial',
  },
  flexShrink: {
    xs: '0',
    tablet: '1',
    md: '1',
    lg: '1',
  },
  flexBasis: {
    xs: '233px',
    phoneMd: '233px',
    tablet: '579px',
    md: '579px',
    lg: '579px',
  },
  height: '394px',
  borderRadius: '30px !important',
  backgroundColor: 'rgba(255, 255, 255, 0.13)',
};

export const containerSkeletonCaptionHeader = {
  display: {
    xs: 'flex',
    phoneMd: 'flex',
    tablet: 'block',
    md: 'block',
    lg: 'block',
  },
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  flexBasis: '50%',
  width: {
    xs: '100%',
    phoneMd: '100%',
    tablet: 'initial',
  },
  margin: {
    xs: 'auto 0',
    phoneMd: 'auto 0',
    tablet: 'initial',
  },
};
export const skeletonReadDuration = {
  background: '#FFB03E',
  width: '50%',
  height: '24px',
};

export const skeletonCaption = {
  background: 'rgba(255, 255, 255, 0.13)',
  width: {
    xs: '100%',
    tablet: '100%',
    md: '80%',
    lg: '80%',
  },
  height: '120px',
};

export const skeletonAuthor = {
  background: 'rgba(255, 255, 255, 0.13)',
  width: '50%',
  height: '19px',
};

export const skeletonRRSS = {
  background: 'rgba(255, 255, 255, 0.13)',
  width: '50%',
  height: '19px',
};

export const containerSkeletonContent = {
  style: {
    width: '100%',
    height: '800px',
    flexDirection: 'column !important',
    justifyContent: 'space-between',
    '& span': {
      marginLeft: '0px !important',
      transform: 'scale(1, 1)',
    },
    '& > span:first-of-type': {
      width: {
        xs: '100%',
        tablet: '100%',
        md: '50%',
        lg: '50%',
      },
      height: '100px !important',
    },
    '& > span:nth-of-type(4)': {
      width: '100%',
      height: '500px !important',
    },
    '& > span:last-of-type': {
      width: '30%',
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  spacing: {
    xs: 1,
    phoneMd: 1,
    md: 14.3,
    lg: 14.3,
    xl: 14.3,
  },
};
