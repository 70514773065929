import { styled } from '@mui/material';

export const layoutGeneralSaveTime = (top) => ({
  minHeight: {
    xs: '71.3vh', sm: '71.3vh', md: '49vh', ls: '49vh',
  },
  padding: {
    xs: '0px',
    sm: '0px',
    md: '0px',
    lg: '0px',
  },
  position: 'relative',
  top: {
    xs: top, sm: top, md: '0', lg: '0',
  },
  marginBottom: '0px',
  '#root': {
    overflow: 'hidden',
  },
});

export const styleAlert = {
  display: 'block',
  backgroundColor: 'alert.primary',
  height: '62px',
  position: {
    xs: 'relative', phoneMd: 'relative', sm: 'relative', md: 'static',
  },
  top: '4.9rem',
  zIndex: 10,
  marginTop: {
    xs: '0rem', sm: '0rem', md: '6.5rem', lg: '6.5rem',
  },
  transtion: 'all .5s ease',
};

export const ProgessHomeContainer = styled('div')(() => ({
  display: 'grid',
  placeItems: 'center',
  width: '100%',
  height: '80vh',
}));
