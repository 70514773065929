import {
  Box,
  ListItemText,
} from '@mui/material';
import { WarrantyIcon, WarrantyListsContainer } from './styles';

const WarrantyList = ({
  itemsList,
}) => {
  return (
    <WarrantyListsContainer>
      {
            itemsList?.map(item => {
              let icon = item?.icon?.data[0] || {};
              icon = icon?.attributes?.url;
              return (
                <Box key={item.id}>
                  <WarrantyIcon src={icon} />
                  <ListItemText primary={item.textContent} />
                </Box>
              );
            })
        }
    </WarrantyListsContainer>
  );
};

export default WarrantyList;
