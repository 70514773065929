const typesTarget = {
  TYPE_TARGET_BLANK: '_blank',
  TYPE_TARGET_SELFT: '_self',
};

const TYPES_COMPONENTS = {
  link: 'a',
  img: 'img',
  span: 'span',
  div: 'div',
  section: 'section',
  li: 'li',
  header: 'header',
  embed: 'embed',
  footer: 'footer',
  main: 'main',
  lists: {
    order: 'ol',
    unordered: 'ul',
  },
};

const variantTypography = {
  body1: 'body1',
  body2: 'body2',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const SISEZ_TYPES = {
  lg: 'large',
  md: 'medium',
  sm: 'small',
};

const typesTexts = {
  paragraph: 'paragraph',
  list: 'list',
  listAndParagraph: 'listParagraph',
};

const typesNumberList = {
  sublist: 'sublist',
};

const typesColors = {
  primary: 'primary',
  main: 'primary.main',
  primaryNormal: 'primary.normal',
  error: 'error',
  textSecondary: 'text.secondary',
  text: 'text',
  buttons: {
    currentProviderButton: 'rgba(120, 114, 242, 0.5)',
    defaultProviderButton: 'rgba(196, 196, 196, 0.5)',
  },
};

const TYPE_LIST_STYLE = {
  decimal: 'decimal',
  disc: 'disc',
};

const TYPES_DEVICES = {
  desktop: 'desktop',
  mobile: 'mobile',
  tablet: 'tablet',
};

const TYPES_CARDS = {
  testimonials: 'testimonials',
  blogPost: 'blogPost',
  imageAndTextOrText: 'imageAndTextOrText',
};

const TYPES_DIRECTIONS = {
  row: 'row',
  column: 'column',
  align: {
    center: 'center',
  },
  spacings: {
    one: 1,
    two: 2,
    three: 3,
  },
};

const TYPES_LOGO = {
  mobile: 'mobile',
  blogPost: 'blogPost',
};

const TYPES_BANNER = {
  help: 'help',
  download: 'download',
  subscribe: 'subscribe',
  rrss: 'rrss',
};

const VARIANT_BUTTONS_TYPES = {
  contained: 'contained',
  outlined: 'outlined',
};

const TYPES_BUTTONS = {
  link: 'link',
  button: 'button',
  buttonWithParams: 'buttonWidthParams',
};

const VARIANT_TEXTFILEDS_TYPES = {
  outlined: 'outlined',
};

const TYPE_FOOTER = {
  blogPost: 'blog',
  lading: 'landig',
};

const TYPES_PARAMS_STRAPI = {
  filedsPost: 'title,readDuration,author,creationDate,description,content',
  fieldsPosts: 'title,readDuration,author,creationDate,description,column',
  pageSize: 12,
  category: {
    fields: {
      name: 'name',
      description: 'description',
    },
    sort: {
      forId: 'id:asc',
    },
  },
  populates: {
    categorie: 'categorie',
    img: 'image',
    categorieImageAndSeo: 'categorie,image,seo',
  },
  sort: {
    forColumn: 'column:asc',
  },
  landing: {
    populate: {
      components: 'landingNavbar,askForGas,imageDownload,saveTime,howHelpYouSection,ourServicesSection,instructionsSection,coverageSection,testimonialsSection,bannerHelpSection,footer,viewFrequentQuestions,seo,SEOFreceuntQuestions',
      imageDownload: 'imageDownload.imageAndroid,imageDownload.imageIOS',
      saveTime: 'saveTime.backgroundImageDesktop,saveTime.backgroundImageMobile,saveTime.imageIphone',
      howHelpYouSection: 'howHelpYouSection.cardHelpSection.imageDesktop,howHelpYouSection.cardHelpSection.imageMobile',
      ourServicesSection: 'ourServicesSection.imageAndTitleServices.image,ourServicesSection.textDownloader',
      bannerCoupon: 'bannerCoupon.imageCoupon',
      instructionsSection: 'instructionsSection.backgroundImageDesktop,instructionsSection.backgroundImageMobile',
      coverageSection: 'coverageSection.textDownloader,coverageSection.image',
      testimonialsSection: 'testimonialsSection.cardTestimonials.imageUser',
      bannerHelpSection: 'bannerHelpSection.buttonLink',
      footer: 'footer.sectionFooter,footer.imageDownload.imageAndroid,footer.imageDownload.imageIOS',
      viewFrequentQuestions: 'viewFrequentQuestions.bannerHeader.backgroundImageDesktop,viewFrequentQuestions.bannerHeader.backgroundImageMobile,viewFrequentQuestions.cardQuestions,viewFrequentQuestions.bannerSection',
    },
  },
  services: {
    gas: {
      fields: 'title,description,slug',
      filters: 'gas-lp',
      populate: 'servicesList,serviceBenefits,buttonOrderService,sectionSelectionService',
      populateWidthComponent: 'servicesList.cardService,serviceBenefits.badgeBenefits.sectionSelectionService.cardServiceTypeOrder,serviceBenefits.cardBenefits',
      populateImage: 'servicesList.cardService.imageService,serviceBenefits.badgeBenefits.image,sectionSelectionService.cardServiceTypeOrder.imageService,serviceBenefits.cardBenefits.imageBenefits',
    },
  },
  serviceTracking: {
    fields: 'title,subTitle,placeholderInput,searchButton,cleanSearchButton,evidenceTitle,titleWithOutEvidence',
    populateGeneral: 'whatsAppButton,warrantyBanner,statusServices,orderInformation,score',
    populate: 'whatsAppButton.whatsAppImage,warrantyBanner.imagesApp.imageAndroid,warrantyBanner.imagesApp.imageIOS,statusServices.status',
  },
  preOrderProductDetail: {
    populateGeneral: 'cardsSection,recomendationsLists,productWarranty,checkInSection,bannerSection,fixedButton',
    populates: 'cardsSection.cards,recomendationsLists.listContent,productWarranty.warrantyLists.icon,bannerSection.phoneImage',
  },
  serviceBilling: {
    fileds: 'title,buttonSend',
    populate: 'serviceBillingInput,serviceBillingRadio,ModalData',
  },
  download: {
    populate: 'download,download.backgroundImageDesktop,download.imageIphone,download.backgroundImageMobile,appDownload,appDownload.image',
  },
};
const TYPES_REGEX = {
  email: /^[^@]+@[^@]+\.[a-zA-Z]{3,}$/,
  // eslint-disable-next-line no-useless-escape
  mxEmail: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  characters: '^[A-ZÁÉÍÓÚÑ ]+$',
  numbers: /^[0-9]+$/,
};

const TYPES_ICON = {
  blog: 'blog',
  landing: 'landing',
};

const TYPES_METAS = {
  desc: 'description',
  keywords: 'keywords',
  robots: {
    name: 'robots',
    noIndex: 'noindex',
    index: 'index',
  },
};

const TYPES_ATTRIBUTES_DOM = {
  atributes: {
    name: 'name',
    content: 'content',
    type: 'type',
  },
};

const TYPES_MODALS = {
  mobile: 'mobile',
  desktop: 'desktop',
};

const TYPE_SCROLL = 'smooth';

const STATUS_SERVICES = {
  CREATED: { NAME: 'Creado', TYPE: 'primary', ID: 1 },
  ACTIVE: { NAME: 'Validado', TYPE: 'warning', ID: 2 },
  ACCEPTED: { NAME: 'Aceptado', TYPE: 'info', ID: 3 },
  EN_CAMINO: { NAME: 'En Camino', TYPE: 'info', ID: 3 },
  ARRIVED: { NAME: 'Llegó', TYPE: 'success', ID: 4 },
  FINISHED: { NAME: 'Terminado', TYPE: 'dark', ID: 5 },
  CANCEL_BY_CLIENT: { NAME: 'Cancelado por cliente', TYPE: 'dark', ID: 6 },
  CANCEL_BY_PROVIDER: { NAME: 'Cancelado por colaborador', TYPE: 'dark', ID: 7 },
  CANCEL_BY_ADMIN_FREE: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  CANCEL_BY_ADMIN_COST: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  ASSIGNED: { NAME: 'Asignado', TYPE: 'success', ID: 9 },
  TAKEN: { NAME: 'Aceptado', TYPE: 'success', ID: 10 },
  PAYMENT_PENDING: { NAME: 'Por pagar', TYPE: 'warning', ID: 11 },
};

const TYPES_SERVICES = {
  digital: 'digital_service',
  service: 'service',
};

const SEARCH_SELECT_TYPES = {
  service: 'modalService',
  location: 'modalLocation',
  date: 'modalDate',
  modalsContainer: 'modalsContainer',
  provider: 'provider',
};

const LOCALSTORAGE_NAME_TYPES = {
  services: 'services',
  productService: 'productService',
  serviceTypeData: 'serviceType',
};

const ACTIONS_TYPES = {
  SERVICE_FILTER: 'SERVICE_FILTER',
  SET_SERVICE_TYPE: 'SERVICE_TYPE',
  LOCATION_FILTER: 'LOCATION_FILTER',
  DATE_FILTER: 'DATE_FILTER',
  GET_SERVICES_TYPE: 'GET_SERVICES_TYPE',
  GET_SERVICE_TYPE: 'GET_SERVICE_TYPE',
  GET_SERVICES_SELECTORS: 'GET_SERVICES_SELECTORS',
  GET_LOCATION_SELECTORS: 'GET_LOCATION_SELECTORS',
  GET_PROVIDER: 'GET_PROVIDER',
};

const SELECTORS_TYPES = {
  services: 'services',
  locations: 'location',
  labels: {
    withoutLabel: { 'aria-label': 'Without label' },
  },
  valueService: 'valueService',
  valueLocation: 'valueLocation',
  valueProvider: 'valueProvider',
};

const FORMAT_DATE_CALENDAR = {
  es: 'L',
  monthDayYear: 'MM/DD/YYYY',
  dayMonthYear: 'DD/MM/YYYY',
  dayMonthYearShort: 'DD/MM/YY',
  yearMonthDay: 'YYYY-MM-DD',
};

const FORMAT_SCHEDULE = {
  twelveHours: 'hh:mm',
};

const TYPES_HANDLE = {
  providerSelect: 'provider',
  productSelect: 'product',
};

const COLOR_TYPES = {
  ratingWidthOutColor: 'rgba(0, 0, 0, 0.26)',
};

const VALUES_PROPERTYS_CSS = {
  color: 'color',
};

const SERVICES_TYPES = {
  homeClean: '9cae23fc-1b18-11ec-9621-0242ac130002',
};

const inputsVariants = {
  outlined: 'outlined',
  basicId: 'outlined-basic',
};

const INPUTS_TYPES = {
  text: 'text',
  emailType: 'email',
  number: 'number',
};

const LOCATION_TYPES = {
  streetNumber: 'street_number',
  route: 'route',
  sublocalityLevelOne: 'sublocality_level_1',
  locality: 'locality',
  administrativeAreaLevelOne: 'administrative_area_level_1',
  postalCode: 'postal_code',
};

const LOCATION_PROPS_TYPES = {
  types: {
    address: [
      'street_number',
      'sublocality',
      'administrative_area_level_1',
      'route',
      'sublocality_level_1',
    ],
  },
  componentRestrictions: {
    mx: 'mx',
  },
  fields: {
    all: 'ALL',
  },
};

const TARGET_PROPERTIES_TYPES = {
  valueControl: 'valuecontrol',
};

const ERROS_TYPES = {
  purchaseMin: 'EA0141',
  invalidPhone: 'EA0094',
  maxCarts: 'EA0187',
  serviceNotFound: 'EA0029',
  alreadyAcceptedService: 'EA0014',
  rfcNotFound: 'Customer not found',
  expiredBilling: 'SE0022',
  incorrectData: 'EA0007',
  coverageNotFound: 'EA0138',
};

export {
  typesTarget,
  TYPES_COMPONENTS,
  variantTypography,
  typesTexts,
  typesColors,
  TYPE_LIST_STYLE,
  typesNumberList,
  TYPES_DEVICES,
  TYPES_CARDS,
  TYPES_DIRECTIONS,
  TYPES_LOGO,
  TYPES_BANNER,
  VARIANT_BUTTONS_TYPES,
  TYPES_BUTTONS,
  VARIANT_TEXTFILEDS_TYPES,
  TYPE_FOOTER,
  TYPES_PARAMS_STRAPI,
  TYPES_REGEX,
  TYPES_ICON,
  TYPE_SCROLL,
  TYPES_METAS,
  TYPES_ATTRIBUTES_DOM,
  TYPES_MODALS,
  STATUS_SERVICES,
  TYPES_SERVICES,
  SISEZ_TYPES,
  SEARCH_SELECT_TYPES,
  LOCALSTORAGE_NAME_TYPES,
  ACTIONS_TYPES,
  SELECTORS_TYPES,
  FORMAT_DATE_CALENDAR,
  TYPES_HANDLE,
  COLOR_TYPES,
  VALUES_PROPERTYS_CSS,
  SERVICES_TYPES,
  inputsVariants,
  INPUTS_TYPES,
  LOCATION_TYPES,
  LOCATION_PROPS_TYPES,
  TARGET_PROPERTIES_TYPES,
  FORMAT_SCHEDULE,
  ERROS_TYPES,
};
