import { ERROS_TYPES } from '../../Common';
import { ErrorsMessages } from '../../shared/messages';
import { formatMoney } from '../service-tracking';

const {
  purchaseMin,
  invalidPhone,
  maxCarts,
  serviceNotFound,
  alreadyAcceptedService,
  expiredBilling,
  incorrectData,
  coverageNotFound,
} = ERROS_TYPES;

const {
  errorMessages,
} = ErrorsMessages;

const errorsValid = (error, data) => {
  switch (error) {
    case purchaseMin: {
      return `${errorMessages.purchaseMin} $${formatMoney(data)}`;
    }
    case invalidPhone: {
      return errorMessages.invalidPhone;
    }
    case maxCarts: {
      return errorMessages.maxCarts;
    }
    case serviceNotFound: {
      return errorMessages.serviceNotFount;
    }
    case alreadyAcceptedService: {
      return errorMessages.alreadyAcceptedService;
    }
    case expiredBilling: {
      return errorMessages.expiredBilling;
    }
    case incorrectData: {
      return errorMessages.incorrectData;
    }
    case coverageNotFound: {
      return errorMessages.coverageNotFound;
    }
    default:
      return 'Hubo un error';
  }
};

export {
  errorsValid,
};
