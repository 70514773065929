import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  contentLogos,
  styleAppStore,
  styleAppPlayStore,
} from './ListMenuLogosMobileStyles';
import DownloadApp from '../DownloadApp/DownloadApp';
import { downloads, listLogosMenu } from '../../Common/messages';
import LogoRRSS from '../LogoRRSS/LogoRRSS';
import { TYPES_LOGO } from '../../Common';
import { DataLading } from '../../api';

const ListMenuLogosMobile = () => {
  const [downLoadIamge, setDownLoadIamge] = useState({
    imageIOS: {
      image: '',
      alternativeText: '',
    },
    imageAndroid: {
      image: '',
      alternativeText: '',
    },
  });
  const { getSaveTime } = DataLading;
  useEffect(() => {
    getSaveTime().then(dataResponseSaveTime => {
      setDownLoadIamge(prev => ({
        ...prev,
        imageIOS: {
          ...dataResponseSaveTime.imageDownloadApp.imageIOS,
        },
        imageAndroid: {
          ...dataResponseSaveTime.imageDownloadApp.imageAndroid,
        },
      }));
    });
  }, []);
  return (
    <Box sx={contentLogos}>
      <Box>
        <DownloadApp
          appStore={downLoadIamge.imageIOS}
          playStore={downLoadIamge.imageAndroid}
          style={{ styleAppStore, styleAppPlayStore }}
          downloads={downloads}
        />
      </Box>
      <Box>
        {listLogosMenu.map((logo) => (
          <LogoRRSS key={logo.name} {...logo} typeLogo={TYPES_LOGO.mobile} />
        ))}
      </Box>
    </Box>
  );
};

export default ListMenuLogosMobile;
