const captionText = {
  variants: {
    variantH1: 'h1',
    variantH2: 'h2',
  },
  texts: {
    textH1: 'Ahorra tiempo cuidando tu hogar',
    textH3:
    'Somos tu aliado administrando tu hogar, te ayudamos a encontrar todo lo que necesitas en un clic.',
  },
  color: {
    colorSecondary: 'secondary.main',
  },
};

export { captionText };
