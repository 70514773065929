import {
  ContainerRadio,
  RadioCustom,
  RadioLabel,
} from './styles';

const ProductConfigurationRadio = ({
  data,
  containerRadioStyles,
  radioStyles,
  handleChange,
  value,
  name,
}) => {
  return (
    <ContainerRadio
      onChange={handleChange}
      sx={containerRadioStyles}
      value={value}
      name={name}
    >
      {
        data?.map(item => {
          return (
            <RadioLabel
              sx={radioStyles}
              value={item.name}
              control={<RadioCustom />}
              label={item.name}
              key={item.id}
            />
          );
        })
      }
    </ContainerRadio>
  );
};

export default ProductConfigurationRadio;
