import { Box } from '@mui/material';
import { TYPES_COMPONENTS } from '../../Common';
import downloadPdf from '../../../assets/img/downloadpdf.png';
import {
  WrapperInformationOrder,
  SectionInformationOrderDate,
  AmountLabel,
} from './styles';
import { downloadServiceTicket } from '../../api/service-tracking';

const getTicket = (fetchedService) => async () => {
  const { data } = await downloadServiceTicket(fetchedService);
  window.open(data.url, '_blank');
};

const LayoutInformationTicket = ({ orderInformationData }) => {
  const { dataStepperServiceTracking } = orderInformationData;
  const { dataTracking } = dataStepperServiceTracking;
  const wrapper = dataTracking && dataTracking.statusId === 5 ? (
    <WrapperInformationOrder>
      <SectionInformationOrderDate>
        <Box component={TYPES_COMPONENTS.div} style={{ display: 'inline-flex' }}>
          <AmountLabel style={{ fontWeight: 'bold', paddingTop: '10px' }}>
            Ticket
          </AmountLabel>
          <button onClick={getTicket(dataTracking)} type='button' style={{ border: '0px', backgroundColor: 'transparent', cursor: 'pointer' }}>
            <img width={48} height={48} alt='Descargar Ticket' src={downloadPdf} />
          </button>
        </Box>
      </SectionInformationOrderDate>
    </WrapperInformationOrder>
  ) : null;
  return (
    wrapper
  );
};

export default LayoutInformationTicket;
