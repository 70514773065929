import { urls } from './paths';

const textHelpSection = {
  titles: {
    titleMain: '¿Necesitas ayuda?',
    titleSecondary: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
  },
  variantsTitle: {
    main: 'h3',
    secondary: 'h5',
  },
  colorTitle: 'secondary.main',
  textsUrls: { watsApp: 'Escríbenos', quetions: 'Preguntas frecuentes' },
  url: urls.whatsApp,
};

export {
  textHelpSection,
};
