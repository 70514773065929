import { axiosService } from '../Configs/axios';

const getServicesType = async (serviceType, state) => {
  const serviceTypeDefault = serviceType ? `types?serviceTypeUuid=${serviceType}` : 'types?';
  const stateDefault = !state ? '' : `&state=${state}`;
  return axiosService.get(`/products/${serviceTypeDefault}${stateDefault}`);
};

const getStates = async () => {
  return axiosService.get('/coverage/state');
};

const getCategories = async (serviceTypeUuid, state) => {
  const stateDefault = !state ? '' : `?&state=${state}`;
  return axiosService.get(`/products/categories/${serviceTypeUuid}${stateDefault}`);
};

export {
  getServicesType,
  getStates,
  getCategories,
};
