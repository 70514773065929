import { Container } from '@mui/material';

const LayoutGeneral = ({ style, children }) => {
  return (
    <Container sx={style}>
      { children }
    </Container>
  );
};

export default LayoutGeneral;
