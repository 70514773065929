import {
  Facebook, Instagram, Tiktok, Linkedin,
} from '../../../assets/icons';
import { pathViews, urls } from './paths';

const { blog: { app, childrens } } = pathViews;

const listLinksMenu = [
  {
    text: 'Beneficios',
    pathName: '#beneficios',
    navLink: false,
    activeViewCategory: false,
  },
  {
    text: 'Servicios',
    pathName: '#servicios',
    navLink: false,
    activeViewCategory: false,
  },
  {
    text: 'Cobertura',
    pathName: '#cobertura',
    navLink: false,
    activeViewCategory: false,
  },
  {
    text: 'Blog',
    pathName: `${app}${childrens.home}`,
    navLink: true,
    activeViewCategory: false,
  },
  {
    text: 'Pedir Gas en Web',
    pathName: urls.orderGas,
    navLink: false,
    activeViewCategory: false,
  },
  {
    text: 'Preguntas frecuentes',
    pathName: '/frecuent-questions',
    navLink: true,
    activeViewCategory: false,
  },
];

const listLogosMenu = [
  {
    icon: <Facebook />,
    name: 'Facebook',
    path: urls.facebook,
  },
  {
    icon: <Instagram />,
    name: 'Instagram',
    path: urls.instagram,
  },
  {
    icon: <Tiktok />,
    name: 'Tiktok',
    path: urls.tiktok,
    prevent: (e) => { e.preventDefault(); },
  },
  {
    icon: <Linkedin />,
    name: 'Linkedin',
    path: urls.linkedIn,
  },
];

const textLinks = {
  whatsApp: 'Contactar',
};

const textsCaptionMenuMobile = {
  titleMain: '¿Tienes dudas?',
};

export {
  listLinksMenu, listLogosMenu, textLinks, textsCaptionMenuMobile,
};
