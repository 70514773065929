import { Box } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { MenuMobileContext } from '../../Contexts/MenuContext';
import { menuMobileStyles } from './LayoutMenuMobileStyles';

const LayoutMenuMobile = ({ children }) => {
  const { toggleMenu } = useContext(MenuMobileContext);
  const refMenuMobile = useRef(null);

  useEffect(() => {
    if (!toggleMenu) refMenuMobile.current.style.transform = 'translateX(0%)';
    else refMenuMobile.current.style.transform = 'translateX(100%)';
  }, [toggleMenu]);

  return (
    <Box ref={refMenuMobile} sx={menuMobileStyles}>
      <Box>{children}</Box>
    </Box>
  );
};

export default LayoutMenuMobile;
