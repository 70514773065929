import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const OurServicesImagesStyledContainer = styled(Box)(({ theme }) => ({
  width: '51%',
  order: 1,
  display: 'flex',
  flexFlow: 'row wrap',
  gap: '10px',
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('md')]: {
    width: '62%',
    order: 1,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    order: 2,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    '& > div': {
      width: '164px !important',
    },
    '& > div:nth-of-type(2)': {
      width: '164px !important',
      marginRight: '7px',
      '& > img': {
        top: '-19px',
      },
    },
    '& > div:nth-of-type(3)': {
      '& > img': {
        top: '-10%',
      },
    },
    '& > div:nth-of-type(4)': {
      marginRight: '7px',
    },
    '& > div:first-of-type': {
      width: '343px !important',
      marginRight: '0px',
    },
  },
}));

export const OurServiceTextContainer = styled(Box)(({ theme }) => ({
  width: '49%',
  order: 2,
  paddingLeft: '5%',
  [theme.breakpoints.down('lg')]: {
    width: '30%',
    paddingLeft: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '38%',
    order: 1,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& > div': {
      padding: '0px 16px !important',
    },
    order: 1,
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  margin: '100px 0px',
  position: 'relative',
  zIndex: '1',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '15px 0px 100px 0px',
    flexDirection: 'column',
  },
}));

export const featuredServiceContainer = {
  width: 402,
  height: 192,
  borderRadius: '35px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',
  padding: '0px',
  flexShrink: 0,
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
};

export const featuredImageDesktop = {
  width: '50%',
  height: '100%',
};

export const featuredImage = {
  width: 163,
  height: 93,
};

export const serviceContainer = {
  width: 193,
  height: 192,
  borderRadius: 35,
  display: 'flex',
  padding: {
    xs: '0px',
    phoneMd: '0px',
    sm: '0px',
    md: '0px 20px',
    lg: '0px 20px',
    xl: '0px 20px',
  },
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
};

export const serviceImage = {
  width: '100%',
  height: {
    xs: '69%',
    phoneMd: '69%',
    sm: '69%',
    md: 135,
    lg: 135,
  },
  position: 'relative',
  top: '-11%',
};

export const serviceText = {
  fontWeight: 800,
  fontSize: {
    xs: '1rem',
    phoneMd: '1rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.25rem',
    xl: '1.25rem',
  },
  color: 'black',
  maxWidth: '165px',
  position: {
    sx: 'static',
    sm: 'static',
    md: 'relative',
  },
  top: '-4%',
};

export const featuredServiceTextDesktop = {
  fontWeight: 800,
  fontSize: 35,
  color: 'black',
  margin: 'auto',
};

export const featuredServiceText = {
  fontWeight: 800,
  fontSize: 35,
  color: 'black',
};
