import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { lengthCardsSwiper } from '../../Common';
import { ProviderCard } from './styles';

const ProviderCarousel = ({ items, handleEvent, targetRef }) => {
  const spaceBetweenCards = 8;
  const classStyle = 'provider-carousel';
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode]}
      freeMode={true}
      slidesPerView={lengthCardsSwiper.oneCard}
      spaceBetween={spaceBetweenCards}
      className={classStyle}
    >
      {
        items?.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <ProviderCard
                onClick={handleEvent}
                value={item.description}
                // eslint-disable-next-line no-param-reassign, no-unsafe-optional-chaining
                ref={targetRef}
              >
                { item.description }
              </ProviderCard>
            </SwiperSlide>
          );
        })
      }
    </Swiper>
  );
};

export default ProviderCarousel;
