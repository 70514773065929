import { useState } from 'react';
import { TYPES_REGEX } from '../Common';

export const useForm = (inputs) => {
  const [form, setForm] = useState(inputs);
  const [error, setError] = useState({ errorInput: false, errorSubmit: true });

  const handleChange = (e) => {
    setForm({ ...form, data: { [e.target.name]: e.target.value } });
    setError({ errorSubmit: false, errorInput: false });
    if (!e.target.value) setError({ errorInput: false, errorSubmit: true });
    if (e.target.value && !TYPES_REGEX.email.test(e.target.value)) {
      setError({ errorSubmit: true, errorInput: true });
    }
  };
  const resetForm = () => {
    setForm(inputs);
  };
  return [form, handleChange, resetForm, error, setError];
};
