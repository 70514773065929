import { Box, Skeleton, styled } from '@mui/material';

export const ContentButtonOrderService = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '175px',
  position: 'fixed',
  bottom: '0px',
  backgroundColor: theme.palette.background.primary,
  zIndex: '20',
  borderRadius: '20px 20px 0px 0px',
}));

export const containerCaptionAndButtonOrder = {
  maxWidth: {
    xs: '100%',
    md: '742px',
  },
  height: '82%',
  padding: '0px 2rem',
  paddingBottom: '2rem',
};

export const captionOrder = {
  textAlign: {
    xs: 'start',
    md: 'center',
  },
  fontWeight: '800',
  fontSize: '1.375rem',
  lineHeight: '1.878125rem',
  marginBottom: '2rem',
  color: 'text.secondary',
};

export const CaptionSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '42%',
  margin: '0 auto',
  backgroundColor: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    width: '69%',
  },
}));
