export const containerContentBlogPost = {
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    width: '100%',
    fontWeight: '900',
    margin: {
      xs: '2rem auto',
      sm: '2rem auto',
      md: '2rem 0rem 2rem 0rem',
      lg: '2rem 0rem 2rem 0rem',
    },
    color: 'text.secondary',
  },
  '& h1': {
    fontSize: {
      xs: '1.90rem',
      phoneMd: '1.90rem',
      sm: '1.90rem',
      md: '2.8rem',
      lg: '2.8rem',
      xl: '2.8rem',
    },
  },
  '& h2': {
    fontSize: {
      xs: '1.75rem',
      phoneMd: '1.75rem',
      sm: '1.75rem',
      md: '2.5rem',
      lg: '2.5rem',
      xl: '2.5rem',
    },
  },
  '& h3': {
    fontSize: {
      xs: '1.375rem',
      phoneMd: '1.375rem',
      sm: '1.375rem',
      md: '2.3rem',
      lg: '2.3rem',
      xl: '2.3rem',
    },
  },
  '& h4': {
    fontSize: {
      xs: '1.15rem',
      phoneMd: '1.25rem',
      sm: '1.25rem',
      md: '2rem',
      lg: '2rem',
      xl: '2rem',
    },
  },
  '& h5': {
    fontSize: {
      xs: '0.90rem',
      phoneMd: '1rem',
      sm: '1rem',
      md: '1.7rem',
      lg: '1.7rem',
      xl: '1.7rem',
    },
  },
  '& h6': {
    fontSize: {
      xs: '0.70rem',
      phoneMd: '0.75rem',
      sm: '0.75rem',
      md: '1.4rem',
      lg: '1.4rem',
      xl: '1.4rem',
    },
  },
  '& ol': {
    listStyle: 'decimal',
    paddingLeft: '1.4rem',
    marginBottom: '1rem',
  },
  '& ul': {
    listStyle: 'disc',
    paddingLeft: '1.4rem',
    marginBottom: '1rem',
  },
  '& p': {
    color: 'text.secondary',
    marginBottom: '1rem',
  },
  '& p, & li': {
    color: 'text.secondary',
    fontSize: {
      xs: '1rem',
      phoneMd: '1rem',
      sm: '1rem',
      md: '1.125rem',
      lg: '1.125rem',
    },
    lineHeight: {
      xs: '',
      phoneMd: '1.36625rem',
      sm: '1.36625rem',
      md: '1.536875rem',
      lg: '1.536875rem',
    },
  },
  '& img': {
    width: '100%',
    objectFit: 'cover',
    marginTop: '1.5rem',
  },
  '& a': {
    color: 'primary.main',
    textDecoration: 'underline',
  },
};

export const containerContentAndTableContent = {
  display: 'flex',
  justifyContent: {
    xs: 'center',
    md: 'space-between',
  },
  alignItems: 'start',
  width: {
    laptop: '107.3%',
    laptopLarge: '127.3%',
    xl: '132.3%',
  },
  '& > div': {
    width: '100%',
    flexBasis: {
      xs: '80%',
      md: '75.6%',
    },
  },
  '& > section': {
    position: 'static',
    top: '10px',
    paddingRight: '2rem',
    right: '10px',
    display: {
      xs: 'none',
      tablet: 'none',
      md: 'block',
    },
    flexBasis: '22%',
    height: '285px',
    overflowY: 'auto',
    '& > h4': {
      fontSize: '1.375rem',
      margin: '0px',
      marginBottom: '1.5rem',
    },
    '& > ul': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '0',
      height: 'auto',
      '& > li': {
        listStyle: 'none',
        width: '100%',
        padding: '0px',
        backgroundColor: 'transparent !important',
        maxWidth: 'initial !important',
        marginBottom: '1rem',
        '& > a': {
          display: 'block',
          width: '100% !important',
          textAlign: 'start !important',
          padding: '0px !important',
          textDecoration: 'none',
          color: 'rgba(32, 32, 34, 0.6) !important',
          fontWeight: '500 !important',
          fontSize: '18px',
          lineHeight: '25px',
        },
        '& > a.linkActive': {
          color: 'rgba(32, 32, 34, 1) !important',
        },
      },
    },
  },
  '& > section::-webkit-scrollbar': {
    width: '4px',
    backgroundColor: '#ccc',
  },
  '& > section::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary.main',
    borderRadius: '4px',
  },
  '& > section.fixedTable': {
    position: 'fixed',
    top: '90px',
    right: {
      md: '0.6%',
      laptop: '1%',
      laptopLarge: '2%',
      lg: '11.5%',
      xl: '12.3%',
    },
    width: {
      md: '17.6%',
      laptop: '20%',
      laptopLarge: '17.8%',
      lg: '16%',
      xl: '13%',
    },
  },
  '& > section.opacityTableActive': {
    opacity: 1,
  },
  '& > section.opacityTableInactive': {
    opacity: 0,
  },
};

export const skeletonCaptionTable = {
  width: '90%',
  height: '2rem',
  marginBottom: '1rem',
  backgroundColor: 'rgba(32, 32, 34, .6)',
};

export const skeletonListLinks = {
  width: '60%',
  height: '1rem',
  marginBottom: '1rem',
  backgroundColor: 'rgba(32, 32, 34, .6)',
};
