import { Box } from '@mui/material';
import { variantTypography } from '../../Common';
import { Captions } from '../../Components';
import { content } from './NotFoundLayoutStyles';

const NotFoundLayout = () => {
  return (
    <Box sx={content}>
      <Box>
        <Captions variant={variantTypography.h2}>
          Página no encontrada
        </Captions>
      </Box>
    </Box>
  );
};

export default NotFoundLayout;
