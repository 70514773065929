/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { sections } from '../../Common/messages';
import { LayoutGeneral } from '../../Layouts';
import PrivacyPolicyLayout from '../../Layouts/PrivacyPolicyLayout/PrivacyPolicyLayout';
import { sectionContent } from './PrivacyPolicyStyles';

const PrivacyPolicy = () => {
  const { scrollTop } = document.documentElement;
  const [scroll, setScroll] = useState(scrollTop);
  const initialScroll = 0;
  const typeScroll = 'smooth';
  useEffect(() => {
    setScroll(initialScroll);
    window.scroll({
      behavior: typeScroll,
      top: scroll,
    });
  }, [scroll]);
  return (
    <LayoutGeneral style={sectionContent}>
      <PrivacyPolicyLayout sections={sections} />
    </LayoutGeneral>
  );
};

export default PrivacyPolicy;
