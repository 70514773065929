import {
  Accordion,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Container,
  Rating,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Typography,
  Link,
} from '@mui/material';

export const ContainerTraking = styled(Container)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, max-content)',
  gridTemplateRows: 'repeat(1, 630px)',
  height: 'auto',
  width: '100%',
  paddingLeft: '1rem !important',
  paddingRight: '1rem !important',
  gap: '1.2rem',
  [theme.breakpoints.down(1110)]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto auto',
    justifyItems: 'center',
  },
}));

export const captionTacking = {
  width: '100%',
  fontWeight: '700',
  fontSize: '1.25rem',
  lineHeight: '2.81rem',
  color: 'primary.purple',
};

export const Folio = styled('span')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '.875rem',
  lineHeight: '1.125rem',
  color: theme.palette.primary.black2,
  marginLeft: '1rem',
}));

export const TextTraking = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '1.36rem',
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '98%',
  },
}));

export const buttonPrimary = {
  padding: '0.5rem 1.25rem',
};

export const SectionStepper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginRight: '0rem',
  },
}));

export const captionStepper = {
  width: '100%',
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  color: 'text.secondary',
};

export const styledEvidenceTitle = {
  marginBottom: '1rem',
  fontWeight: '700',
  lineHeight: '1.25rem',
  color: 'primary.purple',
};

export const styledOrderInformationTitle = {
  fontWeight: '700',
  lineHeight: '1.25rem',
  color: 'primary.black2',
  marginBottom: '.9rem',
  '& > span': {
    display: 'iniline-block',
    fontSize: '.75rem',
    lineHeight: '.9375rem',
    color: 'primary.purple',
    marginLeft: '.5rem',
  },
};

export const styledAccordionDetail = {
  padding: '.25rem .5rem 1rem 0',
};

export const StepperServiceTracking = styled(Stepper)(({ theme }) => ({
  width: '100%',
  paddingTop: '1.7rem',
  borderTop: `1px solid ${theme.palette.primary.gray}`,
  '& .MuiStep-root': {
    height: '16px',
    '& .MuiStepLabel-root': {
      height: '100%',
    },
  },
}));

export const StepServiceTracking = styled(Step)(({ theme }) => ({
  paddingLeft: '2.1rem',
  position: 'relative',
  '& .MuiStepIcon-text': {
    display: 'none',
  },
  '& .MuiStepIcon-root': {
    fontSize: '0.625rem',
  },
  '& .MuiStepLabel-iconContainer': {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    marginRight: '8px',
    paddingRight: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 'initial',
  },
}));

export const StepLabelServiceTracking = styled(StepLabel)(() => ({
  padding: '0px',
}));

export const AccordionInformationOrder = styled(Accordion)(({ theme }) => ({
  width: '100%',
  maxWidth: 'initial',
  boxShadow: 'initial',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  '& #panel1a-header.MuiButtonBase-root': {
    minHeight: 'initial',
    padding: '0',
    paddingLeft: '.5rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  gap: '5.7rem',
  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
  },
}));
export const WrapperInformationOrder = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 'initial',
  boxShadow: 'initial',
  background: theme.palette.secondary.main,
  borderRadius: '16px',
  padding: '1.6rem .85rem 1.2rem 1.5rem',
  marginBottom: '10px',
}));

const fontStatus = {
  fontWeight: '500',
  fontSize: '.875rem',
  lineHeight: '1.125rem',
  color: 'primary.black2',
};

export const StatusLabel = styled(Box)(() => ({
  ...fontStatus,
}));

export const StatusTag = styled(Chip)(({ background, theme }) => {
  return {
    backgroundColor: background,
    height: '20px',
    borderRadius: '7px',
    padding: '.3rem .01rem',
    '& > .MuiChip-label': {
      ...fontStatus,
      fontWeight: '800',
      color: theme.palette.primary.black2,
      whiteSpace: 'nowrap',
      maxWidth: '124px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '112px',
    },
  };
});

export const styleIconAccordion = {
  color: 'primary.black2',
  fontSize: '1.8rem',
};

export const TitleAddress = styled(Typography)(() => ({
  width: 'max-content',
}));

export const titles = {
  fontWeight: '900',
  fontSize: '14px',
  lineHeight: '19px',
  color: 'primary.normal',
  marginBottom: '.5rem',
};

export const imageIcon = {
  width: '20px',
  height: '20px',
};

export const SectionAddressSeviceTracking = styled(Box)(() => ({
  width: '100%',
  marginBottom: '1rem',
}));

export const SectionInformationOrderDate = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2.3rem',
  '& > span.MuiBox-root, & > span.MuiBox-root strong': {
    fontWeight: '500',
    fontSize: '.75rem',
    lineHeight: '.9375rem',
  },
  '& > span.MuiBox-root': {
    color: theme.palette.primary.black2,
  },
  '& > span.MuiBox-root strong': {
    fontWeight: '900',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '.3rem',
  },
}));

export const SectionInformationOrder = styled(Box)(() => ({
  width: '100%',
}));

export const quantityStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, max-content)',
  gap: '0.3rem 0',
  marginTop: '1rem',
};

export const AmountLabel = styled('span')(({ theme }) => ({
  display: 'inline-block',
  fontWeight: '500',
  fontSize: '.875rem',
  lineHeight: '1.125rem',
  minWidth: '325px',
  color: theme.palette.primary.black2,
  [theme.breakpoints.down('sm')]: {
    minWidth: '221px',
  },
}));

export const marginTopStyles = {
  marginTop: '.15rem',
};

export const styledTotal = {
  paddingTop: '0.8rem',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '-6px',
    backgroundColor: 'background.tertiary',
    width: '101.5%',
    height: '1px',
  },
  '& > span': {
    color: 'primary.purple',
    fontWeight: '700',
  },
};

export const AmountLabelValue = styled('span')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '.875rem',
  lineHeight: '1.125rem',
  color: theme.palette.primary.black2,
}));

export const ContentAddressServiceTracking = styled(Box)(() => ({
  width: '50%',
}));

export const stylesContentInformationAddressAndOrder = {
  display: 'flex',
  flexDirection: {
    xs: 'row',
    md: 'row',
  },
  width: {
    xs: '58%',
    md: '52%',
  },
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const TextAddressServiceTracking = styled(Typography)(() => ({
  width: '100px',
}));

export const stylesInformationAddressAndOrder = {
  fontWeight: '500',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: 'text.secondary',
  alignSelf: 'center',
};

export const boxStepperAndOrderInformation = {
  width: {
    xs: '100%',
  },
  height: 'max-content',
  gridAutoRows: {
    xs: '86% auto',
    md: '80% 20%',
  },
};

export const ContentStepperAndOrder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '79%',
  backgroundColor: 'white',
  borderRadius: '8px',
  marginBottom: '1.5rem',
  padding: '.3rem .6rem .3rem 1rem',
  overflow: 'hidden',
  '& .MuiAccordion-root': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    marginTop: '1.3rem',
  },
}));

export const contentProgress = {
  display: 'grid',
  placeItems: 'center',
  gridTemplateColumns: '1fr',
  gridTemplateRows: {
    xs: '100%',
    md: '1fr',
  },
  height: {
    xs: '270px',
    md: '270px',
  },
};

export const SectionOrderInformation = styled(Box)(({ theme }) => ({
  gridRow: '1 / 3',
  width: '100%',
  paddingLeft: '2rem',
  [theme.breakpoints.down('sm')]: {
    gridRow: '2 / 3',
    marginTop: '1rem',
    paddingLeft: '0rem',
    paddingTop: '.5rem',
    borderLeft: 'none',
    borderTop: 'solid 2px #D9D9D9',
  },
}));

export const SectionOrderSeviceTracking = styled(Box)(() => ({
  width: '100%',
}));

export const TitleOrder = styled(Typography)(() => ({
  width: 'max-content',
}));

export const ContentOrder = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, auto)',
  gridTemplateRows: 'repeat(2, minmax(auto, 1fr))',
  width: '89%',
}));

export const iconService = {
  gridColumn: '1 / 2',
  marginBottom: '0.5rem',
};

export const TexOrderServiceTracking = styled(Typography)(() => ({
  width: 'max-content',
  gridColumn: '2 / 5',
}));

export const TexOrderPrice = styled(Typography)(() => ({
  width: 'max-content',
  gridColumn: '5 / 6',
}));

export const TexTotalOrderPrice = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  color: theme.palette.primary.main,
  gridColumn: '2 / 4',
}));

export const TexTotalPrice = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  gridColumn: '4 / 6',
  justifySelf: 'center',
  marginRight: '-2px',
}));

export const stylesTotalPrice = {
  fontWeight: '900',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  alignSelf: 'center',
};

export const styleButtonWhatsApp = {
  width: '100%',
  maxWidth: {
    xs: 'initial',
    lg: '325px',
  },
  height: '40px',
  display: 'grid',
  placeItems: 'center',
  gridTemplateColumns: '1fr',
  marginTop: '0rem',
  backgroundColor: 'primary.purple2',
  border: '1px solid',
  borderColor: 'primary.purple2',
  padding: '0',
  borderRadius: '15px',
  gridColumn: {
    xs: '1 / 3',
    md: '2 / 3',
  },
  justifySelf: {
    xs: 'center',
    md: 'end',
  },
  '& a.MuiBox-root .MuiTypography-root': {
    fontWeight: '900',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: 'primary.black2',
  },
  transition: 'transform .3s ease,box-shadow .3s ease',
  '&:hover': {
    backgroundColor: 'primary.purple2',
    borderColor: 'primary.purple2',
    boxShadow: '2px 3px 5px #4433e026',
  },
};

export const TextButttonWatsApp = styled(Typography)(({ theme }) => ({
  fontWeight: '900',
  fontSize: '1.375rem',
  lineHeight: '1.875rem',
  color: theme.palette.primary.main,
}));

export const ContainerBanner = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1.16fr',
  gridTemplateRows: '1fr',
  placeItems: 'center',
  gridColumn: '1 / 3',
  alignSelf: 'end',
  width: '100%',
  height: '102px',
  borderRadius: '30px',
  backgroundColor: theme.palette.secondary.bannerDownload,
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    width: '111%',
    position: 'relative',
    left: '-5%',
    height: '199px',
    '& > div': {
      width: '82%',
      justifySelf: 'center',
      gridColumn: '1 / 3',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '100%',
      },
    },
  },
}));

export const TextWarranty = styled(Typography)(({ theme }) => ({
  gridColumn: '1 / 2',
  width: '290px',
  justifySelf: 'center',
  fontWeight: '900',
  fontSize: '1.5rem',
  lineHeight: '2.1875rem',
  color: theme.palette.text.secondary,
  '& strong': {
    fontWeight: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 3',
    alignSelf: 'end',
  },
}));

export const styleAppStore = {
  width: '10.375rem',
  marginRight: '16px',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: '10.375rem',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};

export const SectionEvidenceService = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '3.125rem',
  marginLeft: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 'initial',
  },
}));

export const DateTime = styled(Box)(() => ({
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
}));

export const AccordionEvidence = styled(Accordion)(() => ({
  width: '100%',
  boxShadow: 'none',
}));

export const EvidenceTitle = styled(AccordionSummary)(() => ({
  width: '100%',
}));

export const DetailsEvidence = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '.4rem',
  '& .MuiButton-root': {
    width: 'max-content',
  },
  '& .MuiPaper-root': {
    width: '360px',
    height: '85px',
    borderRadius: '9px',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiButton-root': {
      width: '100%',
    },
    '& .MuiPaper-root': {
      width: '311px',
    },
  },
}));

export const ModalLayoutContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr',
  placeItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#0000009c',
  transition: 'opacity .6s ease',
  transitionDelay: '.2s',
  opacity: '0',
}));

export const ContentEvidenceCardModal = styled(Card)(({ theme }) => ({
  width: '33%',
  height: '52.8%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  tranform: 'translateY(152%)',
  transition: 'transform .5s cubic-bezier(0,1.7,1,1)',
  transitionDelay: '.3s',
  '& figure, & div, img.iiz__img': {
    width: '100%',
    height: '100%',
    maxWidth: 'initial',
  },
  [theme.breakpoints.down('sm')]: {
    width: '91%',
    height: '42.8%',
  },
}));

export const iconModal = {
  width: '46px',
  height: '46px',
  color: 'white',
};

export const closeButton = {
  position: 'absolute',
  right: {
    xs: '41%',
    md: '4%',
  },
  top: {
    xs: '13%',
    md: '10%',
  },
};

export const scoreButtonStyle = {
  width: '100%',
  marginTop: '0',
  gridColumn: '1 / 3',
  '&.Mui-disabled': {
    color: '#ffffff8f',
    backgroundColor: '#867BE8',
  },
};

export const ScoreModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '670px',
  height: '348px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '16px',
  transition: 'height .5s cubic-bezier(.5,-0.53,.25,1)',
  transitionDelay: '.2s',
  [theme.breakpoints.down(1110)]: {
    maxWidth: 'initial',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '344px',
  },
}));

export const ScoreModalContainerAnimation = {
  height: '289px',
};

export const buttonClosCustom = {
  top: {
    xs: '8%',
  },
};

export const captionScore = {
  alignSelf: 'end',
  color: 'black',
  width: '100%',
  transform: 'translateY(0%)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: '700',
  transition: 'transform .5s cubic-bezier(.5,-0.53,.25,1)',
  transitionDelay: '.2s',
  marginTop: '16px',
};
export const subTitleScore = {
  color: 'black',
  width: '100%',
  transform: 'translateY(0%)',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '500',
  marginTop: '16px',
};
export const subTitleScoreSecond = {
  color: 'black',
  width: '100%',
  transform: 'translateY(0%)',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '500',
};

export const captionsScoreAnimation = {
  transform: {
    xs: 'translateY(237%)',
    md: 'translateY(259%)',
  },
};

export const ButtonScoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '82.9%',
  marginTop: '24px',
  [theme.breakpoints.down('sm')]: {
    width: '94.9%',
  },
}));

export const ScoreBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
}));

export const scoreBoxAnimation = {
  transform: {
    xs: 'translateY(183%)',
    md: 'translateY(165%)',
  },
};

export const StyledScore = styled(Rating)(({ theme }) => ({
  '&.MuiRating-root': {
    width: 'auto',
    fontSize: '25px',
  },
  '& .MuiRating-icon': {
    marginRight: '1rem',
  },
  '& .MuiRating-iconFilled': {
    color: '#4433E0',
    '& .MuiSvgIcon-root': {
      filter: 'drop-shadow(1px 1px 4px #4433E0)',
    },
  },
  '& .MuiRating-iconHover': {
    color: '#4433E0',
  },
  [theme.breakpoints.down('sm')]: {
    '&.MuiRating-root': {
      width: '60%',
    },
  },
}));

export const FormContainer = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  width: '100%',
  height: 'auto',
  opacity: '1',
  zIndex: '1',
}));

export const FormAnimation = {
  opacity: '0',
  zIndex: '-1',
};

export const captionForm = {
  fontSize: '1rem',
  marginBottom: '0.3rem',
};

export const CommentForm = styled(TextField)(({ theme }) => ({
  width: '440px',
  height: '79px',
  '& .MuiOutlinedInput-root, .MuiOutlinedInput-input': {
    paddingTop: '1.5rem',
    height: '79px !important',
    borderRadius: '8px',
    fontWeight: '700',
    fontSize: '.6875rem',
    lineHeight: '.875rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const sendScoreButton = {
  width: '210px',
  borderRadius: '15px',
  marginTop: '0',
  gridColumn: '1/5',
  justifySelf: 'center',
  fontSize: '16px',
  alignSelf: 'start',
  transform: 'translateY(0%)',
  transition: 'transform .5s cubic-bezier(.5,-0.53,.25,1), background-color .3s ease',
  transitionDelay: '.2s',
  height: '40px',
  lineHeight: '0rem',
  padding: '22px 8px',
  '&.Mui-disabled': {
    color: '#ffffff8f',
    backgroundColor: '#867BE8',
  },
  '&:hover': {
    color: '#ffffff',
    backgroundColor: 'primary.main',
  },
};

export const sendButtonAnimation = {
  transform: {
    xs: 'translateY(-364%)',
    md: 'translateY(-381%)',
  },
};

export const circularProgressStyles = {
  '&.MuiCircularProgress-root': {
    width: '24px !important',
    height: '24px !important',
    '& svg': {
      color: 'secondary.main',
    },
  },
};

export const alertStyle = {
  width: '100%',
  backgroundColor: 'primary.main',
  color: 'secondary.main',
  fontSize: {
    xs: '0.82rem',
    md: '0.875rem',
  },
  '& .MuiSvgIcon-root': {
    fill: 'secondary.main',
  },
};

export const StatusDateContainer = styled(Box)(({ direction }) => ({
  display: 'flex',
  gap: '.5rem',
  flexDirection: direction || 'row',
  alignItems: 'center',
  marginLeft: '1.4rem',
  paddingTop: '.35rem',
  position: 'absolute',
}));

export const StatusDateLabel = styled(Box)(() => ({
  fontWeight: '500',
  fontSize: '.875rem',
}));

export const styledDateIcon = {
  fontSize: '.9rem',
  color: 'text.authorBlogPost',
};

export const stylesDefault = {
  minWidth: '141px',
};

export const AddressSection = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: '16px',
  width: '100%',
  maxWidth: '440px',
  height: 'max-content',
  padding: '1rem 1rem 1rem 1.6rem',
  gridColumn: '1 / 2',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const AddressTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '.1rem',
  alignItems: 'center',
  fontWeight: '700',
  fontSize: '.875rem',
  lineHeight: '1.125rem',
  marginLeft: '-.26rem',
  marginBottom: '.16rem',
  '&, & svg': {
    color: theme.palette.text.authorBlogPost,
  },
}));

export const elementPseudo = {
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: '-11px',
    backgroundColor: 'background.tertiary',
    height: '1px',
    width: '104%',
  },
};

export const AddressText = styled(Box)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '.6875rem',
  lineHeight: '.875rem',
  position: 'relative',
  color: theme.palette.primary.black2,
  paddingBottom: '.5rem',
}));

export const WhatsAppTitle = styled(Box)(({ theme }) => ({
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '22px',
  color: theme.palette.primary.black2,
  marginBottom: '.5rem',
}));
export const AddressContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, max-content)',
  gridTemplateRows: 'max-content',
  marginTop: '1.9rem',
  gap: '1rem',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const LogoImageContainer = styled(Box)(() => ({
  width: '150px',
  height: '60px',
  margin: '2.1rem auto',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));

export const styledColorWaitStatus = {
  '& > .MuiBox-root, & > .MuiSvgIcon-root': {
    color: 'primary.gray',
  },
};

export const styledDateCompleted = {
  '& > .MuiBox-root, & > .MuiSvgIcon-root': {
    color: 'primary.black2',
  },
};

export const styledStepCompleted = {
  '& .MuiStepLabel-label': {
    color: 'gray',
    fontWeight: '700',
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: 'primary.black2',
    fontWeight: '700',
  },
};

export const styledCustomMap = { height: { xs: '491px', md: '343px' } };

export const styledCustomAddress = { display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' };

export const styledCustomWithoutAddress = {
  gridTemplateColumns: { laptop: '43.7% max-content', xs: '50% max-content' },
};

export const styledCustomAddressText = {
  paddingTop: '.5rem',
  paddingBottom: '0rem',
  marginBottom: '.18rem',
};

export const whatsAppContainer = { gridColumn: '2 / 3', gridRow: '1 / 2' };

export const Anchor = styled(Link)(() => ({
  color: '#462687',
  fontSize: 'auto',
  fontWeight: 600,
  cursor: 'pointer',
  marginLeft: '0.25rem',
}));

export const GoogleOrScoreSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridColumn: '1 / 2',
  width: '100%',
  flexDirection: 'column',
  [theme.breakpoints.down(1110)]: {
    marginTop: 'initial',
  },
}));
