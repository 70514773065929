import { Box, Button, styled } from '@mui/material';

export const ButtonPrimaryGeneral = styled(Button)(({ theme }) => ({
  width: 'max-content',
  height: 'auto',
  marginTop: '2rem',
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  fontWeight: '900',
  fontSize: '1.37rem',
  lineHeight: '1.87rem',
  textTransform: 'initial',
  border: '1px solid transparent',
  borderRadius: '15px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const ButtonLink = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  textTransform: 'initial',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:active': {
    colr: `${theme.palette.primary.main} !important`,
  },
}));

export const ButtonSelectCustom = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '45px',
  backgroundColor: theme.palette.background.secondary,
  border: '1px solid rgba(121, 121, 122, 0.1)',
  borderRadius: '15px',
  fonrtWeight: '900',
  fontSize: '1rem',
  lineHeight: '22px',
  color: theme.palette.text.select,
  textTransform: 'capitalize',
  padding: '0.625rem 1.171875rem 0.8125rem 0.8125rem',
}));

/* Counter Button */
export const CounterButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.1875rem',
  marginBottom: '1.5rem',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
  },
}));

export const SubtractionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.menu.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.menu.primary,
  },
  '&.Mui-disabled': {
    color: theme.palette.menu.primary,
    backgroundColor: '#C4C4C4',
  },
}));

export const CounterNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '212px',
  height: '45px',
  background: '#F0F1F5',
  border: '1px solid rgba(121, 121, 122, 0.1)',
  borderRadius: '15px',
  [theme.breakpoints.down('tablet')]: {
    width: '201px',
  },
}));

export const AdditionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.menu.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.menu.primary,
  },
}));

export const counterButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '15px',
  height: '45px',
  fontSize: '1.5rem',
  width: {
    xs: '16.2%',
    md: '74px',
  },
  padding: '0px',
  minWidth: 'initial',
};
