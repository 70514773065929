import Proptypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { typesTexts } from '../../Common';
import { TYPES_COMPONENTS, variantTypography } from '../../Common/types';

const ParagraphOrListGeneral = ({
  typeParagraph,
  textParagraph,
  textList,
  numberList,
  orderAlphabet,
  numberSublist,
  numberSubListMain,
}) => {
  return typeParagraph === typesTexts.paragraph ? (
    <Typography
      dangerouslySetInnerHTML={{ __html: textParagraph }}
      variant={variantTypography.body2}
    />
  ) : typeParagraph === typesTexts.list ? (
    <>
      {numberList && (
      <Box component={TYPES_COMPONENTS.span}>
        {numberList}
        .
      </Box>
      )}
      {orderAlphabet && (
      <Box component={TYPES_COMPONENTS.span}>
        {orderAlphabet}
        )
      </Box>
      )}
      {numberSubListMain && numberSublist && (
      <Box component={TYPES_COMPONENTS.span}>
        { `${numberSubListMain}.${numberSublist}` }
      </Box>
      )}
      <Box
        component={TYPES_COMPONENTS.li}
        dangerouslySetInnerHTML={{ __html: textList }}
      />
    </>
  ) : (
    <>
      {numberList && (
      <Box component={TYPES_COMPONENTS.span}>
        {numberList}
        .
      </Box>
      )}
      {orderAlphabet && (
      <Box component={TYPES_COMPONENTS.span}>
        {orderAlphabet}
        .
      </Box>
      )}
      <Box
        component={TYPES_COMPONENTS.li}
        dangerouslySetInnerHTML={{ __html: textList }}
      />
      {textParagraph && (
        <Typography
          dangerouslySetInnerHTML={{ __html: textParagraph }}
          variant={variantTypography.body2}
        />
      )}
    </>
  );
};

ParagraphOrListGeneral.protoTypes = {
  typeParagraph: Proptypes.string.isRequired,
  textParagraph: Proptypes.string.isRequired,
  textList: Proptypes.string.isRequired,
};

export default ParagraphOrListGeneral;
