import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataServiceTrackingContent } from '../../api';
import { paymentTypes, typesColors } from '../../Common';
import { ContextToken } from '../../Contexts';
import LayoutInformationOrder from './LayoutInformationOrder';
import LayoutStepper from './LayoutStepper';
import {
  boxStepperAndOrderInformation,
  contentProgress,
  ContentStepperAndOrder,
  styledColorWaitStatus,
  styledDateCompleted,
} from './styles';
import {
  LayoutMapOrScore,
} from '../index';
import StatusDate from './StatusDate';
import { generateId } from '../../shared/messages';
import LayoutInformationProvider from './LayoutInformationProvider';
import LayoutInformationTicket from './LayoutInformationTicket';

const {
  getDetailServiceTracking,
  getDataServiceTrackingContent,
} = DataServiceTrackingContent;

const ContentStepperAndOrderLayout = ({
  dataTracking: trackingData,
}) => {
  const [dataSeviceTracking, setDataSeviceTracking] = useState({
    dataStepperServiceTracking: {
      status: [],
      activeStep: 0,
      date: {
        dateData: '',
        deliveryTime: '',
      },
      serviceStatus: '',
      statusCurrent: {},
      dataTracking: {},
    },
    dataInformationOrder: {
      title: '',
      addresTitle: '',
      orderTitle: '',
      address: '',
      details: [],
      folio: '',
      dataTracking: {},
    },
    evidenceImages: [],
    evidenceTitle: '',
    titleWithOutEvidence: '',
    score: {
      calificatedButton: '',
      modalTitle: '',
      labelText: '',
      placeholderText: '',
      textareaTitle: '',
      sendButton: '',
    },
    serviceUuid: '',
    isCalificated: null,
  });
  const [loading, setLoading] = useState(true);
  const { dataStepperServiceTracking } = dataSeviceTracking;
  const navigate = useNavigate();
  const tokenContext = useContext(ContextToken);
  useEffect(() => {
    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        dataStepperServiceTracking: {
          ...prevDataSeviceTracking?.dataStepperServiceTracking,
          status: dataResponseServiceTrackingContent.dataStatus,
        },
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getDetailServiceTracking(tokenContext).then(responseDataDetailServiceTracking => {
      const {
        status,
        customStatusIdActive,
        cutsomStatus,
        statusCurrent,
        dataTracking,
      } = responseDataDetailServiceTracking;
      const statusShow = cutsomStatus.map((obj, index) => {
        return {
          ...obj,
          description: (
            <StatusDate
              dateLabel={obj.date}
              timeLabel={obj.hour}
              styledDate={
                customStatusIdActive > index ? styledDateCompleted : styledColorWaitStatus
              }
              styledTime={
                customStatusIdActive > index ? styledDateCompleted : styledColorWaitStatus
              }
            />
          ),
          id: generateId(),
        };
      });
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        dataStepperServiceTracking: {
          ...prevDataSeviceTracking?.dataStepperServiceTracking,
          date: { ...responseDataDetailServiceTracking.date },
          activeStep: customStatusIdActive,
          status: statusShow,
          serviceStatus: status,
          statusCurrent,
          dataTracking,
        },
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));
  }, [tokenContext, dataStepperServiceTracking.activeStep]);

  useEffect(() => {
    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      const { orderInformation } = dataResponseServiceTrackingContent;
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        dataInformationOrder: {
          ...prevDataSeviceTracking?.dataInformationOrder,
          title: orderInformation.title,
          addresTitle: orderInformation.addresTitle,
          orderTitle: orderInformation.orderTitle,
          dataTracking: prevDataSeviceTracking.dataTracking,
        },
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));

    getDetailServiceTracking(tokenContext).then(dataResponseInformationOrder => {
      const {
        addressCustom,
        details,
        folio,
        deliveryDate,
        deliveryTime,
        paymentTypeId,
        serviceFee,
        total,
        provider,
        amount,
        tip,
        discount,
        dataTracking,
      } = dataResponseInformationOrder;
      const paymentTypeText = paymentTypes.find(obj => obj.id === paymentTypeId);
      const hourRange = 2;
      const hour = Number(deliveryTime.split(':')[0]) + hourRange;
      const time = `${deliveryTime} - ${hour}:${deliveryTime.split(':')[1]}`;
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        dataInformationOrder: {
          ...prevDataSeviceTracking?.dataInformationOrder,
          address: addressCustom,
          details,
          folio,
          deliveryDate,
          deliveryTime: time,
          paymentTypeText: paymentTypeText?.name,
          serviceFee,
          discount,
          total,
          provider,
          amount,
          tip,
          dataTracking,
        },
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));
  }, [tokenContext]);

  useEffect(() => {
    getDetailServiceTracking(tokenContext).then(dataResponseInformationOrder => {
      const { evidence, registryUuid, isCalificated } = dataResponseInformationOrder;
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        evidenceImages: evidence?.map(obj => ({ ...obj, id: generateId() })),
        serviceUuid: registryUuid,
        isCalificated,
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));

    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      const { evidenceTitle, titleWithOutEvidence, score } = dataResponseServiceTrackingContent;
      setDataSeviceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        evidenceTitle,
        titleWithOutEvidence,
        score,
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => setLoading(false));
  }, [tokenContext]);

  return loading ? (
    <ContentStepperAndOrder sx={contentProgress}>
      <CircularProgress color={typesColors.primary} />
    </ContentStepperAndOrder>
  ) : (
    <Box sx={boxStepperAndOrderInformation}>
      <LayoutMapOrScore company={trackingData?.company} />
      <LayoutInformationOrder orderInformationData={dataSeviceTracking} />
      <LayoutInformationTicket orderInformationData={dataSeviceTracking} />
      <LayoutInformationProvider orderInformationData={dataSeviceTracking} />
      <ContentStepperAndOrder>
        <LayoutStepper
          dataStepperStatus={dataSeviceTracking}
          evidenceData={dataSeviceTracking.evidenceImages}
          evidenceTitle={dataSeviceTracking.evidenceTitle}
          titleWithOutEvidence={dataSeviceTracking.titleWithOutEvidence}
        />
      </ContentStepperAndOrder>
    </Box>
  );
};

export default ContentStepperAndOrderLayout;
