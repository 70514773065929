import { Button } from '@mui/material';
import { useContext } from 'react';
import { ContextImageAndToggle } from '../../Contexts/TokenServiceTracking';
import { TYPES_COMPONENTS } from '../../Common';
import {
  buttonCard,
  CardImage,
  ContentEvidenceCard,
  SpanImage,
} from './styles';

const alternativeText = 'evidence';
const EvidenceCard = ({ img, type }) => {
  const { handleToggle } = useContext(ContextImageAndToggle);

  const handleModalShow = (e) => {
    const { target } = e;
    handleToggle(true, target.src);
  };

  return (
    <>
      <Button disableRipple={true} disableTouchRipple={true} sx={buttonCard}>
        <ContentEvidenceCard>
          <SpanImage>{type}</SpanImage>
          <CardImage
            component={TYPES_COMPONENTS.img}
            onClick={handleModalShow}
            src={img}
            alt={alternativeText}
          />
        </ContentEvidenceCard>
      </Button>
    </>
  );
};

export default EvidenceCard;
