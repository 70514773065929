import { Box, Button } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { noBar, TYPES_COMPONENTS } from '../../Common';
import { pathViews } from '../../Common/messages';
import { MenuMobileContext } from '../../Contexts/MenuContext';
import { contentMenu, contentMenuBlog } from './ButtonMenuStyles';

const ButtonMenu = () => {
  const { pathname } = useLocation();
  const { toggleMenu, setToggleMenu } = useContext(MenuMobileContext);
  const refSpan = useRef(null);
  const refButton = useRef(null);
  refSpan.current = [];

  const getSpan = (el) => {
    if (el && !refSpan.current.includes(el)) refSpan.current.push(el);
    return refSpan.current;
  };

  useEffect(() => {
    refButton.current = getSpan();
    if (toggleMenu) {
      refButton.current[0].style.transform = 'rotate(0deg)';
      refButton.current[1].style.opacity = '1';
      refButton.current[1].style.display = 'block';
      refButton.current[2].style.transform = 'rotate(0deg)';
    }
  }, [toggleMenu]);

  const handleToggle = () => {
    let toggle = toggleMenu;
    if (toggle) {
      refButton.current[0].style.transform = 'rotate(45deg) translate(4px, 3px)';
      refButton.current[1].styletransform = 'rotate(0deg) scale(0.2, 0.2)';
      refButton.current[1].style.opacity = '0';
      refButton.current[1].style.display = 'none';
      refButton.current[2].style.transform = 'rotate(-45deg) translate(2px, -1.2px)';
      toggle = false;
    } else {
      refButton.current[0].style.transform = 'rotate(0deg)';
      refButton.current[1].style.opacity = '1';
      refButton.current[1].style.display = 'block';
      refButton.current[2].style.transform = 'rotate(0deg)';
      toggle = true;
    }
    setToggleMenu(toggle);
  };
  return (
    <Button
      onClick={handleToggle}
      variant='contained'
      sx={pathname.slice(0, noBar) !== pathViews.blog.app
        ? contentMenu
        : { ...contentMenu, ...contentMenuBlog }}
    >
      <Box ref={getSpan} component={TYPES_COMPONENTS.span} />
      <Box ref={getSpan} component={TYPES_COMPONENTS.span} />
      <Box ref={getSpan} component={TYPES_COMPONENTS.span} />
    </Button>
  );
};

export default ButtonMenu;
