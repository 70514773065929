import { IconButton } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { typesColors, variantTypography } from '../../Common';
import {
  CaptionSkeleton,
  captionSkeletonDesktop,
  cardDesktopSelectionOrder,
  ContainerCard,
  ContainerCloseIcon,
  ContainerListServices,
  ModalDesktop,
  ModalDesktopBox,
  styleCaptionServiceList,
  styleCaptionServiceListDesktop,
} from './styles';
import LayoutPointServiceOrder from '../LayoutPointServiceOrder/LayoutPointServiceOrder';
import { ButtonOrderService, Captions, CardServiceOrder } from '../../Components';
import { CardServiceSelectionSkeleton } from '../../Components/Services-OKBOY';
import { linkDefault, pathViews } from '../../Common/messages';
import './styles.css';

const twoCards = 2;
const classButtonNext = 'button-next';
const classButtonNextDesktop = 'button-next-desktop';
const LayoutModalDesktop = ({
  loading,
  title,
  cardsServices,
  buttonText,
  steps,
}) => {
  const { primary } = typesColors;
  const { state } = useLocation();
  const {
    app,
    services,
  } = pathViews.pathServices;
  const pathServiceGas = `${app}${services.gas}`;

  return (
    <ModalDesktop open={state.showModal}>
      <ModalDesktopBox>
        <ContainerCloseIcon>
          <NavLink state={{ showModal: false }} to={pathServiceGas}>
            <IconButton color={primary}>
              <CloseIcon />
            </IconButton>
          </NavLink>
        </ContainerCloseIcon>
        <LayoutPointServiceOrder loading={loading} length={steps} />
        <ContainerListServices>
          <Captions
            style={{ ...styleCaptionServiceList, ...styleCaptionServiceListDesktop }}
            variant={variantTypography.h3}
          >
            { loading ? <CaptionSkeleton sx={captionSkeletonDesktop} /> : title }
          </Captions>
          <ContainerCard sx={cardDesktopSelectionOrder}>
            {
              loading ? (
                <CardServiceSelectionSkeleton length={twoCards} />
              ) : (
                cardsServices.map(card => (
                  <CardServiceOrder
                    key={card.nameTypeService}
                    image={card.imageService}
                    title={card.nameTypeService}
                    loading={loading}
                  />
                ))
              )
            }
          </ContainerCard>
        </ContainerListServices>
        <ButtonOrderService
          link={linkDefault}
          classStyle={`${classButtonNext} ${classButtonNextDesktop}`}
          loading={loading}
        >
          { buttonText }
        </ButtonOrderService>
      </ModalDesktopBox>
    </ModalDesktop>
  );
};

export default LayoutModalDesktop;
