import {
  lazy,
  Suspense,
} from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { pathViews } from '../Common/messages';
import { ViewLoader } from '../Components';
import {
  BlogApp,
  BlogCategories,
  BlogHome,
  BlogPost,
  CancellationPolicies,
  Download,
  FrequentQuestions,
  Home,
  NotFound,
  PrivacyPolicy,
  ServiceBilling,
  ServicesSearchApp,
} from '../Views';
import TermsConditions from '../Views/TermsConditions/TermsConditions';

const Services = lazy(() => import('../Views/ServicesOKBOY/Services'));
const GasLP = lazy(() => import('../Views/ServicesOKBOY/GasLP/GasLP'));
const LayoutModalServiceOrder = lazy(() => import('../Layouts/LayoutModalServiceOrder/LayoutModalServiceOrder'));
const ServiceTracking = lazy(() => import('../Views/ServiceTracking/ServiceTracking'));

const ServicesSearch = lazy(() => import('../Views/ServicesSearch/ServicesSearch'));
const ServiceProduct = lazy(() => import('../Views/ServicesSearch/ServiceProduct'));
const PreOrder = lazy(() => import('../Views/ServicesSearch/PreOrder'));
const SuccesPreOrder = lazy(() => import('../Views/ServicesSearch/SuccesPreOrder'));

const LandingRoute = () => {
  return (
    <Routes>
      <Route index path={pathViews.home} element={<Home />} />
      <Route path={pathViews.subLanding} element={<Home />} />

      <Route path={pathViews.servicesSearch.app} element={<ServicesSearchApp />}>
        <Route
          index
          element={(
            <Suspense fallback={<ViewLoader />}>
              <ServicesSearch />
            </Suspense>
      )}
        />
        <Route
          path={pathViews.servicesSearch.chidren.product}
          element={(
            <Suspense fallback={<ViewLoader />}>
              <ServiceProduct />
            </Suspense>
          )}
        />
        <Route
          path={pathViews.servicesSearch.chidren.preOrder}
          element={(
            <Suspense fallback={<ViewLoader />}>
              <PreOrder />
            </Suspense>
        )}
        />
        <Route
          path={pathViews.servicesSearch.chidren.succesPreOrder}
          element={(
            <Suspense fallback={<ViewLoader />}>
              <SuccesPreOrder />
            </Suspense>
          )}
        />
      </Route>

      <Route
        path={pathViews.frecuentQuestions}
        element={<FrequentQuestions />}
      />

      <Route
        path={pathViews.faqs}
        element={<FrequentQuestions />}
      />

      <Route
        path={pathViews.cancellationPolicies}
        element={<CancellationPolicies />}
      />

      <Route path={pathViews.privacyPolicy} element={<PrivacyPolicy />} />

      <Route path={pathViews.termsConditions} element={<TermsConditions />} />

      <Route path={pathViews.blog.app} element={<BlogApp />}>
        <Route path={pathViews.blog.childrens.home} element={<BlogHome />} />
        <Route path={pathViews.blog.childrens.post} element={<BlogPost />} />
        <Route
          path={pathViews.blog.childrens.categories}
          element={<BlogCategories />}
        />
      </Route>

      <Route
        path={pathViews.pathServices.app}
        element={(
          <Suspense>
            <Services />
          </Suspense>
        )}
      >
        <Route
          path={pathViews.pathServices.services.gas}
          element={(
            <Suspense>
              <GasLP />
            </Suspense>
          )}
        >
          <Route
            path={pathViews.pathServices.services.order}
            element={(
              <Suspense fallback={<ViewLoader />}>
                <LayoutModalServiceOrder />
              </Suspense>
        )}
          />
        </Route>
      </Route>

      <Route
        path={pathViews.pathTrackOrder.path}
        element={(
          <Suspense fallback={<ViewLoader />}>
            <ServiceTracking />
          </Suspense>
        )}
      />

      <Route
        path={pathViews.pathTrackOrderWithQuery.path}
        element={(
          <Suspense fallback={<ViewLoader />}>
            <ServiceTracking />
          </Suspense>
        )}
      />

      <Route
        path={pathViews.webViews.serviceBilling}
        element={(
          <Suspense fallback={<ViewLoader />}>
            <ServiceBilling />
          </Suspense>
        )}
      />
      <Route
        path={`${pathViews.webViews.serviceBilling}:folio`}
        element={(
          <Suspense fallback={<ViewLoader />}>
            <ServiceBilling />
          </Suspense>
        )}
      />
      <Route
        path={pathViews.download}
        element={(
          <Suspense fallback={<ViewLoader />}>
            <Download />
          </Suspense>
        )}
      />

      <Route path={pathViews.notFoundPath} element={<NotFound />} />

      {/* Default */}
      <Route path='/' element={<Navigate to={pathViews.home} />} />

    </Routes>
  );
};

export default LandingRoute;
