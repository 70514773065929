import { Box } from '@mui/material';
import { CardServiceOrBenefits } from '../../Components/Services-OKBOY';
import { containerCardSkeleton } from './styles';

const LayoutServiceOrBenefitsCardSkeleton = ({ lenghtCard, withTextPrice }) => {
  const oneCard = 1;
  return (
    <Box sx={containerCardSkeleton}>
      {Array.from(new Array(lenghtCard || oneCard)).map(() => (
        <CardServiceOrBenefits withTextPrice={withTextPrice} key={Math.random()} />
      ))}
    </Box>
  );
};

LayoutServiceOrBenefitsCardSkeleton.defaultProps = {
  withTextPrice: true,
};

export default LayoutServiceOrBenefitsCardSkeleton;
