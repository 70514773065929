import { useEffect, useState } from 'react';
import { variantTypography } from '../../Common';
import {
  Captions,
  Loader,
  ProductRecommendationsList,
  ProductRecommendationsTitle,
} from '../../Components';
import {
  captionDoesItWork,
  CaptionDoesItWorkSkeleton,
  ListRecomendationSectionContainer,
  ListRecomendationSectionContainerCustom,
  ListRecomendationSectionFooter,
  ListSection,
} from './styles';

const RecomandationsListLayout = () => {
  const [recomendationsSectionData, setRecomendationsSectionData] = useState({
    title: '',
    beforeSectionList: {
      title: '',
      list: [],
    },
    duringSectionList: {
      title: '',
      list: [],
    },
    afterSectionList: {
      title: '',
      list: [],
    },
    footer: '',
    loading: false,
  });

  useEffect(() => {
    const recomendationsSectionDataResponse = {
      title: 'Algunas recomendaciones',
      beforeSectionList: {
        title: 'Antes',
        list: [
          {
            id: 1,
            text: 'Recomendamos que solo un integrante del hogar acompañe el proceso.',
          },
          {
            id: 2,
            text: 'Llevaremos tapabocas para su protección No genera olores ni alergias.',
          },
          {
            id: 3,
            text: 'Tampoco genera efectos secundarios en bebés, ancianos o mascotas',
          },
          {
            id: 4,
            text: 'Podrán activarse los detectores de humo',
          },
        ],
      },
      duringSectionList: {
        title: 'Durante',
        list: [
          {
            id: 1,
            text: 'No es necesario desocupar espacios',
          },
          {
            id: 2,
            text: 'No debes cubrir ninguna superficie o elemento',
          },
          {
            id: 3,
            text: '',
          },
        ],
      },
      afterSectionList: {
        title: 'Después',
        list: [
          {
            id: 1,
            text: 'No debes estar en tu hogar durante la siguiente hora a la desinfección.',
          },
          {
            id: 2,
            text: 'No es necesario secar, limpiar o barrer después de la desinfección.',
          },
          {
            id: 3,
            text: '*Nuestro personal es especialista en desinfección y va protegido de pies a cuello con un traje especial de bioseguridad y una máscara media cara con filtro de vapores, además realiza protocolo de desinfección previo al ingreso a tu hogar.',
          },
        ],
      },
      footer: '**Entre más seguido hagas tus desinfecciones, tendrás menor riesgo de tener virus, bacterias y gérmenes en tus espacios.',
    };
    setRecomendationsSectionData(prevRecomendationsSectionData => ({
      ...prevRecomendationsSectionData,
      ...recomendationsSectionDataResponse,
    }));
  }, []);

  const {
    loading,
  } = recomendationsSectionData;

  return (
    <ListRecomendationSectionContainer>
      <Captions
        variant={variantTypography.h2}
        style={captionDoesItWork}
      >
        {loading ? <CaptionDoesItWorkSkeleton /> : recomendationsSectionData.title}
      </Captions>

      {
        loading ? (
          <Loader styles={ListRecomendationSectionContainerCustom} />
        ) : (
          <>
            <ListSection>
              <ProductRecommendationsTitle
                title={recomendationsSectionData.beforeSectionList.title}
              />
              <ProductRecommendationsList
                itemsList={recomendationsSectionData.beforeSectionList.list}
              />
            </ListSection>

            <ListSection>
              <ProductRecommendationsTitle
                title={recomendationsSectionData.duringSectionList.title}
              />
              <ProductRecommendationsList
                itemsList={recomendationsSectionData.duringSectionList.list}
              />
            </ListSection>

            <ListSection>
              <ProductRecommendationsTitle
                title={recomendationsSectionData.afterSectionList.title}
              />
              <ProductRecommendationsList
                itemsList={recomendationsSectionData.afterSectionList.list}
              />
            </ListSection>
            <ListRecomendationSectionFooter>
              {recomendationsSectionData.footer}
            </ListRecomendationSectionFooter>
          </>
        )
      }

    </ListRecomendationSectionContainer>
  );
};

export default RecomandationsListLayout;
