export const containHeader = {
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: {
    xs: '6.0625rem',
    phoneMd: '6.0625rem',
    sm: '6.0625rem',
    md: '4.9rem',
    lg: '4.9rem',
  },
  marginBottom: {
    xs: '3.4rem',
    phoneMd: '3.4rem',
    sm: '3.4rem',
    md: '3.75rem',
    lg: '3.75rem',
  },
  height: {
    sx: '561px',
    phoneMd: '561px',
    md: '458px',
    lg: '458px',
    xl: '458px',
  },
  color: 'secondary.main',
  '& > div': {
    maxWidth: {
      xs: '343px',
      sm: '343px',
      tablet: '700px',
      md: '1190px',
      lg: '1190px',
    },
    padding: '0px',
  },
  '& > div, & > div > div': {
    height: '100%',
  },
  backgroundColor: 'primary.main',
};

export const directionImageAndCaption = {
  xs: 'column',
  phoneMd: 'column',
  tablet: 'row',
  md: 'row',
  lg: 'row',
  xl: 'row',
};

export const spacingImageAndCaption = {
  xs: 1,
  phoneMd: 1,
  tablet: 3.3,
  md: 14.3,
  lg: 14.3,
  xl: 14.3,
};

export const imageHeader = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexBasis: {
    xs: '269px',
    phoneMd: '269px',
    sm: '269px',
    md: '50%',
    lg: '50%',
  },
  flex: { tablet: '1 0 56.8%' },
  justifyContent: 'center',
  '& > img': {
    width: '100%',
    height: {
      xs: '233px',
      sm: '233px',
      tablet: '300px',
      md: '394px',
      lg: '394px',
    },
    objectFit: 'cover',
    borderRadius: '30px',
  },
};

export const contentWidthImageAndText = {
  flexBasis: '50%',
};

export const contentTextHeader = {
  display: 'flex',
  flexFlow: 'column wrap',
  justifyContent: 'center',
  paddingBottom: {
    xs: '1.2rem',
    phoneMd: '1.2rem',
    sm: '1.2rem',
    tablet: 'initial',
    md: 'initial',
    lg: 'initial',
  },
  flex: { tablet: '1 0 44%' },
  lineHeight: {
    xs: '0',
    phoneMd: '0',
    sm: '0',
    md: '1.5',
    lg: '1.5',
  },
};

export const contentBadgeAndLectureTime = {
  display: 'flex',
  alignItems: 'center',
  height: '24px',
};
export const spacingTypeCategory = {
  '& > span': {
    width: 'auto',
    display: 'flex',
    position: 'static',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transform: 'none',
    fontSize: '1rem',
    fontWeight: '900',
    padding: '1px 16px 1px 16px',
    transition: 'none',
    color: 'primary.main',
  },
  '& + span': {
    width: 'auto',
    position: 'static',
    fontWeight: '500',
    color: 'background.textFieldSubscribe',
    transform: 'none',
    transition: 'none',
  },
  marginRight: {
    xs: '1rem',
    phoneMd: '1rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '1.5rem',
  },
  display: 'inline-block',
  width: 'auto',
  height: '100%',
};

export const captionHeader = {
  maxWidth: '418px',
  fontSize: {
    xs: '1.75rem',
    phoneMd: '1.75rem',
    sm: '1.75rem',
    md: '2.5rem',
    lg: '2.5rem',
    xl: '2.5rem',
  },
  fontWeight: '900',
  margin: {
    xs: '1rem 0rem 1.9375rem 0rem',
    phoneMd: '1rem 0rem 1.9375rem 0rem',
    sm: '1rem 0rem 1.9375rem 0rem',
    md: '.5rem 0rem 1rem 0rem',
    lg: '.5rem 0rem 1rem 0rem',
  },
  lineHeight: '2.5rem',
};
export const contentCustomAuthorAndDateOfBlogPost = {
  marginBottom: {
    xs: '2.4rem',
    phoneMd: '2.4rem',
    sm: '2.4rem',
    md: '2.625rem',
    lg: '2.625rem',
  },
};

export const conentIcon = {
  '& > a': {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      height: '78%',
      '& > svg > path': {
        fill: '#fff',
      },
    },
  },
};
