import {
  Box, Button, ButtonBase, FormControl, InputBase, InputLabel, Modal, styled,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StaticDatePicker } from '@mui/x-date-pickers';

export const ContentSelect = styled(Box)(({ theme }) => ({
  gridColumn: '1 / 5',
  gridRow: '3 / 4',
  height: '62px',
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
  padding: '0.5rem 0.5rem 0.5rem 2.5rem',
  borderRadius: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '83%',
    height: 'max-content',
    padding: '1.75rem 1.5rem 1rem 1.5rem',
  },
}));

export const SelectService = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid rgba(77, 77, 78, 0.5)',
  paddingBottom: '0.6rem',
  '& .MuiSvgIcon-root': {
    transform: 'rotate(0deg)',
    fontSize: '2rem',
    transition: theme.transitions.create(['transform'], {
      duration: '.3s',
      easing: 'ease',
    }),
  },
}));

export const SelectLocation = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid rgba(77, 77, 78, 0.5)',
  paddingBottom: '0.6rem',
  marginTop: '0.4rem',
  '& .MuiSvgIcon-root': {
    transform: 'rotate(0deg)',
    fontSize: '2rem',
    transition: theme.transitions.create(['transform'], {
      duration: '.3s',
      easing: 'ease',
    }),
  },
}));

export const Date = styled(StaticDatePicker)(() => ({
  '& .MuiCalendarPicker-root': {
    position: 'relative',
    zIndex: '10',
    backgroundColor: '#f9f9f9',
    '& .MuiPickersDay-root, & .MuiDayPicker-weekDayLabel': {
      backgroundColor: 'transparent',
      fontWeight: '900',
      color: '#102653',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: '#1f95af',
    },
    '& .MuiPickersCalendarHeader-root': {
      position: 'relative',
      '& > .MuiPickersCalendarHeader-labelContainer': {
        marginRight: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& > button': {
          display: 'none',
        },
      },
      '& > .MuiPickersArrowSwitcher-root': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
          display: 'none',
        },
      },
    },
    '& .MuiPickersDay-root:hover, & .MuiPickersDay-root:focus': {
      backgroundColor: '#1f95af',
    },
  },
}));

export const SelectDate = styled(ButtonBase)(({ theme }) => ({
  display: 'none',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '0.4rem',
  '& .MuiSvgIcon-root': {
    transform: 'rotate(0deg)',
    fontSize: '2rem',
    transition: theme.transitions.create(['transform'], {
      duration: '.3s',
      easing: 'ease',
    }),
  },
}));

export const TextButtonSelect = {
  display: '-webkit-box',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '1.36625rem',
  flexBasis: 'auto',
  color: 'text.secondary',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

export const arrowIcon = {
  flexBasis: 'auto',
  color: '#4D4D4E',
};

export const ModalSelect = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ServicesContiner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column wrap',
  gap: '0.625rem',
  position: 'relative',
  backgroundColor: theme.palette.secondary.main,
  width: '91.5%',
  height: 'max-content',
  padding: '1.3125rem 1rem 2.5rem',
  borderRadius: '30px',
}));

export const closeIconStyles = {
  position: 'absolute',
  right: '2%',
  top: '1%',
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
};

export const captionServiceOption = {
  width: '100%',
  marginBottom: '1.5rem',
  color: 'text.secondary',
  textAlign: 'left',
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.6875rem',
};

export const ServicesCheck = styled(Button)(({ theme }) => ({
  display: 'block',
  width: '100%',
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['background-color'], {
    duration: '.2s',
    easing: 'ease',
  }),
  color: theme.palette.text.secondary,
  border: 'solid 1px #F0F1F5',
  borderRadius: '8px',
  padding: '0.25rem 0.625rem',
  textTransform: 'capitalize',
  textAlign: 'left',
}));

export const buttonSearch = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  color: 'background.primary',
  fontWeight: '900',
  fontSize: '1.375rem',
  lineHeight: '1.878125rem',
  '&.Mui-disabled': {
    color: 'background.primary',
    backgroundColor: '#4433E04D',
  },
};

export const modalLocationStyles = {
  height: '554px',
  overflowY: 'auto',
  flexWrap: 'nowrap',
};

export const locationButton = {
  marginTop: '3.688rem',
};

export const buttonSearchMobile = {
  marginTop: '1.25rem',
  width: '100%',
  '&.Mui-disabled': {
    color: '#F9F9F9',
    backgroundColor: '#4433E04D',
  },
};

/** *** DESKTOP **** */

export const DesktopContentSelect = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '125px 150px auto',
  gridTempalteRows: 'auto',
  justifyItems: 'left',
  gridColumn: '1 / 5',
  gridRow: '3 / 4',
  height: '62px',
  gap: '1.5rem',
  width: 'max-content',
  backgroundColor: theme.palette.secondary.main,
  padding: '0.5rem 0.5rem 0.5rem 2.5rem',
  borderRadius: '15px',
}));

export const SelectContainer = styled(FormControl)(() => ({
  width: '100%',
  height: '45px',
  gap: '1rem',
}));

export const InputSelect = styled(InputBase)(() => ({
  gridColumn: '1 / 2',
  width: '122px',
  padding: '0px',
  height: '100%',
  borderRight: '1px solid rgba(77, 77, 78, 0.5);',
  '& .MuiInputBase-input': {
    width: '66%',
    paddingRight: '2rem !important',
    paddingTop: '0.3rem',
    position: 'relative',
    fontSize: '1rem',
    borderRadius: 'initial',
    '&:focus': {
      '& + .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      },
    },
  },
}));

export const servicesSelectorsStyles = {
  textAlign: {
    xs: 'start',
    md: 'center',
  },
};

export const locationSelectorsStyles = {
  gridColumn: '2 / 3',
  width: 'auto',
  textAlign: {
    xs: 'start',
    md: 'center',
  },
  '& .MuiInputBase-input': {
    width: '100%',
    paddingRight: '3rem !important',
    paddingTop: '0.3rem',
    position: 'relative',
    fontSize: '1rem',
    borderRadius: 'initial',
    '&:focus': {
      '& + .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      },
    },
  },
};

export const InputLabelCustom = styled(InputLabel)(() => ({
  transform: 'translate(0, 11px) scale(1)',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '1.36625rem',
  '&[data-shrink="true"]': {
    transform: 'translate(0, -8.5px) scale(0.75)',
  },
}));

export const customServiceInput = {
  '&[data-shrink="true"]': {
    transform: 'translate(0, 11px) scale(1)',
  },
  '&.Mui-focused': {
    color: 'text.secondary',
  },
};

export const customLocationInput = {
  '&[data-shrink="true"]': {
    transform: 'translate(0, 11px) scale(1)',
  },
  '&.Mui-focused': {
    color: 'text.secondary',
  },
};

export const KeyboardArrowDownIconCustom = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  transform: 'rotate(0deg)',
  marginRight: '0.5rem',
  color: '#4D4D4E',
  transition: theme.transitions.create(['transform'], {
    duration: '.2s',
    easing: 'ease',
  }),
  fontSize: '2.1rem',
}));

export const dateSelectCustomStyles = {
  display: 'none',
  width: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  height: '41px',
};

export const arrowDateIcon = {
  marginLeft: '2.5rem',
};

export const dateCustomStyles = {
  marginTop: '0rem',
};

export const buttonSearchDescktop = {
  width: '150px',
  marginTop: '0px',
};

export const labelShow = {
  display: 'none',
};
