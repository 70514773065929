import { Badge, Box, Stack } from '@mui/material';
import {
  TYPES_COMPONENTS,
  TYPES_DIRECTIONS,
  TYPES_LOGO,
  variantTypography,
} from '../../Common';
import { listLogosMenu } from '../../Common/messages';
import { LayoutGeneral } from '..';
import {
  captionHeader,
  conentIcon,
  containHeader,
  contentWidthImageAndText,
  imageHeader,
  spacingTypeCategory,
  contentBadgeAndLectureTime,
  contentTextHeader,
  directionImageAndCaption,
  spacingImageAndCaption,
  contentCustomAuthorAndDateOfBlogPost,
} from './HeaderBlogPostStyles';
import {
  AuthorAndDateOfBlogPost, Captions, LogoRRSS, SkeletonHeaderBlogPost,
} from '../../Components';

const colorBadge = 'badge';

const HeaderBlogPostLayout = ({
  typeCategory, readDuration, title, author, creationDate, imageUrl, loading, altImage,
}) => {
  return (
    <Box sx={containHeader}>
      <LayoutGeneral>
        <Stack
          direction={directionImageAndCaption}
          spacing={spacingImageAndCaption}
        >
          { loading ? (
            <SkeletonHeaderBlogPost />
          ) : (
            <>
              <Box sx={{ ...contentWidthImageAndText, ...imageHeader }}>
                <Box component={TYPES_COMPONENTS.img} src={imageUrl} alt={altImage} />
              </Box>
              <Box sx={{ ...contentWidthImageAndText, ...contentTextHeader }}>
                <Box sx={contentBadgeAndLectureTime}>
                  <Badge
                    color={colorBadge}
                    badgeContent={typeCategory}
                    sx={spacingTypeCategory}
                  />
                  <Box component={TYPES_COMPONENTS.span}>{readDuration}</Box>
                </Box>
                <Captions variant={variantTypography.h1} style={captionHeader}>
                  {title}
                </Captions>
                <AuthorAndDateOfBlogPost
                  styleCustom={contentCustomAuthorAndDateOfBlogPost}
                  author={author}
                  creationDate={creationDate}
                />
                <Stack
                  direction={TYPES_DIRECTIONS.row}
                  spacing={2.6}
                  sx={conentIcon}
                >
                  {listLogosMenu.map((logo) => (
                    <LogoRRSS
                      key={logo.name}
                      {...logo}
                      typeLogo={TYPES_LOGO.blogPost}
                    />
                  ))}
                </Stack>
              </Box>
            </>
          )}
        </Stack>
      </LayoutGeneral>
    </Box>
  );
};

export default HeaderBlogPostLayout;
