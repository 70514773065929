import { ServicePreOrder } from '../../Common/messages';

const preOrderState = {
  configurationData: {},
  company: {},
  productShowCase: {},
  date: {
    dateView: '',
    defaultDate: '',
  },
  preOrderSummary: {},
  checkoutData: {},
  checkoutParamsData: {},
};

const {
  ACTION_TYPES,
  localStorageData,
} = ServicePreOrder;

export const intialPreOrderState = () => {
  // eslint-disable-next-line no-undef
  return JSON.parse(localStorage.getItem(localStorageData.preOrder)) || preOrderState;
};
// eslint-disable-next-line no-unused-vars, default-param-last
export const preOrderReducer = (state = preOrderState, action) => {
  switch (action.type) {
    case ACTION_TYPES.setConfigurationData: {
      return { ...state, configurationData: action.payload };
    }
    case ACTION_TYPES.getCompanyId: {
      return { ...state, company: action.payload };
    }
    case ACTION_TYPES.getProductShowCase: {
      return { ...state, productShowCase: action.payload };
    }
    case ACTION_TYPES.setDate: {
      return { ...state, date: action.payload };
    }
    case ACTION_TYPES.getPreOrderSummary: {
      return { ...state, preOrderSummary: action.payload };
    }
    case ACTION_TYPES.getCheckout: {
      return { ...state, checkoutData: action.payload };
    }
    case ACTION_TYPES.setCheckoutParamsData: {
      return { ...state, checkoutParamsData: action.payload };
    }
    default:
      return { ...state };
  }
};
