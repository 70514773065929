import { STATUS_SERVICES_FINISHED_SERVICE, TYPES_REGEX } from '../../Common';

const validLetterCharater = (characters) => {
  // eslint-disable-next-line prefer-regex-literals
  const expressionRegular = new RegExp(TYPES_REGEX.characters, 'i');
  return !!characters && !expressionRegular.test(characters.trim());
};

const validNumbers = (numbers) => {
  // eslint-disable-next-line prefer-regex-literals
  const expressionRegular = new RegExp(TYPES_REGEX.numbers);
  return !!numbers && !expressionRegular.test(numbers);
};

const validEmail = (email) => {
  // eslint-disable-next-line prefer-regex-literals
  const expressionRegular = new RegExp(TYPES_REGEX.email);
  return !!email && !expressionRegular.test(email);
};
const validMxEmail = (email) => {
  // eslint-disable-next-line prefer-regex-literals
  const expressionRegular = new RegExp(TYPES_REGEX.mxEmail);
  return !!email && !expressionRegular.test(email);
};

const finishedStatus = (status) => {
  return STATUS_SERVICES_FINISHED_SERVICE.includes(status);
};

export {
  validLetterCharater,
  validNumbers,
  validEmail,
  validMxEmail,
  finishedStatus,
};
