import {
  Box, StepConnector, stepConnectorClasses, styled,
} from '@mui/material';

export const ConnectorStatus = styled(StepConnector)(({ theme }) => ({
  marginLeft: '.4375rem',
  padding: '.125rem 0',
  paddingLeft: '2.1rem',
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      borderColor: theme.palette.primary.purple,
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: theme.palette.primary.purple,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
      borderLeftStyle: 'solid',
      borderColor: 'transparent',
      borderLeftColor: theme.palette.primary.purple,
      borderLeftWidth: '1px',
    },
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderLeftStyle: 'dashed',
    borderLeftWidth: '1px',
    borderLeftColor: theme.palette.primary.purple,
    height: '100%',
    [`&.${stepConnectorClasses.line}`]: {
      minHeight: 'initial',
      height: '39px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 'initial',
  },
}));

const styledDefault = {
  position: 'relative',
};

const styledPseudoElement = {
  content: '""',
  position: 'absolute',
  borderRadius: '50%',
  border: 'solid 2px #fff',
  width: '84%',
  height: '56%',
  left: '9%',
  top: '23%',
};

export const Circles = styled(Box)(({ theme, ownerState }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '22px',
  color: '#eaeaf0',
  ...(ownerState.active ? {
    ...styledDefault,
    color: theme.palette.primary.purple,
    '&::after': {
      ...styledPseudoElement,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-3.7%',
    },
  } : {
    ...styledDefault,
    color: theme.palette.primary.purple,
    '&::after': {
      ...styledPseudoElement,
    },
  }),
  '& .circle-completed': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
    backgroundColor: theme.palette.primary.purple,
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
  '& .circle': {
    border: '1px solid #79797A',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));
