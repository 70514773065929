import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { LOCALSTORAGE_NAME_TYPES } from '../Common';
import { initialState, productServiceReducer } from '../store/serviceProduct/store';

export const ServiceProductContext = createContext(null);

const ServiceProductProvider = ({ children }) => {
  const [productServiceState, dispatch] = useReducer(productServiceReducer, [], initialState);
  const productServiceMemo = useMemo(() => {
    return [productServiceState, dispatch];
  }, [
    productServiceState.product,
    productServiceState.provider,
    productServiceState.dateData?.date,
    productServiceState.dateData?.schedule,
    productServiceState.location?.formatted_address,
    productServiceState?.productDetail,
    productServiceState?.companies,
    productServiceState?.companieId,
    productServiceState?.companieProducts,
    productServiceState?.companieSchedules,
    productServiceState?.showCaseProduct?.length,
    productServiceState.showCaseProductId,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      LOCALSTORAGE_NAME_TYPES.productService,
      JSON.stringify(productServiceState),
    );
  }, [
    productServiceState.product,
    productServiceState.provider,
    productServiceState.dateData?.date,
    productServiceState.dateData?.schedule,
    productServiceState.location?.formatted_address,
    productServiceState?.productDetail,
    productServiceState?.companies,
    productServiceState?.companieId,
    productServiceState?.companieProducts,
    productServiceState?.companieSchedules,
    productServiceState?.showCaseProduct.length,
    productServiceState.showCaseProductId,
  ]);

  return (
    <ServiceProductContext.Provider value={productServiceMemo}>
      { children }
    </ServiceProductContext.Provider>
  );
};

export default ServiceProductProvider;
