import { Box } from '@mui/material';
import { TYPES_COMPONENTS } from '../../Common';
import { conntentAuthorAndDate } from './AuthorAndDateOfBlogPostStyles';

const AuthorAndDateOfBlogPost = ({ author, creationDate, styleCustom }) => {
  return (
    <Box sx={{ ...conntentAuthorAndDate, ...styleCustom }}>
      <Box component={TYPES_COMPONENTS.span}>{ author }</Box>
      <Box component={TYPES_COMPONENTS.span}>{ creationDate }</Box>
    </Box>
  );
};

export default AuthorAndDateOfBlogPost;
