import { Box } from '@mui/material';
import { typesTarget, TYPES_COMPONENTS, TYPES_LOGO } from '../../Common';
import { linkDefault } from '../../Common/messages';

const LogoRRSS = ({
  path, prevent, icon, name, typeLogo, widthName,
}) => {
  return typeLogo === TYPES_LOGO.mobile ? (
    <Box
      component={TYPES_COMPONENTS.link}
      href={path}
      onClick={path === linkDefault ? prevent : undefined}
      target={
        path !== linkDefault
          ? typesTarget.TYPE_TARGET_BLANK
          : typesTarget.TYPE_TARGET_SELFT
      }
    >
      {icon}
    </Box>
  ) : typeLogo === TYPES_LOGO.blogPost ? (
    <Box
      component={TYPES_COMPONENTS.link}
      href={path}
      target={typesTarget.TYPE_TARGET_BLANK}
    >
      <Box component={TYPES_COMPONENTS.span}>
        {icon}
      </Box>
      {widthName && <Box component={TYPES_COMPONENTS.span}>{name}</Box>}
    </Box>
  ) : null;
};

LogoRRSS.defaultProps = {
  widthName: false,
};
export default LogoRRSS;
