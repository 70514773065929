import { Typography } from '@mui/material';

const Captions = ({
  variant, style, color, children,
}) => {
  return (
    <Typography sx={style} variant={variant} color={color}>
      {children}
    </Typography>
  );
};

export default Captions;
