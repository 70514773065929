import { useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import {
  containerFooter, footerHeightBlog, footerServiceGas, layoutFooterStyle,
} from './LayoutFooterStyle';
import { pathViews } from '../../Common/messages';
import { isPathBlogDistinc, validPathDistinc, validPathEqual } from '../../utils/validations/path';

const LayoutFooter = ({ children }) => {
  const { pathname } = useLocation();
  const { pathServices: { services } } = pathViews;
  const isTrackingView = !(pathname.indexOf(pathViews.pathTrackOrderWithQuery.path) !== -1);
  return isTrackingView && validPathEqual(pathname, services.gas) ? (
    <Box
      sx={
        isPathBlogDistinc(pathname, pathViews.blog.app)
          ? layoutFooterStyle
          : { ...layoutFooterStyle, ...footerHeightBlog }
      }
    >
      <Container sx={containerFooter}>
        {children}
      </Container>
    </Box>
  ) : validPathDistinc(pathname, services.gas) ? (
    <Box
      sx={{ ...layoutFooterStyle, ...footerServiceGas }}
    >
      <Container sx={containerFooter}>
        {children}
      </Container>
    </Box>
  ) : null;
};

export default LayoutFooter;
