import {
  Box,
  styled,
} from '@mui/material';

export const WrapperTracking = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: '.01rem',
  background: `linear-gradient(0deg, rgba(231, 234, 237, 0.5), rgba(231, 234, 237, 0.5)), ${theme.palette.secondary.main}`,
  [theme.breakpoints.down(1200)]: {
    width: '100%',
  },
}));

export const LogoSection = styled(Box)(() => ({
  width: '100%',
  textAlign: 'center',
}));

export const Footer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#262242',
  [theme.breakpoints.up(1110)]: {
    marginTop: '150px',
  },
}));

export const FooterWrapper = styled(Box)(({ theme }) => ({
  width: '90%',
  maxWidth: '1240px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '120px',
  paddingBottom: '40px',
  display: 'flex',
  [theme.breakpoints.down(479)]: {
    justifyContent: 'flex-start',
    paddingTop: '100px',
  },
}));

export const FooterTopWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  display: 'flex',
  [theme.breakpoints.down(991)]: {
    flexDirection: 'column',
  },
}));

export const FooterLeftContain = styled(Box)(({ theme }) => ({
  width: '40%',
  maxWidth: '360px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  display: 'flex',
  [theme.breakpoints.down(991)]: {
    width: '100%',
    maxWidth: '450px',
  },
}));

export const FooterDivider = styled(Box)(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#242424',
  marginTop: '100px',
  marginBottom: '32px',
}));

export const FooterWrapperCopyRigths = styled(Box)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.down(767)]: {
    flexDirection: 'column',
  },
}));
