import { Box, styled } from '@mui/material';

export const MapContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

export const mapStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '16px',
};
