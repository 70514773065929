const LinkedinCircle = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.78353 4.16677C5.78331 4.6088 5.6075 5.03263 5.29478 5.34504C4.98207 5.65744 4.55806 5.83282 4.11603 5.8326C3.674 5.83238 3.25017 5.65657 2.93776 5.34386C2.62536 5.03114 2.44997 4.60713 2.4502 4.1651C2.45042 3.72307 2.62622 3.29924 2.93894 2.98683C3.25166 2.67443 3.67567 2.49905 4.1177 2.49927C4.55972 2.49949 4.98356 2.6753 5.29596 2.98801C5.60837 3.30073 5.78375 3.72474 5.78353 4.16677ZM5.83353 7.06677H2.5002V17.5001H5.83353V7.06677ZM11.1002 7.06677H7.78353V17.5001H11.0669V12.0251C11.0669 8.9751 15.0419 8.69177 15.0419 12.0251V17.5001H18.3335V10.8918C18.3335 5.7501 12.4502 5.94177 11.0669 8.46677L11.1002 7.06677Z' fill='white' />
    </svg>
  );
};

export default LinkedinCircle;
