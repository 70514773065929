export const titleSkeleton = {
  backgroundColor: 'primary.main',
  maxWidth: '200px',
  width: '100%',
  marginBottom: '1rem',
};

export const descriptionSkeleton = {
  height: '105px',
};
