import { Box, styled } from '@mui/material';

export const Form = styled('form')(() => ({
  width: '100%',
  height: 'max-content',
}));

export const styledTitle = {
  fontSize: {
    xs: '1.125rem',
    md: '2.5rem',
  },
  fontWeight: '800',
  color: 'webView.primary',
  lineHeight: {
    xs: '.75rem',
    md: '2.8125rem',
  },
  marginBottom: {
    xs: '1rem',
    md: '1.5rem',
  },
};

export const InputAndBannerContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '423px 713px',
  gap: '7.5rem',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const InputContainer = styled(Box)(() => ({
  gridColumn: '1 / 2',
}));

export const WarrantyBannerContainer = styled(Box)(() => ({
  display: 'grid',
  gridColumn: '2 / 3',
  '& > .MuiBox-root': {
    alignSelf: 'center',
  },
}));

export const styledInputsAndButtonContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const styledBillingCompletedTitle = {
  color: 'webView.text',
  textAlign: 'center',
  fontSize: {
    xs: '.75rem',
    md: '1rem',
  },
  lineHeight: '1.125rem',
  fontWeight: '900',
  width: {
    xs: '80%',
    md: '100%',
  },
  margin: '0 auto',
  marginBottom: {
    xs: '1rem',
    md: '1.5rem',
  },
};
