import { typesTarget } from '../types';
import { urls } from './paths';

const captions = {
  main: 'Términos y Condiciones',
  fistTitleSection: 'Los Servicios',
  secondTitleSection: 'Del usuario',
  thirdTitleSection: 'Acceso y navegación en el Sitio Web o APP',
  fourthTitleSection: 'Política de privacidad y protección de datos.',
  fifthTitleSection: 'Política de enlaces',
  sixthTitleSection: 'Política en materia de Propiedad Intelectual e Industrial',
  seventhTitleSection: 'Legislación y jurisdicción aplicable.',
  eighthTitleSection: 'Aceptación de los Términos y Condiciones.',
  ninethTitleSection: 'Renuncias; Limitación de responsabilidad.',
  tenthTitleSection: 'Política de Cancelación, Devolución y Quejas por servicio incompleto.',
};

const sectionsTermsConditions = {
  introduction: 'Los presentes términos y condiciones (en adelante únicamente "Términos y Condiciones") aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio Okboy.app (en adelante, "Sitio Web o APP"), del cual es <strong>OKBOY, SOCIEDAD DE RESPONSABILIDAD LIMITADA DE CAPITAL VARIABLE</strong> (en adelante, <strong>"OKBOY"</strong>) quien tiene su domicilio en Zamora 187 (ciento ochenta y siete), Colonia Condesa, Alcaldía Cuauhtémoc, C.P. 06140, Ciudad de México. <br /><br /> LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS. <br /><br /> Cualquier persona que desee acceder o hacer uso del sitio o los servicios que en él se ofrecen, podrá hacerlo sujetándose a los presentes Términos y Condiciones, así como a políticas y principios incorporados al presente documento. Por ello es que le son aplicables por el simple uso o acceso a la aplicación móvil y/o página web de OKBOY. En todo caso, cualquier persona que no acepte los presentes términos y condiciones, deberá abstenerse de utilizar la aplicación móvil y/o página web de OKBOY y/o adquirir los productos y servicios que en su caso sean ofrecidos.',
  listFirstSection: [
    {
      list: 'Los Servicios constituyen una plataforma de tecnología que permite a los usuarios de aplicaciones móviles o páginas web proporcionadas como parte de los Servicios (cada una, una “Aplicación”) organizar y planear y contratar servicios de terceros prestadores particulares independientes de servicios de suministro de gas.',
    },
    {
      list: '<strong>OKBOY</strong> se reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la configuración que pudiera estar contenida en el Sitio Web o APP; en este sentido, el Usuario reconoce y acepta que OKBOY, SOCIEDAD DE RESPONSABILIDAD LIMITADA DE CAPITAL VARIABLE en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el Sitio Web o APP o el acceso a los mismos.',
    },
    {
      list: 'El acceso al Sitio Web o APP por parte del Usuario tiene carácter libre y, por regla general es gratuito sin que el Usuario tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la conexión a internet suministrada por el proveedor de este tipo de servicios que hubiere contratado el mismo Usuario.',
    },
    {
      list: 'El acceso a parte de los contenidos y servicios del Sitio Web o APP podrá realizarse previa suscripción o registro previo del Usuario.',
    },
    {
      list: 'Los servicios que presta <strong>OKBOY</strong> se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de edad (mayores de 18 años); y aunque el sitio no contiene material de uso para mayores de edad, <strong>OKBOY</strong> declina cualquier responsabilidad por el incumplimiento de este requisito.',
    },
    {
      list: 'El Sitio Web o APP está dirigido principalmente a Usuarios residentes en la República Mexicana, el Usuario reconoce que no todos los productos y servicios están disponibles en todas las áreas geográficas, luego, <strong>OKBOY</strong> no asegura que el Sitio Web o APP cumpla total o parcialmente con la legislación de otros países, de forma que, si el Usuario reside o tiene su domicilio establecido en otro país y decide acceder o utilizar el Sitio Web o APP lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo <strong>OKBOY</strong> ninguna responsabilidad que se pueda derivar de dicho acto.',
    },
    {
      list: 'Se hace del conocimiento del Usuario que <strong>OKBOY</strong> podrá administrar o gestionar el Sitio Web o APP de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido en los presentes Términos y Condiciones.',
    },
  ],
  listSecondSection: [
    {
      list: 'El acceso o utilización del Sitio Web o APP, confiere la condición de Usuario del Sitio Web o APP, por lo que quedará sujeto a los presentes Términos y Condiciones, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al Sitio Web o APP. Dada la relevancia de lo anterior, se recomienda al Usuario revisar las actualizaciones que se realicen a los presentes Términos y Condiciones.',
    },
    {
      list: 'Es responsabilidad del Usuario utilizar el Sitio Web o APP de acuerdo a la forma en la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través del Sitio Web o APP. Además, el Usuario se compromete a utilizar la información, contenidos o servicios ofrecidos a través del Sitio Web o APP de manera lícita, sin contravenir lo dispuesto en los presentes Términos y Condiciones, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del Sitio Web o APP.',
    },
    {
      list: 'El usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en el Sitio Web o APP, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por el propio Sitio Web o APP. En todo caso, el Usuario notificará de forma inmediata <strong>OKBOY</strong> acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.',
    },
    {
      list: 'El sólo acceso al Sitio Web o APP no supone el establecimiento de ningún tipo de relación entre <strong>OKBOY</strong> y el Usuario. Por lo que el uso del Sitio Web o APP es responsabilidad del Usuario quien deberá sujetarse estrictamente a los Términos y Condiciones.',
    },
  ],
  listThirdSection: [
    {
      list: '<strong>OKBOY</strong> no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, productos o servicios ofrecidos a través del Sitio Web o APP, ni la utilidad de la aplicación en relación con cualquier actividad específica, independientemente del medio de acceso que utilice el Usuario incluido la telefonía móvil; no obstante, OKBOY llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen funcionamiento del Sito Web, sin que esto suponga alguna responsabilidad de parte de OKBOY.',
    },
    {
      list: '<strong>OKBOY</strong> no será responsable por daño o pérdida alguna de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación de la aplicación móvil, pagina web y/o de los productos y servicios.',
    },
    {
      list: '<strong>OKBOY</strong> no será responsable ni garantiza que el contenido o software al que pueda accederse a través del Sitio Web o APP, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el Usuario accede al Sitio Web o APP.',
    },
    {
      list: '<strong>OKBOY</strong> tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del Sitio Web o APP. En ningún caso OKBOY será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del Sitio Web o APP.',
    },
  ],
  listFourthSection: [
    {
      list: 'De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, <strong>OKBOY</strong> se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.',
    },
    {
      list: 'A efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través del Sitio Web o APP, será tratado de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de <strong>OKBOY</strong>. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus Titulares, no obstante, esta podrá darse a través del propio Sitio Web o APP utilizando los mecanismos habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos.',
    },
    {
      list: 'En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados. El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el Aviso de Privacidad el cual se encontrará disponible en la siguiente dirección electrónica:',
    },
    {
      list: 'El Sitio Web o APP podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de terceros distintos de OKBOY. Los Titulares de dichos sitios web dispondrán de sus propias políticas de privacidad y protección de datos, por lo cual <strong>OKBOY</strong> no asume ningún tipo de responsabilidad por los datos que san facilitados por el Usuario a través de cualquier Sitio Web o APP distinto a https://landing.okboy.app/politica-privacidad/',
    },
    {
      list: '<strong>OKBOY</strong> se reserva el derecho a modificar su Política de Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización del Sitio Web o APP después de dichos cambios, implicará la aceptación de estos cambios.',
    },
    {
      list: 'El Sitio Web o APP puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el Usuario. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar al Sitio Web o APP, almacenar las preferencias del Usuario, así como la interacción que este tenga con el Sitio Web o APP, como por ejemplo: la fecha y hora en la que se accede al Sitio Web o APP, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etc. <br /> Este tipo de información será utilizada para mejorar el Sitio Web o APP, detectar errores, y posibles necesidades que el Usuario pueda tener, lo anterior a efecto de ofrecer a los Usuarios servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.',
    },
    {
      list: 'En caso de que el Usuario no desee que se recopile este tipo de información deberá deshabilitar rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizarestas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instruccionesfacilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total oparcialmente) el Usuario podrá continuar haciendo uso del Sitio Web o APP, aunque podrían quedar deshabilitadasalgunas de las funciones del mismo. <br /> Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es  recomendable revisar las actualizaciones que se realicen a los presentes Términos y Condiciones, con objetivo de  estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer  uso del Sitio Web o APP.',
    },
  ],
  listFifthSection: [
    {
      list: 'El Sitio Web o APP puede contener enlaces, contenidos, servicios o funciones, de otros sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos, comentarios, motores de búsqueda, etc. <br /> La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar la experiencia del Usuario al hacer uso del Sitio Web o APP, sin que pueda considerarse una sugerencia, recomendación o invitación para hacer uso de sitios externos. <strong>OKBOY</strong> en ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no hace propios los productos, servicios, contenidos, y cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a través del Sitio Web o APP. Así mismo, <strong>OKBOY</strong> no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos, productos o servicios disponibles en los sitios web no gestionados por <strong>OKBOY</strong> a los que se pueda acceder mediante el Sitio Web o APP.',
    },
    {
      list: 'Los Usuarios o terceros que realicen o publiquen un enlace web desde una página web externa, a este Sitio Web o APP deberán tomar en cuenta que no se permite la reproducción (total o parcial) de los contenidos, productos o servicios disponibles en el Sitio Web o APP sin la autorización expresa de <strong>OKBOY</strong> o su Titular. Tampoco se permitirán manifestaciones falsas, inexactas o incorrectas sobre el SITIO WEB O APP, ni sobre sus contenidos, productos o servicios, pudiendo <strong>OKBOY</strong> restringir el acceso al Sitio Web o APP a toda aquella persona que incurra en este tipo de actos.',
    },
    {
      list: 'El establecimiento de un enlace al Sitio Web o APP desde cualquier sitio externo, no implicará la existencia de alguna relación entre <strong>OKBOY</strong> y el Titular del Sitio Web o APP desde el cual se realice, tampoco implicará el conocimiento de <strong>OKBOY</strong> de los contenidos, productos o servicios ofrecidos en los sitios externos desde los cuales se pueda acceder al Sitio Web o APP.',
    },
  ],
  listSixthSection: [
    {
      list: '<strong>OKBOY</strong> por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web o APP, luego todo el diseño de este sitio, el contenido, incluyendo sus gráficos, fotografías, textos, información y el programa de cómputo asociado, son derechos reservados y exclusivos de <strong>OKBOY</strong>, y Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, derechos de autor así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del Sitio Web o APP, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización de <strong>OKBOY</strong>.',
    },
    {
      list: 'El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial y derechos de autor de <strong>OKBOY</strong>. No obstante, además de poder visualizar los elementos del Sitio Web o APP podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.',
    },
    {
      list: `El Usuario se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido, del Sitio Web o APP, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos. <br /> En caso de que el Usuario o algún tercero consideren que cualquiera de los contenidos del Sitio Web o APP suponga una violación de los derechos de protección de la propiedad industrial o intelectual y derechos de autor, deberá comunicarlo inmediatamente a <strong>OKBOY</strong> a través de los datos de contacto disponibles en el propio SITIO WEB O APP y/o a través de <a href=${urls.mailSupport} target=${typesTarget.TYPE_TARGET_BLANK}>soporte@okboy.app</a>`,
    },
    {
      list: 'El Usuario no adquiere ningún derecho de propiedad intelectual por el simple uso de los productos, Servicios y Contenidos de la aplicación móvil y la Página web y en ningún momento dicho uso será considerado como una autorización o licencia para utilizar los Servicios y Contenidos con fines distintos a los que se contemplan en los presentes Términos y Condiciones',
    },
  ],
  listSeventhSection: [
    {
      list: '<strong>OKBOY</strong> se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web o APP, sus contenidos, productos o servicios, o por el incumplimiento de los presentes Términos y Condiciones.',
    },
    {
      list: 'La relación entre el Usuario y <strong>OKBOY</strong> se regirá por la legislación vigente en México, específicamente en la Ciudad de México renunciando expresamente a cualquier otro fuero o jurisdicción estatal que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa, De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes Términos y Condiciones, las partes se someterán a la jurisdicción Federal de los tribunales que correspondan conforme a derecho en el estado al que se hace referencia.',
    },
  ],
  paragraphEighthSection: 'Al momento de ingresar al Sitio Web o APP usted acepta en su totalidad los Términos y Condiciones del uso de página y de servicio. En caso de no estar conforme no deberá continuar con el uso del Sitio Web o APP o los servicios que se ofrecen, por lo que deberá abandonar el Sitio Web o APP.',
  paragraphNinethSection: [
    {
      listTitle: '<strong>Limitación.</strong>',
      paragraph: 'OKBOY no será responsable de daños indirectos, incidentales, especiales, ejemplares, punitivos o emergentes, incluidos el lucro cesante, la pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en relación con, o de otro modo derivados de cualquier uso de los servicios, incluso aunque ok boy haya sido advertido de la posibilidad de dichos daños. no será responsable de cualquier daño, responsabilidad o pérdida que deriven de: (i) su uso o dependencia de los servicios o su incapacidad para acceder o utilizar los servicios; o (ii) cualquier transacción o relación entre usted y cualquier tercero proveedor, aunque OK BOY hubiera sido advertido de la posibilidad de dichos daños; Las advertencias y consejos obtenidos a través de la aplicación móvil y pagina web son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones personales ni profesionales. Para ello se debe consultar a un profesional apropiado que pueda asesorar al Usuario de acuerdo con sus necesidades específicas; OK BOY no será responsable del retraso o de la falta de ejecución resultante de causas que vayan más allá del control razonable. <br /> Las limitaciones y la renuncia en este apartado no pretenden limitar la responsabilidad o alterar sus derechos como consumidor que no puedan excluirse por la ley aplicable.',
    },
    {
      listTitle: '<strong>Renuncia.</strong>',
      paragraph: 'Los servicios se proporcionan “tal cual” y “como estén disponibles”. OKBOY renuncia a toda declaración y garantía, expresa, implícita o estatutaria, no expresamente establecida en estas condiciones, incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no violación. OKBOY renuncia a toda garantía expresa o tácita que conceda el tercero que proporcione el producto y/o servicio; OK BOY no hace declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios o cualquiera de los servicios o bienes solicitados a través del uso de los servicios, o que los servicios no serán interrumpidos o estarán libres de errores. OK BOY no garantiza la calidad, idoneidad, seguridad o habilidad de los terceros proveedores. usted acuerda que todo riesgo derivado de su uso de los servicios y cualquier servicio o bien solicitado en relación con aquellos será únicamente suyo, en la máxima medida permitida por la ley aplicable.',
    },
  ],
  paragraphTenthSection: {
    firstParagrph: [
      {
        paragraph: 'En OKBOY estamos obsesionados con ofrecer la mejor experiencia de consumo de servicios para el hogar. Es por esto, que dedicamos mucho tiempo a seleccionar y actualizar constantemente a nuestros proveedores. Y aunque no somos perfectos, lo intentamos 😀',
      },
      {
        paragraph: 'Tenemos una política de cancelación y devolución que comunicamos en nuestros Términos y Condiciones, todos nuestros clientes y proveedores deben aceptarlas antes de hacer su solicitud de servicios a través de OKBOY.',
      },
      {
        paragraph: 'Nuestras políticas consideran los siguientes casos:',
        lists: [
          {
            list: 'En caso de no confirmar tu pedido hasta 2 horas después de haber lo realizado con el protocolo antifraude, tu pedido será cancelado con un cargo del 3.5% + 3 pesos sobre el monto total. El cual es nuestro costo del procesador de pagos.',
          },
          {
            list: 'En caso de solicitar una devolución de una orden, una vez que el proveedor asistió al domicilio y no fue atendido o recibido, tendrás un cargo de $100 pesos por costo de cancelación de pedido no atendido',
          },
          {
            list: 'En caso de cancelar una orden, cuando el proveedor ya te confirmó que va en camino, tendrás un cargo de $70 pesos por costo de cancelación de pedido en tránsito.',
          },
          {
            list: 'En caso de cancelar una orden, dentro del horario de entrega calendarizado, tendrás un cargo de $20 pesos por costo de cancelación de pedido confirmado.',
          },
          {
            list: 'En caso de cancelar una orden, fuera del horario de entrega calendarizado, con al menos 1 min de retraso no existirá ningún costo de cancelación de pedido tardío.',
          },
        ],
      },
      {
        paragraph: 'Todas nuestras devoluciones se reflejarán en tu banco entre 48 horas y hasta 7 días a partir de ser solicitadas. Para que una devolución sea válida debe ser solicitada el mismo día que solicitaste tu pedido. Usualmente respondemos en menos de 24 horas.',
      },
      {
        paragraph: 'Esperamos no nos pase pero en caso que tengas una <strong>queja de tu servicio o proveedor</strong>. Queremos ayudarte y resolver cualquier controversia de manera fácil y rápida.',
        lists: [
          {
            list: 'Compártenos tu Folio de pedido',
          },
          {
            list: 'Tu queja (en caso de ser gas L.P. no olvides compartirnos cuánto cargaste y cuánto te cargaron).',
          },
        ],
      },
      {
        paragraph: 'Es importante recordar que nosotros no somos los únicos responsables de ofrecerte la mejor experiencia y dependemos de la calidad del servicio de nuestros proveedores.',
        lists: [
          {
            list: 'En caso de presentar una queja, en la cual el proveedor no subió la evidencia del servicio, en menos de 24hrs te agregaremos el valor en dinero digital proporcional al valor de tu queja en tu cuenta OKBOY. Dicho valor, no puede ser utilizado en 48 hrs, que es el tiempo de respuesta para que un proveedor resuelva cualquier queja.',
          },
          {
            list: 'En caso de presentar una queja, en la cual el proveedor subió una evidencia y no concuerda, nosotros solicitaremos al proveedor en menos de 48 horas, asistir al domicilio para completar el servicio de forma óptima.',
          },
          {
            list: 'En caso de presentar una queja, en la cual no hay evidencia de tu parte o hay sospecha de alguna fuga o mal interpretación deberemos enviar al proveedor para levantar un caso de queja directamente, dicho proceso suele tomar de 24 horas a 5 días.',
          },
        ],
      },
      {
        paragraph: 'Queremos ofrecerte la mejor experiencia así que siempre puedes comunicarnos cualquier queja o sugerencia a <strong>ayuda@okboy.app<strong>. <br /><br /> <strong>Actualizaciones:<strong> <br /><br /> <strong> El presente documento fue actualizado el 28 de Mayo de 2021. <br/> El presente documento fue actualizado el 13 de Octubre de 2021. <strong>',
      },
    ],
  },
};

export { captions, sectionsTermsConditions };
