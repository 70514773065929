import {
  ContainerSkeletonCardService,
  ContainerSkeletonContent,
  ContentImageSkelton,
  SkeletonCardHeaderDescription,
  SkeletonCardHeaderPrice,
  SkeletonCardHeaderTitle,
  SkeletonImage,
} from './styles';

const CardServiceOrBenefits = ({ withTextPrice }) => {
  return (
    <>
      <ContainerSkeletonCardService>
        <ContentImageSkelton>
          <SkeletonImage />
        </ContentImageSkelton>
        <ContainerSkeletonContent>
          <SkeletonCardHeaderTitle />
          {withTextPrice && <SkeletonCardHeaderPrice /> }
          <SkeletonCardHeaderDescription />
        </ContainerSkeletonContent>
      </ContainerSkeletonCardService>
    </>
  );
};

CardServiceOrBenefits.defaultProps = {
  withTextPrice: true,
};

export default CardServiceOrBenefits;
