import { texts } from '../../Common/messages';
import {
  AdditionButton,
  CounterButtons,
  counterButtonStyles,
  CounterNumber,
  SubtractionButton,
} from './styles';

const {
  addition,
  subtraction,
} = texts;

const CounterButton = ({
  setCount,
  count,
}) => {
  const productOne = 1;
  const handleCount = (e, countType) => {
    if (countType === subtraction) {
      return setCount(prevCount => (
        prevCount === productOne ? productOne : (prevCount - productOne)
      ));
    }
    if (countType === addition) {
      return setCount(prevCount => prevCount + productOne);
    }
    return null;
  };
  return (
    <CounterButtons>
      <SubtractionButton
        sx={counterButtonStyles}
        onClick={(e) => handleCount(e, subtraction)}
        disabled={count === productOne}
      >
        { subtraction }
      </SubtractionButton>
      <CounterNumber>{ count }</CounterNumber>
      <AdditionButton
        sx={counterButtonStyles}
        onClick={(e) => handleCount(e, addition)}
      >
        { addition }
      </AdditionButton>
    </CounterButtons>
  );
};

export default CounterButton;
