import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breakPoints } from '../../Common';
import './styles.css';
import { DataServiceGas } from '../../api';
import { useBreakpoint } from '../../hooks';
import LayoutModalMobile from './LayoutModalMobile';
import LayoutModalDesktop from './LayoutModalDesktop';

const LayoutModalServiceOrder = () => {
  const [dataSectionSelectionService, setDataSectionSelectionService] = useState({
    title: '',
    buttonText: '',
    cardsServices: [],
    steps: null,
    loading: true,
  });

  const navigate = useNavigate();
  const { getDataServiceGas } = DataServiceGas;
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);

  useEffect(() => {
    getDataServiceGas().then(dataServiceresponse => {
      const { sectionSelectionService } = dataServiceresponse;
      setDataSectionSelectionService(prevDataSectionSelectionService => ({
        ...prevDataSectionSelectionService,
        title: sectionSelectionService.title,
        buttonText: sectionSelectionService.textButton,
        cardsServices: sectionSelectionService.cardsServices,
        steps: sectionSelectionService.steps,
      }));
    })
      .catch(() => navigate('/*'))
      .finally(() => {
        setDataSectionSelectionService(prevDataSectionSelectionService => ({
          ...prevDataSectionSelectionService,
          loading: false,
        }));
      });
  }, []);

  const {
    title,
    buttonText,
    loading,
    cardsServices,
    steps,
  } = dataSectionSelectionService;
  return (
    toggleBreakpoint ? (
      <LayoutModalMobile
        buttonText={buttonText}
        cardsServices={cardsServices}
        loading={loading}
        steps={steps}
        title={title}
      />
    ) : (
      <LayoutModalDesktop
        buttonText={buttonText}
        cardsServices={cardsServices}
        loading={loading}
        steps={steps}
        title={title}
      />
    )
  );
};

export default LayoutModalServiceOrder;
