export const contentPersonalizedHelp = (backgroundImageDesktop, backgroundImageMobile) => {
  return {
    width: '100%',
    height: {
      xs: '453px',
      phoneMd: '453px',
      sm: '453px',
      md: '351px',
      lg: '351px',
      xl: '351px',
    },
    marginTop: {
      xs: '0',
      phoneMd: '0',
      sm: '0',
      md: '6.5rem',
      lg: '6.5rem',
      xl: '6.5rem',
    },
    backgroundImage: {
      xs: `url(${backgroundImageMobile})`,
      phoneMd: `url(${backgroundImageMobile})`,
      sm: `url(${backgroundImageMobile})`,
      md: `url(${backgroundImageDesktop})`,
      lg: `url(${backgroundImageDesktop})`,
      xl: `url(${backgroundImageDesktop})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& > div': {
      position: 'relative',
      width: {
        xs: '100%',
        phoneMd: '100%',
        sm: '100%',
        md: '67.4%',
        lg: '67.4%',
        xl: '67.4%',
      },
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > div:first-of-type': {
        height: '60%',
        width: {
          xs: '83%',
          phoneMd: '83%',
          sm: '83%',
          md: '76.2%',
          lg: '76.2%',
          xl: '76.2%',
        },
        marginLeft: {
          xs: '0px',
          phoneMd: '0px',
          sm: '0px',
          md: '-29px',
          lg: '-29px',
          xl: '-29px',
        },
        color: 'primary.main',
        fontWeight: '900',
        '& > h1': {
          position: 'relative',
          fontSize: {
            xs: '2rem',
            sm: '2rem',
            phoneMd: '2rem',
            md: '2.4625rem',
            lg: '2.4625rem',
            xl: '2.4625rem',
          },
          color: 'inherit',
          fontWeight: 'inherit',
          marginBottom: '18px',
          marginTop: {
            xs: '44px',
            phoneMd: '44px',
            sm: '44px',
            md: '0px',
            lg: '0px',
            xl: '0px',
          },
          zIndex: '20',
        },
        '& > h2': {
          position: 'relative',
          fontSize: {
            xs: '1.25rem',
            sm: '1.25rem',
            phoneMd: '1.25rem',
            md: '1.375rem',
            lg: '1.375rem',
            xl: '1.375rem',
          },
          width: {
            xs: '100%',
            phoneMd: '100%',
            sm: '100%',
            md: '62.5%',
            lg: '62.5%',
            xl: '62.5%',
          },
          fontWeight: 'inherit',
          color: 'inherit',
          zIndex: '20',
        },
        '& > a': {
          position: 'relative',
          width: {
            xs: '100%',
            phoneMd: '100%',
            sm: '100%',
            md: '29.4%',
            lg: '29.4%',
            xl: '29.4%',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          marginTop: {
            xs: '43px',
            phoneMd: '43px',
            sm: '43px',
            md: '40px',
            lg: '40px',
            xl: '40px',
          },
          fontSize: '1.375rem',
          textDecoration: 'none',
          backgroundColor: 'primary.main',
          borderRadius: '15px',
          padding: '8px 20px 8px 20px',
          fontWeight: '900',
          color: 'secondary.main',
          textAlign: 'center',
          zIndex: '20',
        },
      },
    },
  };
};
export const titleDisplayStyles = (phone, desktop) => {
  return {
    display: {
      xs: phone,
      phoneMd: phone,
      sm: phone,
      md: desktop,
      lg: desktop,
      xl: desktop,
    },
  };
};
