import {
  Box, Stack, Typography,
} from '@mui/material';
import {
  Captions,
  ButtonGeneral,
  DownloadApp,
  Subscribe,
} from '../../Components';
import {
  boxCaptions,
  contentButtons,
  contentCaptions,
  contentHelpSections,
} from './HelpSectionLayoutStyles';
import {
  downloads,
  listLogosMenu,
  pathViews,
  textHelpSection,
} from '../../Common/messages';
import WhatsAppLogo from '../../../assets/img/WhatsAppLogo.png';
import {
  TYPES_BANNER,
  TYPES_COMPONENTS,
  TYPES_DIRECTIONS,
  TYPES_LOGO,
} from '../../Common';
import LogoRRSS from '../../Components/LogoRRSS/LogoRRSS';

const HelpSectionLayout = ({
  titles,
  textsUrls,
  url,
  typeBanner,
  stylesContentBanner,
  stylesBannerCaption,
  stylesBannerImage,
  stylesIconRRSS,
  styleDownload,
  imageBanner,
  nameImage,
  variantTitle,
  variantSubTitle,
  textLabel,
  textButton,
  imageIOS,
  imageAndroid,
}) => {
  const { variantsTitle, colorTitle } = textHelpSection;
  return (
    <Box sx={{ ...contentHelpSections, ...stylesContentBanner }}>
      {typeBanner === TYPES_BANNER.help ? (
        <>
          <Box sx={contentCaptions}>
            <Box sx={boxCaptions}>
              <Captions variant={variantsTitle?.main} color={colorTitle}>
                {titles?.titleMain}
              </Captions>
              <Captions variant={variantsTitle?.secondary} color={colorTitle}>
                {titles?.titleSecondary}
              </Captions>
            </Box>
          </Box>
          <Box sx={contentButtons}>
            <Box>
              <Box>
                <ButtonGeneral
                  icon={WhatsAppLogo}
                  textUrl={textsUrls?.whatsApp}
                  url={url}
                />
                {textsUrls && (
                  <ButtonGeneral
                    textUrl={textsUrls?.quetions}
                    path={pathViews.frecuentQuestions}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ ...contentCaptions, ...stylesBannerCaption }}>
            <Captions variant={variantTitle} color={colorTitle}>
              {titles?.titleMain}
            </Captions>
            {typeBanner !== TYPES_BANNER.rrss && (
              <Typography variant={variantSubTitle} color={colorTitle}>
                {titles?.titleSecondary}
              </Typography>
            )}
            {typeBanner === TYPES_BANNER.download && (
              <Box>
                <DownloadApp
                  downloads={downloads}
                  appStore={imageIOS}
                  playStore={imageAndroid}
                  style={styleDownload}
                />
              </Box>
            )}
            {typeBanner === TYPES_BANNER.rrss && (
              <Stack
                direction={TYPES_DIRECTIONS.row}
                spacing={stylesIconRRSS.spacing}
                sx={stylesIconRRSS.style}
              >
                {listLogosMenu.map((logo) => (
                  <LogoRRSS
                    key={logo.name}
                    {...logo}
                    typeLogo={TYPES_LOGO.blogPost}
                    widthName={true}
                  />
                ))}
              </Stack>
            )}
            {typeBanner === TYPES_BANNER.subscribe && (
              <Subscribe
                textButton={textButton}
                textLabel={textLabel}
              />
            )}
          </Box>
          {typeBanner !== TYPES_BANNER.rrss && (
            <Box sx={stylesBannerImage}>
              <Box
                component={TYPES_COMPONENTS.img}
                src={imageBanner}
                alt={nameImage}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default HelpSectionLayout;

HelpSectionLayout.defaultProps = {
  typeBanner: 'help',
};
