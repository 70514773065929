export const contentMenu = {
  display: {
    xs: 'flex', sm: 'flex', md: 'none', lg: 'none',
  },
  minWidth: '46px',
  width: '46px',
  height: '46px',
  borderRadius: '10px',
  padding: '0px',
  flexDirection: {
    xs: 'column', sm: 'column',
  },
  gap: { xs: '4px', sm: '4px' },
  justifyContent: { xs: 'center', sm: 'center' },
  '& span': {
    display: 'block',
    backgroundColor: 'secondary.main',
    width: '28px',
    height: '3px',
    borderRadius: '10px',
    transition: 'transform .6s ease',
  },
  '& span:nth-of-type(2)': {
    width: '24px',
    margin: '0 auto',
  },
  '& span:nth-of-type(4)': {
    display: 'none',
  },
};

export const contentMenuBlog = {
  marginRight: '1.2rem',
};
