import {
  Box, Grid, styled,
} from '@mui/material';

export const ContainerBannerCoupon = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '281px',
  margin: 'initial',
  backgroundColor: theme.palette.secondary.bannerDownload,
  marginLeft: '0rem',
  marginTop: '0rem',
  marginBottom: '4rem',
  borderRadius: '30px',
  [theme.breakpoints.down('sm')]: {
    width: '343px',
    height: 'min-content',
    margin: '0 auto',
    marginLeft: 'auto',
    marginBottom: '4rem',
  },
}));

export const directionContainerBannerCoupon = {
  xs: 'column',
  md: 'row',
};

export const TextAndImagesDownLoad = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'minmax(0, 1fr)',
  paddingTop: '3.25rem !important',
  paddingBottom: '2.5rem !important',
  paddingLeft: '5rem !important',
  order: 1,
  '& > div:last-of-type': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(50%, .35fr))',
    gridTemplateRows: 'repeat(1, minmax(0, 1fr))',
    width: 'min-content',
    gridColumnGap: '8px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0rem !important',
    paddingBottom: '2.5rem !important',
    paddingLeft: '0rem !important',
    width: '100%',
    order: 2,
    '& > div:last-of-type': {
      width: '89%',
      margin: '0 auto',
      gridColumnGap: '0px',
    },
  },
}));

export const Title = styled(Box)(({ theme }) => ({
  width: '434px',
  '& > p, & > h2': {
    color: theme.palette.text.secondary,
    fontWeight: '800',
    fontSize: '2.5rem',
    lineHeight: '2.8125rem',
  },
  '& > p > strong, & > h2 > strong': {
    display: 'inline',
    color: theme.palette.primary.main,
    fontWeight: '900',
    lineHeight: '2.81rem',
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '89%',
    margin: '1rem auto',
    '& > p': {
      color: theme.palette.text.secondary,
      fontWeight: '800',
      fontSize: '1.8rem',
      lineHeight: '1.813rem',
    },
    '& > p > strong': {
      display: 'inline',
      color: theme.palette.primary.main,
      fontWeight: '900',
      lineHeight: '1.813rem',
      fontSize: '1.8rem',
    },
  },
}));

export const NameCouponAndImageCoupon = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '10fr 1fr',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  paddingTop: '2.0625rem !important',
  paddingBottom: '0rem !important',
  paddingRight: '1.625rem !important',
  paddingLeft: '0rem !important',
  order: 2,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    backgroundPosition: '-147px 100px',
    backgroundSize: '132%',
    paddingRight: '0rem !important',
    paddingTop: '0rem !important',
    order: 1,
    '& > div:first-of-type': {
      width: '91%',
      justifySelf: 'center',
    },
  },
}));

export const TextNameCoupon = styled(Box)(({ theme }) => ({
  fontWeight: '800',
  fontSize: '2.5rem',
  lineHeight: '2.81rem',
  width: 'min-content',
  marginTop: '1.625rem',
  color: theme.palette.text.secondary,
  '& > p > strong': {
    display: 'inline-block',
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: '900',
    lineHeight: '2.8125rem',
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '0rem',
    width: '100%',
    paddingTop: '2rem',
    '& > p > strong': {
      display: 'inline',
    },
  },
}));

export const backgroundCoupon = (image) => {
  return {
    backgroundImage: `url(${image})`,
  };
};

export const TextFooterCoupon = styled(Box)(({ theme }) => ({
  '& > p': {
    width: '100%',
    fontWeight: '500',
    fontSize: '0.75rem',
    lineHeight: '1.02rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '89%',
    margin: '0 auto',
  },
}));

export const styleAppStore = {
  width: '10.375rem',
  marginRight: '16px',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: '10.375rem',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};
