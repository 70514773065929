import ProductImage from '../../../assets/img/ServiceProduct/dummy-product.jpg';
import PasswordIcon from '../../../assets/img/ServiceProduct/icons/PasswordIcon';
import SecurityIcon from '../../../assets/img/ServiceProduct/icons/SecurityIcon';
import StatisticsIcon from '../../../assets/img/ServiceProduct/icons/StatisticsIcon';
import BannerImage from '../../../assets/img/ServiceProduct/BannerImage.png';

const title = 'Lavandería por pieza';
const price = '$7.00 c/u';

const locationItems = [
  {
    id: 1,
    text: 'Guadalajara',
  },
  {
    id: 2,
    text: 'San Luis Potosí',
  },
  {
    id: 3,
    text: 'Colima',
  },
  {
    id: 4,
    text: 'Leon',
  },
  {
    id: 5,
    text: 'Toluca',
  },
  {
    id: 6,
    text: 'CDMX',
  },
];

const imageAndDescription = {
  image: ProductImage,
  nameProduct: 'Lavandería',
  description: 'Con Okboy puedes tener tus prendas lisas e impecables sin mucho esfuerzo. Nosotros te ayudamos con esa difícil tarea de planchar. Ponemos a tu disposición uno de nuestros expertos que irá a tu casa y durante el tiempo que solicites planchará tus camisas, blusas, vestidos y linos, dejándolas sin arrugas y listas para usar. Si necesitas planchar prendas de materiales especiales como seda, paño, impermeables y lino, escoge nuestro servicio plus, donde adicionalmente, llevamos a tu casa una máquina de vapor vertical, para realizar esta labor.',
};

const moobileTitles = [
  {
    id: 1,
    text: 'Que incluye',
  },
  {
    id: 2,
    text: 'Que no incluye',
  },
];

const listIncludeService = [
  {
    id: 1,
    text: 'La duración del servicio es de 4 horas',
  },
  {
    id: 2,
    text: 'Planchado y doblado de prendas según tiempo contratado.',
  },
  {
    id: 3,
    text: 'En una hora nuestro personal alcanzará a planchar 9 prendas aprox.',
  },
];

const listNotIncludeService = [
  {
    id: 1,
    text: 'La duración del servicio es de 40 horas',
  },
  {
    id: 2,
    text: 'Planchado y doblado de prendas según tiempo contratado.',
  },
  {
    id: 3,
    text: 'En una hora nuestro personal alcanzará a planchar 9 prendas aprox.',
  },
];

const itemsCards = {
  title: 'Cómo funciona Okboy',
  cards: [
    {
      id: 1,
      numberText: '1',
      contentText: 'Selecciona el servicio que necesitas.',
    },
    {
      id: 2,
      numberText: '2',
      contentText: 'Elige entre los cientos de proveedores que tenemos y elige la mejor opción para ti.',
    },
    {
      id: 3,
      numberText: '3',
      contentText: 'Sigue el trayecto de tu servicio',
    },
    {
      id: 4,
      numberText: '4',
      contentText: 'Disfruta de tu servicio con garantías exclusivas',
    },
  ],
};

const dataProductRecommendationsSection = {
  title: 'Algunas recomendaciones',
  beforeSectionList: {
    title: 'Antes',
    list: [
      {
        id: 1,
        text: 'No es necesario desocupar espacios',
      },
      {
        id: 2,
        text: 'No debes cubrir ninguna superficie o elemento',
      },
    ],
  },
  duringSectionList: {
    title: 'Durante',
    list: [
      {
        id: 1,
        text: 'Recomendamos que solo un integrante del hogar acompañe el proceso. Llevaremos tapabocas para su protección',
      },
      {
        id: 2,
        text: 'No genera olores ni alergias. Tampoco genera efectos secundarios en bebés, ancianos o mascotas',
      },
      {
        id: 3,
        text: 'Podrán activarse los detectores de humo',
      },
    ],
  },
  afterSectionList: {
    title: 'Después',
    list: [
      {
        id: 1,
        text: 'No debes estar en tu hogar durante la siguiente hora a la desinfección.',
      },
      {
        id: 2,
        text: 'No es necesario secar, limpiar o barrer después de la desinfección.',
      },
      {
        id: 3,
        text: '*Nuestro personal es especialista en desinfección y va protegido de pies a cuello con un traje especial de bioseguridad y una máscara media cara con filtro de vapores, además realiza protocolo de desinfección previo al ingreso a tu hogar.',
      },
    ],
  },
  footer: '**Entre más seguido hagas tus desinfecciones, tendrás menor riesgo de tener virus, bacterias y gérmenes en tus espacios.',
};

const warrantySectionData = {
  title: 'Nuestras garantías',
  list: [
    {
      id: 1,
      text: 'Seguridad en tu compra',
      icon: <PasswordIcon />,
    },
    {
      id: 2,
      text: 'Seguridad en tu compra',
      icon: <StatisticsIcon />,
    },
    {
      id: 3,
      text: 'Seguridad en tu compra',
      icon: <SecurityIcon />,
    },
    {
      id: 4,
      text: 'Seguridad en tu compra',
      icon: <PasswordIcon />,
    },
    {
      id: 5,
      text: 'Seguridad en tu compra',
      icon: <StatisticsIcon />,
    },
    {
      id: 6,
      text: 'Seguridad en tu compra',
      icon: <SecurityIcon />,
    },
  ],
};

const BillingSectionData = {
  title: 'Cómo facturar',
  text: 'Aplica exclusivamente para los servicios de Mudanza, Supe paquetes de Aseo, Maestro de Obra, Combo hogar limpio y Combo cama + sofá + alfombra. Aplica exclusivamente para los servicios de Mudanza, Supe paquetes de Aseo, Maestro de Obra, Combo hogar limpio y Combo cama + sofá + alfombra.',
  linkText: 'Ver más información',
  link: '#',
};

const AppBannerData = {
  image: {
    src: BannerImage,
    alt: 'BannerImage',
  },
  subTitle: 'Además tenemos una App',
  title: 'Ahorra tiempo cuidando tu hogar',
  textContent: 'Revisa el estatus de tu orden en tiempo real, home management, historial',
};

const buttonConfigData = {
  title: 'Pide tu servicio con un clic',
  buttonText: 'Crear orden',
};

const orderConfigurationData = {
  title: 'Configura tu servicio',
  locationButton: {
    label: 'Agrega tu ubicación',
    text: 'Ubicación',
  },
  providerButton: {
    label: '¿Con quién deseas realizar tu pedido?',
    text: 'Selecciona un proveedor',
    modalTitle: 'Selecciona un proveedor',
  },
  product: {
    label: 'Selecciona el tipo de producto',
    text: 'Tipo de producto',
    modalTitle: 'Selecciona el tipo de producto',
  },
  productLength: {
    label: '¿Cuántos necesitas?',
    number: 1,
    date: 'Fecha de entrega',
    modalTitle: 'Selecciona la fecha y horario',
  },
  prices: {
    subTotal: {
      label: 'Subtotal',
      price: '$895.00 MXN',
    },
    serviceFee: {
      label: 'Cuota de servicio',
      price: '$12.00 MXN',
    },
    dynamicRate: {
      label: 'Tarifa dinámica',
      price: '$22.00 MXN',
    },
    iva: {
      label: 'IVA',
      price: '$6.00 MXN',
    },
    total: {
      label: 'Total',
      price: '$6.00 MXN',
    },
  },
  continueButton: 'Continuar',
};

const providerData = [
  {
    id: 1,
    itemName: 'Gas rosaaaa',
  },
  {
    id: 2,
    itemName: 'Gas rosaaa',
  },
  {
    id: 3,
    itemName: 'Gas rosaaaaaaa',
  },
  {
    id: 4,
    itemName: 'Gas ro$$aaaaaaa',
  },
  {
    id: 5,
    itemName: 'Rosa Gas***',
  },
];

const productType = [
  {
    id: 1,
    name: 'Playera',
  },
  {
    id: 2,
    name: 'Pantalón',
  },
  {
    id: 3,
    name: 'Tenis',
  },
  {
    id: 4,
    name: 'Overol',
  },
  {
    id: 5,
    name: 'Camisa',
  },
];

const dateData = [
  {
    id: 1,
    name: '10:00 - 12:00',
  },
  {
    id: 2,
    name: '12:00 - 14:00',
  },
  {
    id: 3,
    name: '14:00 - 16:00',
  },
  {
    id: 4,
    name: '16:00 - 18:00',
  },
  {
    id: 5,
    name: '18:00 - 20:00',
  },
];

const modalTextButton = 'Guardar';

const ACTION_TYPES = {
  selectProduct: 'SELECT_PRODUCT',
  selectProvider: 'SELECT_PROVIDER',
  selectDate: 'SELECT_DATE',
  selectSchedule: 'SELECT_SCHEDULE',
  selectLocation: 'SELECT_LOCATION',
  productDetail: 'PRODUCT_DETAIL',
  getCompanies: 'GET_COMPANIES',
  setCompanieId: 'SET_COMPANIE_ID',
  getCompanieProducts: 'GET_COMPANIE_PRODUCTS',
  getCompanieSchedules: 'GET_COMPANIE_SCHEDULES',
  getShowCaseProduct: 'GET_SHOWCASE_PRODUCT',
  setShowcaseProductId: 'SET_SHOWCASE_PRODUCT',
};

const modalsNames = {
  productModal: 'productModal',
  providerModal: 'providerModal',
  dateModal: 'dateModal',
};

const radioNameValues = {
  provider: 'provider',
  product: 'product',
  schedule: 'schedule',
};

const messageScheduleEmpty = 'Lo siento no tenemos horarios disponibles';

const withoutScheduleMessage = 'Lo sentimos 😕, no tenemos horarios disponibles para esta fecha.';

const localStorageCounter = 'count';

export {
  title,
  price,
  locationItems,
  imageAndDescription,
  listIncludeService,
  listNotIncludeService,
  moobileTitles,
  itemsCards,
  dataProductRecommendationsSection,
  warrantySectionData,
  BillingSectionData,
  AppBannerData,
  buttonConfigData,
  orderConfigurationData,
  providerData,
  productType,
  modalTextButton,
  ACTION_TYPES,
  modalsNames,
  radioNameValues,
  dateData,
  messageScheduleEmpty,
  withoutScheduleMessage,
  localStorageCounter,
};
