import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Date } from '../ContentSelectors/styles';

const DateStatic = ({
  handleDate,
  value,
  stylesCustom,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en'>
      <Date
        displayStaticWrapperAs='desktop'
        value={value}
        onChange={(newValue) => handleDate(newValue)}
        renderInput={() => (
          <></>
        )}
        sx={stylesCustom}
      />
    </LocalizationProvider>
  );
};

export default DateStatic;
