import { ServicePreOrderContext, ServiceSearchProvider } from '../../Contexts';
import {
  LayoutGeneral,
  PreOrderLayout,
} from '../../Layouts';

import {
  containerProduct,
  preOrderContainer,
  serviceSearchContainer,
} from './styles';

const PreOrder = () => {
  return (
    <ServiceSearchProvider>
      <ServicePreOrderContext>
        <LayoutGeneral
          style={{ ...serviceSearchContainer, ...containerProduct, ...preOrderContainer }}
        >
          <PreOrderLayout />
        </LayoutGeneral>
      </ServicePreOrderContext>
    </ServiceSearchProvider>
  );
};

export default PreOrder;
