import axios from 'axios';

const { REACT_APP_BASE_API_URL_STRAPI: baseURLSTRAPI, REACT_APP_PORT: localhost } = process.env;
const axiosStrapi = axios.create({
  baseURL: baseURLSTRAPI || localhost,
});

export default axiosStrapi;

const { REACT_APP_BASE_API_URL: baseURL, REACT_APP_X_API_KEY: xApiKey } = process.env;

export const axiosService = axios.create({
  baseURL,
  headers: {
    'x-api-key': xApiKey,
  },
});
