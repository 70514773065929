export const titleStyle = {
  fontWeight: '900',
  marginBottom: '16px',
  variant: 'h5',
  opacity: '.6',
};

export const contentAppsStyles = {
  height: 'auto',
  display: 'flex',
};
