import {
  Button, Chip, styled, Typography,
} from '@mui/material';

export const ProductContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  width: '522px',
  position: 'relative',
  gridTemplateRows: '130px auto',
  gridTemplateColumns: 'max-content 1fr',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateRows: '91px auto',
  },
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  borderRadius: '15px',
  width: '119px',
  marginRight: '2rem',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    width: '86px',
    marginRight: '.8rem',
  },
}));

export const Image = styled('img')({
  width: '100%',
  height: ' 100%',
  borderRadius: '15px',
  objectFit: 'cover',
});

export const DetailServiceContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridColumn: '2 / 3',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'repeat(2, max-content)',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'initial',
    gridTemplateRows: 'initial',
    display: 'flex',
    flexDirection: 'column',
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
}));

export const PriceAndTitleContainer = styled('div')(({ theme }) => ({
  alignItems: 'baseline',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '173px',
    gap: '0.1875rem',
    marginBottom: '0.45rem',
  },
}));

export const ButtonIcon = styled(Button)(({ theme }) => ({
  display: 'none',
  position: 'relative',
  width: 'max-content',
  height: 'max-content',
  padding: '0',
  minWidth: 'initial',
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    right: '0px',
    top: '8px',
  },
}));

export const ServiceName = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: 'rgba(68, 51, 224, 0.1)',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  padding: '.5rem 1rem',
  height: 'max-content',
  width: 'max-content',
  marginRight: '4.0625rem',
  [theme.breakpoints.down('sm')]: {
    marginRight: '0rem',
    padding: '.4rem 1rem',
  },
}));

export const Price = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 'max-content',
  marginRight: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    marginRight: '0rem',
  },
}));

export const ProviderDetail = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '1.125rem',
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: ' repeat(3, max-content)',
    gridTemplateRows: 'max-content',
    marginTop: 'initial',
  },
}));

export const avatarStyle = {
  width: '30px',
  height: '30px',
  '& img': {
    objectFit: 'contain',
  },
  marginRight: {
    xs: '9px',
    md: '0.5625rem',
  },
};

export const NameAndRatingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
  width: '77px',
  marginRight: '1.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.down('sm')]: {
    marginRight: '1.5rem',
  },
}));

export const ProviderName = styled('span')(() => ({
  display: '-webkit-box',
  width: '100%',
  position: 'relative',
}));

export const ratingStyles = {
  fontSize: '.85rem',
  marginTop: '-1.7px',
  '&.MuiRating-root': {
    color: '#F5BF47',
  },
};

export const CalificationText = styled('span')(({ theme }) => ({
  display: '-webkit-box',
  color: theme.palette.text.select,
  width: 'max-content',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  height: 'max-content',
  [theme.breakpoints.down('sm')]: {
    width: '114px',
  },
}));

export const textProduct = {
  fontWeight: '500',
  fontSize: '0.75rem',
  lineHeight: '1.1875rem',
};

export const DetailProductContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridRow: '2 / 3',
  gridColumn: '2 / 3',
  marginTop: '-1.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gridColumn: '1 / 3',
    marginTop: 'initial',
  },
}));

export const ProductTitle = styled(Typography)({
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  color: '#202022',
  marginBottom: '1rem',
});

export const DataProductDetailContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  marginBottom: '.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '265px',
  },
}));

export const DetailProductText = styled('span')({
  color: 'inherit',
});

export const DetailProductPriceText = styled('span')(({ theme }) => ({
  color: 'inherit',
  paddingRight: '2rem',
  WebkitLineClamp: '1',
  width: '123px',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    display: '-webkit-box',
    width: '88px',
    paddingRight: '0rem',
  },
}));

export const ComplementContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.text.select,
  padding: '0rem 1rem',
  '& > span:last-of-type': {
    paddingRight: '0rem',
    marginRight: '0.9rem',
    width: '85px',
  },
  [theme.breakpoints.down('sm')]: {
    '& > span:last-of-type': {
      paddingright: '4.6rem',
      width: '119px',
      marginRight: '0.9rem',
    },
  },
}));

export const DateAndDeliveryContainer = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
}));

export const ContentDate = styled('span')(() => ({
  fontWeight: '500',
  fontSize: '0.75rem',
  lineHeight: '1.1875rem',
}));

export const dateStyleCustom = {
  marginTop: '1.5rem',
};

/* Checket */

export const CheckedContainer = styled('div')(() => ({
  display: 'flex',
  gap: '.5rem',
  alignItems: 'flex-start',
  marginTop: '2.5rem',
}));

export const checkedStyles = {
  width: '25px',
  height: '25px',
  padding: '0px',
};

export const CheckedLabel = styled('div')(({ theme }) => ({
  fontWeight: '900',
  color: theme.palette.text.select,
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  marginTop: '4px',
  '& > a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    lineHeight: '1.024375rem',
  },
}));
