const tooltipProps = {
  placement: {
    top: 'top',
    right: 'right',
  },
};

export {
  tooltipProps,
};
