import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TYPES_BUTTONS, variantTypography } from '../../Common';
import { urls } from '../../Common/messages';
import { styleButtonWhatsApp, TextButttonWatsApp } from './styles';
import { ButtonPrimary } from '../../Components';
import { DataServiceTrackingContent } from '../../api';

const LayoutButtonWhatsApp = () => {
  const [dataWhatsAppButton, setDataWhatsAppButton] = useState({
    text: '',
    image: {
      src: '',
      alternativeText: '',
    },
  });
  const navigate = useNavigate();
  const { getDataServiceTrackingContent } = DataServiceTrackingContent;
  useEffect(() => {
    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      setDataWhatsAppButton(prevDataWhatsAppButton => ({
        ...prevDataWhatsAppButton,
        text: dataResponseServiceTrackingContent.whatsAppButton.text,
        image: { ...dataResponseServiceTrackingContent.whatsAppButton.image },
      }));
    }).catch(() => navigate('/*'));
  }, []);
  return (
    <ButtonPrimary
      path={urls.whatsAppEmpresa}
      typeButton={TYPES_BUTTONS.link}
      styles={styleButtonWhatsApp}
      disabledRipple={true}
    >
      <TextButttonWatsApp variant={variantTypography.body1}>
        {dataWhatsAppButton.text}
      </TextButttonWatsApp>
    </ButtonPrimary>
  );
};

export default LayoutButtonWhatsApp;
