import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef } from 'react';
import { closeButton, iconModal, ModalLayoutContainer } from './styles';
import { typesColors } from '../../Common';

const LayoutTrackingModal = ({
  children, toggle, handleToggle, styleButton,
}) => {
  const buttonSize = 'large';
  const modalRef = useRef(null);
  useEffect(() => {
    if (toggle) {
      modalRef.current.style.zIndex = '10';
      modalRef.current.style.opacity = '1';
    } else {
      modalRef.current.style.opacity = '0';
      setTimeout(() => {
        modalRef.current.style.zIndex = '-10';
      }, 500);
    }
  }, [toggle]);
  return (
    // eslint-disable-next-line no-return-assign
    <ModalLayoutContainer ref={(el) => modalRef.current = el}>
      <IconButton
        onClick={handleToggle}
        size={buttonSize}
        sx={{ ...closeButton, ...styleButton }}
        aria-label='delete'
        color={typesColors.primary}
      >
        <CloseIcon sx={iconModal} />
      </IconButton>
      { children }
    </ModalLayoutContainer>
  );
};

export default LayoutTrackingModal;
