import { useEffect, useState } from 'react';
import { DataLading } from '../../api';
import { AppBanner } from '../../Components';
import { BannerSkeleton } from './styles';

const {
  getSaveTime,
} = DataLading;

const BannerLayout = () => {
  const [appBannerData, setApBannerData] = useState({
    image: {
      src: '',
      alt: '',
    },
    badge: '',
    title: '',
    textContent: '',
    imageDownloadApp: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
    loading: false,
  });

  useEffect(() => {
    const bannerSectionData = {
      image: {
        src: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Iphone_Image_6e1e505be2.png',
        alt: 'IphoneImage.png',
      },
      badge: 'Además tenemos una App',
      title: 'Ahorra tiempo cuidando tu hogar',
      textContent: 'Revisa el estatus de tu orden en tiempo real, home management, historial',
    };
    getSaveTime().then(response => {
      const imageDownloadAppData = response;
      setApBannerData(prevApBannerData => ({
        ...prevApBannerData,
        imageDownloadApp: { ...imageDownloadAppData.imageDownloadApp },
        image: {
          ...bannerSectionData.image,
        },
        badge: bannerSectionData.badge,
        title: bannerSectionData.title,
        textContent: bannerSectionData.textContent,
      }));
    });
  }, []);

  const {
    loading,
  } = appBannerData;

  return (
    <>
      {
        loading ? (
          <BannerSkeleton />
        ) : (
          <AppBanner
            imageDownloadApp={appBannerData.imageDownloadApp}
            image={appBannerData.image}
            badge={appBannerData.badge}
            title={appBannerData.title}
            textContent={appBannerData.textContent}
          />
        )
      }
    </>
  );
};

export default BannerLayout;
