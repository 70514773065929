import { urls } from './paths';

const textPersonalizedHelp = {
  titles: {
    titleMainDesktop: '¿Necesitas ayuda?',
    titleMainMobile: '¿Necesitas ayuda?',
    titleSecondaryDesktop: 'Escríbenos por WhatsApp y un asesor te ayudara.',
    titleSecondaryMobile: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
  },
  variantsTitle: {
    main: 'h1',
    secondary: 'h2',
  },
  colorTitle: 'secondary.main',
  textUrl: 'Contactar',
  url: urls.whatsApp,
};

const textSectionPersonalizedHelp = {
  titles: {
    titleMain: '¿Necesitas ayuda?',
    titleSecondary: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
  },
  variantsTitle: {
    main: 'h4',
    secondary: 'h5',
  },
  colorTitle: 'secondary.main',
  textsUrls: { watsApp: 'Escríbenos' },
  url: urls.whatsApp,
};

export { textPersonalizedHelp, textSectionPersonalizedHelp };
