import { Box, Button } from '@mui/material';
import { contentPointCarouselStyle, pointCarouselStyle } from './CardStyles';
import './PointCarousel.css';

const PointCarousel = ({
  lengthCard = [],
  currentIndex,
  contentCardsRef,
  getRefstButtons,
  buttonsRef,
  hendleCurrentIndex,
  mobile,
}) => {
  const contentCards = contentCardsRef;
  const leftForEachCardDesktop = -520;
  const pointCurrent = (index) => {
    if (mobile) {
      contentCards.current.style.transform = `translateX(${index * -87.5}vw)`;
    } else {
      contentCards.current.style.left = `${index * leftForEachCardDesktop}px`;
    }
    buttonsRef.current.forEach((el) => {
      if (currentIndex === index) return;
      el.classList.remove('activePoint');
    });
    buttonsRef.current[index].classList.add('activePoint');
    hendleCurrentIndex(index);
  };

  return (
    <Box sx={contentPointCarouselStyle}>
      {
            lengthCard.map((card, i) => (
              <Button
                ref={getRefstButtons}
                onClick={() => pointCurrent(i)}
                className={`${currentIndex === i ? 'activePoint' : 'notActive'} `}
                sx={pointCarouselStyle}
                key={card.title}
              />
            ))
        }
    </Box>
  );
};

export default PointCarousel;
