import { Fragment, useEffect } from 'react';
import { Box, List, Typography } from '@mui/material';
import { Captions, ParagraphOrListGeneral } from '../../Components';
import {
  typesColors,
  variantTypography,
  typesTexts,
  TYPES_COMPONENTS,
  TYPE_STYLE_TEXT,
  TYPE_LIST_STYLE,
} from '../../Common';
import {
  contentFourthListSection,
  contentFourthSubListSection,
  contentLastListSection,
  contentLastSubListSection,
  contentSubListThirdListSection,
  contentThirdListSection,
  firstSection,
  listGlobal,
  listTypeSecondList,
  modifiedParagraph,
  sectionGlobal,
  sectionGlobalListAndParagrapgh,
  sectionParagraph,
  subList,
  subListThirdListSectionSecond,
  subTitle,
  subTitleSubList,
  titleMain,
} from './PrivacyPolicyLayoutStyles';
import { titlesPrivacyPolicy } from '../../Common/messages';
import { useResetScrollTop } from '../../hooks';

const PrivacyPolicyLayout = ({ sections }) => {
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  const initialScroll = 0;
  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  const subSectionFirstListQuestions = 2;
  const subSectionList = sections.firstListQuestions[subSectionFirstListQuestions]
    .subListQuestionsDatePerson;

  const displayInline = 'inline !important';

  const firstFinality = 'A)';
  const SecondFinality = 'B)';
  const numberInitialListFirstListSection = 1;
  const numberInitialListSecondListSection = 4;
  const numberInitialListThirdListSection = 6;
  const numberInitilListThirdListSectionSecond = 15;
  const numberListThirdListSection = 2;
  const numberAditionCorrectionList = 6;
  const firstIndexListThirdSection = 0;
  const initialAlphabet = 65;
  const numberInitialFourthListSection = 16;
  const numberFourthListSectionSecond = 20;

  const indexConentList = 4;

  const firstRightSectionFourthListSection = 0;
  const numberListfirstRightSectionFourthListSection = 1;
  const lastRightsSectionsFourthListSection = [8, 9, 10];
  const initialLastRightsSectionsFourthListSection = 2;
  const numberSubstractionLastRights = 8;
  const firstIndexSubList = 0;
  const fourthIndexSubList = 4;

  const numberInitialLastSection = 21;
  const firstListLastSection = 0;
  const indexContentSubListsLastSections = 0;
  const numberSecdonInitialLasSection = 21;
  const indexFirstSubListSection = 1;
  const indexLastSubListSection = 8;
  const firstListSectionLastSection = 1;
  const intiaListLastSection = 7;
  const numberSubstractionLastRightsLastSection = 6;
  return (
    <>
      <Box
        component={TYPES_COMPONENTS.section}
        sx={{ ...firstSection, ...sectionGlobal }}
      >
        <Captions
          variant={variantTypography.h1}
          color={typesColors.textSecondary}
          style={titleMain}
        >
          {titlesPrivacyPolicy.mainTitile}
        </Captions>
        {sections.firstSection.map((textSection) => (
          <ParagraphOrListGeneral
            key={Math.random()}
            typeParagraph={typesTexts.paragraph}
            textParagraph={textSection.paragraph}
          />
        ))}
      </Box>
      <Box component={TYPES_COMPONENTS.section} sx={sectionGlobal}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {titlesPrivacyPolicy.titleSecondSection}
        </Captions>
        {sections.secondSection}
      </Box>
      <Box
        component={TYPES_COMPONENTS.section}
        sx={{
          ...sectionGlobal,
          ...sectionGlobalListAndParagrapgh,
          ...modifiedParagraph,
        }}
      >
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={listGlobal('', displayInline)}
        >
          {sections.firstListQuestions.map((textSection, i) => (
            <ParagraphOrListGeneral
              key={Math.random()}
              typeParagraph={typesTexts.listAndParagraph}
              textList={textSection.listTitle}
              textParagraph={textSection.paragraph}
              numberList={numberInitialListFirstListSection + i}
            />
          ))}
        </List>
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={subList(
            TYPE_STYLE_TEXT.weight.bold,
            TYPE_LIST_STYLE.decimal,
            '',
            '5px 0px',
          )}
        >
          {subSectionList.map((textSection) => (
            <ParagraphOrListGeneral
              key={Math.random()}
              typeParagraph={typesTexts.listAndParagraph}
              textList={textSection.listTitle}
              textParagraph={textSection.paragraph}
            />
          ))}
        </List>
      </Box>
      <Box component={TYPES_COMPONENTS.section} sx={sectionGlobal}>
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={listGlobal('', displayInline)}
        >
          {sections.secondListQuestions.fistList.map((textSection, i) => {
            return (
              <ParagraphOrListGeneral
                key={Math.random()}
                typeParagraph={typesTexts.listAndParagraph}
                textList={textSection.listTitle}
                textParagraph={textSection.paragraph}
                numberList={numberInitialListSecondListSection + i}
              />
            );
          })}
        </List>
        <Box component={TYPES_COMPONENTS.section}>
          <Captions
            variant={variantTypography.h2}
            color={typesColors.textSecondary}
            style={subTitleSubList('5px')}
          >
            <Box component={TYPES_COMPONENTS.span} sx={listTypeSecondList}>
              {firstFinality}
            </Box>
            {sections.secondListQuestions.firstListFinality.title}
          </Captions>
          <List
            component={TYPES_COMPONENTS.lists.unordered}
            sx={subList(
              TYPE_STYLE_TEXT.weight.normal,
              TYPE_LIST_STYLE.disc,
              '35px',
            )}
          >
            {sections.secondListQuestions.firstListFinality.lists.map(
              (textSection) => (
                <ParagraphOrListGeneral
                  key={Math.random()}
                  typeParagraph={typesTexts.list}
                  textList={textSection.list}
                />
              ),
            )}
          </List>
        </Box>
        <Box component={TYPES_COMPONENTS.section}>
          <Captions
            variant={variantTypography.h2}
            color={typesColors.textSecondary}
            style={subTitleSubList('5px')}
          >
            <Box component={TYPES_COMPONENTS.span} sx={listTypeSecondList}>
              {SecondFinality}
            </Box>
            {sections.secondListQuestions.secondtListFinality.title}
          </Captions>
          <List
            component={TYPES_COMPONENTS.lists.unordered}
            sx={subList(
              TYPE_STYLE_TEXT.weight.normal,
              TYPE_LIST_STYLE.disc,
              '35px',
            )}
          >
            {sections.secondListQuestions.secondtListFinality.lists.map(
              (textSection) => (
                <ParagraphOrListGeneral
                  key={Math.random()}
                  typeParagraph={typesTexts.list}
                  textList={textSection.list}
                />
              ),
            )}
          </List>
        </Box>
        <Box component={TYPES_COMPONENTS.section} sx={sectionParagraph}>
          {sections.secondListQuestions.lastListSection.map((textSection) => (
            <ParagraphOrListGeneral
              key={Math.random()}
              typeParagraph={typesTexts.paragraph}
              textParagraph={textSection.paragraph}
            />
          ))}
        </Box>
      </Box>
      <Box
        component={TYPES_COMPONENTS.section}
        sx={{ ...sectionGlobal, ...sectionGlobalListAndParagrapgh }}
      >
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={listGlobal('', displayInline)}
        >
          {sections.thirdListSection
            .slice(firstIndexListThirdSection, sections.thirdListSection.length - 1)
            .map((textSection, i) => (i !== numberListThirdListSection ? (
              <ParagraphOrListGeneral
                key={Math.random()}
                typeParagraph={typesTexts.listAndParagraph}
                textParagraph={textSection.paragraph}
                textList={textSection.listTitle}
                numberList={numberInitialListThirdListSection + i}
              />
            ) : (
              <Fragment key={Math.random()}>
                <ParagraphOrListGeneral
                  key={Math.random()}
                  typeParagraph={typesTexts.listAndParagraph}
                  textList={textSection.listTitle}
                  textParagraph={textSection.paragraph.textTitle}
                  numberList={i + numberAditionCorrectionList}
                />
                <List sx={subListThirdListSectionSecond}>
                  {textSection.paragraph.lists.map(textList => (
                    <ParagraphOrListGeneral
                      key={Math.random()}
                      typeParagraph={typesTexts.list}
                      textList={textList.list}
                    />
                  ))}
                </List>
              </Fragment>
            )))}
        </List>
        <Box sx={contentThirdListSection}>
          {sections.thirdListSection
            .slice(sections.thirdListSection.length - 1)
            .map((textSection, i) => (
              <Fragment key={Math.random()}>
                <List sx={listGlobal('', displayInline)}>
                  <ParagraphOrListGeneral
                    key={Math.random()}
                    typeParagraph={typesTexts.listAndParagraph}
                    textList={textSection.listTitle}
                    numberList={numberInitilListThirdListSectionSecond + i}
                  />
                </List>
                <Box sx={contentSubListThirdListSection}>
                  <Typography variant={variantTypography.body2}>
                    {textSection.paragraph.subListTitle}
                  </Typography>
                  <List component={TYPES_COMPONENTS.lists.order}>
                    {textSection.paragraph.lists.map((textlist, j) => (
                      <Box key={Math.random()}>
                        <ParagraphOrListGeneral
                          typeParagraph={typesTexts.list}
                          textList={textlist.list}
                          orderAlphabet={String.fromCharCode(
                            initialAlphabet + j,
                          ).toLocaleLowerCase()}
                        />
                      </Box>
                    ))}
                  </List>
                </Box>
              </Fragment>
            ))}
        </Box>
      </Box>
      <Box component={TYPES_COMPONENTS.section} sx={sectionGlobal}>
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={{
            ...listGlobal('', displayInline),
            ...contentFourthListSection,
          }}
        >
          {sections.fourthListSection
            .slice(firstIndexSubList, fourthIndexSubList)
            .map((textSection, i) => (
              <ParagraphOrListGeneral
                key={Math.random()}
                typeParagraph={typesTexts.listAndParagraph}
                textList={textSection.listTitle}
                textParagraph={textSection.paragraph}
                numberList={numberInitialFourthListSection + i}
              />
            ))}
          <ParagraphOrListGeneral
            key={Math.random()}
            typeParagraph={typesTexts.listAndParagraph}
            textParagraph={
              sections.fourthListSection[indexConentList].paragraph.contentText
            }
            textList={sections.fourthListSection[indexConentList].listTitle}
            numberList={numberFourthListSectionSecond}
          />
          <List
            component={TYPES_COMPONENTS.lists.order}
            sx={listGlobal('', displayInline)}
          >
            {sections.fourthListSection[indexConentList].paragraph.lists.map(
              (textSection, i) => (i === firstRightSectionFourthListSection ? (
                <Fragment key={Math.random()}>
                  <Box>
                    <ParagraphOrListGeneral
                      typeParagraph={typesTexts.list}
                      textList={textSection.list}
                      numberList={firstRightSectionFourthListSection
                    + numberListfirstRightSectionFourthListSection}
                    />
                  </Box>
                  <List sx={contentFourthSubListSection}>
                    {sections.fourthListSection[indexConentList].paragraph.lists
                      .slice(indexFirstSubListSection, indexLastSubListSection)
                      .map((subListSection) => (
                        <ParagraphOrListGeneral
                          key={Math.random()}
                          typeParagraph={typesTexts.list}
                          textList={subListSection.list}
                        />
                      ))}
                  </List>
                </Fragment>
              ) : lastRightsSectionsFourthListSection.includes(i) ? (
                <Box key={Math.random()}>
                  <ParagraphOrListGeneral
                    typeParagraph={typesTexts.list}
                    textList={textSection.list}
                    numberList={
                      initialLastRightsSectionsFourthListSection
                      + (i - numberSubstractionLastRights)
                    }
                  />
                </Box>
              ) : null),
            )}
          </List>
          <ParagraphOrListGeneral
            typeParagraph={typesTexts.paragraph}
            textParagraph={
              sections.fourthListSection[sections.fourthListSection.length - 1].paragraph
            }
          />
        </List>
      </Box>
      <Box component={TYPES_COMPONENTS.section} sx={sectionGlobal}>
        <List sx={contentLastListSection} component={TYPES_COMPONENTS.lists.order}>
          {
          sections.lastListSection.map((textSection, i) => (i === firstListLastSection ? (
            <Fragment key={Math.random()}>
              <Box>
                <ParagraphOrListGeneral
                  typeParagraph={typesTexts.list}
                  textList={textSection.listTitle}
                  numberList={numberInitialLastSection
                    + i}
                />
              </Box>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.paragraph}
                textParagraph={
                  sections.lastListSection[indexContentSubListsLastSections].paragraph.contentText
                }
              />
              <List sx={contentLastSubListSection} component={TYPES_COMPONENTS.lists.order}>
                {sections.lastListSection[indexContentSubListsLastSections].paragraph.lists
                  .map((subListSection, j) => (j === 0 ? (
                    <Fragment key={Math.random()}>
                      <Box>
                        <ParagraphOrListGeneral
                          typeParagraph={typesTexts.list}
                          textList={subListSection.list}
                          numberList={j + firstListSectionLastSection}
                        />
                      </Box>
                      <List component={TYPES_COMPONENTS.lists.unordered}>
                        {
                          sections.lastListSection[indexContentSubListsLastSections].paragraph.lists
                            .slice(indexFirstSubListSection, indexLastSubListSection)
                            .map((textSublist) => (
                              <ParagraphOrListGeneral
                                key={Math.random()}
                                typeParagraph={typesTexts.list}
                                textList={textSublist.list}
                              />
                            ))
                        }
                      </List>
                    </Fragment>
                  ) : j > intiaListLastSection ? (
                    <Box key={Math.random()}>
                      <ParagraphOrListGeneral
                        typeParagraph={typesTexts.list}
                        textList={subListSection.list}
                        numberList={j - numberSubstractionLastRightsLastSection}
                      />
                    </Box>
                  ) : null))}
              </List>
            </Fragment>
          ) : (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.listAndParagraph}
                textList={textSection.listTitle}
                textParagraph={textSection.paragraph}
                numberList={numberSecdonInitialLasSection + i}
              />
            </Box>
          )))
        }
        </List>
      </Box>
    </>
  );
};

export default PrivacyPolicyLayout;
