const getDataServiceGas = async () => {
  const dataServiceGas = {};
  /* List services */
  const dataServicesList = dataServiceGas.attributes.servicesList;

  /*  Section Benefits  */
  const dataBenefits = dataServiceGas.attributes.serviceBenefits;
  const bage = dataBenefits.badgeBenefits;
  const badgeImage = bage.image.data[0] || {};
  const dataCardBenefits = dataServiceGas.attributes.serviceBenefits.cardBenefits;

  /* Section Order Service */
  const dataButtonOrder = dataServiceGas.attributes.buttonOrderService;

  /* Section Selection Service */
  const dataSectionSelectionService = dataServiceGas.attributes.sectionSelectionService;
  const { steps } = dataServiceGas.attributes.buttonOrderService;
  return {
    title: dataServiceGas.attributes.title,
    description: dataServiceGas.attributes.description,
    listServices: {
      title: dataServicesList.title,
      itemsServices: dataServicesList.cardService,
    },
    sectionBenefits: {
      title: dataBenefits.title,
      dataBage: {
        title: bage.title,
        subTitle: bage.subTitle,
        image: {
          src: badgeImage.attributes.url,
          alt: badgeImage.attributes.alternativeText,
        },
      },
      dataCardBenefits,
    },
    dataButton: {
      title: dataButtonOrder.title,
      steps: dataButtonOrder.steps,
      textButton: dataButtonOrder.textButton,
    },
    sectionSelectionService: {
      title: dataSectionSelectionService.title,
      textButton: dataSectionSelectionService.textButton,
      cardsServices: dataSectionSelectionService.cardServiceTypeOrder,
      steps,
    },
  };
};

export {
  getDataServiceGas,
};
