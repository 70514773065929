import ServiceElectricity from '../../../assets/img/ServicesSearch/service-electricity.png';
import Plumbing from '../../../assets/img/ServicesSearch/Plumbing.png';
import HomeAppliances from '../../../assets/img/ServicesSearch/home-appliances.png';

const services = [
  {
    id: 1,
    description: 'Gas',
  },
  {
    id: 2,
    description: 'Limpieza',
  },
  {
    id: 3,
    description: 'Mantenimiento',
  },
  {
    id: 4,
    description: 'Fumigación',
  },
  {
    id: 5,
    description: 'Jardinería',
  },
  {
    id: 6,
    description: 'Lavandería',
  },
  {
    id: 7,
    description: 'Equipamiento del hogar',
  },
  {
    id: 8,
    description: 'Tanques estacionarios',
  },
  {
    id: 9,
    description: 'Pruebas COVID',
  },
  {
    id: 10,
    description: 'Croquetas',
  },
  {
    id: 11,
    description: 'Medicamentos',
  },
];

const locations = [
  {
    id: 1,
    description: 'Guadalajara',
  },
  {
    id: 2,
    description: 'Colima',
  },
  {
    id: 3,
    description: 'Ciudad de México',
  },
  {
    id: 4,
    description: 'Toluca',
  },
  {
    id: 5,
    description: 'San Luis Potosí',
  },
  {
    id: 6,
    description: 'Aguascalientes',
  },
  {
    id: 7,
    description: 'León',
  },
];

const titles = {
  titleServiceModal: 'Selecciona un Servicio',
  titleLocationModal: 'Seleccionar ubicación',
  titleServiceLabel: 'Servicio',
  titleLocationLabel: 'Ubicación',
  titleSerachButton: 'Buscar',
  titleSearchButtonMobile: 'Filtro',
  titleModalsContainer: 'Filtrar',
  titleProvider: 'Proveedor',
};

const valuesSelects = {
  oneValue: false,
  multipleValues: true,
};

const titlesSelectsMobile = {
  service: 'Servicios',
  location: 'Ubicación',
  date: 'Horario',
};

const textModals = {
  accept: 'Aceptar',
};

const badge = 'Tu hogar, tus reglas';

const dateInvalidMessage = 'Invalid date';

const servicesItems = [
  {
    id: 1,
    text: 'LavaTop',
  },
  {
    id: 2,
    text: 'Río ecológica',
  },
  {
    id: 3,
    text: 'Red Wash',
  },
  {
    id: 4,
    text: '+',
  },
];

const locationsItems = [
  {
    id: 1,
    text: 'Guadalajara',
  },
  {
    id: 2,
    text: 'Colima',
  },
  {
    id: 3,
    text: 'San Luis Potosí',
  },
  {
    id: 4,
    text: 'Toluca',
  },
  {
    id: 5,
    text: 'Aguascalientes',
  },
  {
    id: 6,
    text: 'CDMX',
  },
];

const dataServicesSearchView = {
  title: 'Encuentra el servicio que necesitas',
  cards: [
    {
      registryUuid: 1,
      id: 1,
      icon: ServiceElectricity,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Electricidad',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 2,
      id: 2,
      icon: Plumbing,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Plomería',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 3,
      id: 3,
      icon: HomeAppliances,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Instalaciones',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 4,
      id: 4,
      icon: ServiceElectricity,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Electricidad',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 5,
      id: 5,
      icon: Plumbing,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Plomería',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 6,
      id: 6,
      icon: HomeAppliances,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Instalaciones',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
    {
      registryUuid: 7,
      id: 7,
      icon: ServiceElectricity,
      alt: 'image',
      imageBadgeText: 'Mismo día',
      description: 'Electricidad',
      minPrice: 'Desde $450',
      subTitle: 'Servicio de gas estacionario y LP Servicio de gas estacionario y LP',
      servicesItems,
      locationsItems,
      companies: [
        {
          name: 'test',
          states: [
            'CDMX',
            'Jalisco',
            'Colima',
          ],
        },
      ],
    },
  ],
};

const customLabel = 'demo-simple-select-standard-label';
const customSelect = 'demo-simple-select-standard';

const textMobileModals = {
  location: {
    title: 'Seleccionar ubicación',
    label: 'Ubicación',
  },
  service: {
    title: 'Seleccionar servicio',
    label: 'Servicio',
  },
  date: {
    title: 'Seleccionar una fecha',
  },
  provider: {
    title: 'Seleccionar un proveedor',
  },
};

const tagetValues = {
  cards: {
    registryUuid: 'value',
    description: 'name',
  },
  paramRegistryUuid: 'paramRegistryUuid',
  services: 'services',
};

const paramValues = {
  categories: {
    paramName: 'category',
  },
};

const defaultCaption = 'No se ha encontrado ningún servicio';

const nameRegistryUUid = 'paramRegistryUuid';

const defautlDescriptionText = 'No hay descripción';

const providerData = [
  {
    id: 1,
    description: 'Global Gas',
  },
  {
    id: 2,
    description: 'Gas Rosa',
  },
  {
    id: 3,
    description: 'Zeta Gas',
  },
  {
    id: 4,
    description: 'Sonigas',
  },
  {
    id: 5,
    description: 'Oligas',
  },
  {
    id: 6,
    description: 'Gas Rosa Cilindro',
  },
];

export {
  services,
  titles,
  valuesSelects,
  textModals,
  titlesSelectsMobile,
  badge,
  locations,
  dateInvalidMessage,
  dataServicesSearchView,
  customLabel,
  customSelect,
  textMobileModals,
  tagetValues,
  paramValues,
  defaultCaption,
  nameRegistryUUid,
  defautlDescriptionText,
  providerData,
};
