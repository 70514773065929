import { Grid } from '@mui/material';

const SkeletonCardLayout = ({ styleContentSkeleton, children }) => {
  return (
    <Grid
      container
      direction={styleContentSkeleton.direction}
      columnSpacing={styleContentSkeleton.spacing?.column}
      rowSpacing={styleContentSkeleton.spacing?.row}
      sx={styleContentSkeleton.style}
    >
      { children }
    </Grid>
  );
};

export default SkeletonCardLayout;
