import { useEffect, useState } from 'react';
import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import { breakPoints, lengthCardsSwiper } from '../../Common';
import { CardServiceOrBenefits } from '../../Components';
import { useBreakpoint } from '../../hooks';
import {
  cardContentStyle,
  cardMediaStyle,
  containerSkeletonCard,
  contentImage,
  descriptionCardContent,
  titleCardContent,
} from './styles';
import './styles.css';
import LayoutServiceOrBenefitsCardSkeleton from '../LayoutServiceOrBenefitsCardSkeleton/LayoutServiceOrBenefitsCardSkeleton';

const LayoutCardBenefits = () => {
  const [dataCardsBenefits, setDataCardsBenefits] = useState({
    data: [],
    loading: false,
  });
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);

  useEffect(() => {
    const dataSectionBenfits = {
      title: 'Gas LP',
      description: 'En Okboy puedes pedir el gas con garantía\nde litros completos, con las mejores gasera.\nPrograma tu servicio y hazlo recurrente para\nque nunca te falte.',
      listServices: {
        title: 'Descubre los servicios que tenemos',
        itemsServices: [
          {
            id: 1,
            title: 'Cilindro',
            priceService: 'Desde $300.00',
            description: 'Cambio de cilindro de gas a domicilio.',
            imageService: {
              data: [
                {
                  id: 69,
                  attributes: {
                    name: 'cilindrus.jpg',
                    alternativeText: 'cilindrus.jpg',
                    caption: 'cilindrus.jpg',
                    width: 135,
                    height: 180,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_cilindrus_12845ec6f8.jpg',
                        hash: 'thumbnail_cilindrus_12845ec6f8',
                        mime: 'image/jpeg',
                        name: 'thumbnail_cilindrus.jpg',
                        path: null,
                        size: 2.64,
                        width: 117,
                        height: 156,
                      },
                    },
                    hash: 'cilindrus_12845ec6f8',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 3.12,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/cilindrus_12845ec6f8.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:25:46.858Z',
                    updatedAt: '2022-09-06T14:25:46.858Z',
                  },
                },
              ],
            },
          },
          {
            id: 2,
            title: 'Estacionario',
            priceService: 'Desde $200.00',
            description: 'Recarga de tanque de gas estacionario a domicilio.',
            imageService: {
              data: [
                {
                  id: 70,
                  attributes: {
                    name: 'tanque_s.jpg',
                    alternativeText: 'tanque_s.jpg',
                    caption: 'tanque_s.jpg',
                    width: 165,
                    height: 122,
                    formats: null,
                    hash: 'tanque_s_337b930dc6',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 2.15,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/tanque_s_337b930dc6.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:26:06.737Z',
                    updatedAt: '2022-09-06T14:26:06.737Z',
                  },
                },
              ],
            },
          },
        ],
      },
      sectionBenefits: {
        title: 'Beneficios',
        dataBage: {
          title: 'Litros completos',
          subTitle: 'Proveedores certificados',
          image: {
            src: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Badge_7f4c4ed858.png',
            alt: 'Badge.png',
          },
        },
        dataCardBenefits: [
          {
            id: 1,
            title: 'Sin robos',
            contentText: 'Te damos garantía de litros completos. Tomamos una foto antes y después de tu servicio y si tu carga está incompleta lo solucionamos.',
            imageBenefits: {
              data: [
                {
                  id: 71,
                  attributes: {
                    name: 'no-theft-drummy.jpg',
                    alternativeText: 'no-theft-drummy.jpg',
                    caption: 'no-theft-drummy.jpg',
                    width: 300,
                    height: 169,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_no_theft_drummy_a85a12e7c6.jpg',
                        hash: 'thumbnail_no_theft_drummy_a85a12e7c6',
                        mime: 'image/jpeg',
                        name: 'thumbnail_no-theft-drummy.jpg',
                        path: null,
                        size: 5.26,
                        width: 245,
                        height: 138,
                      },
                    },
                    hash: 'no_theft_drummy_a85a12e7c6',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 6.96,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/no_theft_drummy_a85a12e7c6.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:40:46.257Z',
                    updatedAt: '2022-09-06T14:40:46.257Z',
                  },
                },
              ],
            },
          },
          {
            id: 2,
            title: 'Que nunca te falte',
            contentText: 'Programa y automatiza tu servicio de gas para que nunca te falte.',
            imageBenefits: {
              data: [
                {
                  id: 72,
                  attributes: {
                    name: 'programming-dummy.jpg',
                    alternativeText: 'programming-dummy.jpg',
                    caption: 'programming-dummy.jpg',
                    width: 330,
                    height: 220,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_programming_dummy_ead044e8de.jpg',
                        hash: 'thumbnail_programming_dummy_ead044e8de',
                        mime: 'image/jpeg',
                        name: 'thumbnail_programming-dummy.jpg',
                        path: null,
                        size: 5.76,
                        width: 234,
                        height: 156,
                      },
                    },
                    hash: 'programming_dummy_ead044e8de',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 8.91,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/programming_dummy_ead044e8de.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:41:27.496Z',
                    updatedAt: '2022-09-06T14:41:27.496Z',
                  },
                },
              ],
            },
          },
          {
            id: 3,
            title: 'Paga con tarjeta',
            contentText: 'Realiza tu pago de manera segura con tu tarjeta de crédito o débito.',
            imageBenefits: {
              data: [
                {
                  id: 73,
                  attributes: {
                    name: 'debit-phone-dummy.jpg',
                    alternativeText: 'debit-phone-dummy.jpg',
                    caption: 'debit-phone-dummy.jpg',
                    width: 330,
                    height: 220,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_debit_phone_dummy_5a628dedc2.jpg',
                        hash: 'thumbnail_debit_phone_dummy_5a628dedc2',
                        mime: 'image/jpeg',
                        name: 'thumbnail_debit-phone-dummy.jpg',
                        path: null,
                        size: 6.46,
                        width: 234,
                        height: 156,
                      },
                    },
                    hash: 'debit_phone_dummy_5a628dedc2',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 10.26,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/debit_phone_dummy_5a628dedc2.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:41:51.028Z',
                    updatedAt: '2022-09-06T14:41:51.028Z',
                  },
                },
              ],
            },
          },
          {
            id: 4,
            title: 'Atención personalizada',
            contentText: 'Hacemos seguimiento de los proveedores y ante cualquier imprevisto, estamos aquí para solucionarlo.',
            imageBenefits: {
              data: [
                {
                  id: 74,
                  attributes: {
                    name: 'attention-dummy.jpg',
                    alternativeText: 'attention-dummy.jpg',
                    caption: 'attention-dummy.jpg',
                    width: 330,
                    height: 220,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_attention_dummy_bb405df2cd.jpg',
                        hash: 'thumbnail_attention_dummy_bb405df2cd',
                        mime: 'image/jpeg',
                        name: 'thumbnail_attention-dummy.jpg',
                        path: null,
                        size: 6.38,
                        width: 234,
                        height: 156,
                      },
                    },
                    hash: 'attention_dummy_bb405df2cd',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 9.99,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/attention_dummy_bb405df2cd.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:42:25.204Z',
                    updatedAt: '2022-09-06T14:42:25.204Z',
                  },
                },
              ],
            },
          },
        ],
      },
      dataButton: {
        title: 'En 5 sencillos pasos',
        steps: 5,
        textButton: 'Pedir servicio',
      },
      sectionSelectionService: {
        title: 'Selecciona el tipo de tanque',
        textButton: 'Siguiente',
        cardsServices: [
          {
            id: 1,
            nameTypeService: 'Cilindro',
            imageService: {
              data: [
                {
                  id: 75,
                  attributes: {
                    name: 'cilindru.jpg',
                    alternativeText: 'cilindru.jpg',
                    caption: 'cilindru.jpg',
                    width: 73,
                    height: 180,
                    formats: {
                      thumbnail: {
                        ext: '.jpg',
                        url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_cilindru_706cfc52fb.jpg',
                        hash: 'thumbnail_cilindru_706cfc52fb',
                        mime: 'image/jpeg',
                        name: 'thumbnail_cilindru.jpg',
                        path: null,
                        size: 1.52,
                        width: 63,
                        height: 156,
                      },
                    },
                    hash: 'cilindru_706cfc52fb',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 1.75,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/cilindru_706cfc52fb.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:42:56.984Z',
                    updatedAt: '2022-09-06T14:42:56.984Z',
                  },
                },
              ],
            },
          },
          {
            id: 2,
            nameTypeService: 'Estacionario',
            imageService: {
              data: [
                {
                  id: 70,
                  attributes: {
                    name: 'tanque_s.jpg',
                    alternativeText: 'tanque_s.jpg',
                    caption: 'tanque_s.jpg',
                    width: 165,
                    height: 122,
                    formats: null,
                    hash: 'tanque_s_337b930dc6',
                    ext: '.jpg',
                    mime: 'image/jpeg',
                    size: 2.15,
                    url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/tanque_s_337b930dc6.jpg',
                    previewUrl: null,
                    provider: 'aws-s3',
                    provider_metadata: null,
                    createdAt: '2022-09-06T14:26:06.737Z',
                    updatedAt: '2022-09-06T14:26:06.737Z',
                  },
                },
              ],
            },
          },
        ],
        steps: 5,
      },
    };
    const { sectionBenefits: { dataCardBenefits } } = dataSectionBenfits;
    setDataCardsBenefits(preveDataCardsBenefits => ({
      ...preveDataCardsBenefits,
      data: [...dataCardBenefits],
    }));
  }, []);

  const contentBenefits = 'content-benefits';
  const fourCards = 4;
  const withoutTextPrice = false;
  const { oneCard, forCard } = lengthCardsSwiper;
  const spaceBetweenCards = 16;
  return (
    dataCardsBenefits.loading ? (
      <Box sx={containerSkeletonCard}>
        <LayoutServiceOrBenefitsCardSkeleton
          lenghtCard={fourCards}
          withTextPrice={withoutTextPrice}
        />
      </Box>
    ) : (
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        freeMode={true}
        slidesPerView={toggleBreakpoint ? oneCard : forCard}
        spaceBetween={spaceBetweenCards}
        className={contentBenefits}
      >
        {
          dataCardsBenefits?.data.map(items => (
            <SwiperSlide key={items.id}>
              <CardServiceOrBenefits
                title={items.title}
                description={items.contentText}
                imageService={items.imageBenefits}
                loading={dataCardsBenefits.loading}
                styleContentImage={contentImage}
                styleCardMedia={cardMediaStyle}
                styleCardContent={cardContentStyle}
                styleTitle={titleCardContent}
                styleDescription={descriptionCardContent}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    )
  );
};

export default LayoutCardBenefits;
