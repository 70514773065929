export const conntentAuthorAndDate = {
  fontSize: '1rem',
  fontWeight: '400',
  marginBottom: '2.625rem',
  '& > span:first-of-type': {
    marginRight: '2.375rem',
    color: 'inherit',
  },
  '& > span:last-of-type:before': {
    color: 'inherit',
    content: '"•"',
    position: 'relative',
    left: '-4%',
  },
};
