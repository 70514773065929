import { useEffect, useRef } from 'react';
import { TYPES_ATTRIBUTES_DOM, TYPES_METAS } from '../Common';
import { SEO } from '../Common/messages';

export const useMetaSEO = ({
  metaTitle, metaDescription, keywords, metaRobots, structuredData,
}) => {
  const structuredDataSet = JSON.stringify(structuredData) || '';
  const { robots } = TYPES_METAS;
  const metasElements = useRef(document.querySelector('head').querySelectorAll('meta'));
  const scripts = useRef(document.querySelector('head').querySelectorAll('script'));
  const { atributes } = TYPES_ATTRIBUTES_DOM;
  const TYPE_SCRIPT_STRUCTURED = 'application/ld+json';
  const contentNameMetas = [robots.name, TYPES_METAS.desc, TYPES_METAS.keywords];
  const { messageDescriptionDefault, messageTitleMetaDefault, keywordsDefault } = SEO;

  let structuredDataChildrenHead;
  let metas;
  useEffect(() => {
    document.title = metaTitle || messageTitleMetaDefault;
    metas = [...metasElements.current];
    const valueMeta = [metaTitle, metaDescription, keywords, metaRobots];
    metas.filter(meta => contentNameMetas.includes(meta.getAttribute(atributes.name)))
      .forEach((meta, i) => {
        if (!valueMeta[i]) {
          meta.removeAttribute(atributes.content);
        }
      });
    if (metaRobots) {
      metas
        .find(meta => meta.getAttribute(atributes.name) === robots.name)
        .setAttribute(atributes.content, metaRobots);
    }
    if (metaDescription) {
      metas
        .find(meta => meta.getAttribute(atributes.name) === TYPES_METAS.desc)
        .setAttribute(atributes.content, metaDescription);
    }
    if (keywords) {
      metas
        .find(meta => meta.getAttribute(atributes.name) === TYPES_METAS.keywords)
        .setAttribute(atributes.content, keywords);
    }

    structuredDataChildrenHead = [
      ...scripts.current,
    ].find(script => script.getAttribute(atributes.type) === TYPE_SCRIPT_STRUCTURED);
    structuredDataChildrenHead.innerHTML = structuredDataSet;

    return () => {
      structuredDataChildrenHead.innerHTML = '';
      metas.filter(meta => contentNameMetas.includes(meta.getAttribute(atributes.name)))
        .forEach((meta) => {
          if (meta.name === robots.name) meta.removeAttribute(atributes.content);
          else if (meta.name === TYPES_METAS.desc) {
            meta.setAttribute(atributes.content, messageDescriptionDefault);
          } else if (meta.name === TYPES_METAS.keywords) {
            meta.setAttribute(atributes.content, keywordsDefault);
          }
        });
    };
  }, [
    metaTitle,
    metaDescription,
    keywords,
    metaRobots,
    metasElements?.current,
    scripts?.current,
  ]);
  return [
    metaTitle,
    metaDescription,
    keywords,
    metaRobots,
    metasElements?.current,
    scripts?.current,
    structuredDataChildrenHead,
    metas,
  ];
};
