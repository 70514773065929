import { Button, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { typesTarget, variantTypography } from '../../Common';
import { downloads } from '../../Common/messages';
import {
  HeaderText, TitleText, TextContent, DownloadApp, Container,
} from './style';

const DownloadTextContainer = ({
  header,
  title,
  text,
  downloadText,
  styleTitle,
  styleTextcontent,
}) => {
  return (
    <Box style={Container}>
      {header && <HeaderText variant={variantTypography.h2}>{header}</HeaderText>}
      <TitleText variant={variantTypography.h3} sx={styleTitle}>{title}</TitleText>
      <TextContent sx={styleTextcontent}>
        <ReactMarkdown>{text}</ReactMarkdown>
      </TextContent>
      {downloadText && (
      <Button
        style={DownloadApp}
        href={downloads.downLoadApp}
        target={typesTarget.TYPE_TARGET_BLANK}
      >
        {downloadText}
      </Button>
      )}
    </Box>
  );
};

export default DownloadTextContainer;
