import { useMemo, useState, createContext } from 'react';

export const LoaderContext = createContext(null);

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const loadingMemeo = useMemo(() => [loading, setLoading], [loading]);
  return (
    <LoaderContext.Provider value={loadingMemeo}>{ children }</LoaderContext.Provider>
  );
};

export default LoaderProvider;
