import { Box } from '@mui/material';
import { typesColors, TYPES_COMPONENTS, variantTypography } from '../../Common';
import { Captions, EvidenceCard } from '../../Components';
import {
  WrapperInformationOrder,
  captionStepper,
  styledOrderInformationTitle,
  SectionInformationOrderDate,
} from './styles';
import { ServiceTraking } from '../../Common/messages';

const {
  messagesTracking: { serviceInformation },
} = ServiceTraking;

const LayoutInformationProvider = ({ orderInformationData }) => {
  const { dataInformationOrder } = orderInformationData;
  const { provider } = dataInformationOrder;
  const wrapper = provider ? (
    <WrapperInformationOrder>
      <Captions
        color={typesColors.textSecondary}
        variant={variantTypography.h2}
        style={{ ...captionStepper, ...styledOrderInformationTitle }}
      >
        {`${serviceInformation.providerInformation}`}
      </Captions>
      <SectionInformationOrderDate>
        <Box component={TYPES_COMPONENTS.div} style={{ display: 'inline-flex' }}>
          {
            provider.photo ? (
              <div className='round'>
                <EvidenceCard key='imageUser' img={provider.photo} />
              </div>
            ) : null
          }
          <span style={{ paddingTop: '14px', marginLeft: '20px', fontSize: '14px' }}>
            {`${provider.name} ${provider.lastName}`}
          </span>
        </Box>
      </SectionInformationOrderDate>
    </WrapperInformationOrder>
  ) : null;
  return (
    wrapper
  );
};

export default LayoutInformationProvider;
