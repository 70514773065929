import { Box, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  breakPoints,
  typesColors,
  TYPES_COMPONENTS,
  variantTypography,
} from '../../Common';
import { Captions, CardServiceOrBenefits } from '../../Components';
import { useBreakpoint } from '../../hooks';
import LayoutServiceOrBenefitsCardSkeleton from '../LayoutServiceOrBenefitsCardSkeleton/LayoutServiceOrBenefitsCardSkeleton';
import {
  contentServices,
  titleListSercieSkeleton,
  titleServices,
} from './LayoutServiceCardsStyles';
import './styles.css';

const contentService = 'content-service';
const LayoutServiceCards = () => {
  const [serviceList, setServiceList] = useState({
    title: '',
    itemsService: [],
    loading: false,
  });
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  useEffect(() => {
    const listServices = {
      title: 'Descubre los servicios que tenemos',
      itemsServices: [
        {
          id: 1,
          title: 'Cilindro',
          priceService: 'Desde $300.00',
          description: 'Cambio de cilindro de gas a domicilio.',
          imageService: {
            data: [
              {
                id: 69,
                attributes: {
                  name: 'cilindrus.jpg',
                  alternativeText: 'cilindrus.jpg',
                  caption: 'cilindrus.jpg',
                  width: 135,
                  height: 180,
                  formats: {
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_cilindrus_12845ec6f8.jpg',
                      hash: 'thumbnail_cilindrus_12845ec6f8',
                      mime: 'image/jpeg',
                      name: 'thumbnail_cilindrus.jpg',
                      path: null,
                      size: 2.64,
                      width: 117,
                      height: 156,
                    },
                  },
                  hash: 'cilindrus_12845ec6f8',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 3.12,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/cilindrus_12845ec6f8.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-09-06T14:25:46.858Z',
                  updatedAt: '2022-09-06T14:25:46.858Z',
                },
              },
            ],
          },
        },
        {
          id: 2,
          title: 'Estacionario',
          priceService: 'Desde $200.00',
          description: 'Recarga de tanque de gas estacionario a domicilio.',
          imageService: {
            data: [
              {
                id: 70,
                attributes: {
                  name: 'tanque_s.jpg',
                  alternativeText: 'tanque_s.jpg',
                  caption: 'tanque_s.jpg',
                  width: 165,
                  height: 122,
                  formats: null,
                  hash: 'tanque_s_337b930dc6',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 2.15,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/tanque_s_337b930dc6.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-09-06T14:26:06.737Z',
                  updatedAt: '2022-09-06T14:26:06.737Z',
                },
              },
            ],
          },
        },
      ],
    };
    setServiceList(prevDataServiceList => ({
      ...prevDataServiceList,
      title: listServices.title,
      itemsService: [...listServices.itemsServices],
    }));
  }, []);

  const twoCards = 2;
  return (
    <Box component={TYPES_COMPONENTS.section} sx={contentServices}>
      <Captions
        variant={variantTypography.h2}
        color={typesColors.main}
        style={titleServices}
      >
        {serviceList.loading ? <Skeleton sx={titleListSercieSkeleton} /> : serviceList.title}
      </Captions>

      {
        serviceList.loading ? (<LayoutServiceOrBenefitsCardSkeleton lenghtCard={twoCards} />) : (
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            freeMode={true}
            slidesPerView={toggleBreakpoint ? 1 : 2}
            spaceBetween={16}
            className={contentService}
          >
            {
              serviceList.itemsService.map(items => (
                <SwiperSlide key={items.id}>
                  <CardServiceOrBenefits
                    title={items.title}
                    description={items.description}
                    priceService={items.priceService}
                    imageService={items.imageService}
                    loading={serviceList.loading}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
        )
      }

    </Box>
  );
};

export default LayoutServiceCards;
