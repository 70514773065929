import { SELECTORS_TYPES } from '../Common';

const filterServices = (
  e,
  selectType,
  dispatch,
  serviceFilter,
  locationFilter,
  serviceTypeValue,
) => {
  const { target } = e;
  if (selectType === SELECTORS_TYPES.services) {
    dispatch(serviceFilter({
      service: target.value, serviceType: serviceTypeValue,
    }));
  }
  if (selectType === SELECTORS_TYPES.locations) {
    dispatch(locationFilter(target.value));
  }
};

const handleModal = (isOpen, isClose, setOpenModals, modalName, openOrClose) => {
  if (isOpen) {
    setOpenModals(prevModal => ({
      ...prevModal,
      [modalName]: !openOrClose,
    }));
  }
  if (isClose) {
    setOpenModals(prevModal => ({
      ...prevModal,
      [modalName]: !openOrClose,
    }));
  }
};

const iconRotate = (modal, icon) => {
  const elementIcon = icon.style;
  if (modal) {
    elementIcon.transform = 'rotate(180deg)';
  } else {
    elementIcon.transform = 'rotate(0deg)';
  }
};

const selectButtonChangeColor = (
  target,
  valueState,
  setOpenModals,
  ref,
  currentColor,
  defaultColor,
) => {
  setOpenModals(prevModal => ({
    ...prevModal,
    [valueState]: target.value,
  }));
  const elements = ref;
  elements.current = elements.current.filter(element => element);
  elements.current.forEach(element => {
    const el = element.style;
    if (target.value === element.value) {
      el.backgroundColor = currentColor || 'rgba(68, 51, 224, 0.2)';
    } else {
      el.backgroundColor = defaultColor || 'transparent';
    }
  });
};

export {
  filterServices,
  handleModal,
  iconRotate,
  selectButtonChangeColor,
};
