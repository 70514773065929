import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SISEZ_TYPES, typesColors, TYPES_BUTTONS } from '../../Common';
import ButtonPrimary from '../Button/ButtonPrimary';
import {
  buttonSearch,
  closeIconStyles,
  ModalSelect,
  ServicesContiner,
} from './styles';

const MobileModal = ({
  children,
  openModal,
  handleSearch,
  handleClose,
  textButton,
  modalName,
  data,
  styles,
}) => {
  return (
    <ModalSelect
      keepMounted
      open={openModal}
      onClose={(e) => handleClose(e, modalName, openModal)}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <ServicesContiner sx={styles?.modal}>
        <IconButton
          onClick={(e) => handleClose(e, modalName, openModal)}
          size={SISEZ_TYPES.sm}
          aria-label='delete'
          color={typesColors.primary}
          sx={closeIconStyles}
        >
          <CloseIcon />
        </IconButton>
        { children }
        {textButton && (
        <ButtonPrimary
          handleToggle={handleSearch}
          styles={{ ...buttonSearch, ...styles?.button }}
          disabled={!data?.length}
          params={{ modalName, openModal }}
          typeButton={TYPES_BUTTONS.buttonWithParams}
        >
            { textButton }
        </ButtonPrimary>
        )}
      </ServicesContiner>
    </ModalSelect>
  );
};

export default MobileModal;
