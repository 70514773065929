/* eslint-disable no-undef */
import { Box, List } from '@mui/material';
import { useState, useEffect } from 'react';
import { variantTypography, typesTexts, typesColors } from '../../Common';
import { contentTitle } from '../../Common/messages';
import { Captions, ParagraphOrListGeneral } from '../../Components';
import {
  listStyles,
  linkList,
  secondSubTitle,
  subTitle,
  titleMain,
} from './CancellationPoliciesLayoutStyles';

const CancellationPoliciesLayout = ({ contentTextParagraph }) => {
  const typeScroll = 'smooth';
  const initialScroll = 0;
  const { scrollTop } = document.documentElement;
  const [scroll, setScroll] = useState(scrollTop);

  useEffect(() => {
    setScroll(initialScroll);
    window.scroll({
      top: scroll,
      behavior: typeScroll,
    });
  }, [scroll]);
  return (
    <>
      <Captions variant={variantTypography.h1} style={titleMain} color={typesColors.textSecondary}>
        {contentTitle.mainTitle}
      </Captions>
      <Box>
        {contentTextParagraph.firtsSection.map((sectionsTexts) => (
          <ParagraphOrListGeneral
            key={Math.random() * sectionsTexts.section}
            typeParagraph={typesTexts.paragraph}
            textParagraph={sectionsTexts.text}
          />
        ))}
      </Box>
      <Captions variant={variantTypography.h2} style={subTitle} color={typesColors.textSecondary}>
        {contentTitle.firstSubtitle}
      </Captions>
      <Box>
        <List sx={listStyles}>
          {contentTextParagraph.firstList.map((sectionsTexts) => (
            <ParagraphOrListGeneral
              key={Math.random() * sectionsTexts.section}
              typeParagraph={typesTexts.list}
              textList={sectionsTexts.text}
            />
          ))}
        </List>
      </Box>
      <Box>
        <ParagraphOrListGeneral
          typeParagraph={typesTexts.paragraph}
          textParagraph={contentTextParagraph.secondSection}
        />
      </Box>
      <Captions
        variant={variantTypography.h2}
        style={{ ...subTitle, ...secondSubTitle }}
        color={typesColors.textSecondary}
      >
        {contentTitle.secondSubtitle}
      </Captions>
      <Box>
        <List sx={{ ...listStyles, ...linkList }}>
          {contentTextParagraph.secondList.map((sectionsTexts) => (
            <ParagraphOrListGeneral
              key={Math.random() * sectionsTexts.section}
              typeParagraph={typesTexts.list}
              textList={sectionsTexts.text}
            />
          ))}
        </List>
      </Box>
      <Box>
        <ParagraphOrListGeneral
          typeParagraph={typesTexts.paragraph}
          textParagraph={contentTextParagraph.thirdSection}
        />
      </Box>
    </>
  );
};

export default CancellationPoliciesLayout;
