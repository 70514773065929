import { MenuItem, Select } from '@mui/material';
import { InputLabelCustom, InputSelect, KeyboardArrowDownIconCustom } from './styles';

const SelectsFilters = ({
  title,
  label,
  selectId,
  items,
  handleSelectors,
  value,
  refElements,
  inputLabelStyle,
  selectorsStyles,
  selectType,
}) => {
  return (
    <>
      <InputLabelCustom
        sx={inputLabelStyle}
        id={label}
      >
        { title }
      </InputLabelCustom>
      <Select
        labelId={label}
        id={selectId}
        value={!value ? '' : value}
        onChange={(e) => handleSelectors(e, selectType, items)}
        input={<InputSelect />}
        IconComponent={KeyboardArrowDownIconCustom}
        sx={selectorsStyles}
      >
        {
            items && items?.map(item => {
              return (
                <MenuItem
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  ref={(el) => refElements = [...refElements, el]}
                  key={item.id}
                  value={item.description}
                >
                  { item.description }
                </MenuItem>
              );
            })
          }
      </Select>
    </>
  );
};

export default SelectsFilters;
