import { variantTypography } from '../../Common';
import {
  AboutSkeleton,
  CardServicesContent,
  CompaniesSkeleton,
  hiddenText,
  ImageContainer,
  ImageSkeleton,
  LocationsBadgeContainer,
  serviceCardStyles,
  ServiceCardStyles,
  ServicesBadgeContainer,
  spanReset,
  StatesSkeleton,
  SubTitle,
  Title,
  TitleSkeleton,
} from './styles';

const ServiceCardSkeleton = ({ length }) => {
  return (
    Array.from(new Array(length)).map((item, index) => {
      const id = index + 1;
      return (
        <ServiceCardStyles
          sx={serviceCardStyles}
          key={id}
        >
          <ImageContainer sx={spanReset}>
            <ImageSkeleton />
          </ImageContainer>
          <CardServicesContent>
            <Title
              variant={variantTypography.h4}
            >
              <TitleSkeleton />
            </Title>
            <SubTitle sx={hiddenText} variant={variantTypography.h5}>
              <AboutSkeleton />
            </SubTitle>
            <ServicesBadgeContainer>
              <CompaniesSkeleton />
            </ServicesBadgeContainer>
            <LocationsBadgeContainer>
              <StatesSkeleton />
            </LocationsBadgeContainer>
          </CardServicesContent>
        </ServiceCardStyles>
      );
    })
  );
};

export default ServiceCardSkeleton;
