import { PointService } from '../../Components';
import { ContainerPointsService, PointServiceSkeleton } from './styles';

const LayoutPointServiceOrder = ({ length, loading }) => {
  return (
    <ContainerPointsService>
      {
        loading ? (
          <PointServiceSkeleton />
        ) : (
          Array.from(new Array(length)).map(() => (
            <PointService key={Math.random()} />
          ))
        )
      }
    </ContainerPointsService>
  );
};

export default LayoutPointServiceOrder;
