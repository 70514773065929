/* eslint-disable no-return-assign */
import { variantTypography } from '../../Common';
import Captions from '../Captions/Captions';
import MobileModal from './MobileModal';
import { captionServiceOption, ServicesCheck } from './styles';

const ModalServices = ({
  modal,
  handleClose,
  nameServiceModal,
  textModals,
  searchServices,
  handleSearch,
  items,
  handleSelect,
  itemsRef,
  titleModal,
  styles,
}) => {
  return (
    <MobileModal
      openModal={modal}
      handleClose={handleClose}
      modalName={nameServiceModal}
      textButton={textModals.accept}
      data={searchServices}
      handleSearch={handleSearch}
      styles={styles}
    >
      <Captions
        variant={variantTypography.h2}
        style={captionServiceOption}
      >
        { titleModal }
      </Captions>
      {
             items && items?.map(item => {
               return (
                 <ServicesCheck
                   key={item.id}
                   onClick={(e) => handleSelect(e, nameServiceModal, items)}
                   value={item.description}
                   name={item.registryUuid} // eslint-disable-next-line no-param-reassign
                   ref={(el) => itemsRef.current = [...itemsRef.current, el]}
                 >
                   { item.description }
                 </ServicesCheck>
               );
             })
            }
    </MobileModal>
  );
};

export default ModalServices;
