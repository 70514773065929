import { useContext, useEffect } from 'react';
import { ServicePreOrder } from '../../Common/messages';
import CaptionAndAddressLayout from './CaptionAndAddressLayout';
import PreOrderForm from './PreOrderForm';
import ProductLayout from './ProductLayout';
import { PreOrderContainer } from './styles';
import AddressIcon from '../../../assets/img/ServicePreOrder/addressIcon.png';
import { ServicePreOrderProvider } from '../../Contexts';
import { useResetScrollTop } from '../../hooks';
import { initialScroll } from '../../Common';

const {
  title,
  addressTitle,
  orderTitle,
} = ServicePreOrder;

const PreOrderLayout = () => {
  const [preOrderState] = useContext(ServicePreOrderProvider);

  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  return (
    <>
      <CaptionAndAddressLayout
        title={title}
        addressTitle={addressTitle}
        addressMessage={preOrderState?.configurationData?.location?.formatted_address}
        icon={AddressIcon}
        orderTitle={orderTitle}
      />
      <PreOrderContainer>
        <ProductLayout />
        <PreOrderForm />
      </PreOrderContainer>
    </>
  );
};

export default PreOrderLayout;
