import { Skeleton } from '@mui/material';
import { descriptionSkeleton, titleSkeleton } from './Styles';

const DescriptionServiceSkeleton = () => {
  return (
    <>
      <Skeleton sx={titleSkeleton} />
      <Skeleton sx={descriptionSkeleton} />
    </>
  );
};

export default DescriptionServiceSkeleton;
