export const containerStyles = {
  justifyContent: 'space-evenly',
  position: 'relative',
  paddingTop: {
    xs: '2rem',
    md: '0rem',
  },
  marginTop: {
    xs: '0rem',
    md: '1.25rem',
  },
  marginBottom: {
    xs: '3rem',
    md: 'initial',
  },
  borderTopLeftRadius: {
    xs: '30px', sm: '30px', md: '0', lg: '0',
  },
  borderTopRightRadius: {
    xs: '30px', sm: '30px', md: '0', lg: '0',
  },
  backgroundColor: {
    xs: 'background.primary',
    md: 'transparent',
  },
};
