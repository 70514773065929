import { Box, Skeleton, Grid } from '@mui/material';
import { columns } from '../../Common';

const SkeletonCard = ({
  styleOneColumn,
  styleColumnTwo,
  styleContentColumnThree,
  styleThreeColumn,
  typeColumn,
  lengthCard,
}) => {
  const typeAnimation = 'wave';
  const variantSkeleton = 'rectangular';
  return (
    <>
      {typeColumn === columns.one ? (
        <>
          <Grid item lg={12} sx={styleOneColumn}>
            <Skeleton animation={typeAnimation} variant={variantSkeleton} />
            <Box>
              {Array.from(new Array(4)).map(() => (
                <Skeleton key={Math.random()} animation={typeAnimation} />
              ))}
            </Box>
          </Grid>
        </>
      ) : typeColumn === columns.two ? (
        <>
          {Array.from(new Array(2)).map(() => (
            <Grid
              key={Math.random()}
              sx={styleColumnTwo}
              item
              lg={5.8}
              md={5.8}
              sm={12}
              xs={12}
            >
              <Skeleton animation={typeAnimation} variant={variantSkeleton} />
              <Box>
                {Array.from(new Array(3)).map(() => (
                  <Skeleton key={Math.random()} animation={typeAnimation} />
                ))}
              </Box>
            </Grid>
          ))}
        </>
      ) : typeColumn === columns.three ? (
        <Box sx={styleContentColumnThree}>
          {Array.from(new Array(lengthCard || 3)).map(() => (
            <Grid
              key={Math.random()}
              sx={styleThreeColumn}
              item
              lg={3.73}
              md={3.73}
              sm={12}
              xs={12}
            >
              <Skeleton animation={typeAnimation} variant={variantSkeleton} />
              <Box>
                {Array.from(new Array(3)).map(() => (
                  <Skeleton key={Math.random()} animation={typeAnimation} />
                ))}
              </Box>
            </Grid>
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default SkeletonCard;
