import { Box, List } from '@mui/material';
import { useEffect } from 'react';
import {
  typesColors,
  typesTexts,
  TYPES_COMPONENTS,
  variantTypography,
} from '../../Common';
import { Captions, ParagraphOrListGeneral } from '../../Components';
import { useResetScrollTop } from '../../hooks';
import {
  contentList,
  contentListTenthSection,
  contentSectionEighth,
  contentSectionNineth,
  contentSubListsTenthSection,
  sectionGlobal,
  subTitle,
  titleMain,
} from './TermsConditionsLayoutsStyles';

const TermsConditionsLayouts = ({ captions, sections }) => {
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  const initialScroll = 0;
  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  const numberToAddition = 1;
  const firstNumberList = 1;
  const secondNumberList = 2;
  const thirdNumberList = 3;
  const fourtNumberList = 4;
  const fifthNumberList = 5;
  const sixthNumberList = 6;
  const seventhNumberList = 7;
  const eighthNumberList = 8;
  const ninethNumberList = 9;
  const tenthNumberList = 10;
  const sectionsParagraphAndList = [2, 4, 5];

  return (
    <>
      <Captions
        variant={variantTypography.h1}
        color={typesColors.textSecondary}
        style={titleMain}
      >
        {captions.main}
      </Captions>
      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <ParagraphOrListGeneral
          typeParagraph={typesTexts.paragraph}
          textParagraph={sections.introduction}
        />
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${firstNumberList} ${captions.fistTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listFirstSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={firstNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${secondNumberList}. ${captions.secondTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listSecondSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={secondNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${thirdNumberList}. ${captions.thirdTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listThirdSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={thirdNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${fourtNumberList}. ${captions.fourthTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listFourthSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={fourtNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${fifthNumberList}. ${captions.fifthTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listFifthSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={fifthNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${sixthNumberList}. ${captions.sixthTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listSixthSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={sixthNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${seventhNumberList}. ${captions.seventhTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentList}>
          {sections.listSeventhSection.map((textSection, i) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.list}
                textList={textSection.list}
                numberSubListMain={seventhNumberList}
                numberSublist={i + numberToAddition}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${eighthNumberList}. ${captions.eighthTitleSection}`}
        </Captions>
        <Box sx={contentSectionEighth} key={Math.random()}>
          <ParagraphOrListGeneral
            typeParagraph={typesTexts.paragraph}
            textParagraph={sections.paragraphEighthSection}
          />
        </Box>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${ninethNumberList}. ${captions.ninethTitleSection}`}
        </Captions>
        <List
          component={TYPES_COMPONENTS.lists.order}
          sx={contentSectionNineth}
        >
          {sections.paragraphNinethSection.map((textSection) => (
            <Box key={Math.random()}>
              <ParagraphOrListGeneral
                typeParagraph={typesTexts.listAndParagraph}
                textList={textSection.listTitle}
                textParagraph={textSection.paragraph}
              />
            </Box>
          ))}
        </List>
      </Box>

      <Box sx={sectionGlobal} component={TYPES_COMPONENTS.section}>
        <Captions
          variant={variantTypography.h2}
          color={typesColors.textSecondary}
          style={subTitle}
        >
          {`${tenthNumberList}. ${captions.tenthTitleSection}`}
        </Captions>
        <List component={TYPES_COMPONENTS.lists.order} sx={contentListTenthSection}>
          {sections.paragraphTenthSection.firstParagrph
            .map((textSection, i) => (sectionsParagraphAndList.includes(i) ? (
              <Box sx={contentSubListsTenthSection} key={Math.random()}>
                <ParagraphOrListGeneral
                  typeParagraph={typesTexts.paragraph}
                  textParagraph={textSection.paragraph}
                />
                <List>
                  {textSection.lists.map((testList) => (
                    <ParagraphOrListGeneral
                      key={Math.random()}
                      typeParagraph={typesTexts.list}
                      textList={testList.list}
                    />
                  ))}
                </List>
              </Box>
            ) : (
              <Box key={Math.random()}>
                <ParagraphOrListGeneral
                  typeParagraph={typesTexts.paragraph}
                  textParagraph={textSection.paragraph}
                />
              </Box>
            )))}
        </List>
      </Box>
    </>
  );
};

export default TermsConditionsLayouts;
