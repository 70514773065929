export const contentForm = {
  width: {
    xs: '100% !important',
    phoneMd: '100% !important',
    md: '58.1% !important',
    lg: '58.1% !important',
  },
  height: 'auto',
};

export const styleTextFieldDefault = (error) => ({
  width: '100% !important',
  height: '46px !important',
  marginBottom: '0.6875rem !important',
  '& > div': {
    height: '100% !important',
    borderRadius: '15px',
    backgroundColor: 'background.secondary',
    '& > input': {
      padding: '1.03125rem 1.6875rem',
    },
    '& > fieldset': {
      borderColor: 'transparent',
      '& > legend': {
        width: error ? '122.783px' : '41.4667px',
      },
    },
  },
  '& > label': {
    fontSize: '1.375rem',
    transformOrigin: 'top left',
    transform: 'translate(25px, 7px) scale(1)',
  },
  '& > label[data-shrink="true"]': {
    transform: 'translate(11px, -12px) scale(0.70)',
    color: 'text.secondary',
  },
});

export const stylesButtonDefault = {
  height: '46px !important',
  width: '100% !important',
  backgroundColor: 'background.textFieldSubscribe',
  borderRadius: '15px',
  color: 'primary.main',
  textTransform: 'capitalize',
  fontSize: '1.375rem',
  '&:hover': {
    backgroundColor: 'background.textFieldSubscribe',
  },
  '&.Mui-disabled': {
    backgroundColor: 'background.textFieldSubscribe',
  },
};
