import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CardItem, HeaderCard } from '../../Components';
import {
  boxTestimonials,
  cardTestimonialsStyle,
  contentCardsTestimonials,
} from './TestimonialsLayoutStyles';
import './TestimonialsLayout.css';
import 'swiper/css';
import { lengthCardsSwiper, TYPES_CARDS } from '../../Common';

const TestimonialsLayout = () => {
  const [dataTestimonials, setDataTestimonials] = useState({
    titleHeader: '',
    textTestimonials: [],
  });

  const listId = (Math.random() / Math.random()).toString();

  useEffect(() => {
    const dataTestimonialsResponse = {
      title: 'Lo que opinan nuestros clientes',
      textTestimonials: [
        {
          nameUserTestimonials: 'Alejandra Gonzales.',
          textTestimonials: '“Muy buen servicio, la persona que vino a realizar mi servicio de limpieza del hogar fue muy amable, todo quedó reluciente y la atención por parte de la App fue muy buena.”',
          textTypeService: 'Limpieza del hogar',
          valueRating: 4,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Alejandra_a2fe07eb00.jpg',
        },
        {
          nameUserTestimonials: 'Mario Alejandro.',
          textTestimonials: '“Excelente servicio y a tiempo. Buena comunicación y buen descuento. Llegaron a tiempo en el servicio de gas.”',
          textTypeService: '🔥  Gas LP',
          valueRating: 5,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Mario_dd2b352e26.jpg',
        },
        {
          nameUserTestimonials: 'Alberto Hernández.',
          textTestimonials: '“Excelente disposición para arreglar problemas.”',
          textTypeService: 'Limpieza del hogar',
          valueRating: 4,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Alberto_1b5479dc02.jpeg',
        },
        {
          nameUserTestimonials: 'Manuel Rodríguez.',
          textTestimonials: '“Muy buen servicio, además que ya no me cuido si realmente le echan o no lo que compro de gas, muy fácil y rápida de usar la aplicación.”',
          textTypeService: '🔥  Gas LP',
          valueRating: 4,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Manuel_f5e41001bb.jpg',
        },
        {
          nameUserTestimonials: 'Arturo Godoy.',
          textTestimonials: '“Buen servicio en atención al cliente, preparados, profesionales, y además muy amables.”',
          textTypeService: '💧 Agua',
          valueRating: 5,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Arturo_f1d49bc486.jpg',
        },
        {
          nameUserTestimonials: 'Martín Moscosa.',
          textTestimonials: '“Gran servicio, eficiencia y calidad.”',
          textTypeService: '🔥  Gas LP',
          valueRating: 4,
          avatar: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Martin_01d8059d65.jpg',
        },
      ],
    };
    setDataTestimonials(prevDataTestimonials => ({
      ...prevDataTestimonials,
      titleHeader: dataTestimonialsResponse.title,
      textTestimonials: dataTestimonialsResponse.textTestimonials,
    }));
  });

  const { textTestimonials, titleHeader } = dataTestimonials;
  const { oneCard, forCard } = lengthCardsSwiper;
  const spaceBetweenCards = 1;
  const classSuggestions = 'container_suggestions';

  return (
    <>
      <Box sx={boxTestimonials('none', 'block')}>
        <HeaderCard title={titleHeader} />
        <Box sx={contentCardsTestimonials}>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            freeMode={true}
            slidesPerView={forCard}
            spaceBetween={spaceBetweenCards}
            className={classSuggestions}
          >
            {textTestimonials.map((testimonial) => (
              <SwiperSlide key={`${listId}${testimonial.nameUserTestimonials}`}>
                <CardItem
                  {...testimonial}
                  stylesTestimonials={cardTestimonialsStyle}
                  typeCard={TYPES_CARDS.testimonials}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      <Box sx={boxTestimonials('block', 'none')}>
        <HeaderCard title={titleHeader} />
        <Box sx={contentCardsTestimonials}>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            freeMode={true}
            slidesPerView={oneCard}
            spaceBetween={spaceBetweenCards}
          >
            {textTestimonials.map((testimonial) => (
              <SwiperSlide key={`${listId}${testimonial.nameUserTestimonials}`}>
                <CardItem
                  {...testimonial}
                  stylesTestimonials={cardTestimonialsStyle}
                  typeCard={TYPES_CARDS.testimonials}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default TestimonialsLayout;
