import { useEffect, useState } from 'react';
import { variantTypography } from '../../Common';
import { Captions, Loader, WarrantyList } from '../../Components';
import {
  captionDoesItWork,
  captionWarrantySection,
  CaptionWarrantySectionSkeleton,
  loaderCustom,
  WarrantyContainer,
  WarrantySection,
} from './styles';

const WarrantyLayout = () => {
  const [warrantySectionData, setWarrantySectionData] = useState({
    title: '',
    list: [],
    loading: false,
  });

  useEffect(() => {
    const warrantySectionDataResponse = {
      title: 'Nuestras garantías',
      list: [
        {
          id: 1,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 105,
                attributes: {
                  name: 'password.png',
                  alternativeText: 'password.png',
                  caption: 'password.png',
                  width: 16,
                  height: 20,
                  formats: null,
                  hash: 'password_7bf0e132f5',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.25,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/password_7bf0e132f5.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:35:25.737Z',
                  updatedAt: '2023-01-26T17:35:25.737Z',
                },
              },
            ],
          },
        },
        {
          id: 2,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 106,
                attributes: {
                  name: 'statistics.png',
                  alternativeText: 'statistics.png',
                  caption: 'statistics.png',
                  width: 20,
                  height: 22,
                  formats: null,
                  hash: 'statistics_7fa742e9b3',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.29,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/statistics_7fa742e9b3.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:35:50.536Z',
                  updatedAt: '2023-01-26T17:35:50.536Z',
                },
              },
            ],
          },
        },
        {
          id: 3,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 107,
                attributes: {
                  name: 'icon-buy.png',
                  alternativeText: 'icon-buy.png',
                  caption: 'icon-buy.png',
                  width: 20,
                  height: 16,
                  formats: null,
                  hash: 'icon_buy_db7fbe248a',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.28,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/icon_buy_db7fbe248a.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:36:26.131Z',
                  updatedAt: '2023-01-26T17:36:26.131Z',
                },
              },
            ],
          },
        },
        {
          id: 4,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 105,
                attributes: {
                  name: 'password.png',
                  alternativeText: 'password.png',
                  caption: 'password.png',
                  width: 16,
                  height: 20,
                  formats: null,
                  hash: 'password_7bf0e132f5',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.25,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/password_7bf0e132f5.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:35:25.737Z',
                  updatedAt: '2023-01-26T17:35:25.737Z',
                },
              },
            ],
          },
        },
        {
          id: 5,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 106,
                attributes: {
                  name: 'statistics.png',
                  alternativeText: 'statistics.png',
                  caption: 'statistics.png',
                  width: 20,
                  height: 22,
                  formats: null,
                  hash: 'statistics_7fa742e9b3',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.29,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/statistics_7fa742e9b3.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:35:50.536Z',
                  updatedAt: '2023-01-26T17:35:50.536Z',
                },
              },
            ],
          },
        },
        {
          id: 6,
          textContent: 'Seguridad en tu compra',
          icon: {
            data: [
              {
                id: 107,
                attributes: {
                  name: 'icon-buy.png',
                  alternativeText: 'icon-buy.png',
                  caption: 'icon-buy.png',
                  width: 20,
                  height: 16,
                  formats: null,
                  hash: 'icon_buy_db7fbe248a',
                  ext: '.png',
                  mime: 'image/png',
                  size: 0.28,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/icon_buy_db7fbe248a.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2023-01-26T17:36:26.131Z',
                  updatedAt: '2023-01-26T17:36:26.131Z',
                },
              },
            ],
          },
        },
      ],
    };
    setWarrantySectionData(prevWarrantySectionData => ({
      ...prevWarrantySectionData,
      ...warrantySectionDataResponse,
    }));
  }, []);

  const {
    loading,
  } = warrantySectionData;

  const loaderSize = '2rem';

  return (
    <WarrantySection>
      <Captions
        variant={variantTypography.h2}
        style={{ ...captionDoesItWork, ...captionWarrantySection }}
      >
        {loading ? <CaptionWarrantySectionSkeleton /> : warrantySectionData.title}
      </Captions>
      {
        loading ? (
          <Loader styles={loaderCustom} progressSize={loaderSize} />
        ) : (
          <WarrantyContainer>
            <WarrantyList
              itemsList={warrantySectionData.list}
            />
          </WarrantyContainer>
        )
      }
    </WarrantySection>
  );
};

export default WarrantyLayout;
