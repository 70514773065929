import { MenuItem, Select } from '@mui/material';
import { SELECTORS_TYPES } from '../../Common';
import { KeyboardArrowDownIconCustom } from '../ContentSelectors/styles';
import { InputSelect } from './styles';

const {
  labels,
} = SELECTORS_TYPES;

const ProductConfigurationSelect = ({
  value,
  handleSelectors,
  handleMenuItem,
  selectorsStyles,
  data,
  handleRef,
  defaultValue,
}) => {
  return (
    <Select
      value={!value ? '' : value}
      onChange={handleSelectors}
      input={<InputSelect />}
      IconComponent={KeyboardArrowDownIconCustom}
      sx={selectorsStyles}
      displayEmpty
      inputProps={labels.withoutLabel}
    >
      <MenuItem
        ref={handleRef}
        value=''
        disabled
      >
        { defaultValue }
      </MenuItem>
      {
            data?.map(item => {
              return (
                <MenuItem
                  ref={handleRef}
                  key={Math.random()}
                  value={item.name}
                  onClick={handleMenuItem}
                  name={item.registryUuid}
                >
                  { item.name }
                </MenuItem>
              );
            })
          }
    </Select>
  );
};

export default ProductConfigurationSelect;
