const errorMessages = {
  purchaseMin: 'El monto mínimo de compra debe ser de',
  invalidPhone: 'Número de teléfono inválido',
  maxCarts: '¡Ups! Ya haz creado el límite de carritos para esta vertical!',
  requiredInput: 'Campo obligatorio',
  isEmail: 'Debe ser un correo válido',
  isNumeric: 'Se aceptan solo números',
  serviceNotFount: 'El número de folio capturado no se encuentra en el sistema',
  alreadyAcceptedService: 'El número de folio ya cuenta con una factura generada',
  expiredBilling: 'No es posible facturar este número de folio',
  incorrectData: 'Información incorrecta',
  coverageNotFound: 'No se encuentra la covertura',
};

export {
  errorMessages,
};
