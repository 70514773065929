import React from 'react';
import moment from 'moment';
import { STATUS_SERVICES, TYPES_SERVICES } from '../Common';
import { axiosService } from '../Configs/axios';
import { DateFormats, ServiceTracking } from '../utils';

const trackingService = React.createContext('trackingService');
const getDataServiceTrackingContent = async () => {
  const dataServiceTrackingContent = {
    id: 1,
    attributes: {
      title: 'Realiza el seguimiento de tu pedido',
      subTitle: 'Ingresa tu número, correo o número de folio de tu pedido para ver el estatus.',
      placeholderInput: 'Número, correo o número de folio',
      searchButton: 'Buscar pedido',
      cleanSearchButton: 'Limpiar búsqueda',
      evidenceTitle: 'Evidencias de tu servicio',
      titleWithOutEvidence: 'Aún no tienes evidencias',
      whatsAppButton: {
        id: 1,
        whatsAppText: 'Ayuda por WhatsApp',
        whatsAppImage: {
          data: [
            {
              id: 83,
              attributes: {
                name: 'WhatsAppLogo.png',
                alternativeText: 'WhatsApp',
                caption: 'WhatsAppLogo.png',
                width: 28,
                height: 28,
                formats: null,
                hash: 'Whats_App_Logo_b1b76bb5f4',
                ext: '.png',
                mime: 'image/png',
                size: 0.63,
                url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Whats_App_Logo_b1b76bb5f4.png',
                previewUrl: null,
                provider: 'aws-s3',
                provider_metadata: null,
                createdAt: '2022-09-26T20:16:07.902Z',
                updatedAt: '2023-02-10T15:53:16.841Z',
              },
            },
          ],
        },
      },
      warrantyBanner: {
        id: 1,
        bannerText: 'Garantías de **litros completos** solo en Okboy',
        imagesApp: {
          id: 9,
          imageAndroid: {
            data: [
              {
                id: 15,
                attributes: {
                  name: 'GooglePlay.png',
                  alternativeText: 'GooglePlay.png',
                  caption: 'GooglePlay.png',
                  width: 330,
                  height: 112,
                  formats: {
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_Google_Play_a31893fe17.png',
                      hash: 'thumbnail_Google_Play_a31893fe17',
                      mime: 'image/png',
                      name: 'thumbnail_GooglePlay.png',
                      path: null,
                      size: 12.1,
                      width: 245,
                      height: 83,
                    },
                  },
                  hash: 'Google_Play_a31893fe17',
                  ext: '.png',
                  mime: 'image/png',
                  size: 3.58,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Google_Play_a31893fe17.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T19:39:15.990Z',
                  updatedAt: '2022-08-23T19:39:15.990Z',
                },
              },
            ],
          },
          imageIOS: {
            data: [
              {
                id: 16,
                attributes: {
                  name: 'AppStore.png',
                  alternativeText: 'AppStore.png',
                  caption: 'AppStore.png',
                  width: 332,
                  height: 112,
                  formats: {
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_App_Store_05015dfc5f.png',
                      hash: 'thumbnail_App_Store_05015dfc5f',
                      mime: 'image/png',
                      name: 'thumbnail_AppStore.png',
                      path: null,
                      size: 12.39,
                      width: 245,
                      height: 83,
                    },
                  },
                  hash: 'App_Store_05015dfc5f',
                  ext: '.png',
                  mime: 'image/png',
                  size: 3.8,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/App_Store_05015dfc5f.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T19:39:51.831Z',
                  updatedAt: '2022-08-23T19:39:51.831Z',
                },
              },
            ],
          },
        },
      },
      statusServices: {
        id: 1,
        status: [
          {
            id: 1,
            textStatus: 'Tu solicitud ha sido recibida.',
          },
          {
            id: 2,
            textStatus: 'El aliado acepto tu orden',
          },
          {
            id: 3,
            textStatus: 'El aliado va en camino a tu hogar',
          },
          {
            id: 4,
            textStatus: 'Toc Toc, ¡Estamos afuera!',
          },
        ],
      },
      orderInformation: {
        id: 1,
        title: 'Información de tu pedido',
        addresTitle: 'Dirección de Envío',
        orderTitle: 'Tu pedido',
      },
      score: {
        id: 1,
        calificatedButton: 'Calificar',
        modalTitle: '¿Cómo estuvo tu servicio?',
        labelText: 'Comentanos',
        placeholderText: 'Déjanos un comentario por favor',
        textareaTitle: '¿Qué fue lo que pasó?',
        sendButton: 'Enviar Comentarios',
      },
    },
  };
  const {
    title,
    subTitle,
    placeholderInput,
    searchButton,
    cleanSearchButton,
    statusServices,
    orderInformation,
    whatsAppButton,
    warrantyBanner,
    evidenceTitle,
    titleWithOutEvidence,
    score,
  } = dataServiceTrackingContent.attributes;
  const dataStatus = statusServices.status;
  const whatsAppImage = whatsAppButton.whatsAppImage.data[0] || {};
  const { imagesApp } = warrantyBanner;
  const dataIosImage = imagesApp.imageIOS.data[0] || {};
  const dataAndroidImage = imagesApp.imageAndroid.data[0] || {};
  const imageIOS = {
    image: dataIosImage.attributes.url,
    alternativeText: dataIosImage.attributes.alternativeText,
  };
  const imageAndroid = {
    image: dataAndroidImage.attributes.url,
    alternativeText: dataAndroidImage.attributes.alternativeText,
  };

  return {
    title,
    subTitle,
    placeholderInput,
    button: {
      searchButton,
      cleanSearchButton,
    },
    dataStatus,
    orderInformation: {
      title: orderInformation.title,
      addresTitle: orderInformation.addresTitle,
      orderTitle: orderInformation.orderTitle,
    },
    whatsAppButton: {
      text: whatsAppButton.whatsAppText,
      image: {
        src: whatsAppImage.attributes.url,
        alternativeText: whatsAppImage.attributes.alternativeText,
      },
    },
    banner: {
      text: warrantyBanner.bannerText,
      imagesApp: {
        imageIOS,
        imageAndroid,
      },
    },
    evidenceTitle,
    titleWithOutEvidence,
    score,
  };
};

const { getDateDelivery } = ServiceTracking;
const {
  formatDate,
  formatHour,
} = DateFormats;

const getDetailServiceTracking = async (token) => {
  if (trackingService.Provider.value) {
    return trackingService.Provider.value;
  }
  const dataTracking = !!token && await axiosService.get(`/services/detail?token=${token}`);
  const data = dataTracking.data || {};
  const {
    deliveryDate,
    deliveryTime,
    status,
    address,
    details,
    evidence,
    score,
    registryUuid,
    serviceTypeDetails,
    folio,
    customStatusId,
    customStatus,
    paymentTypeId,
    serviceFee,
    discount,
    company,
    total,
    amount,
    tip,
    provider,
    scoreService,
    scoreComment,
    finishedDate,
  } = data;
  company.phoneNumber = '';
  const dateData = deliveryDate ? getDateDelivery(deliveryDate) : null;
  let deliveryTimeFormatTwentyFourHours = deliveryTime ? Number(deliveryTime.split(':')[0]) : null;
  let dd = 'am';
  const twelveHoursFormat = 12;
  if (deliveryTimeFormatTwentyFourHours >= twelveHoursFormat) {
    deliveryTimeFormatTwentyFourHours = (deliveryTimeFormatTwentyFourHours - twelveHoursFormat)
      .toString();
    dd = 'pm';
  }
  const deliveryTimeFormatTwelveHours = `${deliveryTimeFormatTwentyFourHours} ${dd}`;
  const {
    municipality,
    street,
    zipcode,
  } = address || {};
  const addressCustom = `${municipality}, ${street}, ${zipcode}`;
  const evidenceImages = !evidence
    ? evidence : [...(evidence?.before ? [{ image: evidence?.before?.photo, type: 'ANTES', date: evidence?.before?.timestamp }] : []), ...(evidence?.after ? [{ image: evidence?.after?.photo, type: 'DESPUÉS', date: evidence?.after?.timestamp }] : []), ...(evidence?.ticket ? [{ image: evidence?.ticket?.photo, type: 'TICKET', date: evidence?.ticket?.timestamp }] : [])];
  const isDigitalService = serviceTypeDetails?.type?.toLowerCase() === TYPES_SERVICES.digital;
  const isCalificated = status?.id === STATUS_SERVICES.FINISHED.ID && !score && !isDigitalService;
  const cutsomStatusData = customStatus && customStatus?.map(obj => ({
    textStatus: obj?.labelText || obj?.customStatusText,
    date: obj?.event?.date || finishedDate ? formatDate(obj?.event?.date || finishedDate, 'yyyy-MM-DD') : '',
    hour: obj?.event?.date || finishedDate ? obj?.event?.date ? moment.utc(obj?.event?.date).local().format('HH:mm') : formatHour(finishedDate) : '',
  }));
  const customStatusCurrents = customStatus.map((obj, index) => ({ ...obj, index }))
    .filter((obj) => obj.current);
  const statusCurrent = !customStatusId && customStatusCurrents.length
    ? customStatusCurrents[customStatusCurrents.length - 1]
    : customStatusCurrents.length
      ? customStatusCurrents.find(obj => obj.id === customStatusId)
      : customStatus.sort((a, b) => b.order - a.order)[0];

  const dataServiceTracking = {
    date: {
      dateData,
      deliveryTime: deliveryTimeFormatTwelveHours,
    },
    status,
    statusCurrent,
    addressCustom,
    details,
    evidence: evidenceImages,
    isCalificated,
    registryUuid,
    folio: folio ? `#${folio}` : '',
    customStatusId,
    cutsomStatus: cutsomStatusData,
    customStatusIdActive: statusCurrent?.index,
    address,
    deliveryDate: formatDate(deliveryDate),
    deliveryTime,
    paymentTypeId,
    serviceFee,
    discount,
    total,
    amount,
    tip,
    provider,
    score,
    scoreService,
    scoreComment,
    company,
    logo: company?.logo,
    dataTracking: data,
  };
  trackingService.Provider.value = dataServiceTracking;
  return dataServiceTracking;
};

const scoreProvider = async (score) => {
  return axiosService.post('/provider/service/score', {
    serviceUuid: score.serviceUuid,
    score: score.score,
    comment: score.comment,
    scoreService: score.scoreOperator,
    type: score.type,
  });
};

const downloadServiceTicket = async (serviceData) => {
  return axiosService.post('checkout/ticket', serviceData);
};

const getPaymentMethods = () => {
  return axiosService.get('/customers/paymentMethods');
};

export {
  getDataServiceTrackingContent,
  getDetailServiceTracking,
  scoreProvider,
  downloadServiceTicket,
  getPaymentMethods,
};
