import {
  styled,
} from '@mui/material';

export const FooterParagraph = styled('p')(() => ({
  color: 'white',
  fontFamily: 'Mulish, sans-serif',
  marginTop: '32px',
  marginBottom: '32px',
  fontSize: '16px',
  lineHeight: '178%',
}));

export const FooterCopyRightsParagraph = styled('p')(({ theme }) => ({
  color: 'rgba(255, 255, 255, .7);',
  fontFamily: 'Mulish, sans-serif',
  marginBottom: '0',
  fontSize: '16px',
  lineHeight: '150%',
  [theme.breakpoints.down(479)]: {
    textAlign: 'center',
  },
}));

export const FooterSocialMedia = styled('p')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.down(767)]: {
    marginTop: '15px',
  },
}));

export const FooterParagraphStrong = styled('strong')(() => ({
  fontWeight: 'bold',
}));

export const FooterParagraphEm = styled('em')(() => ({
  fontWeight: '400',
  fontStyle: 'normal',
  textDecoration: 'none',
}));
