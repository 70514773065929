export const layoutGeneralStyles = {
  padding: {
    xs: '0px', phoneMd: '0px', sm: '0px', md: '0px', lg: '0px', xl: '0px',
  },
  '& > div:nth-of-type(4)': {
    height: {
      xs: '343px', phoneMd: '343px', sm: '343px', md: '281px', lg: '281px', xl: '281px',
    },
    '& > div>first-of-type': {
      '& > div': {
        marginTop: {
          xs: '66px', phoneMd: '66px', sm: '66px', md: '0px', lg: '0px', xl: '0px',
        },
      },
    },
    '& > div:last-of-type': {
      '& > div': {
        display: 'flex',
        justifyContent: 'end',
        '& div': {
          width: {
            xs: '100%', phoneMd: '100%', sm: '100%', md: '39%', lg: '39%', xl: '39%',
          },
          marginTop: {
            xs: '-22px', phoneMd: '-22px', sm: '-22px', md: '0px', lg: '0px', xl: '0px',
          },
        },
      },
    },
  },
};
