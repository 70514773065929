const DarkWaddiLogo = (props, { width, height }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width || 79}
    height={height || 17}
    fill='none'
    {...props}
  >
    <g fill='#462687' clipPath='url(#a)'>
      <path d='M42.089 5.807V6.83a5.503 5.503 0 0 0-3.207-1.024 5.571 5.571 0 0 0-5.57 5.577 5.571 5.571 0 0 0 5.57 5.577 5.503 5.503 0 0 0 3.207-1.024v1.004h2.522V5.807h-2.522Zm-3.207 8.688a3.108 3.108 0 0 1-3.108-3.111 3.108 3.108 0 1 1 6.216 0c0 1.72-1.39 3.111-3.108 3.111ZM54.42.02V6.8a5.556 5.556 0 0 0-3.157-.985 5.572 5.572 0 0 0-5.57 5.578 5.572 5.572 0 0 0 5.57 5.577 5.556 5.556 0 0 0 3.158-.984v.964h2.522V.02H54.42Zm-3.147 14.474a3.108 3.108 0 0 1-3.108-3.111 3.108 3.108 0 1 1 6.216 0c0 1.72-1.4 3.111-3.108 3.111ZM67.22.01v6.78a5.556 5.556 0 0 0-3.158-.984 5.572 5.572 0 0 0-5.57 5.577 5.572 5.572 0 0 0 5.57 5.577 5.556 5.556 0 0 0 3.157-.984v.964h2.522V.01H67.22Zm-3.148 14.485a3.108 3.108 0 0 1-3.108-3.112 3.108 3.108 0 1 1 6.216 0c-.01 1.71-1.4 3.112-3.108 3.112ZM11.428 15.946l4.935-6.72a1.264 1.264 0 0 1 2.035 0l4.548 6.173.318.428c1.171 1.61 3.634 1.402 4.517-.388l7.08-14.256L32.597 0l-6.404 12.884a1.063 1.063 0 0 1-1.807.16L16.532 2.365l-.229-.308a2.38 2.38 0 0 0-3.594-.278L6.93 7.814a1.592 1.592 0 0 1-2.432-.159l-2.403-3.28L0 5.854l7.417 10.101c.993 1.352 3.018 1.342 4.011-.01Zm-1.201-2.684a.995.995 0 0 1-1.599 0l-.01-.02-1.46-1.978 6.564-6.83.03-.03a.77.77 0 0 1 1.171.1l.874 1.163-5.57 7.595ZM74.606 5.785h-2.482V16.99h2.482V5.785ZM77.496 14.654c-.646 0-1.162.527-1.162 1.164a1.17 1.17 0 0 0 1.162 1.163 1.17 1.17 0 0 0 1.161-1.164c0-.636-.516-1.163-1.161-1.163Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h78.737v17H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default DarkWaddiLogo;
