export const contentHelpSections = {
  width: {
    xs: '95%', phoneMd: '95%', md: '100%', lg: '100%', xl: '100%',
  },
  height: {
    xs: '418px', phoneMd: '418px', md: '281px', lg: '281px', xl: '281px',
  },
  margin: {
    xs: '0px auto', sm: '0px auto', md: '0px', lg: '0px', xl: '0px',
  },
  marginBottom: {
    xs: '104px', phoneMd: '104', sm: '104px', md: '64px', lg: '64px', xl: '64px',
  },
  borderRadius: '30px',
  backgroundColor: 'primary.main',
  display: 'flex',
  flexDirection: {
    xs: 'column', phoneMd: 'column', md: 'row', lg: 'row', xl: 'row',
  },
  '& > div': {
    flex: '1 1',
  },
};

export const contentCaptions = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div': {
    width: {
      xs: '82.2%', phoneMd: '82.2%', md: '64.2%', lg: '64.2%', xl: '64.2%',
    },
    height: {
      xs: 'auto', phoneMd: 'auto', sm: 'auto', md: '43.2%', lg: '43.2%', xl: '43.2%',
    },
    marginLeft: {
      xs: '-16px', phoneMd: '-16px', sm: '-16px', md: '-53px', lg: '-53px', xl: '-53px',
    },
    marginTop: {
      xs: '58px', phoneMd: '58px', sm: '58px', md: '-7px', lg: '-7px', xl: '-7px',
    },
  },
};

export const boxCaptions = {
  '& > h3': {
    width: {
      laptop: '110%', xl: '100%',
    },
    fontSize: {
      xs: '2rem', phoneMd: '2rem', sm: '2rem', md: '2.5rem', lg: '2.5rem', xl: '2.5rem',
    },
    fontWeight: '800',
    marginBottom: '16px',
  },
  '& > h5': {
    fontSize: {
      xs: '1.25rem', phoneMd: '1.25rem', sm: '1.25rem', md: '1.375rem', lg: '1.375rem', xl: '1.375rem',
    },
    fontWeight: '800',
  },
};

export const contentButtons = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: {
      xs: '87.5%', phoneMd: '87.5%', sm: '87.5%', md: '82.2%', laptop: '95.2%', lg: '82.2%', xl: '82.2%',
    },
    height: {
      xs: '93.2%', phoneMd: '93.2%', sm: '93.2%', md: '43.2%', lg: '43.2%', xl: '43.2%',
    },
    marginLeft: {
      xs: '4px', phoneMd: '4px', sm: '4px', md: '-47px', lg: '-47px', xl: '-47px',
    },
    marginTop: {
      xs: '25px', phoneMd: '25px', sm: '25px', md: '-3px', lg: '-3px', xl: '-3px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: {
        xs: 'column', phoneMd: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row',
      },
      justifyContent: {
        xs: 'space-evenly', phoneMd: 'space-evenly', sm: 'space-evenly', md: 'initial', lg: 'initial', xl: 'initial',
      },
      gap: {
        xs: '5px', phoneMd: '5px', sm: '5px', md: '36px', lg: '36px', xl: '36px',
      },
      width: '100%',
      height: {
        xs: '70%', phoneMd: '70%', sm: '70%', md: '46px', lg: '46px', xl: '46px',
      },
      '& > a': {
        display: 'inline-block',
        textDecoration: 'none',
        backgroundColor: '#fff',
        borderRadius: '15px',
        padding: '8px 20px 8px 20px',
        fontSize: '1.375rem',
        fontWeight: '900',
        color: '#4433E0',
        textAlign: 'center',
      },
      '& > a:first-of-type': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      },
    },
  },
};
