import { inputsVariants } from '../../../../Common';
import { PreOrderInputStyles } from './styles';

const PreOrderInput = ({
  type,
  placeholder,
  handleChange,
  value,
  name,
  styles,
  targetRef,
  error,
  helperText,
  inputProps,
}) => {
  return (
    <PreOrderInputStyles
      type={type}
      placeholder={placeholder}
      id={inputsVariants.basicId}
      variant={inputsVariants.outlined}
      required
      onChange={handleChange}
      value={value}
      name={name}
      sx={styles}
      onBlur={handleChange}
      inputRef={targetRef}
      error={error}
      helperText={error && helperText}
      inputProps={inputProps}
    />
  );
};

export default PreOrderInput;
