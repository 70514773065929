import { ProductPrices } from '../../Components';
import { subTotalStyles, totalStyles } from '../ServiceProductLayout/OrderConfigurationLayout/styles';
import { PricesContainer } from '../ServiceProductLayout/styles';

const SummaryPreOrderLayout = ({
  prices,
  pricesContainerStyles,
  textStyles,
}) => {
  return (
    <PricesContainer sx={pricesContainerStyles}>
      <ProductPrices
        stylesCustom={{ ...subTotalStyles, ...textStyles?.subtotal }}
        label={prices?.subTotal.label}
        priceText={prices?.subTotal.price}
      />
      <ProductPrices
        label={prices?.serviceFee.label}
        priceText={prices?.serviceFee.price}
        stylesCustom={textStyles?.regularText}
      />
      <ProductPrices
        label={prices?.dynamicRate.label}
        priceText={prices?.dynamicRate.price}
        stylesCustom={textStyles?.regularText}
      />
      <ProductPrices
        label={prices?.iva.label}
        priceText={prices?.iva.price}
        stylesCustom={textStyles?.regularText}
      />
      <ProductPrices
        label={prices?.total.label}
        priceText={prices?.total.price}
        stylesCustom={{ ...totalStyles, ...textStyles?.totalText }}
      />
    </PricesContainer>
  );
};

export default SummaryPreOrderLayout;
