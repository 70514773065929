import { useState, useEffect } from 'react';
import InstructionsLayout from '../InstructionsLayout/InstructionsLayout';

const LayoutInstructionsService = () => {
  const [dataInstructions, setdataInstructions] = useState({
    data: {
      listInstructions: [],
      backgroundImageDesktop: '',
      backgroundImageMobile: '',
    },
    loading: true,
  });

  useEffect(() => {
    const dataInstructionSection = {
      listInstruccions: [
        {
          text: 'Pide y agenda tu servicio, el día y hora que más te convenga.',
        },
        {
          text: 'Te conectamos con los mejores proveedores.',
        },
        {
          text: 'Elige pagar como quieras, estés o no en tu hogar.',
        },
        {
          text: 'Sigue el trayecto de tu servicio y conoce el nombre y foto del aliado.',
        },
        {
          text: 'Disfruta de tu servicio con garantías, si algo sale mal, lo resolvemos.',
        },
      ],
      backgroundImageDesktop: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/BANNER_2_ccf8751591.jpg',
      backgroundImageMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Background_Instruccions_Mobile_e080a19dcf.jpg',
    };
    setdataInstructions(prevDataInstructions => ({
      ...prevDataInstructions,
      data: {
        listInstructions: dataInstructionSection.listInstruccions,
        backgroundImageDesktop: dataInstructionSection.backgroundImageDesktop,
        backgroundImageMobile: dataInstructionSection.backgroundImageMobile,
      },
    }));
  }, []);

  const {
    data: {
      listInstructions,
      backgroundImageDesktop,
      backgroundImageMobile,
    },
  } = dataInstructions;

  return (
    <InstructionsLayout
      listInstructions={listInstructions}
      backgroundImageDesktop={backgroundImageDesktop}
      backgroundImageMobile={backgroundImageMobile}
    />

  );
};

export default LayoutInstructionsService;
