/* eslint-disable no-undef */
import { Box, Container } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { pathBlogpost } from '../../Common';
import { pathViews } from '../../Common/messages';
import { Validations } from '../../utils/validations';
import {
  containerHeaderStyleBlog,
  continerHeaderStyleLanding,
  layoutHeaderStyle,
  layoutHeaderStyleBlog,
  loaderLectureBlogPost,
} from './LayoutHeaderStyle';

const LayoutHeader = ({ children }) => {
  const { pathname } = useLocation();
  const loaderRef = useRef();
  const widthLaoderElement = 'width-loader';

  useEffect(() => {
    const widthLoader = document.getElementById(widthLaoderElement)?.getAttribute('id');
    const loader = loaderRef.current;
    const handleLectureLoader = () => {
      const heightViewport = document.documentElement?.clientHeight;
      const heightTotalScroll = document.documentElement.scrollHeight;
      const { scrollY } = window;
      const heightTotal = heightTotalScroll - heightViewport;
      const scrollLoad = Math.round((scrollY * 100) / heightTotal);
      loader?.style.setProperty(`--${widthLoader}`, `${scrollLoad}%`);
    };

    if (pathname.slice(0, pathBlogpost) === `${pathViews.blog.blogPost}/`) {
      window.addEventListener('scroll', handleLectureLoader);
    }
    return () => {
      window.removeEventListener('scroll', handleLectureLoader);
    };
  }, [
    loaderRef.current,
    document.documentElement.scrollHeight,
  ]);

  const { isPathBlogDistinc, isPathBlogEqual } = Validations;
  return (
    <Box sx={
        isPathBlogDistinc(pathname, pathViews.blog.app)
          ? layoutHeaderStyle
          : { ...layoutHeaderStyle, ...layoutHeaderStyleBlog }
        }
    >
      <Container fontSize='1.2rem' sx={!(isPathBlogDistinc(pathname, pathViews.blog.app)) ? containerHeaderStyleBlog : continerHeaderStyleLanding}>
        { children }
      </Container>
      {
          isPathBlogEqual(pathname, `${pathViews.blog.blogPost}/`)
          && <Box id={widthLaoderElement} ref={loaderRef} sx={loaderLectureBlogPost} />
        }
    </Box>
  );
};

export default LayoutHeader;
