import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  useLocation,
} from 'react-router-dom';
import './App.css';
import { AppStyles } from './AppStyles';
import { LayoutFooter } from './App/Layouts';
import {
  CategoriesContextData,
  LandingIdProvider,
  LoaderProvider,
  SectionScroll,
  ServiceProductProvider,
} from './App/Contexts';
import firebase from './App/Configs/firebase';
import LandingRoute from './App/routes/Landing';
import {
  FooterDivider,
  FooterLeftContain,
  FooterTopWrapper,
  FooterWrapper,
  FooterWrapperCopyRigths,
}
  from './App/Components/FooterWaddi/styles';
import FooterTracking from './App/Components/FooterWaddi/FooterTracking';
import FooterCopyRightsTracking from './App/Components/FooterWaddi/FooterCopyRightsTracking';
import FooterSocialMediaTracking from './App/Components/FooterWaddi/FooterSocialMediaTracking';

const App = () => {
  const [scrollSectionHome, setScrollSectionHome] = useState('');
  const { pathname } = useLocation();
  const scrollSectionMemo = useMemo(
    () => ({ scrollSectionHome, setScrollSectionHome }),
    [scrollSectionHome],
  );

  useEffect(() => {
    firebase.logEvent(firebase.analytics, 'page_view', {
      page_path: pathname,
    });
  }, [pathname]);
  return (
    <Box sx={AppStyles}>
      <LoaderProvider>
        <SectionScroll.Provider value={scrollSectionMemo}>
          <LandingIdProvider>
            <ServiceProductProvider>
              <CategoriesContextData />
              <LandingRoute />
            </ServiceProductProvider>
            <LayoutFooter>
              <FooterWrapper>
                <FooterTopWrapper>
                  <FooterLeftContain>
                    <FooterTracking />
                  </FooterLeftContain>
                </FooterTopWrapper>
                <FooterDivider />
                <FooterWrapperCopyRigths>
                  <FooterCopyRightsTracking />
                  <FooterSocialMediaTracking />
                </FooterWrapperCopyRigths>
              </FooterWrapper>
            </LayoutFooter>
          </LandingIdProvider>
        </SectionScroll.Provider>
      </LoaderProvider>
    </Box>
  );
};

export default App;
