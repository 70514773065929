const placeHolder = 'Número, correo o número de folio';

const statusSteppers = {
  Creado: 0,
  Activo: 0,
  Asignado: 1,
  Aceptado: 1,
  'En Camino': 2,
  Llego: 3,
  Terminado: 4,
};

const scoreMessages = {
  subTitleOne: '¿Nuestro servicio cumplió sus expectativas?',
  subTitleTwo: '¿Qué tan satisfecho esta con el nivel de atención del operador?',
  placeHolders: {
    textareaComments: 'Escríbenos tu comentario.',
  },
};

const pricesMessage = {
  currency: {
    pesos: '$',
    total: 'MXN',
  },
  totalText: 'Total:',
};

const messagesTracking = {
  statusTitle: 'Estatus:',
  showDetailText: {
    show: 'Ver detalle',
    hidden: 'Ocultar',
  },
  statusDate: {
    date: 'Fecha:',
    time: 'Hora:',
  },
  serviceInformation: {
    date: 'Fecha de entrega: ',
    time: 'Hora de entrega: ',
    textTestService: 'Recarga de gas estacionario',
    quantity: 'Cantidad: ',
    paymentMethod: 'Forma de pago:',
    amountSub: 'Sub Total:',
    serviceFee: 'Comisión de pedido:',
    discount: 'Descuento:',
    tip: 'Propina:',
    providerInformation: 'Información del repartidor',
    billingUrl: 'Liga de facturación: ',
  },
  addresTitle: 'Punto de entrega',
  reference: 'Referencias:',
  whatsAppTitle: '¿Tienes algún problema con tu pedido?',
  priceDefault: '0.00',
  messageSuccess: 'Gracias por calificarnos, tu opinión es muy importante para nosotros',
  messageError: 'No se ha podido enviar tu comentario',
};

export {
  placeHolder,
  statusSteppers,
  pricesMessage,
  messagesTracking,
  scoreMessages,
};
