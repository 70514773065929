import { Box, CircularProgress } from '@mui/material';
import {
  rowFourForm,
  scores,
  TYPES_BUTTONS,
  variantTypography,
} from '../../Common';
import { ButtonPrimary, Captions } from '../../Components';
import { ServiceTraking } from '../../Common/messages';
import {
  captionScore,
  subTitleScore,
  subTitleScoreSecond,
  ButtonScoreBox,
  circularProgressStyles,
  CommentForm,
  FormContainer,
  ScoreBox,
  ScoreModalContainer,
  sendScoreButton,
  StyledScore,
} from './styles';

const { scoreMessages } = ServiceTraking;

const LayoutScore = ({
  modalTitle,
  placeholderText,
  sendButton,
  handleScore,
  valueScore,
  scoreOperator,
  handleComment,
  handleScoreSend,
  valueComent,
  loading,
  isDisabled,
  detailScoreTracking,
}) => {
  const disabledValid = (
    !valueComent && valueScore <= scores.starsFour
    && !valueComent && scoreOperator <= scores.starsFour
  ) || !valueScore === scores.starsFive;
  const { scoreDetail, scoreSeviceDetail, scoreCommentDetail } = detailScoreTracking;
  const scoreDetailValue = Number(scoreDetail || '0');
  const scoreSeviceDetailValue = Number(scoreSeviceDetail || '0');
  const comments = scoreCommentDetail || '';
  return (

    <ScoreModalContainer>
      <Captions
        variant={variantTypography.h2}
        style={captionScore}
      >
        {modalTitle}
      </Captions>
      <Box>
        <Captions
          variant={variantTypography.h2}
          style={subTitleScore}
        >
          {scoreMessages?.subTitleOne}
        </Captions>
      </Box>
      <ScoreBox>
        <StyledScore
          name='score'
          disabled={scoreDetailValue !== 0}
          value={scoreDetailValue !== 0 ? scoreDetailValue : valueScore}
          onChange={(event, newValue) => handleScore(event, newValue)}
        />
      </ScoreBox>
      <Box>
        <Captions
          variant={variantTypography.h2}
          style={subTitleScoreSecond}
        >
          {scoreMessages?.subTitleTwo}
        </Captions>
      </Box>
      <ScoreBox>
        <StyledScore
          name='scoreOperator'
          disabled={scoreSeviceDetailValue !== 0}
          value={scoreSeviceDetailValue !== 0 ? scoreSeviceDetailValue : scoreOperator}
          onChange={(event, newValue) => handleScore(event, newValue)}
        />
      </ScoreBox>
      <FormContainer>
        <Box sx={{ width: { xs: '90%', sm: 'initial' } }}>
          <CommentForm
            id='filled-multiline-static'
            multiline
            disabled={comments !== ''}
            rows={rowFourForm}
            placeholder={placeholderText}
            onChange={handleComment}
            value={comments !== '' ? comments : valueComent}
          />
        </Box>
      </FormContainer>
      <ButtonScoreBox>
        <ButtonPrimary
          handleToggle={handleScoreSend}
          typeButton={TYPES_BUTTONS.button}
          styles={sendScoreButton}
          disabled={isDisabled || disabledValid}
        >
          {loading ? <CircularProgress sx={circularProgressStyles} /> : sendButton}
        </ButtonPrimary>
      </ButtonScoreBox>
    </ScoreModalContainer>

  );
};

export default LayoutScore;
