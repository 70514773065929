import { TextField } from '@mui/material';
import { stylesInput } from './styles';

const Input = ({
  styles,
  placeholder,
}) => {
  return (
    <TextField
      sx={stylesInput || styles}
      placeholder={placeholder}
    />
  );
};

export default Input;

Input.defaultProps = {
  styles: null,
};
