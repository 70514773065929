export const containerCaptionAndCards = {
  paddingTop: '7.375rem;',
  backgroundColor: 'menu.primary',
};

export const containCaptionAndCards = {
  maxWidth: {
    xs: '343px',
    sm: '343px',
    md: '1190px',
    lg: '1190px',
  },
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

export const contentCaptionAndDescription = {
  width: '100%',
  maxWidth: '440px',
  height: 'auto',
  margin: '0 auto',
  marginBottom: '4rem',
  '& > h1': {
    width: '100%',
    marginBottom: '1rem',
    color: 'primary.main',
    fontWeight: '800',
    fontSize: '2.5rem',
    lineHeight: '2.8125rem',
    textAlign: 'center',
  },
  '& > section': {
    width: '100%',
    '& > p': {
      width: '100%',
      color: 'text.secondary',
      fontWeight: '500',
      fontSize: '1.375rem',
      lineHeight: '1.878125rem',
      textAlign: 'center',
    },
  },
};

export const styleCaptionSkeleton = {
  backgroundColor: 'primary.main',
};

export const styleDescriptionSkeleton = {
  display: 'flex',
  justifyContent: 'center',
};

export const cotainerBannerDownloadCategories = {
  paddingTop: '4rem',
  paddingBottom: '4rem',
  backgroundColor: 'secondary.main',
};

export const containBannerDownloadCategories = {
  maxWidth: {
    xs: '343px',
    sm: '343px',
    md: '1190px',
    lg: '1190px',
  },
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  '& > div': {
    marginBottom: '0px',
  },
};

export const styleContentCard = {
  spacing: {
    row: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
    column: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
  },
  style: {
    width: '100% !important',
    margin: '0px !important',
    '& a': {
      textDecoration: 'none',
    },
    '& > div': {
      padding: '0 !important',
    },
  },
};

export const styleContentSkeletonCategories = {
  spacing: {
    row: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
    column: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
  },
  style: {
    width: '100% !important',
    margin: '0px !important',
    '& a': {
      textDecoration: 'none',
    },
    '& > div': {
      padding: '0 !important',
    },
  },
};

export const contentColumnThreeStyle = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-between !important',
  flexDirection: {
    xs: 'column !important',
    sm: 'column !important',
    md: 'row !important',
    lg: 'row !important',
  },
  flexWrap: 'wrap',
  marginBottom: '2.5rem',
  '& > div': {
    height: '424px',
  },
};
