export const contentLogos = {
  width: '99%',
  height: '158px',
  borderRadius: '20px',
  backgroundColor: 'background.secondary',
  marginTop: '16px',
  paddingTop: '.3px',
  '& > div': {
    width: '90%',
    margin: '0px auto',
  },
  '& > div:first-of-type': {
    marginTop: '24px',
    '& > div:first-of-type': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  '& > div:last-of-type': {
    width: '100%',
    display: 'flex',
    gap: '9px',
    height: '35px',
    marginTop: '26px',
    paddingTop: '6px',
    justifyContent: 'space-evenly',
    '& svg': {
      transform: 'scale(1.7)',
    },
  },
};

export const styleAppStore = {
  width: '8.75rem',
  marginRight: '-1px',
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%',
      sm: '88%',
      md: '100%',
      lg: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: '8.75rem',
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%',
      sm: '88%',
      md: '100%',
      lg: '100%',
    },
  },
};
