const Facebook = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 12.004h-1.867v-2H13V9a3 3 0 0 1 3-3h1v2h-1a1 1 0 0 0-1 1v1.004h1.982v2H15V18h-2v-5.996Z'
        fill='#4433E0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.619 3.429H4.381a.955.955 0 0 0-.952.952v15.238c0 .524.428.952.952.952h15.238a.955.955 0 0 0 .952-.952V4.381a.955.955 0 0 0-.952-.952Zm0-1.429H4.381A2.384 2.384 0 0 0 2 4.381v15.238A2.384 2.384 0 0 0 4.381 22h15.238A2.384 2.384 0 0 0 22 19.619V4.381A2.384 2.384 0 0 0 19.619 2Z'
        fill='#4433E0'
      />
    </svg>
  );
};

export default Facebook;
