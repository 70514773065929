import {
  styleColumnOneSkeleton,
  styleColumnThreeSkeleton,
  styleColumnTwoSkeleton,
  styleContentColumnThreeSkeleton,
} from '../../Views/Blog/BlogAppStyles';

const blogPosts = [
  {
    author: 'Alejandro Benitez',
    column: 1,
    creationDate: '27 mayo 2022',
    id: 1,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 2,
    creationDate: '27 mayo 2022',
    id: 2,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 2,
    creationDate: '27 mayo 2022',
    id: 3,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 4,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 5,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 6,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 7,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 8,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 9,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 10,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 11,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 12,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
];

const blogPostsCategories = [
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 1,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 2,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 3,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 4,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 5,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 6,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 7,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 8,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 9,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 10,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 11,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
  {
    author: 'Alejandro Benitez',
    column: 3,
    creationDate: '27 mayo 2022',
    id: 12,
    img: 'https://okboy-s3-strapi-artifacts-674878422215.s3.us-west-2.amazonaws.com/image_Header_7938836e7b.png',
    readDuration: '5 min de lectura',
    title: 'Cuales son las 5 formas principales en las que te roban Gas',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis minima velit dolores eveniet voluptatem earum aperiam perferendis autem iure expedita. Iusto est aperiam pariatur incidunt consectetur totam eveniet officia eius.',
  },
];

const propsSkeleton = [
  {
    styleOneColumn: styleColumnOneSkeleton,
    typeColumn: 1,
  },
  {
    styleColumnTwo: styleColumnTwoSkeleton,
    typeColumn: 2,
  },
  {
    styleContentColumnThree: styleContentColumnThreeSkeleton,
    styleThreeColumn: styleColumnThreeSkeleton,
    typeColumn: 3,
  },
];

export { blogPosts, propsSkeleton, blogPostsCategories };
