/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import {
  useEffect, useContext, useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import {
  Alert,
  SEO,
  Root,
  ServiceSearch,
} from '../../Components';
import {
  SuggestionsLayout,
  LayoutOurServices,
  TestimonialsLayout,
  CoverageLayout,
  LayoutGeneral,
  HelpSectionLayout,
  LayoutBannerCoupon,
  LayoutSectionBenefits,
} from '../../Layouts';
import { layoutGeneralSaveTime, ProgessHomeContainer, styleAlert } from './HomeStyle';
import InstructionsLayout from '../../Layouts/InstructionsLayout/InstructionsLayout';
import { SectionScroll } from '../../Contexts/SectionScroll';
import {
  getTopSections,
  scrollTopChrome,
  scrollTopMozzilla,
} from '../../Common';
import { useResetScrollTop } from '../../hooks';
import { DataLading } from '../../api';
import { LadingIdContext } from '../../Contexts';

const typeScroll = 'smooth';
const Home = () => {
  const [dataLandingHome, setDataLandingHome] = useState({
    textAlert: {},
    howHelpSection: {
      textSuggestions: [],
      textHeader: '',
      nextButton: '',
      prevButton: '',
    },
    instructionSection: {
      listInstructions: [],
      backgroundImageDesktop: '',
      backgroundImageMobile: '',
    },
    bannerHelpSection: {
      titles: {
        titleMain: '',
        titleSecondary: '',
      },
      textsUrls: {
        whatsApp: '',
        quetions: '',
      },
      url: '',
    },
    inViewport: false,
    loading: true,
  });
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const { scrollSectionHome, setScrollSectionHome } = useContext(SectionScroll);
  const { suggestionsSection, ourServicesSection, coverageSection } = scrollTopMozzilla;
  const scrollTopMozilla = [
    suggestionsSection.mobile,
    suggestionsSection.desktop,
    ourServicesSection.mobile,
    ourServicesSection.desktop,
    coverageSection.mobile,
    coverageSection.mobileXs,
    coverageSection.mobileLg,
    coverageSection.desktop,
  ];
  const scrollsTopChrome = [
    scrollTopChrome.suggestionsSection.desktop,
    scrollTopChrome.ourServicesSection.desktop,
    scrollTopChrome.coverageSection.desktop,
  ];
  const initialScroll = 0;

  useEffect(() => {
    if (scrollSectionHome) {
      const scrollCurrentSection = document.querySelector(scrollSectionHome)?.offsetTop;
      if (scrollTopMozilla.includes(scrollCurrentSection)) {
        const {
          firstSection,
          firstSectionTop,
          scrollCalcFirstSection,
          secondSectionTop,
          scrollCalc,
        } = getTopSections(0, 640, 10, 1463, 155);
        const section = scrollCurrentSection === firstSection
          ? firstSectionTop
          : scrollCurrentSection;
        window.scroll({
          top:
            section === firstSectionTop
              ? firstSectionTop - scrollCalcFirstSection
              : section === secondSectionTop
                ? section - scrollCalc
                : section,
          behavior: typeScroll,
        });
        setScrollSectionHome('');
      } else if (scrollsTopChrome.includes(scrollCurrentSection)) {
        const {
          firstSection,
          firstSectionTop,
          scrollCalcFirstSection,
          secondSectionTop,
          scrollCalc,
        } = getTopSections(0, 410, 260, 967, 100);
        const calcLastSectionTop = 260;
        const section = scrollCurrentSection === firstSection
          ? firstSectionTop - 100
          : scrollCurrentSection;
        window.scroll({
          top:
            section === firstSectionTop
              ? firstSectionTop + scrollCalcFirstSection
              : section === secondSectionTop
                ? section + scrollCalc
                : section + calcLastSectionTop,
          behavior: typeScroll,
        });
        setScrollSectionHome('');
      }
    }
  }, [scrollSectionHome]);

  useEffect(() => {
    if (!scrollSectionHome) {
      setScroll(initialScroll);
    }
  }, []);

  const {
    getDataSeoLanding,
  } = DataLading;

  const { landingId: landingTypeId } = useParams();
  const { pathname } = useLocation();
  const { dataLandingId, setDataLandingId } = useContext(LadingIdContext);

  let landingId;
  useEffect(() => {
    landingId = landingTypeId;
    setDataLandingId(() => ({
      path: landingId && pathname,
      idName: landingId,
    }));
  }, [landingTypeId, dataLandingId.idName]);

  useEffect(() => {
    const textAlert = {
      textTitle: 'Pide Gas En Nuestra Web',
      urlText: 'Pedir Gas',
      url: '/services/pedir-gas/',
    };
    const suggestionData = {
      textHeader: '¿Cómo te ayudamos a ahorrar tiempo?',
      nextButton: 'Siguiente',
      prevButton: 'Anterior',
      textSuggestions: [
        {
          img: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Time_Image_e0615beda8.png',
          imgMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Time_Image_Mobile_164a342606.png',
          title: 'No Pierdas tiempo buscando',
          textContent: 'Encuentra todos los servicios que necesitas y pídelos en un clic.',
        },
        {
          img: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Programs_Image_59d148129e.png',
          imgMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Programs_Image_Mobile_921427d460.png',
          title: 'Programa lo que más necesitas',
          textContent: 'Tu servicio más recurrente puedes programarlo para que nunca te falte.',
        },
        {
          img: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Claims_Image_e3f66c09d2.png',
          imgMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Claims_Image_Mobile_2bd8803d7e.png',
          title: 'No pierdas tiempo en reclamos',
          textContent: 'Nosotros nos aseguramos que recibas el servicio y un trato extraordinario.',
        },
        {
          img: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Information_Image_e34f996e04.png',
          imgMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Information_Image_Mobile_af1284fb76.png',
          title: 'No pierdas tiempo haciendo cuentas',
          textContent: 'Obtén informes y recomendaciones de tus gastos para hacer un mejor uso de tu dinero.',
        },
      ],
    };
    const dataInstructionSection = {
      listInstruccions: [
        {
          text: 'Pide y agenda tu servicio, el día y hora que más te convenga.',
        },
        {
          text: 'Te conectamos con los mejores proveedores.',
        },
        {
          text: 'Elige pagar como quieras, estés o no en tu hogar.',
        },
        {
          text: 'Sigue el trayecto de tu servicio y conoce el nombre y foto del aliado.',
        },
        {
          text: 'Disfruta de tu servicio con garantías, si algo sale mal, lo resolvemos.',
        },
      ],
      backgroundImageDesktop: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/BANNER_2_ccf8751591.jpg',
      backgroundImageMobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Background_Instruccions_Mobile_e080a19dcf.jpg',
    };
    const dataHelpSectionResponse = {
      titles: {
        titleMain: '¿Necesitas ayuda?',
        titleSecondary: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
      },
      textsUrls: {
        whatsApp: 'Escríbenos',
        quetions: 'Preguntas frecuentes',
      },
      url: 'https://api.whatsapp.com/send?phone=+525610109864&text=%C2%A1Hola!%20Necesito%20ayuda%20en',
    };

    setDataLandingHome(prevDataLanding => ({
      ...prevDataLanding,
      textAlert,
      loading: false,
    }));

    setDataLandingHome(prevDataLanding => ({
      ...prevDataLanding,
      howHelpSection: {
        ...suggestionData,
      },
    }));

    setDataLandingHome(prevDataLanding => ({
      ...prevDataLanding,
      instructionSection: {
        listInstructions: dataInstructionSection.listInstruccions,
        backgroundImageDesktop: dataInstructionSection.backgroundImageDesktop,
        backgroundImageMobile: dataInstructionSection.backgroundImageMobile,
      },
    }));

    setDataLandingHome(prevDataLanding => ({
      ...prevDataLanding,
      bannerHelpSection: {
        titles: { ...dataHelpSectionResponse.titles },
        textsUrls: { ...dataHelpSectionResponse.textsUrls },
        url: dataHelpSectionResponse.url,
      },
    }));
  }, []);

  const {
    textAlert,
    howHelpSection: {
      nextButton,
      prevButton,
      textHeader,
      textSuggestions,
    },
    instructionSection: {
      listInstructions,
      backgroundImageDesktop,
      backgroundImageMobile,
    },
    bannerHelpSection: textHelpSection,
    loading,
  } = dataLandingHome;

  const circularProgressSize = '4rem';

  return (
    <Root>
      {
        loading ? (
          <ProgessHomeContainer>
            <CircularProgress size={circularProgressSize} />
          </ProgessHomeContainer>
        ) : (
          <>
            <SEO getDataSeo={getDataSeoLanding} />
            <Alert
              bg={styleAlert}
              textTitle={textAlert.textTitle}
              urlText={textAlert.urlText}
              url={textAlert.url}
            >
              <CloseIcon />
            </Alert>
            <LayoutGeneral style={layoutGeneralSaveTime('-139px')}>
              <ServiceSearch />
              <LayoutSectionBenefits />
              <SuggestionsLayout
                textSuggestions={textSuggestions}
                textHeader={textHeader}
                nextButton={nextButton}
                prevButton={prevButton}
              />
              <LayoutOurServices />
              <LayoutBannerCoupon />
              <InstructionsLayout
                listInstructions={listInstructions}
                backgroundImageDesktop={backgroundImageDesktop}
                backgroundImageMobile={backgroundImageMobile}
              />
            </LayoutGeneral>
            <CoverageLayout />
            <LayoutGeneral style={layoutGeneralSaveTime('49px')}>
              <TestimonialsLayout />
              <HelpSectionLayout {...textHelpSection} />
            </LayoutGeneral>
          </>
        )
      }
    </Root>
  );
};

export default Home;
