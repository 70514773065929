import { IconButton, styled } from '@mui/material';

export const OrderContainer = styled('section')(({ theme }) => ({
  width: '34vw',
  position: 'sticky',
  top: '6vh',
  overflowY: 'auto',
  [theme.breakpoints.down('laptop')]: {
    display: 'block',
    zIndex: '-20',
    opacity: '0',
    transition: 'z-index .3s ease, opacity .3s ease',
    width: '100vw',
    height: '100vh',
    top: '0vh',
    left: '0',
    bottom: '0',
    position: 'fixed',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    opacity: '0',
    transition: 'z-index .3s ease, opacity .3s ease',
    width: '100vw',
    height: '102vh',
    top: '0vh',
  },
  [theme.breakpoints.up('laptop')]: {
    zIndex: '20',
    opacity: '1',
    display: 'block',
    width: '375px',
    height: '82vh',
    top: '9vh',
  },
  [theme.breakpoints.up('lg')]: {
    width: '34vw',
  },
  [theme.breakpoints.up('xl')]: {
    width: '26vw',
  },
}));

export const OrderBox = styled('div')(({ theme }) => ({
  padding: '2.0625rem 2rem',
  backgroundColor: theme.palette.menu.primary,
  boxShadow: '20px 30px 20px 4px rgba(0, 0, 0, 0.05)',
  borderRadius: '30px',
  transform: 'translateY(100%)',
  transition: 'transform .4s ease',
  transitionDelay: '.2s',
  [theme.breakpoints.down('tablet')]: {
    width: '100vw',
    height: '100%',
    padding: '2.0625rem 1rem',
    paddingTop: '4.5rem',
    overflowY: 'auto',
    borderRadius: '0px',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '100vw',
    height: '100%',
    padding: '2.0625rem 1rem',
    paddingTop: '4.5rem',
    overflowY: 'auto',
    borderRadius: '0px',
  },
  [theme.breakpoints.up('laptop')]: {
    transform: 'translateY(0%)',
    width: '375px',
    height: '819px',
    paddingTop: '0rem',
    overflowY: 'initial',
    borderRadius: '30px',
    padding: '2.0625rem 2rem',
  },
  [theme.breakpoints.up('lg')]: {
    width: '462px',
    height: 'max-content',
  },
}));

export const CaptionAndButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const orderConfigurationTitle = {
  fontWeight: '900',
  fontSize: {
    xs: '1.7rem',
    md: '2.1875rem',
  },
  lineHeight: {
    xs: '2.8125rem',
    md: '2.5rem',
  },
  color: {
    xs: 'primary.main',
    md: 'primary.normal',
  },
};

export const CloseButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    position: 'relative',
    left: '26px',
    fontSize: '0rem',
    '& svg': {
      fontSize: '1.8rem',
    },
  },
}));

export const containerButtonselectorsStyles = {
  marginTop: {
    xs: '1rem',
    md: '1.5rem',
  },
};

export const labelButtonSelectStyle = {
  fontSize: '1rem',
  lineHeight: {
    xs: '1.36625rem',
    md: '1.375rem',
  },
};

export const customSelect = {
  width: '100%',
  border: '1px solid rgba(121, 121, 122, 0.1)',
  color: '#79797A',
  backgroundColor: '#F0F1F5',
  borderRadius: '15px',
  '& .MuiSelect-select': {
    fontSize: '1rem',
    width: '100%',
    height: '100%',
    paddingRight: '0rem',
    padding: '0.625rem 1.171875rem 0.8125rem 0.8125rem',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
};

export const LocationButtonText = styled('span')(() => ({
  display: '-webkit-box',
  width: '307px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  textAlign: 'left',
}));

export const customLocationButtonText = {
  width: 'auto',
};

export const textButtonSelectStyle = {
  width: '100%',
  fontSize: '1rem',
  lineHeight: '1.36625rem',
};

export const sendButtonStyles = {
  width: '100%',
  '&.Mui-disabled': {
    color: 'secondary.main',
    backgroundColor: '#C4C4C4',
  },
};

export const showModalContainer = {
  opacity: {
    xs: '1',
  },
  zIndex: {
    xs: '20',
  },
};

export const showModalBoxModal = {
  transform: {
    xs: 'translateY(0%)',
  },
};

export const subTotalStyles = {
  '& > p': {
    fontWeight: '900',
    fontSize: '1rem',
  },
};

export const totalStyles = {
  '& > p': {
    fontWeight: '900',
    fontSize: '1rem',
    lineHeight: '1.375rem',
    color: 'primary.main',
  },
};

export const customInputLocation = {
  marginBottom: '0rem',
};

/* Modals */

export const ModalProduct = {
  width: '343px',
  height: 'max-content',
  borderRadius: '15px',
  padding: '0',
  paddingBottom: '1rem',
  '& > div:first-of-type': {
    width: '100%',
    paddingLeft: '1rem',
    paddingTop: '1rem',
    paddingRight: '0.5rem',
    '& > p': {
      fontSize: '1rem',
    },
    '& > button': {
      position: 'relative',
      padding: '0',
    },
  },
};

export const productModalHeightCustom = {
  height: '516px',
  overflowY: 'auto',
};

export const saveButtonStyles = {
  width: '90.7%',
  margin: '0',
  marginLeft: '1rem',
  '&.Mui-disabled': {
    backgroundColor: '#C4C4C4',
    color: 'menu.primary',
  },
};

export const buttonCustom = {
  marginTop: '4rem',
};

export const modalDateCustom = {
  display: {
    xs: 'block',
    md: 'grid',
  },
  height: {
    xs: '667px',
    md: '500px',
  },
  overflowY: 'scroll',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'repeat(2, auto) max-content',
  width: {
    xs: '343px',
    md: '700px',
  },
  '& > div:first-of-type': {
    marginTop: '1rem',
    marginLeft: '1rem',
    gridRow: '1 / 2',
    gridColumn: '1 / 3',
    '& > .MuiTypography-root': {
      width: {
        xs: '294px',
        md: 'auto',
      },
    },
    '& > .MuiButtonBase-root': {
      right: '0%',
      top: '1.8%',
    },
  },
  '& > div:nth-of-type(2) > div': {
    gridColumn: '1 / 3',
    gridRow: '2 / 3',
    '& > div': {
      backgroundColor: '#f9f9f9',
    },
  },
  '& > div:nth-of-type(3) > div': {
    gridColumn: '1 / 3',
    gridRow: '2 / 3',
  },
  '& > button.MuiButton-root': {
    gridColumn: '1 / 3',
    gridRow: '3 / 4',
    alignSelf: 'start',
    justifySelf: 'center',
  },
};

export const iconArrowStyle = {
  transition: 'transform .2s ease 0ms',
};

export const loaderContainer = {
  display: 'grid',
  placeItems: 'center',
};

export const TextWithoutSchedule = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '900',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    margin: '0 auto',
  },
}));

export const scheduleContainer = {
  height: {
    xs: '215px',
    md: '315px',
  },
  overflowY: {
    xs: 'initial',
    md: 'auto',
  },
};

export const scheduleContinerCustom = {
  marginBottom: '2rem !important',
};
