/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Pagination, Stack } from '@mui/material';
import axiosStrapi from '../../../Configs/axios';
import {
  breakPoints,
  TYPES_BANNER,
  variantTypography,
  TYPES_PARAMS_STRAPI,
  typesColors,
  initialScroll,
  TYPE_SCROLL,
} from '../../../Common';
import { BannerBlogPost, BlogPosts, SEO } from '../../../Common/messages';
import {
  ContentPostsLayout, HelpSectionLayout, LayoutGeneral, SkeletonCardLayout,
} from '../../../Layouts';
import {
  bannerDownLoad,
  styleAppStore,
  styleAppPlayStore,
  stylesBannerImageDownload,
  contentBannerSubscribe,
  stylesBannerCaptionSubscribe,
  stylesBannerImageSubscribe,
  styleColumnThreeSkeleton,
  styleContentColumnThreeSkeleton,
  styleContentSkeleton,
  styleContentColumnThreeSkeletonAfterPageOne,
} from '../BlogAppStyles';
import {
  containerPagination,
  containerPost,
  contentBannerSubscribeHome,
  contentPostHome,
  pagination,
  styleColumnOne,
  styleColumnThree,
  styleColumnTwo,
  styleContentLastPosts,
  styleContentPosts,
} from './BlogHomeStyles';
import ImageBannerDownload from '../../../../assets/img/Blog/imageBanner/imageBannerDownload.png';
import ImageSubscribeDesktop from '../../../../assets/img/Blog/imageBanner/imageSubscribeDesktop.png';
import ImageSubscribeMobile from '../../../../assets/img/Blog/imageBanner/imageSubscribeMobile.png';
import { useBreakpoint, useResetScrollTop } from '../../../hooks';
import { SkeletonCard } from '../../../Components';
import { DataLading } from '../../../api';

const BlogHome = () => {
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const [blogPosts, setBlogPosts] = useState({
    pageCount: 0,
    posts: [],
    dataImageDownload: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const {
    fieldsPosts, populates, sort, pageSize,
  } = TYPES_PARAMS_STRAPI;

  const { messageTitleMetaDefault } = SEO;

  useEffect(() => {
    document.title = messageTitleMetaDefault;
    const getPosts = async (pageCount) => {
      try {
        const {
          data: { data, meta },
        } = await axiosStrapi.get(
          `blog-posts/?fields=${fieldsPosts}&populate=${populates.img}&sort=${sort.forColumn}&pagination[page]=${pageCount}&pagination[pageSize]=${pageSize}`,
        );
        const posts = data.map(post => {
          const { id, attributes } = post;
          const dataImage = attributes.image.data[0] || {};
          return {
            id,
            title: attributes.title,
            author: attributes.author,
            creationDate: attributes.creationDate,
            readDuration: attributes.readDuration,
            img: dataImage.attributes.url,
            alternativeText: dataImage.attributes.alternativeText,
            column: attributes.column,
            description: attributes.description,
          };
        });
        return { pageCount: meta.pagination.pageCount, posts };
      } catch (error) {
        navigate('/*', { replace: true });
      }
      return [];
    };
    getPosts(page)
      .then((responseDataPosts) => {
        setBlogPosts(prevDataBlogPosts => ({
          ...prevDataBlogPosts,
          pageCount: responseDataPosts.pageCount,
          posts: responseDataPosts.posts,
        }));
        setLoading(false);
      });
  }, [page]);

  const { getSaveTime } = DataLading;

  useEffect(() => {
    getSaveTime().then(dataResponseSaveTime => {
      setBlogPosts(prevDataBlogPosts => ({
        ...prevDataBlogPosts,
        dataImageDownload: dataResponseSaveTime.imageDownloadApp,
      }));
    });
  }, []);

  const {
    bannerDownload, bannerSuscribe,
  } = BannerBlogPost;

  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  const { propsSkeleton } = BlogPosts;

  const handlePagination = (e, pageCount) => {
    setPage(pageCount);
    setLoading(true);
    window.scroll({
      top: initialScroll,
      behavior: TYPE_SCROLL,
    });
  };

  const pageOne = 1;
  return (
    <Box sx={contentPostHome}>
      <LayoutGeneral style={containerPost}>
        {
        loading ? (
          <SkeletonCardLayout styleContentSkeleton={styleContentSkeleton}>
            {page === pageOne ? (propsSkeleton.map(item => (
              <SkeletonCard
                key={Math.random()}
                styleOneColumn={item.styleOneColumn}
                styleColumnTwo={item.styleColumnTwo}
                styleThreeColumn={item.styleThreeColumn}
                styleContentColumnThree={item.styleContentColumnThree}
                typeColumn={item.typeColumn}
              />
            ))) : (
              <SkeletonCard
                styleContentColumnThree={{
                  ...styleContentColumnThreeSkeleton,
                  ...styleContentColumnThreeSkeletonAfterPageOne,
                }}
                styleThreeColumn={styleColumnThreeSkeleton}
                typeColumn={3}
                lengthCard={9}
              />
            )}
            <SkeletonCard
              styleContentColumnThree={styleContentColumnThreeSkeleton}
              styleThreeColumn={styleColumnThreeSkeleton}
              typeColumn={3}
            />
          </SkeletonCardLayout>
        ) : (
          <ContentPostsLayout
            styleColumnOne={styleColumnOne}
            styleColumnTwo={styleColumnTwo}
            styleColumnThree={styleColumnThree}
            cardsBlogPost={blogPosts.posts}
            fromIndex={0}
            toIndex={9}
            styleContentPosts={styleContentPosts}
          />
        )
        }
        <HelpSectionLayout
          typeBanner={TYPES_BANNER.subscribe}
          titles={bannerSuscribe.titles}
          textLabel={bannerSuscribe.placeHolder}
          textButton={bannerSuscribe.textButton}
          stylesContentBanner={contentBannerSubscribe}
          stylesBannerCaption={stylesBannerCaptionSubscribe}
          stylesBannerImage={stylesBannerImageSubscribe}
          variantTitle={variantTypography.h3}
          variantSubTitle={variantTypography.h6}
          nameImage={bannerSuscribe.nameImage}
          imageBanner={toggleBreakpoint ? ImageSubscribeMobile : ImageSubscribeDesktop}
        />
        {
        loading ? (
          <SkeletonCardLayout styleContentSkeleton={styleContentSkeleton}>
            <SkeletonCard
              styleContentColumnThree={propsSkeleton[2].styleContentColumnThree}
              styleThreeColumn={propsSkeleton[2].styleThreeColumn}
              typeColumn={propsSkeleton[2].typeColumn}
            />
          </SkeletonCardLayout>
        ) : (
          <ContentPostsLayout
            styleColumnThree={styleColumnThree}
            cardsBlogPost={blogPosts.posts}
            fromIndex={9}
            toIndex={12}
            styleContentPosts={styleContentLastPosts}
          />
        )
      }
        <Stack spacing={2} sx={containerPagination}>
          <Pagination
            onChange={handlePagination}
            sx={pagination}
            count={blogPosts.pageCount}
            color={typesColors.primary}
          />
        </Stack>
      </LayoutGeneral>
      <Box sx={contentBannerSubscribeHome}>
        <LayoutGeneral>
          <HelpSectionLayout
            typeBanner={TYPES_BANNER.download}
            titles={bannerDownload.titles}
            stylesContentBanner={bannerDownLoad}
            styleDownload={{ styleAppStore, styleAppPlayStore }}
            stylesBannerImage={stylesBannerImageDownload}
            variantTitle={variantTypography.h3}
            variantSubTitle={variantTypography.h5}
            nameImage={bannerDownload.nameImage}
            imageBanner={ImageBannerDownload}
            imageIOS={blogPosts.dataImageDownload.imageIOS}
            imageAndroid={blogPosts.dataImageDownload.imageAndroid}
          />
        </LayoutGeneral>
      </Box>
    </Box>
  );
};

export default BlogHome;
