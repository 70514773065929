import { useEffect } from 'react';
import { ServicesSearchMessage } from '../../Common/messages';
import { typesColors, variantTypography } from '../../Common';
import { Captions } from '../../Components';
import { captionStyles } from './styles';
import SelectorsLayout from './SelectorsLayout';
import { useResetScrollTop } from '../../hooks';

const {
  services,
  locations,
  customLabel,
  customSelect,
  titles,
  titlesSelectsMobile,
  dateInvalidMessage,
  textModals,
  textMobileModals,
} = ServicesSearchMessage;

const {
  dataServicesSearchView: {
    title,
  },
} = ServicesSearchMessage;

const CaptionAndSelects = () => {
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const initialScroll = 0;

  useEffect(() => {
    setScroll(initialScroll);
  }, []);
  return (
    <>
      <Captions
        color={typesColors.primary}
        variant={variantTypography.h1}
        style={captionStyles}
      >
        { title }
      </Captions>
      <SelectorsLayout
        services={services}
        locations={locations}
        customLabel={customLabel}
        customSelect={customSelect}
        titles={titles}
        titlesSelectsMobile={titlesSelectsMobile}
        dateInvalidMessage={dateInvalidMessage}
        textModals={textModals}
        textMobileModals={textMobileModals}
      />
    </>
  );
};

export default CaptionAndSelects;
