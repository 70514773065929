export const contentImageIcon = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexBasis: '10%',
  height: '100%',
  '& > a': {
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
};

export const contentImageIconBlog = {
  flexBasis: {
    xs: '46.5%',
    phoneMd: '46.5%',
    tablet: '26.5%',
    md: '13.5%',
    lg: '13.5%',
  },
  '& > a': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& > img': {
      width: {
        xs: '80%',
        phoneMd: '80%',
        md: '90%',
        lg: '90%',
      },
      objectFit: 'cover',
    },
  },
};
