import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Tooltip,
} from '@mui/material';
import { TYPES_COMPONENTS, TYPES_DIRECTIONS } from '../../Common';
import { ConnectorStatus, IconStatus } from '../../Components';
import {
  AccordionInformationOrder,
  SectionStepper,
  StatusLabel,
  StatusTag,
  StepLabelServiceTracking,
  StepperServiceTracking,
  StepServiceTracking,
  styledAccordionDetail,
  styledStepCompleted,
  styleIconAccordion,
} from './styles';
import { ServiceTraking } from '../../Common/messages';
import LayoutEvidence from './LayoutEvidence';
import { tooltipProps } from '../../shared';

const {
  messagesTracking: {
    statusTitle,
    showDetailText,
  },
} = ServiceTraking;

const {
  row,
  align,
} = TYPES_DIRECTIONS;

const {
  placement,
} = tooltipProps;

const LayoutStepper = ({
  dataStepperStatus,
  evidenceData,
  evidenceTitle,
  titleWithOutEvidence,
}) => {
  const {
    activeStep,
    serviceStatus,
    statusCurrent,
  } = dataStepperStatus.dataStepperServiceTracking || {};
  const [expanded, setExpanded] = useState(true);

  const verticalOrientation = 'vertical';
  return (
    <>
      <SectionStepper component={TYPES_COMPONENTS.section}>
        <AccordionInformationOrder
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={styleIconAccordion} />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Stack direction={row} alignItems={align.center} gap={{ sm: '3.7rem', xs: '1rem' }}>
              <Stack direction={row} alignItems={align.center} gap={{ sm: '4.5rem', xs: '1.6rem' }}>
                <StatusLabel>
                  { statusTitle }
                </StatusLabel>
                <Tooltip title={statusCurrent?.labelText || statusCurrent?.customStatusText || ''} placement={placement.top}>
                  <StatusTag
                    background={
                      statusCurrent?.color?.backgroundColor || serviceStatus?.backgroundColor
                    }
                    sx={{ '& > .MuiChip-label': { color: statusCurrent?.color?.color || 'primary.black2' } }}
                    label={statusCurrent?.labelText || statusCurrent?.customStatusText || ''}
                  />
                </Tooltip>
              </Stack>
              <StatusLabel sx={{ width: { xs: 'max-content', sm: 'initial' } }}>
                { !expanded ? showDetailText.show : showDetailText.hidden }
              </StatusLabel>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={styledAccordionDetail}>
            <StepperServiceTracking
              connector={<ConnectorStatus />}
              activeStep={activeStep}
              orientation={verticalOrientation}
            >
              {
              dataStepperStatus.dataStepperServiceTracking.status.map((statusText) => {
                return (
                  <StepServiceTracking key={statusText.id}>
                    <StepLabelServiceTracking
                      sx={styledStepCompleted}
                      StepIconComponent={IconStatus}
                    >
                      { statusText.textStatus }
                    </StepLabelServiceTracking>
                    { statusText.description }
                  </StepServiceTracking>
                );
              })
            }
              <LayoutEvidence
                evidenceData={evidenceData}
                evidenceTitle={evidenceTitle}
                titleWithOutEvidence={titleWithOutEvidence}
              />
            </StepperServiceTracking>
          </AccordionDetails>
        </AccordionInformationOrder>
      </SectionStepper>
    </>
  );
};

export default LayoutStepper;
