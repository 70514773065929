import {
  Box,
  Card,
  styled,
} from '@mui/material';

export const ContentEvidenceCard = styled(Card)(() => ({
  width: '280px',
  height: '100%',
}));

export const buttonCard = {
  height: '100%',
  padding: '0px',
  transition: 'transform .3s ease',
  '&: hover': {
    backgroundColor: 'transparent',
    transform: 'scale(1.03)',
  },
};

export const CardImage = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
}));

export const SpanImage = styled('span')(() => ({
  borderRadius: '10px',
  backgroundColor: '#4433E0',
  position: 'absolute',
  margin: '5px',
  color: 'white',
  padding: '0px 7px 0px 7px',
}));
