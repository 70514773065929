import moment from 'moment';
import { FORMAT_DATE_CALENDAR } from '../Common';

const {
  dayMonthYearShort,
} = FORMAT_DATE_CALENDAR;

const formatDate = (date, formatType) => {
  return !date ? null : moment(date).format(formatType || dayMonthYearShort);
};

const formatHour = (hour) => {
  return !hour ? null : hour.split('T')[1].split(':').slice(0, 2).join(':');
};

export {
  formatDate,
  formatHour,
};
