import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD3Lt1KM-kDNRBpmtScC-sLtMBFu6BpM-k',
  authDomain: 'okboy-cliente.firebaseapp.com',
  databaseURL: 'https://okboy-cliente.firebaseio.com',
  projectId: 'okboy-cliente',
  storageBucket: 'okboy-cliente.appspot.com',
  messagingSenderId: '395832004198',
  appId: '1:395832004198:web:d124cfe460df90023067a7',
  measurementId: 'G-NR3J3NKD8X',
};
const app = initializeApp(firebaseConfig);

export default {
  analytics: getAnalytics(app),
  logEvent,
};
