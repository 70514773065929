import Captions from '../Captions/Captions';
import { variantTypography } from '../../Common';
import { productRecommendationsCaption } from './styles';

const ProductRecommendationsTitle = ({
  title,
}) => {
  return (
    <Captions
      variant={variantTypography.h4}
      style={productRecommendationsCaption}
    >
      { title }
    </Captions>
  );
};

export default ProductRecommendationsTitle;
