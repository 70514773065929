import { useParams } from 'react-router-dom';
/* eslint-disable no-undef */
import {
  useState,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import { Box } from '@mui/material';
import {
  bgImage,
  headingOne,
  headingTwo,
  styleAppStore,
  styleAppPlayStore,
  contentIconApps,
  subTitle,
  ContentTexts,
} from './styles';
import { captionText, downloads, ServicesSearchMessage } from '../../Common/messages';
import Captions from '../Captions/Captions';
import './ServiceSearch.css';
import { DataLading } from '../../api';
import { TYPES_COMPONENTS } from '../../Common';
import ContentSelectors from '../ContentSelectors/ContentSelectors';
import { ServiceSearchProvider } from '../../Contexts';

const DownloadApp = lazy(() => import('../DownloadApp/DownloadApp'));

const ServiceSearch = () => {
  const [dataSaveTime, setDataSaveTime] = useState({
    texts: {},
    imagePhone: {
      image: '',
      alternativeText: '',
    },
    backgroundImage: {},
    imageDownloadApp: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
    inViewport: false,
  });
  const { getSaveTime } = DataLading;

  const { landingId: landingTypeId } = useParams();

  let landingId;
  useEffect(() => {
    landingId = landingTypeId;
  }, [landingTypeId]);

  useEffect(() => {
    getSaveTime(landingId).then(dataResponseSaveTime => {
      setDataSaveTime(prev => ({
        ...prev,
        texts: dataResponseSaveTime.texts,
        imageDownloadApp: dataResponseSaveTime.imageDownloadApp,
        backgroundImage: dataResponseSaveTime.backgroundImage,
      }));
    });
  }, []);

  const {
    texts,
    backgroundImage,
    imageDownloadApp,
  } = dataSaveTime;

  const { variants, color } = captionText;
  const { badge } = ServicesSearchMessage;
  return (
    <ServiceSearchProvider>
      <Box sx={bgImage(backgroundImage.desktop, backgroundImage.mobile)}>
        <ContentTexts>
          <Captions variant={variants.variantH2} style={subTitle} color={color.colorSecondary}>
            <Box component={TYPES_COMPONENTS.span}>{ badge }</Box>
          </Captions>

          <Captions variant={variants.variantH1} style={headingOne} color={color.colorSecondary}>
            {texts.textH1}
          </Captions>

          <ContentSelectors />

          <Captions variant={variants.variantH2} style={headingTwo} color={color.colorSecondary}>
            {texts.textH3}
          </Captions>
          <Box sx={contentIconApps}>
            <Suspense fallback={null}>
              <DownloadApp
                appStore={imageDownloadApp.imageIOS}
                playStore={imageDownloadApp.imageAndroid}
                downloads={downloads}
                style={{ styleAppStore, styleAppPlayStore }}
              />
            </Suspense>
          </Box>
        </ContentTexts>
      </Box>
    </ServiceSearchProvider>
  );
};

export default ServiceSearch;
