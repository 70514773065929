import {
  Box, Container, Typography, IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { typesTarget, TYPES_COMPONENTS } from '../../Common';
import { urls } from '../../Common/messages';
import {
  AlertStyleCustom, contentButtons, titleStyle, hiddenAlert,
} from './AlertStyles';

const Alert = (props) => {
  const [show, setShow] = useState(true);
  const {
    bg, textTitle, urlText, url, children,
  } = props;
  const {
    okboyLinks,
  } = urls;
  return (
    <Box sx={show ? bg : hiddenAlert}>
      <Container sx={show ? AlertStyleCustom : hiddenAlert}>
        <Typography sx={titleStyle} variant='h6'>{textTitle}</Typography>
        <Box sx={contentButtons}>
          {
          urlText && url !== okboyLinks
            ? <NavLink to={url}>{urlText}</NavLink>
            : (
              <Box
                target={typesTarget.TYPE_TARGET_BLANK}
                component={TYPES_COMPONENTS.link}
                href={url}
              >
                { urlText }
              </Box>
            )
        }
          <IconButton onClick={() => setShow(!show)} variant='contained'>{children}</IconButton>
        </Box>
      </Container>
    </Box>
  );
};

Alert.propTypes = {
  bg: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
  }),
};

export default memo(Alert);
