import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

export const cardServiceContainer = {
  height: '369px',
  maxWidth: '300px',
  minWidth: '300px',
  boxShadow: '20px 30px 100px rgba(0, 0, 0, 0.1)',
  borderRadius: '30px',
  backgroundColor: 'secondary.main',
};

export const ContentImage = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '174px',
}));

export const cardServiceImage = {
  width: '50%',
  objectFit: 'contain',
  height: '73%',
};

export const cardServiceContainerContent = {
  height: '44%',
  padding: '0rem 1rem',
  paddingTop: '2rem',
  paddingBottom: '0rem !important',
};

export const TitleCardHeader = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  fontSize: '2rem',
  fontWeight: '900',
  lineHeight: '2.390625rem',
  marginBottom: '0.5rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}));

export const PriceService = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  fontSize: '1.60rem',
  fontWeight: '900',
  lineHeight: '1.7075rem',
  color: theme.palette.primary.main,
  marginBottom: '.56rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

export const DescriptionService = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  fontSize: '1.30rem',
  fontWeight: '900',
  lineHeight: '1.536875rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  color: 'rgba(32, 32, 34, 0.8)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
}));

export const CardServiceSelectionOrder = styled(Card)(({ theme }) => ({
  width: '148px',
  height: '136px',
  backgroundColor: theme.palette.secondary.main,
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  borderRadius: '20px',
}));

export const ContentImageCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  alignItems: 'center',
  height: '80px',
  width: '100%',
}));

export const CardImage = styled(CardMedia)(() => ({
  backgroundSize: 'contain',
  height: '84%',
  width: '93px',
}));

export const CardImageSelectionOrderSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '80px',
  width: '100%',
  transform: 'scale(1, 1)',
  borderRadius: '20px',
  borderBottomLeftRadius: 'initial',
  borderBottomRightRadius: 'initial',
  backgroundColor: theme.palette.primary.main,
}));

export const CardText = styled(CardHeader)(({ theme }) => ({
  marginTop: '1rem',
  padding: '0rem',
  '& span': {
    display: 'block',
    fontWeight: '900',
    fontSize: '1rem',
    lineHeight: '1.5625rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export const CardTextSelectionOrderSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '80%',
  height: '20px',
  margin: '0 auto',
  marginTop: '1rem',
  padding: '0rem',
  backgroundColor: theme.palette.text.secondary,
}));
