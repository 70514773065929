const DeleteIcon = (props) => (
  <svg
    width={19}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.433 2.4h-4.208v-.436A1.964 1.964 0 0 0 10.26 0H8.1a1.964 1.964 0 0 0-1.968 1.964v.4h-4.17A1.964 1.964 0 0 0 .002 4.369v.962a1.964 1.964 0 0 0 1.643 1.924v.2l1.082 10.02A2.766 2.766 0 0 0 5.45 20h7.415a2.766 2.766 0 0 0 2.766-2.525l1.082-10.02v-.2a1.964 1.964 0 0 0 1.643-1.924v-.962A1.964 1.964 0 0 0 16.433 2.4Zm-8.978-.436a.681.681 0 0 1 .68-.681h2.126a.681.681 0 0 1 .68.681v.4H7.456v-.4Zm6.894 15.391a1.483 1.483 0 0 1-1.483 1.363H5.49a1.483 1.483 0 0 1-1.483-1.363L2.926 7.335H15.43l-1.082 10.02Zm2.726-12.024a.68.68 0 0 1-.641.681H1.964a.681.681 0 0 1-.681-.681v-.962a.681.681 0 0 1 .68-.681h14.47a.681.681 0 0 1 .68.681l-.038.962Z'
      fill='#4433E0'
    />
    <path
      d='M9.178 17.436a.64.64 0 0 0 .64-.641V9.259a.642.642 0 0 0-1.282 0v7.535a.64.64 0 0 0 .64.64M5.654 16.832a.641.641 0 0 0 .64.561h.08a.64.64 0 0 0 .562-.721l-.8-7.535a.644.644 0 0 0-1.283.12l.8 7.575ZM12.024 17.433h.08a.641.641 0 0 0 .64-.6l.8-7.535a.644.644 0 1 0-1.282-.12l-.8 7.535a.641.641 0 0 0 .56.721'
      fill='#4433E0'
    />
  </svg>
);

export default DeleteIcon;
