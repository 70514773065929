import { styled } from '@mui/material/styles';
import {
  Box,
} from '@mui/material';

export const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: 462,
  [theme.breakpoints.down('sm')]: {
    width: '91%',
    order: 2,
  },
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  minWidth: 387,
  minHeight: 478,
  width: '641.2px',
  [theme.breakpoints.down('sm')]: {
    order: 1,
    minHeight: 350,
    width: '100%',
  },
}));

export const Container = {
  backgroundColor: 'white',
  borderRadius: '50px',
  top: '-50px',
  position: 'relative',
  padding: {
    xs: '70px 0px 41px 0px', md: '150px',
  },
  '& > div:first-of-type': {
    gap: {
      xs: '0px', md: '84px',
    },
  },
};

export const sectionContainerSecondStyles = {
  justifyContent: {
    xs: 'center',
    md: 'center',
  },
  gap: {
    xs: '0px',
    md: '84px',
  },
};

export const sectionSecondStyles = {
  order: 2,
};
