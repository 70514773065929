import { noBar, pathBlogpost } from '../../Common';
import { pathViews } from '../../Common/messages';

const validPathEqual = (path, view) => {
  return path.indexOf(view) === -1;
};

const validPathDistinc = (path, view) => {
  return path.indexOf(view) !== -1;
};

const isPathBlogDistinc = (path, view) => {
  return path.slice(0, noBar) !== view;
};

const isPathBlogEqual = (path, view) => {
  return path.slice(0, pathBlogpost) === view;
};

const validDefaultPath = (path) => {
  const defaultPath = 'landing';
  return path === pathViews.home ? defaultPath : path.replace('/', '');
};

const includePathLandingSub = (path, view) => {
  return Array.isArray(view) ? view.includes(path) : path.split('/').includes(view);
};

export {
  validPathEqual,
  validPathDistinc,
  isPathBlogDistinc,
  isPathBlogEqual,
  validDefaultPath,
  includePathLandingSub,
};
