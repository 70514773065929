import {
  ButtonSelectCustom,
} from './styles';

const ButtonSelect = ({
  children,
  handleClick,
  disabled,
  styles,
  handleFocus,
}) => {
  return (
    <ButtonSelectCustom
      onClick={handleClick}
      onFocus={handleFocus}
      disabled={disabled}
      sx={styles}
    >
      { children }
    </ButtonSelectCustom>
  );
};

export default ButtonSelect;
