/* eslint-disable no-return-assign */
import { useContext, useEffect, useRef } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import { ContextImageAndToggle } from '../../Contexts/TokenServiceTracking';
import {
  ContentEvidenceCardModal,
} from './styles';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import LayoutTrackingModal from './LayoutTrackingModal';

const ModalServiceEvidenceLayout = () => {
  const { toggleAndImage, handleToggle } = useContext(ContextImageAndToggle);
  const imageRef = useRef(null);
  useEffect(() => {
    if (toggleAndImage.toggle) {
      imageRef.current.style.transform = 'translateY(0%)';
    } else {
      imageRef.current.style.transform = 'translateY(152%)';
    }
  }, [toggleAndImage.toggle]);
  const zoomScale = 2;

  const hiddenHandle = () => {
    handleToggle(false, toggleAndImage.image);
  };
  return (
    <LayoutTrackingModal
      handleToggle={hiddenHandle}
      toggle={toggleAndImage.toggle}
    >
      <ContentEvidenceCardModal ref={(el) => imageRef.current = el}>
        <InnerImageZoom
          zoomScale={zoomScale}
          src={toggleAndImage.image}
          zoomSrc={toggleAndImage.image}
        />
      </ContentEvidenceCardModal>
    </LayoutTrackingModal>
  );
};

export default ModalServiceEvidenceLayout;
