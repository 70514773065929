import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataServiceTrackingContent } from '../../api';
import { typesColors, variantTypography } from '../../Common';
import { Captions } from '../../Components';
import { captionTacking, Folio } from './styles';
import { ContextToken } from '../../Contexts';

const LayoutMakeTrakingText = () => {
  const [dataTextServiceTracking, setDataTextServiceTracking] = useState({
    title: '',
    subtitle: '',
  });
  const [dataServiceTracking, setDataServiceTracking] = useState({
    folio: '',
  });
  const tokenContext = useContext(ContextToken);
  const navigate = useNavigate();
  const { getDataServiceTrackingContent, getDetailServiceTracking } = DataServiceTrackingContent;
  useEffect(() => {
    getDataServiceTrackingContent().then(dataResponseServiceTrackingContent => {
      setDataTextServiceTracking(prevDataTextServicetracking => ({
        ...prevDataTextServicetracking,
        title: dataResponseServiceTrackingContent.title,
        subtitle: dataResponseServiceTrackingContent.subTitle,
      }));
    }).catch(() => navigate('/*'));

    getDetailServiceTracking(tokenContext).then(dataResponseInformationOrder => {
      const { folio } = dataResponseInformationOrder;
      setDataServiceTracking(prevDataSeviceTracking => ({
        ...prevDataSeviceTracking,
        folio,
      }));
    }).catch(() => navigate('/*'));
  }, []);

  return (
    <>
      <Captions
        color={typesColors.main}
        variant={variantTypography.h1}
        style={captionTacking}
      >
        {dataTextServiceTracking.title}
        <Folio>{ dataServiceTracking?.folio }</Folio>
      </Captions>
    </>
  );
};

export default LayoutMakeTrakingText;
