export const menuMobileStyles = {
  display: {
    xs: 'block',
    md: 'none',
  },
  position: 'fixed',
  top: '0',
  overflowY: 'auto',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'menu.primary',
  zIndex: '28',
  '& > div': {
    width: '84%',
    height: '100%',
    margin: '0 auto',
    '& > a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      gap: '10px',
      width: '100%',
      marginTop: '22px',
      fontSize: '1.375rem',
      textDecoration: 'none',
      backgroundColor: 'secondary.main',
      borderRadius: '15px',
      border: '2px solid',
      borderColor: 'primary.main',
      padding: '8px 20px 8px 20px',
      fontWeight: '900',
      color: 'primary.main',
      textAlign: 'center',
      zIndex: '20',
    },
  },
  transform: 'translateX(100%)',
  transition: 'transform .3s ease',
};
