import { Stack, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataServicesSearch } from '../../api';
import {
  initialScroll,
  LOCALSTORAGE_NAME_TYPES,
  serviceNumber,
  typesColors,
  variantTypography,
} from '../../Common';
import { pathViews, ServicesSearchMessage } from '../../Common/messages';
import { Captions, ServiceCard, ServiceCardSkeleton } from '../../Components';
import { LoaderContext, ServiceSearchContext } from '../../Contexts';
import { useResetScrollTop } from '../../hooks';
import {
  getSelectorsData,
  getServiceType,
  setServiceType,
} from '../../store/action';
import CaptionAndSelects from './CaptionAndSelects';
import { captionServicesNofound, CardContainer, cardsContainer } from './styles';

const {
  getServicesType,
  getCategories,
} = DataServicesSearch;

const {
  tagetValues,
  defaultCaption,
  nameRegistryUUid,
} = ServicesSearchMessage;

const {
  servicesSearch,
} = pathViews;

const {
  serviceTypeData,
} = LOCALSTORAGE_NAME_TYPES;

const ServicesSearchLayout = () => {
  const [searchServices, dispatch] = useContext(ServiceSearchContext);
  const [registryUuid, setRegistryUuid] = useState('');
  const [loading, setLoading] = useContext(LoaderContext);
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  // eslint-disable-next-line no-undef
  const registryUuidValue = JSON.parse(sessionStorage.getItem(nameRegistryUUid));
  // eslint-disable-next-line no-undef
  const serviceType = JSON.parse(sessionStorage.getItem(serviceTypeData));

  const serviceTypeValue = searchServices?.serviceType?.registryUuid
    ? searchServices?.serviceType?.registryUuid
    : searchServices?.serviceType;

  const validRegistryUuidValue = serviceTypeValue || serviceType?.registryUuid || registryUuidValue;

  useEffect(() => {
    setScroll(initialScroll);
    if (validRegistryUuidValue) {
      setLoading(true);
      getCategories(validRegistryUuidValue, searchServices.location).then(categoriesResponse => {
        const { data } = categoriesResponse;
        dispatch(getServiceType(data));
      }).finally(() => setLoading(false));
    } else if (!validRegistryUuidValue) {
      setLoading(true);
      getServicesType(
        searchServices.serviceType,
        searchServices.location,
      ).then(servicesTypeResponse => {
        const { data } = servicesTypeResponse;
        dispatch(getServiceType(data));
      }).finally(() => setLoading(false));
      getServicesType().then(servicesTypeResponse => {
        const { data } = servicesTypeResponse;
        dispatch(getSelectorsData(data));
      });
    }
  }, [
    registryUuid,
    searchServices.serviceType,
    searchServices.location,
  ]);

  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, []);

  const navigate = useNavigate();

  const handleServiceSearch = (e) => {
    const { target } = e;
    const valueData = JSON.parse(target.getAttribute(tagetValues.cards.registryUuid));
    dispatch(setServiceType(valueData?.description));
    setRegistryUuid(valueData?.registryUuid);
    // eslint-disable-next-line no-undef
    sessionStorage.setItem(nameRegistryUUid, JSON.stringify(valueData?.registryUuid));
    setLoading(true);
  };

  const handleRedirect = (e) => {
    const { target } = e;
    const valueData = JSON.parse(target.getAttribute(tagetValues.cards.registryUuid));
    const product = searchServices.servicesData.find(
      service => service.registryUuid === valueData?.registryUuid,
    );
    const {
      app,
      chidren,
    } = servicesSearch;

    const serviceTypeRegistryUuid = searchServices?.serviceType || registryUuidValue;
    navigate(`${app}${chidren.product}`, {
      state: { productDetail: product, serviceType: serviceTypeRegistryUuid },
    });
  };

  const registryUuidValid = registryUuidValue || serviceType?.registryUuid;

  return (
    <>
      <CaptionAndSelects />
      <Stack sx={cardsContainer}>
        {
          loading ? (
            <ServiceCardSkeleton length={serviceNumber} />
          )
            : !searchServices.servicesData.length
              ? (
                <Captions
                  variant={variantTypography.h2}
                  color={typesColors.primary}
                  style={captionServicesNofound}
                >
                  { defaultCaption }
                </Captions>
              )
              : (
                searchServices.servicesData?.map(card => {
                  const cardTitle = !registryUuidValid ? card?.description : card?.name;
                  const valuePropertyData = JSON.stringify(card);
                  const states = card.companies && card.companies.filter(
                    companie => companie.state !== null,
                  ).map(companieItem => companieItem.state);
                  return (
                    <Tooltip key={card.id} arrow title={cardTitle || ''}>
                      <CardContainer
                        onClick={!registryUuidValid ? handleServiceSearch : handleRedirect}
                        value={valuePropertyData}
                      >
                        <ServiceCard loading={loading} {...card} states={states?.flat()} />
                      </CardContainer>
                    </Tooltip>
                  );
                })
              )
      }
      </Stack>
    </>
  );
};

export default ServicesSearchLayout;
