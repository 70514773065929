export const layoutHeaderStyle = {
  position: 'fixed',
  top: '0px',
  marginTop: {
    xs: '0px', sm: '0px', md: '0px', lg: '0px',
  },
  bgcolor: {
    xs: 'transparent', sm: 'transparent', md: 'background.primary', ls: 'background.primary',
  },
  width: '100vw',
  height: '78px',
  zIndex: '30',
};

export const continerHeaderStyleLanding = {
  paddingLeft: {
    xs: '0px !important',
    md: '0px !important',
  },
  paddingRight: {
    xs: '0px !important',
    md: '0px !important',
  },
  textAlign: 'center',
  paddingTop: '15px',
  backgroundColor: 'background.primary',
  '& img': {
    width: '150px',
    height: 'auto',
  },
};

export const layoutHeaderStyleBlog = {
  padding: '0px !important',
  marginTop: '0px',
};
export const containerHeaderStyleBlog = {
  padding: '0px !important',
  maxWidth: '1252px !important',
};

export const loaderLectureBlogPost = {
  '--width-loader': '0%',
  width: '100%',
  height: '8px',
  backgroundColor: 'background.secondary',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '0',
    height: '100%',
    width: 'var(--width-loader)',
    backgroundColor: 'primary.main',
  },
};

export const layoutHeaderStyleService = {
  padding: '0px',
  margin: {
    xs: '0px',
    md: '0px',
  },
  position: 'static',
  height: 'auto',
};

export const containerServices = {
  paddingLeft: '1.3rem',
  paddingRight: '1rem',
  margin: '0 auto',
};
