export const stylesInput = {
  width: {
    xs: '100%',
    md: '423px',
  },
  height: '58px',
  '&:placeholder': {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.37rem',
    color: '#D9D9D9',
  },
  '& input': {
    padding: '1.06rem 2rem',
    color: 'text.secondary',
    width: '100%',
    '&:focus + fieldset': {
      border: '1px solid primary.main !important',
    },
  },
  '& fieldset': {
    border: '1px solid #C4C4C4',
    borderRadius: '16px',
    width: '100%',
    backgroundColor: 'secondary.main',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.37rem',
    zIndex: '-1',
  },
};
