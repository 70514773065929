export const contentList = {
  position: 'relative',
  top: '79px',
  '& > li': {
    paddingLeft: '0px',
  },
  '& > li:nth-of-type(4)': {
    display: 'none',
  },
  '& a': {
    fontSize: '1.375rem',
    color: 'primary.main',
  },
};

export const contentListMobileBlog = {
  color: 'primary.main !important',
  '& > li:nth-of-type(8)': {
    display: 'none',
  },
  '& > li > a': {
    color: 'primary.main',
    fontSize: '1.375rem',
    textDecoration: 'none',
  },
  '& > li:last-of-type': {
    textDecoration: 'underline',
  },
};
