import NotFoundImageDesktop from '../../../assets/img/NotFound/NotFoundDesktop.png';
import NotFoundImageMobile from '../../../assets/img/NotFound/NotFoundMobile.png';

export const content = {
  display: 'flex',
  height: {
    xs: '455px',
    sm: '455px',
    md: '355px',
    lg: '355px',
    xl: '355px',
  },
  backgroundImage: {
    xs: `url(${NotFoundImageMobile})`,
    sm: `url(${NotFoundImageMobile})`,
    md: `url(${NotFoundImageDesktop})`,
    lg: `url(${NotFoundImageDesktop})`,
    xl: `url(${NotFoundImageDesktop})`,
  },
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  borderRadius: {
    xs: 'initial',
    sm: 'initial',
    md: '30px',
    lg: '30px',
    x: '30px',
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: {
      xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row',
    },
    '& > h2': {
      height: '118px',
      flexBasis: {
        xs: '47%', sm: '47%', md: '76%', lg: '76%', xl: '76%',
      },
      marginLeft: {
        xs: '30px', sm: '30px', md: '0px', lg: '0px', xl: '0px',
      },
      fontWeight: '800',
      color: 'primary.main',
      fontSize: '3.125rem',
    },
  },
};
