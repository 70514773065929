const Linkedin = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.619 3.429H4.381a.955.955 0 0 0-.952.952v15.238c0 .524.428.952.952.952h15.238a.955.955 0 0 0 .952-.952V4.381a.955.955 0 0 0-.952-.952Zm0-1.429H4.381A2.384 2.384 0 0 0 2 4.381v15.238A2.384 2.384 0 0 0 4.381 22h15.238A2.384 2.384 0 0 0 22 19.619V4.381A2.384 2.384 0 0 0 19.619 2Z'
        fill='#4433E0'
      />
      <path
        d='M9.438 16.662a.339.339 0 0 1-.34.338H7.651a.339.339 0 0 1-.34-.338v-6.025c0-.187.152-.338.34-.338h1.447c.188 0 .34.151.34.338v6.025Zm-1.063-6.93A1.37 1.37 0 0 1 7 8.365 1.37 1.37 0 0 1 8.375 7c.759 0 1.374.611 1.374 1.366A1.37 1.37 0 0 1 8.375 9.73ZM17 16.688c0 .172-.14.311-.313.311h-1.552a.311.311 0 0 1-.313-.31v-2.827c0-.421.125-1.847-1.109-1.847-.957 0-1.151.976-1.19 1.414v3.26c0 .171-.14.31-.313.31H10.71a.312.312 0 0 1-.313-.31v-6.08c0-.172.14-.31.313-.31h1.501c.173 0 .313.138.313.31v.525c.355-.529.882-.937 2.005-.937 2.486 0 2.472 2.307 2.472 3.575v2.916Z'
        fill='#4433E0'
      />
    </svg>
  );
};

export default Linkedin;
