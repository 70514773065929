import {
  CardImage,
  CardServiceSelectionOrder,
  CardText,
  ContentImageCard,
} from './styles';

const CardServiceOrder = ({
  title,
  image,
  styleCard,
  styleCardMedia,
  styleCardHeader,
}) => {
  const imageService = image.data[0] || {};
  return (
    <>
      <CardServiceSelectionOrder sx={styleCard}>
        <ContentImageCard>
          <CardImage
            sx={styleCardMedia}
            image={imageService.attributes.url}
          />
        </ContentImageCard>
        <CardText
          title={title}
          sx={styleCardHeader}
        />
      </CardServiceSelectionOrder>
    </>
  );
};

export default CardServiceOrder;
