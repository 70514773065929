const SuccesObkoy = ({ width, height }) => (
  <svg
    width={width || 152}
    height={height || 152}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#a)'>
      <g clipPath='url(#b)'>
        <rect x={16} y={11} width={100} height={100} rx={8} fill='#4433E0' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M71.193 39.19a1.636 1.636 0 0 1-2.265-.484 1.644 1.644 0 0 1 .482-2.273l5.496-3.576a1.63 1.63 0 0 1 2.263.482 1.645 1.645 0 0 1-.48 2.272l-5.496 3.578Zm-16.218-3.908-3.478-5.568a1.645 1.645 0 0 1 1.759-2.472 1.632 1.632 0 0 1 1.02.73l3.478 5.567a1.642 1.642 0 0 1-1.756 2.471 1.634 1.634 0 0 1-1.02-.73l-.003.002Zm9.394-1.848a1.638 1.638 0 0 1-1.52-1.753l.455-6.554a1.632 1.632 0 0 1 1.749-1.516 1.64 1.64 0 0 1 1.519 1.745l-.456 6.554a1.632 1.632 0 0 1-1.112 1.442c-.204.069-.42.096-.635.082ZM91.202 72.65a5.488 5.488 0 0 1-1.545 7.593l-4.324 2.867a5.453 5.453 0 0 1-7.57-1.549 5.487 5.487 0 0 1 1.547-7.596l4.325-2.867a5.452 5.452 0 0 1 7.569 1.553h-.002ZM75.969 82.752a7.652 7.652 0 0 0 4.854 3.298l-7.415 4.868a19.702 19.702 0 0 1-19.878.948 19.65 19.65 0 0 1-7.56-6.995 19.589 19.589 0 0 1-2.974-9.85 6.11 6.11 0 0 1-.057-.747l-.332-27.417a6.79 6.79 0 0 1 2.09-4.76 6.823 6.823 0 0 1 4.848-1.896 6.817 6.817 0 0 1 4.892 1.778 6.785 6.785 0 0 1 2.202 4.706l.1 8.07L79.26 39.951a6.534 6.534 0 0 1 9.069 1.893 6.579 6.579 0 0 1-1.889 9.095l-10.958 7.201.02.03c-.146.083-.29.167-.433.263l-8.447 5.6a7.718 7.718 0 0 0-2.172 10.679 7.652 7.652 0 0 0 4.714 3.268 7.668 7.668 0 0 0 5.666-.923 7.704 7.704 0 0 0 1.137 5.694Zm.476-22.558a5.451 5.451 0 0 1 7.569 1.549 5.481 5.481 0 0 1-1.544 7.591l-5.34 3.54-3.094 2.053a5.452 5.452 0 0 1-7.57-1.549 5.489 5.489 0 0 1 1.545-7.593l8.434-5.59Z'
          fill='#F9F9F9'
        />
      </g>
    </g>
    <defs>
      <clipPath id='b'>
        <rect x={16} y={11} width={100} height={100} rx={8} fill='#fff' />
      </clipPath>
      <filter
        id='a'
        x={0}
        y={0}
        width={152}
        height={152}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx={10} dy={15} />
        <feGaussianBlur stdDeviation={13} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_4446_12231'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_4446_12231'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

export default SuccesObkoy;
