import LightWaddiLogo from '../../../assets/icons/LightWaddiLogo';
import { urls } from '../../Common/messages';
import { FooterParagraph, FooterParagraphEm, FooterParagraphStrong } from './FooterTrackingStyle';

const FooterTracking = () => {
  return (
    <>
      <a href={urls.waddiTracking} title='Waddi'>
        <LightWaddiLogo />
      </a>
      <FooterParagraph>
        Sorprende a tus clientes con el poder de la
        <FooterParagraphStrong>
          <FooterParagraphEm> Inteligencia Artificial.</FooterParagraphEm>
        </FooterParagraphStrong>
        🚀
      </FooterParagraph>
      <a href={urls.mailTracking} className='tracking-email-link'>
        {urls.mailTracking.replace('mailto:', '')}
      </a>
      <a href={urls.trackingTermsConditions} target='_blank' rel='noreferrer' className='tracking-footer-links'>
        Términos y condiciones
      </a>
      <a href={urls.trackingPrivacyPolicy} target='_blank' rel='noreferrer' className='tracking-footer-links'>
        Políticas de privacidad
      </a>
    </>
  );
};

export default FooterTracking;
