import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { alertStyle } from './AlertStyles';

const AlertMessage = ({
  openProp,
  handleClose,
  error,
  message,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!openProp);
  }, [openProp]);

  const positions = {
    y: 'top',
    x: 'center',
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: positions.y, horizontal: positions.x }}
      open={open}
      onClose={handleClose}
      key={positions.y + positions.x}
    >
      <Alert
        onClose={handleClose}
        sx={error ? undefined : alertStyle}
        severity={error ? 'error' : 'success'}
      >
        { message }
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
