export const contentCardQA = (mobile, desktop) => ({
  display: {
    xs: mobile, phoneMd: mobile, sm: mobile, md: desktop, lg: desktop, xl: desktop,
  },
  flexWrap: 'wrap',
  width: {
    xs: '100%', phoneMd: '100%', sm: '100%', md: '110%', lg: '110%', xl: '110%',
  },
  alignItems: 'baseline',
  justifyContent: {
    xs: 'center', phoneMd: 'center', sm: 'center', md: 'start', lg: 'start', xl: 'start',
  },
  position: {
    xs: 'relative', phoneMd: 'relative', sm: 'relative', md: 'static', lg: 'static', xl: 'static',
  },
  top: '-35px',
  paddingTop: {
    xs: '57px', phoneMd: '57px', sm: '57px', md: '0px', lg: '0px', xl: '0px',
  },
  zIndex: {
    xs: '25', phoneMd: '25', sm: '25', md: '0', lg: '0', xl: '0',
  },
  backgroundColor: {
    xs: 'menu.primary', phoneMd: 'menu.primary', sm: 'menu.primary', md: 'transparent', lg: 'transparent', xl: 'transparent',
  },
  borderTopLeftRadius: {
    xs: '30px', phoneMd: '30px', sm: '30px', md: '0px', lg: '0px', xl: '0px',
  },
  borderTopRightRadius: {
    xs: '30px', phoneMd: '30px', sm: '30px', md: '0px', lg: '0px', xl: '0px',
  },
  marginTop: {
    xs: '0px', phoneMobile: '0px', sm: '0px', md: '80px', lg: '80px', xl: '80px',
  },
  marginBottom: {
    xs: '47px', phoneMd: '47px', sm: '47px', md: '128px', lg: '128px', xl: '128px',
  },
});

export const styleCardQA = {
  width: {
    xs: '91.5%', phoneMd: '91.5%', sm: '91.5%', md: '43.8%', lg: '43.8%', xl: '43.8%',
  },
  height: 'auto',
  backgroundColor: 'secondary.main',
  padding: {
    xs: '16px', phoneMd: '16px', sm: '16px', md: '16px 24px 16px 24px', lg: '16px 24px 16px 24px', xl: '16px 24px 16px 24px',
  },
  borderRadius: {
    xs: '15px', phoneMd: '15px', sm: '15px', md: '20px', lg: '20px', xl: '20px',
  },
  boxShadow: '10px 15px 100px rgba(0, 0, 0, 0.06)',
  marginBottom: '25px',
  marginRight: {
    xs: '0px', phoneMd: '0px', sm: '0px', md: '32px', lg: '32px', xl: '32px',
  },
  overflow: 'initial',
};

export const contentText = {
  width: {
    xs: '110%', phoneMd: '110%', sm: '110%', md: '100%', lg: '100%', xl: '100%',
  },
  maxWidth: {
    xs: '311px', phoneMd: '311px', sm: '311px', md: '530px', lg: '530px', xl: '530px',
  },
  overflowY: {
    xs: 'initial', sm: 'initial', md: 'initial', lg: 'initial',
  },
  height: 'auto',
  '& > div': {
    padding: '0px',
  },
  '& > div:first-of-type': {
    color: 'primary.main',
    height: 'auto',
    '& span': {
      fontSize: '1.375rem',
      fontWeight: '800',
    },
  },
  '& > div:last-of-type': {
    width: '92%',
    height: 'auto',
    marginTop: {
      xs: '9px', phoneMd: '9px', sm: '9px', md: '16px', lg: '16px', xl: '16px',
    },
    padding: '0px',
    fontSize: '1.125rem',
    fontWeight: '500',
    lineHeight: '1.536875rem',
  },
};
