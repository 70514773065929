import {
  createContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { DataServicesSearch } from '../api';
import { LOCALSTORAGE_NAME_TYPES } from '../Common';
import { ServicesSearchMessage } from '../Common/messages';
import {
  getSelectorsData,
  getSelectorsLocation,
} from '../store/action';
import { initSearchServices, serviceReducer } from '../store/store';

export const ServiceSearchContext = createContext(null);

const {
  getServicesType,
  getStates,
} = DataServicesSearch;

const {
  nameRegistryUUid,
} = ServicesSearchMessage;
const ServiceSearchProvider = ({ children }) => {
  const [searchServices, dispatch] = useReducer(serviceReducer, [], initSearchServices);

  const searchServicesMemo = useMemo(() => [searchServices, dispatch], [searchServices, dispatch]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    sessionStorage.setItem(LOCALSTORAGE_NAME_TYPES.services, JSON.stringify(searchServices));
  }, [
    searchServices.service,
    searchServices.location,
    searchServices.date,
    searchServices.provider,
    searchServices?.serviceType,
  ]);

  // eslint-disable-next-line no-undef
  const registryUuidValue = JSON.parse(sessionStorage.getItem(nameRegistryUUid));
  useEffect(() => {
    if (!registryUuidValue) {
      getServicesType().then(servicesTypeResponse => {
        const { data } = servicesTypeResponse;
        dispatch(getSelectorsData(data));
      });
    }
  }, []);

  useEffect(() => {
    getStates().then(selectsLocationResponse => {
      let { data } = selectsLocationResponse;
      data = data.map((states, index) => {
        const sumByOne = 1;
        return {
          id: index + sumByOne,
          registryUuid: Math.random(),
          description: states.state,
        };
      });
      dispatch(getSelectorsLocation(data));
    });
  }, []);

  return (
    <ServiceSearchContext.Provider value={searchServicesMemo}>
      { children }
    </ServiceSearchContext.Provider>
  );
};

export default ServiceSearchProvider;
