import { CircularProgress, FormControl, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import { styledForm, styledItems, StyledSelect } from './styles';
import ServiceBillingLabel from '../ServiceBillingTextField/ServiceBillingLabel';
import { HelperText } from '../ServiceBillingTextField/styles';

const ServiceBillingSelect = ({
  placeholder,
  items,
  onChange,
  name,
  formStyles,
  valueDefault,
  labelText,
  itemStyles,
  error,
  messageError,
  loading,
  success,
}) => {
  const [value, setValue] = useState(valueDefault || '');

  useEffect(() => {
    if (!valueDefault) {
      setValue('');
    }
  }, [valueDefault]);

  const valueHandler = ({ target }) => {
    setValue(target.value);
    onChange({ value: target.value, name: target.name });
  };

  let styledError;
  let styledSuccess;

  if (error) {
    styledError = {
      marginBottom: { xs: '0rem', md: '0rem' },
      borderColor: { xs: 'webView.error', md: 'webView.error' },
    };
  }
  if (success) {
    styledSuccess = {
      marginBottom: { xs: '1.4rem', md: '1.25rem' },
      '& > .MuiSvgIcon-root': {
        color: 'webView.success',
        right: '4px',
      },
      '& > .MuiSvgIcon-root.MuiSelect-icon': {
        transform: 'rotate(0deg)',
      },
    };
  }
  return (
    <FormControl sx={{ ...styledForm, ...formStyles }}>
      <ServiceBillingLabel>{ labelText }</ServiceBillingLabel>
      {
        loading ? (
          <CircularProgress size='2rem' />
        ) : (
          <>
            <StyledSelect
              displayEmpty
              value={value}
              onChange={valueHandler}
              renderValue={(selected) => {
                if (!selected) {
                  return <span>{placeholder}</span>;
                }
                return selected;
              }}
              name={name}
              IconComponent={!success ? KeyboardArrowDownIcon : CheckCircleIcon}
              sx={error ? styledError : success ? styledSuccess : undefined}
            >
              <MenuItem disabled value=''>
                <span>{placeholder}</span>
              </MenuItem>
              {items && items.length ? items?.map((item) => {
                return (
                  <MenuItem
                    key={item?.id}
                    value={item.name}
                    sx={{ ...styledItems, ...itemStyles }}
                    disableTouchRipple={true}
                    disableRipple={true}
                  >
                    {item?.name}
                  </MenuItem>
                );
              }) : null}
            </StyledSelect>
            { error && <HelperText error={error}>{ messageError }</HelperText> }
          </>
        )
      }
    </FormControl>
  );
};

export default ServiceBillingSelect;

ServiceBillingSelect.defaulProps = {
  success: false,
};
