import { coverageEnums, TYPES_PARAMS_STRAPI } from '../Common';
import { downloads, pathViews, textPersonalizedHelp } from '../Common/messages';
import { urls } from '../Common/messages/paths';
import {
  WhatsApp,
  Telephone,
  Mail,
  Facebook,
  Instagram,
  Tiktok,
  Linkedin,
} from '../../assets/icons';
import { ApiUtils } from '../utils';
import SaveTimeImageDesktopDefault from '../../assets/img/SaveTimeImage.jpg';
import SaveTimeImageMobileDefault from '../../assets/img/SaveTimeImageMobile.jpg';

const { validDataQuery, apiDefaultWithParams } = ApiUtils;

const { blog: { app, childrens }, pathTrackOrder: { path: pathTrackingOrder } } = pathViews;
const { landing: { populate } } = TYPES_PARAMS_STRAPI;
const {
  components,
  howHelpYouSection,
  ourServicesSection,
  bannerCoupon,
  instructionsSection,
  coverageSection,
  testimonialsSection,
  bannerHelpSection,
  footer,
  viewFrequentQuestions,
} = populate;

const getDataLandingGeneral = async (path) => {
  const dataLanding = await validDataQuery(path, { components });
  /* landing navbar */
  const {
    benefits,
    services,
    coverage,
    orderTracking,
    blog,
    downloadApp,
  } = dataLanding.attributes.landingNavbar;
  const textNavBar = [
    benefits,
    services,
    coverage,
    orderTracking,
    blog,
    downloadApp,
  ];
  const textBlog = textNavBar[4];
  const textTrackOrder = textNavBar[3];

  const { askForGas } = dataLanding.attributes;
  return {
    listLinks: textNavBar.map(text => {
      if (text === textBlog) {
        return {
          text,
          pathName: `${app}${childrens.home}`,
        };
      }
      if (text === textTrackOrder) {
        return {
          text,
          pathName: pathTrackingOrder,
        };
      } else if (text === textNavBar[textNavBar.length - 1]) {
        return {
          text,
          pathName: downloads.downLoadApp,
          prevent: (e) => { e.preventDefault(e); },
        };
      } else {
        return {
          text,
          pathName: `#${text.toLowerCase()}`,
        };
      }
    }),
    textAlert: {
      textTitle: askForGas.orderText,
      urlText: askForGas.orderButton,
      url: askForGas.url,
    },
  };
};

const dataSaveTime = {
  data: [
    {
      id: 3,
      attributes: {
        createdAt: '2022-08-23T22:47:55.339Z',
        updatedAt: '2023-02-13T19:36:47.816Z',
        publishedAt: '2022-08-24T14:54:45.956Z',
        webSiteTitle: 'landing',
        slug: 'landing',
        landingNavbar: {
          id: 9,
          benefits: 'Beneficios',
          services: 'Servicios',
          coverage: 'Cobertura',
          blog: 'Blog',
          downloadApp: 'Descarga la App',
          orderTracking: 'Seguir mi pedido',
        },
        askForGas: {
          id: 7,
          orderText: 'Pide Gas En Nuestra Web',
          orderButton: 'Pedir Gas',
          url: '/services/pedir-gas/',
        },
        imageDownload: {
          id: 7,
          imageIOS: {
            data: [
              {
                id: 16,
                attributes: {
                  name: 'AppStore.png',
                  alternativeText: 'AppStore.png',
                  caption: 'AppStore.png',
                  width: 332,
                  height: 112,
                  formats: {
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_App_Store_05015dfc5f.png',
                      hash: 'thumbnail_App_Store_05015dfc5f',
                      mime: 'image/png',
                      name: 'thumbnail_AppStore.png',
                      path: null,
                      size: 12.39,
                      width: 245,
                      height: 83,
                    },
                  },
                  hash: 'App_Store_05015dfc5f',
                  ext: '.png',
                  mime: 'image/png',
                  size: 3.8,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/App_Store_05015dfc5f.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T19:39:51.831Z',
                  updatedAt: '2022-08-23T19:39:51.831Z',
                },
              },
            ],
          },
          imageAndroid: {
            data: [
              {
                id: 15,
                attributes: {
                  name: 'GooglePlay.png',
                  alternativeText: 'GooglePlay.png',
                  caption: 'GooglePlay.png',
                  width: 330,
                  height: 112,
                  formats: {
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_Google_Play_a31893fe17.png',
                      hash: 'thumbnail_Google_Play_a31893fe17',
                      mime: 'image/png',
                      name: 'thumbnail_GooglePlay.png',
                      path: null,
                      size: 12.1,
                      width: 245,
                      height: 83,
                    },
                  },
                  hash: 'Google_Play_a31893fe17',
                  ext: '.png',
                  mime: 'image/png',
                  size: 3.58,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Google_Play_a31893fe17.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-23T19:39:15.990Z',
                  updatedAt: '2022-08-23T19:39:15.990Z',
                },
              },
            ],
          },
        },
        saveTime: {
          id: 8,
          title: 'Pide todos los servicios de tu hogar en un clic',
          subTitle: 'Descarga la App',
          imageIphone: {
            data: [
              {
                id: 103,
                attributes: {
                  name: 'SCREEN1.5.png',
                  alternativeText: 'SCREEN1.5.png',
                  caption: 'SCREEN1.5.png',
                  width: 1000,
                  height: 1000,
                  formats: {
                    small: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_SCREEN_1_5_0728da8656.png',
                      hash: 'small_SCREEN_1_5_0728da8656',
                      mime: 'image/png',
                      name: 'small_SCREEN1.5.png',
                      path: null,
                      size: 150.2,
                      width: 500,
                      height: 500,
                    },
                    medium: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/medium_SCREEN_1_5_0728da8656.png',
                      hash: 'medium_SCREEN_1_5_0728da8656',
                      mime: 'image/png',
                      name: 'medium_SCREEN1.5.png',
                      path: null,
                      size: 303.43,
                      width: 750,
                      height: 750,
                    },
                    thumbnail: {
                      ext: '.png',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_SCREEN_1_5_0728da8656.png',
                      hash: 'thumbnail_SCREEN_1_5_0728da8656',
                      mime: 'image/png',
                      name: 'thumbnail_SCREEN1.5.png',
                      path: null,
                      size: 23.51,
                      width: 156,
                      height: 156,
                    },
                  },
                  hash: 'SCREEN_1_5_0728da8656',
                  ext: '.png',
                  mime: 'image/png',
                  size: 111.96,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/SCREEN_1_5_0728da8656.png',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-11-01T16:21:54.045Z',
                  updatedAt: '2022-11-01T16:21:54.045Z',
                },
              },
            ],
          },
          backgroundImageMobile: {
            data: [
              {
                id: 52,
                attributes: {
                  name: 'SaveTimeImageMobile.jpg',
                  alternativeText: 'SaveTimeImageMobile.jpg',
                  caption: 'SaveTimeImageMobile.jpg',
                  width: 750,
                  height: 1338,
                  formats: {
                    large: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/large_Save_Time_Image_Mobile_15e0284a4d.jpg',
                      hash: 'large_Save_Time_Image_Mobile_15e0284a4d',
                      mime: 'image/jpeg',
                      name: 'large_SaveTimeImageMobile.jpg',
                      path: null,
                      size: 57.77,
                      width: 561,
                      height: 1000,
                    },
                    small: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_Save_Time_Image_Mobile_15e0284a4d.jpg',
                      hash: 'small_Save_Time_Image_Mobile_15e0284a4d',
                      mime: 'image/jpeg',
                      name: 'small_SaveTimeImageMobile.jpg',
                      path: null,
                      size: 20.08,
                      width: 280,
                      height: 500,
                    },
                    medium: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/medium_Save_Time_Image_Mobile_15e0284a4d.jpg',
                      hash: 'medium_Save_Time_Image_Mobile_15e0284a4d',
                      mime: 'image/jpeg',
                      name: 'medium_SaveTimeImageMobile.jpg',
                      path: null,
                      size: 37.36,
                      width: 420,
                      height: 750,
                    },
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_Save_Time_Image_Mobile_15e0284a4d.jpg',
                      hash: 'thumbnail_Save_Time_Image_Mobile_15e0284a4d',
                      mime: 'image/jpeg',
                      name: 'thumbnail_SaveTimeImageMobile.jpg',
                      path: null,
                      size: 3.09,
                      width: 87,
                      height: 156,
                    },
                  },
                  hash: 'Save_Time_Image_Mobile_15e0284a4d',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 89.46,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Save_Time_Image_Mobile_15e0284a4d.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:26:15.338Z',
                  updatedAt: '2022-08-25T20:26:15.338Z',
                },
              },
            ],
          },
          backgroundImageDesktop: {
            data: [
              {
                id: 101,
                attributes: {
                  name: 'Banner1.jpg',
                  alternativeText: 'Banner1.jpg',
                  caption: 'Banner1.jpg',
                  width: 1175,
                  height: 614,
                  formats: {
                    large: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/large_Banner1_60c0c25674.jpg',
                      hash: 'large_Banner1_60c0c25674',
                      mime: 'image/jpeg',
                      name: 'large_Banner1.jpg',
                      path: null,
                      size: 53.83,
                      width: 1000,
                      height: 523,
                    },
                    small: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_Banner1_60c0c25674.jpg',
                      hash: 'small_Banner1_60c0c25674',
                      mime: 'image/jpeg',
                      name: 'small_Banner1.jpg',
                      path: null,
                      size: 17.36,
                      width: 500,
                      height: 261,
                    },
                    medium: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/medium_Banner1_60c0c25674.jpg',
                      hash: 'medium_Banner1_60c0c25674',
                      mime: 'image/jpeg',
                      name: 'medium_Banner1.jpg',
                      path: null,
                      size: 33.73,
                      width: 750,
                      height: 392,
                    },
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_Banner1_60c0c25674.jpg',
                      hash: 'thumbnail_Banner1_60c0c25674',
                      mime: 'image/jpeg',
                      name: 'thumbnail_Banner1.jpg',
                      path: null,
                      size: 5.47,
                      width: 245,
                      height: 128,
                    },
                  },
                  hash: 'Banner1_60c0c25674',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 69.36,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Banner1_60c0c25674.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-11-01T16:11:50.761Z',
                  updatedAt: '2022-11-01T16:11:50.761Z',
                },
              },
            ],
          },
        },
        howHelpYouSection: {
          id: 1,
          title: '¿Cómo te ayudamos a ahorrar tiempo?',
          prevButton: 'Anterior',
          nextButton: 'Siguiente',
        },
        ourServicesSection: {
          id: 2,
        },
        instructionsSection: {
          id: 1,
          oneInstruction: 'Pide y agenda tu servicio, el día y hora que más te convenga.',
          secondInstruction: 'Te conectamos con los mejores proveedores.',
          thirdInstruction: 'Elige pagar como quieras, estés o no en tu hogar.',
          fourthInstruction: 'Sigue el trayecto de tu servicio y conoce el nombre y foto del aliado.',
          fifthInstruction: 'Disfruta de tu servicio con garantías, si algo sale mal, lo resolvemos.',
        },
        coverageSection: [
          {
            id: 1,
          },
          {
            id: 2,
          },
        ],
        testimonialsSection: {
          id: 1,
          title: 'Lo que opinan nuestros clientes',
        },
        bannerHelpSection: [
          {
            id: 1,
            title: '¿Necesitas ayuda?',
            contentText: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
          },
        ],
        footer: {
          id: 1,
          address: 'Zamora 187, Condesa Ciudad De México, 06140 México',
          headingDownload: '¡Descarga la App, es gratis!',
        },
        viewFrequentQuestions: {
          id: 1,
        },
        seo: {
          id: 10,
          metaTitle: 'OKBOY -  Somos tu aliado administrando tu hogar',
          metaDescription: 'Descripción de prueba para landing page asdasd asdasdas',
          keywords: 'pedir gas, gas, gas lp, lavandería, planchaduria, mantenimiento, hogar, limpieza, servicios, internet, luz, recargas, plomero, gastos, pagar recibos',
          metaRobots: 'noindex,follow',
          structuredData: null,
          metaViewport: null,
          canonicalURL: null,
        },
        SEOFreceuntQuestions: [
          {
            id: 11,
            metaTitle: 'Preguntas Frecuentes',
            metaDescription: 'Acá encontrarás las precuntas mas freceuntes, lee cuidadosamente y llámanos en caso de que tengas más dudas',
            keywords: '',
            metaRobots: '',
            structuredData: null,
            metaViewport: null,
            canonicalURL: null,
          },
        ],
      },
    },
  ],
  meta: {
    pagination: {
      page: 1,
      pageSize: 25,
      pageCount: 1,
      total: 1,
    },
  },
};

const getSaveTime = async () => {
  const dataLanding = dataSaveTime.data[0] || {};
  const {
    data: dataBackgrundImageDesktop,
  } = dataLanding.attributes.saveTime.backgroundImageDesktop;
  const backgrundImageDesktop = dataBackgrundImageDesktop[0] || {};
  const { data: dataBackgroundImageMobile } = dataLanding.attributes.saveTime.backgroundImageMobile;
  const backgroundImageMobile = dataBackgroundImageMobile[0] || {};
  const { title: textH1, subTitle: textH3, imageIphone } = dataLanding.attributes.saveTime;
  const dataImagePhone = imageIphone.data[0] || {};
  const imagePhone = {
    image: dataImagePhone.attributes.url,
    alternativeText: dataImagePhone.attributes.alternativeText,
  };

  const dataImageAndroid = dataLanding.attributes.imageDownload.imageAndroid.data[0] || {};
  const dataImageIOS = dataLanding.attributes.imageDownload.imageIOS.data[0] || {};

  const imageIOS = {
    image: dataImageIOS.attributes.url,
    alternativeText: dataImageIOS.attributes.alternativeText,
  };
  const imageAndroid = {
    image: dataImageAndroid.attributes.url,
    alternativeText: dataImageAndroid.attributes.alternativeText,
  };

  return {
    texts: {
      textH1,
      textH3,
    },
    imagePhone,
    backgroundImage: {
      desktop: backgrundImageDesktop.attributes.url || SaveTimeImageDesktopDefault,
      mobile: backgroundImageMobile.attributes.url || SaveTimeImageMobileDefault,
    },
    imageDownloadApp: { imageIOS, imageAndroid },
  };
};

const getSuggestions = async (path) => {
  const params = `${howHelpYouSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const { howHelpYouSection: suggestionsSection } = dataLanding.attributes;
  const {
    title: textHeader, prevButton, nextButton, cardHelpSection,
  } = suggestionsSection;
  return {
    textHeader,
    nextButton,
    prevButton,
    textSuggestions: cardHelpSection.map((card) => {
      const { imageDesktop, imageMobile } = card;
      const dataImageMobile = imageMobile.data[0] || {};
      return {
        img: imageDesktop.data.attributes.url,
        imgMobile: dataImageMobile.attributes.url,
        title: card.title,
        textContent: card.contentText,
      };
    }),
  };
};

const getOurServices = async (path) => {
  const params = `${ourServicesSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const ourServices = dataLanding.attributes.ourServicesSection;
  const { textDownloader, imageAndTitleServices } = ourServices;

  return {
    services: {
      header: textDownloader.title,
      title: textDownloader.subTitle,
      text: textDownloader.contentText,
      downloadText: textDownloader.button,
    },
    imageAndTitleServices,
  };
};

const getBannerCoupon = async (path) => {
  const params = `${bannerCoupon}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const dataBannerCoupon = dataLanding.attributes.bannerCoupon;
  const dataImageCoupon = dataBannerCoupon.imageCoupon.data[0] || {};
  return {
    title: dataBannerCoupon.title,
    nameCoupon: dataBannerCoupon.nameCoupon,
    imageCoupon: dataImageCoupon.attributes.url,
    footerCoupon: dataBannerCoupon.footerCoupon,
  };
};

const getInstructionsSections = async (path) => {
  const params = `${instructionsSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const instructionsSections = dataLanding.attributes.instructionsSection;
  const {
    oneInstruction,
    secondInstruction,
    thirdInstruction,
    fourthInstruction,
    fifthInstruction,
    backgroundImageDesktop,
    backgroundImageMobile,
  } = instructionsSections;
  const contentText = [
    oneInstruction,
    secondInstruction,
    thirdInstruction,
    fourthInstruction,
    fifthInstruction,
  ];
  return {
    listInstruccions: contentText.map(text => ({
      text,
    })),
    backgroundImageDesktop: backgroundImageDesktop.data.attributes.url,
    backgroundImageMobile: backgroundImageMobile.data[0].attributes.url,
  };
};

const getCoverage = async (path) => {
  const params = `${coverageSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const coverageSectionData = dataLanding.attributes.coverageSection;
  const dataCoverageSectionData = coverageSectionData.sort((a, b) => a.id - b.id);
  const sectionBenefits = coverageEnums.sectionThird;
  const dataSectionBenefits = dataCoverageSectionData.find(
    (e, index) => index === sectionBenefits,
  ) || null;
  return {
    dataCoverageSectionData: dataCoverageSectionData.slice(0, 2).sort((a, b) => b.id - a.id),
    dataSectionBenefits,
  };
};

const getTestimonials = async (path) => {
  const params = `${testimonialsSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const dataTestimonialsSection = dataLanding.attributes.testimonialsSection;
  const { title, cardTestimonials } = dataTestimonialsSection;
  const ratingEnum = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
  };
  return {
    title,
    textTestimonials: cardTestimonials.map((card) => {
      return {
        nameUserTestimonials: card.nameTitle,
        textTestimonials: card.textContent,
        textTypeService: card.typeService,
        valueRating: ratingEnum[card.rating],
        avatar: card.imageUser.data.attributes.url,
      };
    }),
  };
};

const getBannerHelpSection = async (path) => {
  const params = `${bannerHelpSection}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const bannerHelpSectionData = dataLanding.attributes.bannerHelpSection[0] || {};

  const whatsAppLink = bannerHelpSectionData.buttonLink[0] || {};
  const quetionsLink = bannerHelpSectionData.buttonLink[1] || {};
  return {
    titles: {
      titleMain: bannerHelpSectionData.title,
      titleSecondary: bannerHelpSectionData.contentText,
    },
    textsUrls: {
      whatsApp: whatsAppLink.title,
      quetions: quetionsLink.title,
    },
    url: urls.whatsApp,
  };
};

const addSection = (icon, text, path, typeLink = false) => {
  return icon ? {
    icon,
    text,
    typeNavLink: typeLink,
    path,
  } : {
    text,
    typeNavLink: typeLink,
    path,
  };
};

const getFooterData = async (path) => {
  const params = `${footer}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const footerData = dataLanding.attributes.footer;
  const {
    address,
    headingDownload,
    sectionFooter,
  } = footerData;
  const downloaderImages = footerData.imageDownload;
  const { imageAndroid, imageIOS } = downloaderImages;

  const dataImageAndroid = imageAndroid.data[0] || {};
  const dataImageIOS = imageIOS.data[0] || {};

  const titlesSections = [
    'Ayuda',
    'Síguenos',
    'Navegar',
    'Aviso de privacidad',
  ];
  const textHelpSection = [
    'WhatsApp',
    '+52 33 8526 1422',
    'hola@okboy.app',
  ];
  const textFollowNavigation = [
    'Facebook',
    'Instagram',
    'TikTok',
    'LinkedIn',
  ];
  const textNavigation = [
    'Preguntas frecuentes',
    'Pedir Gas en Web',
  ];
  const textPrivacySection = [
    'Términos y condiciones',
    'Políticas de privacidad',
    'Políticas de cancelación',
  ];

  /* Helpfooter */
  let helpFooter = sectionFooter.find((section) => {
    return section.title === titlesSections[0];
  });
  const titleHelpFooter = helpFooter.title;
  helpFooter = Object.values(helpFooter).map(section => {
    return section === textHelpSection[0] ? addSection(
      <WhatsApp />,
      section,
      urls.whatsApp,
    ) : section === textHelpSection[1] ? addSection(
      <Telephone />,
      section,
      urls.telephone,
    ) : section === textHelpSection[2] && addSection(
      <Mail />,
      section,
      urls.mail,
    );
  }).filter(section => section);

  /* followSection */

  let folowSection = sectionFooter.find((section) => {
    return section.title === titlesSections[1];
  });

  const titleFollowSection = folowSection.title;

  folowSection = Object.values(folowSection).map(section => {
    return section === textFollowNavigation[0] ? addSection(
      <Facebook />,
      section,
      urls.facebook,
    ) : section === textFollowNavigation[1] ? addSection(
      <Instagram />,
      section,
      urls.instagram,
    ) : section === textFollowNavigation[2] ? addSection(
      <Tiktok />,
      section,
      urls.tiktok,
    ) : section === textFollowNavigation[3] && addSection(
      <Linkedin />,
      section,
      urls.linkedIn,
    );
  }).filter(section => section);

  /* navigationSection */

  let navigationSection = sectionFooter.find((section) => {
    return section.title === titlesSections[2];
  });

  const titlenavigationSection = navigationSection.title;

  const pathFrecuentQuestions = pathViews.frecuentQuestions;
  const pathOrderGas = `${pathViews.pathServices.app}${pathViews.pathServices.services.gas}`;
  navigationSection = Object.values(navigationSection).map(section => {
    return section === textNavigation[0] ? addSection(
      false,
      section,
      pathFrecuentQuestions,
      true,
    ) : section === textNavigation[1] && addSection(
      false,
      section,
      pathOrderGas,
      true,
    );
  }).filter(section => section);

  /* sectionPrivacyFooter */

  let privacySection = sectionFooter.find((section) => {
    return section.title === titlesSections[3];
  });
  const titlePivacySection = privacySection.title;
  privacySection = Object.values(privacySection).map(section => {
    return section === textPrivacySection[0] ? {
      ...addSection(
        false,
        section,
        pathViews.termsConditions,
        true,
      ),
      prevent: (e) => { e.preventDefault(); },
    } : section === textPrivacySection[1] ? {
      ...addSection(
        false,
        section,
        pathViews.privacyPolicy,
        true,
      ),
      prevent: (e) => { e.preventDefault(); },
    } : section === textPrivacySection[2] && {
      ...addSection(
        false,
        section,
        pathViews.cancellationPolicies,
        true,
      ),
      prevent: (e) => { e.preventDefault(); },
    };
  }).filter(section => section);

  return {
    address,
    headingDownload,
    dataHelpFooter: {
      texts: helpFooter,
      title: titleHelpFooter,
    },
    dataFollowFooter: {
      texts: folowSection,
      title: titleFollowSection,
    },
    dataNavigationFooter: {
      texts: navigationSection,
      title: titlenavigationSection,
    },
    dataPrivacyFooter: {
      text: privacySection,
      title: titlePivacySection,
    },
    images: {
      appStore: {
        image: dataImageIOS.attributes.url,
        alternativeText: dataImageIOS.attributes.alternativeText,
      },
      playStore: {
        image: dataImageAndroid.attributes.url,
        alternativeText: dataImageAndroid.attributes.alternativeText,
      },
    },
    dataTitleBlog: {
      sectionMoreContent: 'Para más contenido',
      sectionNavigationBlogPost: 'Navegar',
      sectionSubscribe: 'Subscríbete',
    },
  };
};

const getDataFrecuentQuestions = async (path) => {
  const params = `${viewFrequentQuestions}`;
  const dataLanding = await apiDefaultWithParams(path, { params, components });
  const viewFrequentQuestionsData = dataLanding.attributes.viewFrequentQuestions;
  const { bannerHeader, cardQuestions, bannerSection } = viewFrequentQuestionsData;
  return {
    dataBannerHeader: {
      titles: {
        titleMain: bannerHeader.title,
        titleSecondaryDesktop: bannerHeader.subTitle,
        titleSecondaryMobile: textPersonalizedHelp.titles.titleSecondaryMobile,
      },
      backgroundImages: {
        desktop: bannerHeader.backgroundImageDesktop.data[0].attributes.url,
        mobile: bannerHeader.backgroundImageMobile.data[0].attributes.url,
      },
      textUrl: bannerHeader.button,
    },
    dataQa: cardQuestions.map(card => {
      return {
        title: card.title,
        textContent: card.textContent,
      };
    }),
    dataSectionPersonalizedHelp: {
      titles: {
        titleMain: bannerSection.title,
        titleSecondary: bannerSection.contentText,
      },
      textsUrls: {
        watsApp: bannerSection.button,
      },
      url: urls.whatsApp,
    },
  };
};

const getDataSeoLanding = async () => {
  const seo = {
    id: 18,
    metaTitle: 'OKBOY -  Somos tu aliado administrando tu hogar',
    metaDescription: 'Descripción de prueba para landing page asdasd asdasdas',
    keywords: 'pedir gas, gas, gas lp, lavandería, planchaduria, mantenimiento, hogar, limpieza, servicios, internet, luz, recargas, plomero, gastos, pagar recibos',
    metaRobots: 'noindex,follow',
    structuredData: null,
    metaViewport: null,
    canonicalURL: null,
  };
  return {
    metaTitle: seo.metaTitle,
    metaDescription: seo.metaDescription,
    keywords: seo.keywords,
    metaRobots: seo.metaRobots,
    structuredData: seo.structuredData || {},
  };
};

const getDataSeoFreceuntQuestions = async () => {
  const dataLanding = {
    id: 5,
    attributes: {
      createdAt: '2022-10-14T12:35:20.250Z',
      updatedAt: '2022-10-26T16:09:55.173Z',
      publishedAt: '2022-10-14T12:44:02.137Z',
      webSiteTitle: 'zapopan',
      slug: 'zapopan',
      landingNavbar: {
        id: 11,
        benefits: 'Beneficios',
        services: 'Servicios',
        coverage: 'Cobertura',
        blog: 'Blog',
        downloadApp: 'Descarga la App',
        orderTracking: 'Seguir mi pedido',
      },
      askForGas: {
        id: 9,
        orderText: 'Sin comisión Ni costo de envió en octubre.',
        orderButton: 'Pide ya',
        url: 'https://okboy.app.link/okboy-web',
      },
      imageDownload: {
        id: 10,
      },
      saveTime: {
        id: 10,
        title: 'Servicios para el hogar sin costos extras',
        subTitle: 'Limpieza, lavandería, gas, jardinería, restaurantes y mucho más en una sola app. ',
      },
      howHelpYouSection: {
        id: 2,
        title: 'Beneficios de pedir en Okboy',
        prevButton: 'Anterior',
        nextButton: 'Siguiente',
      },
      ourServicesSection: {
        id: 3,
      },
      instructionsSection: {
        id: 2,
        oneInstruction: 'Pide y agenda tu servicio, el día y hora que más te convenga.',
        secondInstruction: 'Te conectamos con los mejores proveedores.',
        thirdInstruction: 'Elige pagar como quieras, estés o no en tu hogar.',
        fourthInstruction: 'Sigue el trayecto de tu servicio y conoce el nombre y foto del aliado.',
        fifthInstruction: 'Disfruta de tu servicio con garantías, si algo sale mal, lo resolvemos.',
      },
      coverageSection: [
        {
          id: 3,
        },
      ],
      testimonialsSection: {
        id: 2,
        title: 'Lo que opinan nuestros clientes',
      },
      bannerHelpSection: [
        {
          id: 2,
          title: '¿Necesitas ayuda?',
          contentText: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
        },
      ],
      footer: {
        id: 2,
        address: 'Zamora 187, Condesa Ciudad De México, 06140 México',
        headingDownload: '¡Descarga la App, es gratis!',
      },
      viewFrequentQuestions: {
        id: 2,
      },
      seo: {
        id: 18,
        metaTitle: 'OKBOY -  Somos tu aliado administrando tu hogar',
        metaDescription: 'Descripción de prueba para landing page asdasd asdasdas',
        keywords: 'pedir gas, gas, gas lp, lavandería, planchaduria, mantenimiento, hogar, limpieza, servicios, internet, luz, recargas, plomero, gastos, pagar recibos',
        metaRobots: 'noindex,follow',
        structuredData: null,
        metaViewport: null,
        canonicalURL: null,
      },
      SEOFreceuntQuestions: [
        {
          id: 19,
          metaTitle: 'Preguntas Frecuentes',
          metaDescription: 'Acá encontrarás las precuntas mas freceuntes, lee cuidadosamente y llámanos en caso de que tengas más dudas',
          keywords: null,
          metaRobots: null,
          structuredData: null,
          metaViewport: null,
          canonicalURL: null,
        },
      ],
    },
  };
  const { SEOFreceuntQuestions } = dataLanding.attributes;
  const dataSeoFrecuentQuestions = SEOFreceuntQuestions[0] || {};
  return {
    metaTitle: dataSeoFrecuentQuestions.metaTitle,
    metaDescription: dataSeoFrecuentQuestions.metaDescription,
    keywords: dataSeoFrecuentQuestions.keywords,
    metaRobots: dataSeoFrecuentQuestions.metaRobots,
    structuredData: dataSeoFrecuentQuestions.structuredData || {},
  };
};

export {
  getDataLandingGeneral,
  getSaveTime,
  getSuggestions,
  getOurServices,
  getBannerCoupon,
  getInstructionsSections,
  getCoverage,
  getTestimonials,
  getBannerHelpSection,
  getFooterData,
  getDataFrecuentQuestions,
  getDataSeoLanding,
  getDataSeoFreceuntQuestions,
};
