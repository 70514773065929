/* eslint-disable no-undef */
import { Box, List, ListItem } from '@mui/material';

import { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  linkDownloadApp, noBar, typesTarget, TYPES_ICON,
} from '../../Common';
import { pathViews } from '../../Common/messages';
import { SectionScroll } from '../../Contexts/SectionScroll';
import ButtonMenu from '../ButtonMenu/ButtonMenu';
import ImageIcon from '../ImageIcon/ImageIcon';
import ListItems from './ListItems';
import { linkItemStyles, linkItemStylesBlog } from './ListLinksStyles';

const LINK_TYPE = 'link';
const COMPONENT_TYPE = 'a';
const textBlog = 'Blog';
const textTrackOrder = 'Seguir mi pedido';

const ListLinks = ({ listLinks, widthImageIcon }) => {
  const listId = (Math.random() / Math.random()).toString();
  const { pathname } = useLocation();
  const { setScrollSectionHome } = useContext(SectionScroll);
  const refLinks = useRef(null);
  refLinks.current = [];
  const typeScroll = 'smooth';
  const atribute = 'href';
  const lastLink = 2;

  const getLinks = (el) => {
    if (el && !refLinks.current.includes(el)) refLinks.current.push(el);
  };
  const handleEventScrollSection = (e) => {
    const { target } = e;
    const { parentNode } = target;
    const linkElement = !target.getAttribute(atribute)
      ? parentNode.parentNode.getAttribute(atribute)
      : target.getAttribute(atribute);
    refLinks.current.forEach((link, i) => {
      e.preventDefault();
      if (target === link && i <= lastLink) {
        const section = document.querySelector(linkElement).offsetTop;
        window.scroll({
          top: section,
          behavior: typeScroll,
        });
      }
    });
  };

  const handleLink = ({ target }) => {
    if (pathname !== pathViews.home) {
      setScrollSectionHome(`#${target.text.toLowerCase()}`);
    }
  };

  return (
    <>
      {widthImageIcon && (
      <ImageIcon typeIcon={
        pathname.slice(0, noBar) !== pathViews.blog.app ? TYPES_ICON.landing : TYPES_ICON.blog
        }
      />
      )}
      <List sx={
        pathname.slice(0, noBar) !== pathViews.blog.app
          ? linkItemStyles
          : { ...linkItemStyles, ...linkItemStylesBlog }
      }
      >
        {listLinks?.map((link) => {
          if (link.text !== linkDownloadApp) {
            if (link.text !== textBlog || link.text !== textTrackOrder) {
              return (
                <ListItems
                  event={handleEventScrollSection}
                  refLinks={getLinks}
                  key={`${listId}${link.text}`}
                  {...link}
                  typeList={LINK_TYPE}
                  eventLink={handleLink}
                />
              );
            } else {
              return (
                <ListItems
                  key={`${listId}${link.text}`}
                  {...link}
                  typeList={LINK_TYPE}
                />
              );
            }
          } else {
            return (
              <ListItem key={`${listId}${link.text}`}>
                <Box
                  component={COMPONENT_TYPE}
                  href={link.pathName}
                  target={typesTarget.TYPE_TARGET_BLANK}
                >
                  {link.text}
                </Box>
              </ListItem>
            );
          }
        })}
      </List>
      <ButtonMenu />
    </>
  );
};

ListLinks.defaultProps = {
  widthImageIcon: true,
};

export default ListLinks;
