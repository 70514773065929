import { TYPES_PARAMS_STRAPI } from '../Common';
import axiosStrapi from '../Configs/axios';
import SaveTimeImageDesktopDefault from '../../assets/img/SaveTimeImage.jpg';
import SaveTimeImageMobileDefault from '../../assets/img/SaveTimeImageMobile.jpg';

const {
  download,
} = TYPES_PARAMS_STRAPI;

const getDownloadData = async () => {
  const query = new URLSearchParams({ populate: download.populate });
  const { data: { data } } = await axiosStrapi.get(`downloads?${query}`);
  let downLoadData = data[0] || {};
  downLoadData = downLoadData?.attributes?.download[0] || {};
  let appDownLoadData = data[0] || {};
  appDownLoadData = appDownLoadData?.attributes?.appDownload.map(item => {
    const image = item.image.data[0] || {};
    return {
      url: item?.url,
      image: image?.attributes?.url,
      alternativeText: image?.attributes?.alternativeText,
    };
  });

  const imagePhoneData = downLoadData?.imageIphone?.data[0] || {};
  const imagePhone = {
    image: imagePhoneData.attributes.url,
    alternativeText: imagePhoneData.attributes.alternativeText,
  };

  let backgroundImageModileData = downLoadData?.backgroundImageMobile?.data[0] || {};
  backgroundImageModileData = backgroundImageModileData?.attributes?.url || {};

  let backgroundImageDesktopData = downLoadData?.backgroundImageDesktop?.data[0] || {};
  backgroundImageDesktopData = backgroundImageDesktopData?.attributes?.url || {};

  return {
    texts: {
      textH1: downLoadData?.title,
      textH3: downLoadData?.subTitle,
    },
    imagePhone,
    backgroundImage: {
      desktop: backgroundImageDesktopData || SaveTimeImageDesktopDefault,
      mobile: backgroundImageModileData || SaveTimeImageMobileDefault,
    },
    imageDownloadApp: {
      imageIOS: appDownLoadData[0] || {}, imageAndroid: appDownLoadData[1] || {},
    },
  };
};

export {
  getDownloadData,
};
