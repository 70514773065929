export const titleMain = {
  fontSize: '2.5rem',
  fontWeight: '900',
  marginBottom: '29px',
};

export const subTitle = {
  fontSize: '1.375rem',
  fontWeight: '800',
  lineHeight: '1.878125rem',
};

export const secondSubTitle = {
  fontWeight: '500',
  marginBottom: '29px',
};

export const listStyles = {
  listStyle: 'disc',
  paddingLeft: '34px',
  fontWeight: '500',
  fontSize: '1.375rem',
};

export const linkList = {
  '& > li:nth-of-type(2)': {
    width: '101%',
  },
  '& a': {
    fontWeight: '900',
    color: 'primary.main',
  },
};
