import {
  captionStepper,
  DetailsEvidence,
  SectionEvidenceService,
  styledEvidenceTitle,
  DateTime,
} from './styles';
import { typesColors, TYPES_COMPONENTS, variantTypography } from '../../Common';
import { Captions, EvidenceCard } from '../../Components';
import 'swiper/css';
import './styles.css';
import { formatDate, formatHour } from '../../utils/date';

const LayoutEvidence = ({ evidenceData, evidenceTitle }) => {
  return (
    <SectionEvidenceService component={TYPES_COMPONENTS.section}>
      {
        !evidenceData?.length
          ? null : (
            <>
              <Captions
                color={typesColors.textSecondary}
                variant={variantTypography.h2}
                style={{ ...captionStepper, ...styledEvidenceTitle }}
              >
                {evidenceTitle}
              </Captions>
              <DetailsEvidence>
                {
                  evidenceData?.map(imageEvidence => {
                    return (
                      <>
                        <EvidenceCard
                          type={imageEvidence?.type}
                          key={imageEvidence?.id}
                          img={imageEvidence?.image}
                        />
                        <DateTime>{imageEvidence.date ? `Subida: ${formatDate(imageEvidence.date, 'yyyy-MM-DD')} ${formatHour(imageEvidence.date.toString().replace(' ', 'T'))}` : ''}</DateTime>
                      </>
                    );
                  })
                }
              </DetailsEvidence>
            </>
          )
      }
    </SectionEvidenceService>
  );
};

export default LayoutEvidence;
