import { Box, Skeleton, styled } from '@mui/material';

export const ContainerSkeletonCardService = styled(Box)(({ theme }) => ({
  height: '369px',
  maxWidth: '300px',
  minWidth: '300px',
  boxShadow: '20px 30px 100px rgba(0, 0, 0, 0.1)',
  borderRadius: '30px',
  backgroundColor: theme.palette.secondary.main,
}));

export const ContentImageSkelton = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '174px',
}));

export const SkeletonImage = styled(Skeleton)(() => ({
  display: 'block',
  width: '100%',
  height: '100%',
  transform: 'scale(1, 1)',
  borderRadius: 'initial',
  borderTopLeftRadius: '30px',
  borderTopRightRadius: '30px',
  backgroundColor: '#ccc',
}));

export const ContainerSkeletonContent = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column wrap',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  height: '44%',
  padding: '0rem 1rem',
  paddingTop: '2rem',
  paddingBottom: '0rem !important',
}));

export const SkeletonCardHeaderTitle = styled(Skeleton)(() => ({
  width: '65%',
}));

export const SkeletonCardHeaderPrice = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '65%',
}));

export const SkeletonCardHeaderDescription = styled(Skeleton)(() => ({
  width: '90%',
}));
