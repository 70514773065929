import { useEffect, useState } from 'react';
import { variantTypography } from '../../Common';
import { BillingSection, Captions, Loader } from '../../Components';
import {
  BillingSectionContainer,
  captionDoesItWork,
  CheckInCaptionSkeleton,
  loaderContainerCustom,
} from './styles';

const BillingSectionLayout = () => {
  const [billingSectionData, setBillingSectionData] = useState({
    title: '',
    text: '',
    loading: false,
  });

  useEffect(() => {
    const billingSectionDataResponse = {
      title: 'Cómo facturar',
      text: 'Aplica exclusivamente para los servicios de Mudanza, Supe paquetes de Aseo, Maestro de Obra, Combo hogar limpio y Combo cama + sofá + alfombra. Aplica exclusivamente para los servicios de Mudanza, Supe paquetes de Aseo, Maestro de Obra, Combo hogar limpio y Combo cama + sofá + alfombra.  [Ver más información](#)',
    };
    setBillingSectionData(prevBillingSectionData => ({
      ...prevBillingSectionData,
      ...billingSectionDataResponse,
    }));
  }, []);

  const {
    loading,
  } = billingSectionData;

  const loaderSize = '2rem';
  return (
    <BillingSectionContainer>
      <Captions
        variant={variantTypography.h2}
        style={captionDoesItWork}
      >
        {loading ? <CheckInCaptionSkeleton /> : billingSectionData.title}
      </Captions>
      {
        loading ? (
          <Loader styles={loaderContainerCustom} progressSize={loaderSize} />
        ) : (
          <BillingSection
            text={billingSectionData.text}
            linkText={billingSectionData.linkText}
            link={billingSectionData.link}
          />
        )
      }
    </BillingSectionContainer>
  );
};

export default BillingSectionLayout;
