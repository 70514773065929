import { LayoutGeneral, ServiceBillingLayout } from '../../Layouts';
import { containerGeneral } from './styles';

const ServiceBilling = () => {
  return (
    <LayoutGeneral style={containerGeneral}>
      <ServiceBillingLayout />
    </LayoutGeneral>
  );
};

export default ServiceBilling;
