import { InputAdornment } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import ServiceBillingLabel from './ServiceBillingLabel';
import { HelperText, StyledInput } from './styles';

const ServiceBillingTextField = ({
  onChange,
  name,
  props,
  labelText,
  error,
  messageError,
  styledInput,
  defaultValue,
  success,
  isValuesReset,
}) => {
  const [value, setValue] = useState(defaultValue || '');

  const changeHadler = ({ target }) => {
    setValue(target.value);
    onChange({ value: target.value, name: target.name });
  };

  useEffect(() => {
    if (!defaultValue) {
      setValue('');
    }
  }, [isValuesReset]);

  let styledError;

  if (error) {
    styledError = {
      marginBottom: { xs: '0rem', md: '0rem' },
      borderColor: { xs: 'webView.error', md: 'webView.error' },
    };
  }

  const errorValid = (
    error && (
      <InputAdornment position='start'>
        <CancelIcon sx={{ color: 'webView.error' }} />
      </InputAdornment>
    )
  );

  const successValid = (
    success && (
      <InputAdornment position='start'>
        <CheckCircleIcon sx={{ color: 'webView.success', position: 'relative', right: '-5px' }} />
      </InputAdornment>
    )
  );

  return (
    <>
      <ServiceBillingLabel>
        { labelText }
      </ServiceBillingLabel>
      <StyledInput
        value={value}
        name={name}
        onChange={changeHadler}
        sx={{ ...styledInput, ...styledError }}
        {...props}
        endAdornment={error ? errorValid : success ? successValid : null}
      />
      { error && <HelperText error={error}>{ messageError }</HelperText> }
    </>
  );
};

export default ServiceBillingTextField;
