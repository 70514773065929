import { Box, Typography } from '@mui/material';
import { typesTarget, TYPES_COMPONENTS } from '../../Common';
import { contentAppsStyles, titleStyle } from './DownloadAppStyles';

const DownloadApp = (props) => {
  const {
    title,
    appStore,
    playStore,
    style: { styleAppStore, styleAppPlayStore },
    downloads,
  } = props;
  return (
    <>
      {title && <Typography sx={titleStyle}>{title}</Typography>}
      <Box sx={contentAppsStyles}>
        <Box sx={styleAppStore}>
          <Box
            component={TYPES_COMPONENTS.link}
            href={downloads?.iphone}
            target={typesTarget.TYPE_TARGET_BLANK}
          >
            <Box>
              <Box
                component={TYPES_COMPONENTS.img}
                src={appStore?.image}
                alt={appStore?.alternativeText}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styleAppPlayStore}>
          <Box
            component={TYPES_COMPONENTS.link}
            href={downloads?.android}
            target={typesTarget.TYPE_TARGET_BLANK}
          >
            <Box
              component={TYPES_COMPONENTS.img}
              src={playStore?.image}
              alt={playStore?.alternativeText}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DownloadApp;
