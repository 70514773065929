/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { Card, CardMedia, Typography } from '@mui/material';
import {
  MainContainer,
  OurServicesImagesStyledContainer,
  featuredServiceContainer,
  serviceContainer,
  serviceImage,
  serviceText,
  OurServiceTextContainer,
  featuredImageDesktop,
  featuredServiceTextDesktop,
} from './LayoutOurServicesStyle';
import { DownloadTextContainer } from '../../Components';

const COMPONENT_TYPE = 'img';
const idElement = 'servicios';

const LayoutOurServices = () => {
  const [dataOurServices, setDataOurServices] = useState({
    services: {
      header: '',
      title: '',
      text: '',
      downloadText: '',
    },
    imageAndTitleServices: [],
  });

  useEffect(() => {
    const responseOurServices = {
      services: {
        header: 'Nuestros Servicios',
        title: 'Todo lo que necesitas en la palma de tu mano',
        text: '¡Pide el  **Gas, lavandería, limpieza del hogar**\ny mucho más! ¿Necesitas un **plomero**? Con el servicio\nde  **Handyman**  encuentra arreglos para todo.\nPaga tus  **recibos** y has **recargas**.',
        downloadText: 'Descarga la App',
      },
      imageAndTitleServices: [
        {
          id: 1,
          title: 'Gas LP',
          image: {
            data: [
              {
                id: 53,
                attributes: {
                  name: 'gas.jpg',
                  alternativeText: 'gas.jpg',
                  caption: 'gas.jpg',
                  width: 500,
                  height: 667,
                  formats: {
                    small: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_gas_36a7d6c470.jpg',
                      hash: 'small_gas_36a7d6c470',
                      mime: 'image/jpeg',
                      name: 'small_gas.jpg',
                      path: null,
                      size: 17.98,
                      width: 375,
                      height: 500,
                    },
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_gas_36a7d6c470.jpg',
                      hash: 'thumbnail_gas_36a7d6c470',
                      mime: 'image/jpeg',
                      name: 'thumbnail_gas.jpg',
                      path: null,
                      size: 3.35,
                      width: 117,
                      height: 156,
                    },
                  },
                  hash: 'gas_36a7d6c470',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 28.39,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/gas_36a7d6c470.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:28:55.187Z',
                  updatedAt: '2022-08-25T20:28:55.187Z',
                },
              },
            ],
          },
        },
        {
          id: 2,
          title: 'Limpieza del hogar',
          image: {
            data: [
              {
                id: 54,
                attributes: {
                  name: 'cleaning.jpg',
                  alternativeText: 'cleaning.jpg',
                  caption: 'cleaning.jpg',
                  width: 500,
                  height: 310,
                  formats: {
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_cleaning_1e2cba8f66.jpg',
                      hash: 'thumbnail_cleaning_1e2cba8f66',
                      mime: 'image/jpeg',
                      name: 'thumbnail_cleaning.jpg',
                      path: null,
                      size: 6.9,
                      width: 245,
                      height: 152,
                    },
                  },
                  hash: 'cleaning_1e2cba8f66',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 19.87,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/cleaning_1e2cba8f66.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:29:13.465Z',
                  updatedAt: '2022-08-25T20:29:13.465Z',
                },
              },
            ],
          },
        },
        {
          id: 3,
          title: 'Lavandería',
          image: {
            data: [
              {
                id: 55,
                attributes: {
                  name: 'laundry.jpg',
                  alternativeText: 'laundry.jpg',
                  caption: 'laundry.jpg',
                  width: 500,
                  height: 333,
                  formats: {
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_laundry_db0c6487b3.jpg',
                      hash: 'thumbnail_laundry_db0c6487b3',
                      mime: 'image/jpeg',
                      name: 'thumbnail_laundry.jpg',
                      path: null,
                      size: 7.84,
                      width: 234,
                      height: 156,
                    },
                  },
                  hash: 'laundry_db0c6487b3',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 25.29,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/laundry_db0c6487b3.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:29:25.030Z',
                  updatedAt: '2022-08-25T20:29:25.030Z',
                },
              },
            ],
          },
        },
        {
          id: 4,
          title: 'Mantenimiento',
          image: {
            data: [
              {
                id: 56,
                attributes: {
                  name: 'reparaciones.jpg',
                  alternativeText: 'reparaciones.jpg',
                  caption: 'reparaciones.jpg',
                  width: 500,
                  height: 750,
                  formats: {
                    small: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/small_reparaciones_fec46977db.jpg',
                      hash: 'small_reparaciones_fec46977db',
                      mime: 'image/jpeg',
                      name: 'small_reparaciones.jpg',
                      path: null,
                      size: 18.88,
                      width: 333,
                      height: 500,
                    },
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_reparaciones_fec46977db.jpg',
                      hash: 'thumbnail_reparaciones_fec46977db',
                      mime: 'image/jpeg',
                      name: 'thumbnail_reparaciones.jpg',
                      path: null,
                      size: 3.84,
                      width: 104,
                      height: 156,
                    },
                  },
                  hash: 'reparaciones_fec46977db',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 33.14,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/reparaciones_fec46977db.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:29:49.728Z',
                  updatedAt: '2022-08-25T20:29:49.728Z',
                },
              },
            ],
          },
        },
        {
          id: 5,
          title: 'Servicios',
          image: {
            data: [
              {
                id: 57,
                attributes: {
                  name: 'cfe.jpg',
                  alternativeText: 'cfe.jpg',
                  caption: 'cfe.jpg',
                  width: 500,
                  height: 375,
                  formats: {
                    thumbnail: {
                      ext: '.jpg',
                      url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/thumbnail_cfe_e029a47d84.jpg',
                      hash: 'thumbnail_cfe_e029a47d84',
                      mime: 'image/jpeg',
                      name: 'thumbnail_cfe.jpg',
                      path: null,
                      size: 6.91,
                      width: 208,
                      height: 156,
                    },
                  },
                  hash: 'cfe_e029a47d84',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 28.37,
                  url: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/cfe_e029a47d84.jpg',
                  previewUrl: null,
                  provider: 'aws-s3',
                  provider_metadata: null,
                  createdAt: '2022-08-25T20:30:02.856Z',
                  updatedAt: '2022-08-25T20:30:02.856Z',
                },
              },
            ],
          },
        },
      ],
    };
    setDataOurServices(prevOurServices => ({
      ...prevOurServices,
      services: responseOurServices.services,
      imageAndTitleServices: responseOurServices.imageAndTitleServices,
    }));
  }, []);

  const {
    services,
    imageAndTitleServices,
  } = dataOurServices;

  const cardFirst = 0;
  const cardSecond = 1;
  return (
    <MainContainer id={idElement}>
      <OurServicesImagesStyledContainer>
        {
          imageAndTitleServices?.map((service, index) => {
            const image = service.image.data[0] || {};
            return (
              <Card
                key={service.id}
                sx={index === cardFirst ? featuredServiceContainer : undefined}
                style={index >= cardSecond ? serviceContainer : undefined}
              >
                <CardMedia
                  component={COMPONENT_TYPE}
                  style={index === cardFirst ? featuredImageDesktop : undefined}
                  sx={index >= cardSecond ? serviceImage : undefined}
                  image={image.attributes.url}
                  alt={image.attributes.alternativeText}
                />
                <Typography
                  style={index === cardFirst ? featuredServiceTextDesktop : undefined}
                  sx={index >= cardSecond ? serviceText : undefined}
                >
                  {service.title}
                </Typography>
              </Card>
            );
          })
        }
      </OurServicesImagesStyledContainer>
      <OurServiceTextContainer>
        <DownloadTextContainer
          header={services.header}
          title={services.title}
          text={services.text}
          downloadText={services.downloadText}
        />
      </OurServiceTextContainer>
    </MainContainer>
  );
};

export default LayoutOurServices;
