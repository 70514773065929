import FacebookCircle from '../../../assets/icons/FacebookCircle';
import LinkedinCircle from '../../../assets/icons/LinkedinCircle';
import TwitterCircle from '../../../assets/icons/TwitterCircle';
import InstagramCircle from '../../../assets/icons/InstagramCircle';
import { urls } from '../../Common/messages';
import { FooterSocialMedia } from './FooterTrackingStyle';

const FooterSocialMediaTracking = () => {
  return (
    <>
      <FooterSocialMedia>
        <a href={urls.trackingFacebook} target='_blank' rel='noreferrer' className='tracking-social-links _2-0 w-inline-block'>
          <FacebookCircle />
        </a>
        <a href={urls.trackingTwitter} target='_blank' rel='noreferrer' className='tracking-social-links _2-0 w-inline-block'>
          <TwitterCircle />
        </a>
        <a href={urls.trackingLinkedIn} rel='noreferrer' target='_blank' className='tracking-social-links _2-0 w-inline-block'>
          <LinkedinCircle />
        </a>
        <a href={urls.trackingInstagram} rel='noreferrer' target='_blank' className='tracking-social-links last _2-0 w-inline-block'>
          <InstagramCircle />
        </a>
      </FooterSocialMedia>
    </>
  );
};

export default FooterSocialMediaTracking;
