import { axiosService } from '../Configs/axios';

const serviceBillingDataResponse = {
  data: [
    {
      id: 1,
      attributes: {
        title: 'Facturar pedido',
        buttonSend: 'Enviar',
        serviceBillingInput: [
          {
            id: 2,
            label: 'Folio del pedido',
            placeholder: 'Aparece en el detalle del pedido',
          },
          {
            id: 1,
            label: 'Razón social',
            placeholder: 'Nombre completo de la persona física o moral',
          },
          {
            id: 3,
            label: 'RFC',
            placeholder: 'CHNM9012055W9',
          },
          {
            id: 4,
            label: 'Régimen del contribuyente',
            placeholder: 'Seleccionar',
          },
          {
            id: 5,
            label: 'Uso de CFDI',
            placeholder: 'Seleccionar',
          },
          {
            id: 6,
            label: 'Código postal',
            placeholder: 'Código postal',
          },
          {
            id: 7,
            label: 'Correo electrónico',
            placeholder: 'correo@ejemplo.com',
          },
        ],
        serviceBillingRadio: [
          {
            id: 2,
            label: 'Persona física',
          },
          {
            id: 1,
            label: 'Persona moral',
          },
        ],
        ModalData: {
          id: 1,
          title: 'Tu factura se enviará en breve',
          button: 'Aceptar',
        },
      },
    },
  ],
  meta: {
    pagination: {
      page: 1,
      pageSize: 25,
      pageCount: 1,
      total: 1,
    },
  },
};

const getCfdi = () => {
  return axiosService.get('/catalogs/sat/cfdi-uses');
};

const getFiscalRegimen = () => {
  return axiosService.get('/catalogs/sat/fiscal-regime');
};

const saveServiceBilling = (data) => {
  return axiosService.post('/services/billing-service', data);
};

const getServiceBillingContent = async () => {
  const { attributes: serviceBillingData } = serviceBillingDataResponse.data[0];
  const titleData = serviceBillingData?.title;
  const buttonSendData = serviceBillingData?.buttonSend;
  const [
    folioInput,
    businessNameInput,
    rfcInput,
    fiscalRegimenInput,
    cfdiInput,
    postalCodeInput,
    emailInput,
  ] = serviceBillingData.serviceBillingInput || {};
  const [
    physicalPersonRadio,
    moralPersonRadio,
  ] = serviceBillingData.serviceBillingRadio || {};
  const {
    title: modaTitle,
    button,
  } = serviceBillingData.ModalData || {};

  return {
    title: titleData,
    buttonSendData,
    inputsData: {
      folioInput,
      businessNameInput,
      rfcInput,
      fiscalRegimenInput,
      cfdiInput,
      postalCodeInput,
      emailInput,
    },
    radiosData: {
      physicalPersonRadio,
      moralPersonRadio,
    },
    modalData: {
      title: modaTitle,
      button,
    },
  };
};

export {
  getCfdi,
  getFiscalRegimen,
  saveServiceBilling,
  getServiceBillingContent,
};
