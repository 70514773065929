import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { TYPES_COMPONENTS, variantTypography } from '../../Common';
import {
  cardServiceContainer,
  cardServiceContainerContent,
  cardServiceImage,
  ContentImage,
  DescriptionService,
  PriceService,
  TitleCardHeader,
} from './styles';

const CardServiceOrBenefits = ({
  title,
  priceService,
  description,
  imageService,
  styleContentImage,
  styleCardMedia,
  styleCardContent,
  styleTitle,
  styleDescription,
}) => {
  const image = imageService?.data[0] || {};
  return (
    <Card sx={cardServiceContainer}>
      <ContentImage sx={styleContentImage}>
        {
          image?.attributes && image?.attributes.url && (
            <CardMedia
              image={image.attributes.url}
              component={TYPES_COMPONENTS.img}
              alt={image.attributes.alternativeText}
              sx={styleCardMedia || cardServiceImage}
            />
          )
        }
      </ContentImage>
      <CardContent sx={styleCardContent || cardServiceContainerContent}>
        {title && (
        <TitleCardHeader sx={styleTitle} variant={variantTypography.h4}>
          { title }
        </TitleCardHeader>
        )}
        {priceService && (
        <PriceService variant={variantTypography.body2}>
          { priceService }
        </PriceService>
        )}
        {description && (
        <DescriptionService sx={styleDescription} variant={variantTypography.body2}>
          { description }
        </DescriptionService>
        )}
      </CardContent>
    </Card>
  );
};

export default CardServiceOrBenefits;

CardServiceOrBenefits.defaultProps = {
  styleCardMedia: null,
  styleContentImage: null,
  styleCardContent: null,
  styleDescription: null,
  styleTitle: null,
};
