/* eslint-disable no-undef */
import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import {
  Box, List, ListItem, Skeleton,
} from '@mui/material';
import {
  containerContentAndTableContent,
  containerContentBlogPost,
  skeletonCaptionTable,
  skeletonListLinks,
} from './ContentBlogPostLayoutStyles';
import { Captions, SkeletonContentBlogPost } from '../../Components';
import { TYPES_COMPONENTS, TYPE_SCROLL, variantTypography } from '../../Common';
import { ContentBlogPostText } from '../../Common/messages';

const ContentBlogPostLayout = ({
  content, loading, contentTableContent, captions,
}) => {
  const listId = (Math.random() / Math.random()).toString();
  const { contentTable } = ContentBlogPostText;
  const sectionRef = useRef();
  const sectionContentTable = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const bottomContent = Math.round(sectionRef.current?.getBoundingClientRect().bottom);
      const heightNavBar = 80;
      if (
        bottomContent > heightNavBar
        && window.pageYOffset + heightNavBar >= sectionRef.current.offsetTop
      ) {
        sectionContentTable.current?.classList.add('fixedTable');
      } else {
        sectionContentTable.current?.classList.remove('fixedTable');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionRef.current, sectionContentTable.current, contentTableContent]);

  const contentList = {
    ul: 'contentList',
    li: 'li',
    a: 'a',
  };
  const atributteHref = 'href';

  useEffect(() => {
    const handleLinkScroll = (e, caption, i) => {
      const { target } = e;
      const titlePost = 0;
      const heightOfNavBar = 78;
      const intialScroll = 0;
      const idCaption = `#${caption?.getAttribute('id')}`;
      const idLink = target.getAttribute(atributteHref);
      if (idCaption === idLink) {
        window.scrollTo({
          top: i === titlePost ? intialScroll : caption.offsetTop - heightOfNavBar,
          behavior: TYPE_SCROLL,
        });
      }
    };
    const links = document.getElementById(contentList.ul)?.querySelectorAll('li a');
    links?.forEach((link, i) => {
      link.addEventListener('click', (e) => handleLinkScroll(e, captions[i], i));
    });
  }, [document.getElementById(contentList.ul), contentTableContent, captions]);

  useEffect(() => {
    const links = document.getElementById(contentList.ul)?.querySelectorAll('li a');
    const observer = new IntersectionObserver((headings) => {
      headings.forEach((heading) => {
        if (heading.isIntersecting) {
          links?.forEach(link => {
            link.classList.remove('linkActive');
            const idCaption = `#${heading.target.getAttribute('id')}`;
            const idLink = link.getAttribute(atributteHref);
            if (idCaption === idLink) {
              link.classList.add('linkActive');
            }
          });
        }
      });
    }, {
      rootMargin: '0% 0% -50% 0%',
    });
    captions.forEach(caption => observer.observe(caption));
  }, [captions]);

  return (
    <Box sx={containerContentBlogPost}>
      {loading ? (
        <SkeletonContentBlogPost />
      ) : (
        <Box sx={containerContentAndTableContent}>
          <Box ref={sectionRef}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </Box>
          <Box component={TYPES_COMPONENTS.section} ref={sectionContentTable}>
            {
              loading
                ? (
                  <Skeleton sx={skeletonCaptionTable} animation='wave' />
                )
                : (
                  <Captions variant={variantTypography.h4}>
                    {contentTable.title}
                  </Captions>
                )
            }
            {
              loading
                ? (
                  Array.from(new Array(3)).map(() => (
                    <Skeleton sx={skeletonListLinks} key={Math.random()} animation='wave' />
                  ))
                )
                : (
                  <List id={contentList.ul}>
                    {
                      contentTableContent?.map((contentText) => (
                        <ListItem key={`${listId}${contentText}`}>
                          <Box
                            component={TYPES_COMPONENTS.link}
                            onClick={(e) => e.preventDefault()}
                            href={`#${contentText}`}
                          >
                            { contentText }
                          </Box>
                        </ListItem>
                      ))
                    }
                  </List>
                )
            }
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContentBlogPostLayout;
