import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ServiceProduct } from '../../Common/messages';
import {
  ImageAndDescription,
  OrderButton,
  ProductHeader,
  ServiceIncluded,
} from '../../Components';
import CardLayout from './CardLayout';
import {
  ProductContainer,
  showOrderButton,
} from './styles';
import { OrderConfigurationLayout } from '..';
import RecomandationsListLayout from './RecomandationsListLayout';
import WarrantyLayout from './WarrantyLayout';
import BillingSectionLayout from './BillingSectionLayout';
import BannerLayout from './BannerLayout';
import { ServiceProductContext } from '../../Contexts';
import { setproduct } from '../../store/serviceProduct/action';
import { PreOrderProductDetail } from '../../utils';
import DummyImage from '../../../assets/img/okboy_default.png';
import { useResetScrollTop } from '../../hooks';
import { initialScroll } from '../../Common';

const {
  moobileTitles,
  listIncludeService,
  listNotIncludeService,
  buttonConfigData,
} = ServiceProduct;

const {
  getStatesOnly,
} = PreOrderProductDetail;

const ServiceProductLayout = () => {
  const [showModal, setShowModal] = useState(false);

  const [productServiceState, dispatch] = useContext(ServiceProductContext);
  const { state: data } = useLocation();

  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  useEffect(() => {
    dispatch(setproduct(data.productDetail));
  }, []);

  const handleNavbarShow = () => {
    setShowModal(!showModal);
  };

  const {
    productDetail,
  } = productServiceState;

  return (
    <>
      <ProductContainer>
        <ProductHeader
          title={productDetail?.name}
          priceText={productDetail?.minPrice}
          locationItems={getStatesOnly(productDetail)}
        />
        <ImageAndDescription
          image={productDetail?.icon || DummyImage}
          nameProduct={productDetail?.description}
          description={productDetail?.description}
        />
        <ServiceIncluded
          titles={moobileTitles}
          listIncludeService={listIncludeService}
          listNotIncludeService={listNotIncludeService}
        />

        <CardLayout />

        <RecomandationsListLayout />

        <WarrantyLayout />

        <BillingSectionLayout />

        <BannerLayout />

        <OrderButton
          buttonText={buttonConfigData.buttonText}
          title={buttonConfigData.title}
          handleEvent={handleNavbarShow}
          containerButtonStyles={showModal ? showOrderButton : undefined}
        />
      </ProductContainer>

      <OrderConfigurationLayout
        showValue={showModal}
        handleNavbarShow={handleNavbarShow}
      />
    </>
  );
};

export default ServiceProductLayout;
