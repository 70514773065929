import { Box, Skeleton, Stack } from '@mui/material';
import {
  containerSkeletonCaptionHeader,
  containerSkeletonHeader,
  skeletonAuthor,
  skeletonCaption,
  skeletonImage,
  skeletonReadDuration,
  skeletonRRSS,
} from './SkeletonBlogPostStyle';

const SkeletonHeaderBlogPost = () => {
  return (
    <Stack
      direction={containerSkeletonHeader.direction}
      spacing={containerSkeletonHeader.spacing}
      sx={containerSkeletonHeader.style}
    >
      <Skeleton animation='wave' variant='rectangular' sx={skeletonImage} />
      <Box sx={containerSkeletonCaptionHeader}>
        <Skeleton animation='wave' sx={skeletonReadDuration} />
        <Skeleton animation='wave' sx={skeletonCaption} />
        <Skeleton animation='wave' sx={skeletonAuthor} />
        <Skeleton animation='wave' sx={skeletonRRSS} />
      </Box>
    </Stack>
  );
};

export default SkeletonHeaderBlogPost;
