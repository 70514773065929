import { variantTypography } from '../../Common';
import { Captions } from '../../Components';
import {
  AddressContianer,
  AddressIcon,
  CaptionAndAddressContainer,
  captionStyles,
  titleAddressStyles,
  TitleOrder,
} from './styles';

const CaptionAndAddressLayout = ({
  title,
  addressTitle,
  addressMessage,
  icon,
  orderTitle,
}) => {
  return (
    <CaptionAndAddressContainer>
      <Captions variant={variantTypography.h1} style={captionStyles}>
        { title }
      </Captions>
      <Captions variant={variantTypography.h2} style={titleAddressStyles}>
        { addressTitle }
      </Captions>
      <AddressContianer>
        <AddressIcon src={icon} />
        { addressMessage }
      </AddressContianer>
      <TitleOrder>{ orderTitle }</TitleOrder>
    </CaptionAndAddressContainer>
  );
};

export default CaptionAndAddressLayout;
