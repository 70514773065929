import { ListContainerRecommendations } from './styles';

const ProductRecommendationsList = ({
  itemsList,
}) => {
  return (
    <>
      <ListContainerRecommendations>
        {
            itemsList?.map((item) => {
              return (
                <li key={item.id}>{ item?.text }</li>
              );
            })
        }
      </ListContainerRecommendations>
    </>
  );
};

export default ProductRecommendationsList;
