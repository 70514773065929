import ReactMarkdown from 'react-markdown';
import { BillingSectionStyles } from './styles';

const BillingSection = ({
  text,
}) => {
  return (
    <>
      <BillingSectionStyles>
        <ReactMarkdown>
          { text }
        </ReactMarkdown>
      </BillingSectionStyles>
    </>
  );
};

export default BillingSection;
