const FilterIcon = (props) => (
  <svg
    width={20}
    height={17}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M.642 4.37h10.34a3.727 3.727 0 0 0 7.335 0h1.042a.68.68 0 0 0 0-1.324h-1.044a3.727 3.727 0 0 0-7.335 0H.642a.681.681 0 0 0 0 1.323ZM14.63 1.321a2.4 2.4 0 1 1 0 4.8 2.4 2.4 0 0 1 0-4.8ZM19.315 12.185h-10.3a3.727 3.727 0 0 0-7.335 0H.642a.681.681 0 0 0 0 1.323h1.042a3.727 3.727 0 0 0 7.335 0h10.296a.68.68 0 0 0 0-1.323ZM5.371 15.27a2.4 2.4 0 1 1 0-4.8 2.4 2.4 0 0 1 0 4.8Z'
      fill='#4433E0'
    />
  </svg>
);

export default FilterIcon;
