export const contentList = (backgroundImageDesktop, backgroundImageMobile) => {
  return {
    width: {
      xs: '162%', sm: '162%', md: '159%', lg: '159%',
    },
    position: 'relative',
    left: {
      xs: '-31%', sm: '-31%', md: '-29.2%', lg: '-29.2%',
    },
    height: {
      xs: '971px', sm: '971px', md: '770px', lg: '770px',
    },
    backgroundImage: {
      xs: `url(${backgroundImageMobile})`,
      sm: `url(${backgroundImageMobile})`,
      md: `url(${backgroundImageDesktop})`,
      lg: `url(${backgroundImageDesktop})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: {
      xs: '62% 100%', sm: '62% 100%', md: 'cover', lg: 'cover',
    },
    backgroundPosition: 'center center',
    paddingTop: '1px',
  };
};

export const contentListItem = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: {
    xs: '58.5%', sm: '58.5%', md: '63%', lg: '63%',
  },
  margin: '0 auto',
  height: '100%',
  marginTop: {
    xs: '16px', sm: '16px', md: '55px', lg: '55px',
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: {
      xs: '100%', sm: '100%', md: '42.8%', lg: '42.8%',
    },
    height: {
      xs: '76px', sm: '76px', md: '14.1%', lg: '14.1%',
    },
    paddingLeft: '19px',
    borderRadius: '20px',
    backgroundColor: 'background.primary',
    '& > p': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 'initial',
      width: {
        xs: '40px', sm: '40px', md: '60px', lg: '60px',
      },
      height: {
        xs: '40px', sm: '40px', md: '60px', lg: '60px',
      },
      position: {
        xs: 'absolute', sm: 'absolute', md: 'static', lg: 'static',
      },
      left: {
        xs: '13px', md: '13px',
      },
      backgroundColor: 'primary.main',
      color: 'secondary.main',
      fontSize: {
        xs: '1.75rem', sm: '1.75rem', md: '2.5rem', lg: '2.5rem',
      },
      fontWeight: {
        xs: '900', sm: '900', md: '800', lg: '800',
      },
      textTransform: 'uppercase',
      textAlign: 'center',
      borderRadius: '50%',
    },
    '& > h3': {
      width: {
        xs: '89%', sm: '89%', md: '100%', lg: '100%',
      },
      maxWidth: '435px',
      position: {
        xs: 'absolute', sm: 'absolute', md: 'static', lg: 'static',
      },
      right: {
        xs: '-11px', md: '-11px',
      },
      fontSize: {
        xs: '0.83125rem', phoneMd: '1rem', sm: '0.83125rem', md: '1.375rem', lg: '1.375rem',
      },
      fontWeight: '800',
    },
  },
};
