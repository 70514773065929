import ImageTest from '../../../assets/img/Blog/cardsPost/imgCard.png';

const bannerRRSS = {
  titles: {
    titleMain: '¿Te gusto el contenido? No olvides compartir 😀',
  },
};

const bannerDownload = {
  titles: {
    titleMain: 'Descarga la App',
    titleSecondary:
      'Pide el Gas, Lavandería, Limpieza del hogar en un solo lugar.',
  },
  nameImage: 'downloadImage',
};
const bannerSuscribe = {
  titles: {
    titleMain: 'Subscríbete al Blog',
    titleSecondary:
      'Tu información es importante para nosotros, no compartiremos tus datos con terceros.',
  },
  placeHolder: {
    text: 'Email',
    error: 'Correo no válido',
  },
  textButton: {
    send: 'Enviar',
    success: 'Enviado',
  },
  nameImage: 'imageSuscribe',
};

const mightInterested = {
  texTitle: 'Te podría interesar',
  cardsBlogPost: [
    {
      id: 1,
      title: 'Como ahorrar dinero en los servicios de tu hogar',
      author: 'Alejandro Benitez',
      creationDate: '27 mayo 2022',
      readDuration: '9 min de lectura',
      img: ImageTest,
    },
    {
      id: 2,
      title: 'Un hogar minimalista en 3 sencillos pasos',
      author: 'Alejandro Benitez',
      creationDate: '27 mayo 2022',
      readDuration: '9 min de lectura',
      img: ImageTest,
    },
    {
      id: 3,
      title: 'Como mantener tu hogar perfecto en Navidad',
      author: 'Alejandro Benitez',
      creationDate: '27 mayo 2022',
      readDuration: '9 min de lectura',
      img: ImageTest,
    },
  ],
};

export {
  bannerRRSS, bannerDownload, mightInterested, bannerSuscribe,
};
