import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccesObkoy from '../../../assets/icons/SuccesOkBoy';
import { initialScroll, TYPES_BUTTONS, VARIANT_BUTTONS_TYPES } from '../../Common';
import { pathViews, SuccessPreOrder, urls } from '../../Common/messages';
import { ButtonPrimary, Captions } from '../../Components';
import { useResetScrollTop } from '../../hooks';
import {
  backButton,
  buttonStyles,
  captionStyles,
  SuccessPreOrderContainer,
  whatsAppButton,
} from './styles';

const {
  messages,
} = SuccessPreOrder;

const {
  servicesSearch,
} = pathViews;

const SuccesPreOrderLayout = () => {
  const navigate = useNavigate();

  const linkHandler = () => {
    navigate(servicesSearch.app);
  };

  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);

  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  return (
    <SuccessPreOrderContainer>
      <SuccesObkoy />
      <Captions style={captionStyles}>
        { messages.title }
      </Captions>
      <ButtonPrimary
        typeButton={TYPES_BUTTONS.link}
        path={urls.whatsApp}
        variant={VARIANT_BUTTONS_TYPES.outlined}
        styles={{ ...buttonStyles, ...whatsAppButton }}
      >
        { messages.whatsAppText }
      </ButtonPrimary>
      <ButtonPrimary
        styles={{ ...buttonStyles, ...backButton }}
        handleToggle={linkHandler}
      >
        { messages.catalogueText }
      </ButtonPrimary>
    </SuccessPreOrderContainer>
  );
};

export default SuccesPreOrderLayout;
