/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { BannerBlogPost } from '../../../Common/messages';
import {
  ContentBlogPostLayout,
  HeaderBlogPostLayout,
  HelpSectionLayout,
  LayoutGeneral,
  YouMightInterestedLayout,
} from '../../../Layouts';
import {
  breakPoints,
  initialScroll,
  TYPES_BANNER,
  TYPES_PARAMS_STRAPI,
  TYPE_SCROLL,
  variantTypography,
} from '../../../Common';
import {
  containBannerRRSS,
  containtCaptionAndRRSS,
  layoutBlogPost,
  containerContetAndBannerRRSS,
  contentBannerAndYouMightInterestedLayout,
  containFlexIconRRSS,
} from './BlogPostStyles';
import ImageSubscribeDesktop from '../../../../assets/img/Blog/imageBanner/imageSubscribeDesktop.png';
import ImageSubscribeMobile from '../../../../assets/img/Blog/imageBanner/imageSubscribeMobile.png';
import ImageBannerDownload from '../../../../assets/img/Blog/imageBanner/imageBannerDownload.png';
import axiosStrapi from '../../../Configs/axios';
import { useBreakpoint, useMetaSEO, useResetScrollTop } from '../../../hooks';
import {
  bannerDownLoad,
  styleAppStore,
  styleAppPlayStore,
  stylesBannerImageDownload,
  contentBannerSubscribe,
  stylesBannerCaptionSubscribe,
  stylesBannerImageSubscribe,
} from '../BlogAppStyles';
import { Params } from '../../../utils';

const BlogPost = () => {
  const {
    bannerRRSS, bannerDownload, mightInterested, bannerSuscribe,
  } = BannerBlogPost;
  const [blogPost, setBlogPost] = useState({});
  const [category, setCategory] = useState({ typeCategory: '' });
  const [loading, setLoading] = useState(true);
  const [contentTable, setContentTable] = useState([]);
  const [postsForCategory, setPostsForCategory] = useState({ texTitle: '', cardsBlogPost: [] });
  const [elemntsCaptions, setElementsCaptions] = useState([]);
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const [metaData, setMetaData] = useState({});
  useMetaSEO(metaData);
  const navigate = useNavigate();
  const { id: idPost } = useParams();
  const { pathname } = useLocation();
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const captionsRefs = useRef();
  const { paramsBlogPost } = Params;

  useEffect(() => {
    const getPost = async (id) => {
      const idTitle = id.split('-').join(' ');
      try {
        const {
          data: { data },
        } = await axiosStrapi.get(paramsBlogPost(idTitle));
        const post = data[0] || {};
        const onlyLetters = 3;
        const includeHeidingTwoWidthSpace = '## ';
        const betweenLines = '\n';
        let textTable = post.attributes.content.split(betweenLines)
          .filter(text => text.substring(0, 3) === includeHeidingTwoWidthSpace)
          .map(text => text.slice(onlyLetters));
        textTable = [post.attributes.title, ...textTable];
        setContentTable(textTable);
        setMetaData({
          metaTitle: post.attributes.seo[0]?.metaTitle,
          metaDescription: post.attributes.seo[0]?.metaDescription,
          keywords: post.attributes.seo[0]?.keywords,
          metaRobots: post.attributes.seo[0]?.metaRobots,
          structuredData: post.attributes.seo[0]?.structuredData,
        });
        const categorieResponse = post.attributes?.categorie?.data?.attributes;
        setBlogPost(() => {
          return {
            ...post,
            imageUrl: post.attributes.image.data[0].attributes.url,
            altImage: post.attributes.image.data[0].attributes.alternativeText,
          };
        });
        setCategory({ typeCategory: categorieResponse?.name });
      } catch (error) {
        navigate('/*', { replace: true });
      }
    };
    getPost(idPost).then(() => setLoading(false));
  }, [pathname]);

  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  let captions;
  useEffect(() => {
    captions = document.getElementById('contentCaptions').querySelectorAll('h1, h2');
    captions.forEach((caption, i) => {
      caption.setAttribute('id', contentTable[i]);
      setElementsCaptions(captions);
    });
  }, [contentTable]);

  const { populates, category: { fields } } = TYPES_PARAMS_STRAPI;
  const numberSubtractRandomOrder = 0.5;

  useEffect(() => {
    const getPostYouMightInterested = async (name) => {
      const { data: { data } } = await axiosStrapi.get(`categories/?fields=${fields.name},${fields.description}&populate[blog_posts][populate]=${populates.img}&filters[name][$eq]=${name}`);
      const responsePosts = data[0]?.attributes.blog_posts.data.map(post => {
        const { attributes, id } = post;
        const dataImage = attributes.image.data[0] || {};
        return {
          id,
          title: attributes.title,
          author: attributes.author,
          creationDate: attributes.creationDate,
          readDuration: attributes.readDuration,
          img: dataImage.attributes.url,
          altImage: dataImage.attributes?.alternativeText,
        };
      })
        .filter(post => post.id !== blogPost.id)
        .sort(() => Math.random() - numberSubtractRandomOrder);
      const postsAndTitleForCategory = {
        texTitle: mightInterested.texTitle, cardsBlogPost: responsePosts,
      };
      setPostsForCategory(postsAndTitleForCategory);
    };
    getPostYouMightInterested(category.typeCategory);
  }, [category, pathname]);

  useEffect(() => {
    window.scrollTo({
      top: initialScroll,
      behavior: TYPE_SCROLL,
    });
  }, [pathname]);

  const idContentCaptions = 'contentCaptions';

  return (
    <Box id={idContentCaptions} ref={captionsRefs}>
      <HeaderBlogPostLayout
        imageUrl={blogPost.imageUrl}
        altImage={blogPost.altImage}
        {...blogPost.attributes}
        typeCategory={category.typeCategory}
        loading={loading}
      />
      <LayoutGeneral style={containerContetAndBannerRRSS}>
        <ContentBlogPostLayout
          loading={loading}
          content={blogPost.attributes?.content}
          contentTableContent={contentTable}
          captions={elemntsCaptions}
        />
        <HelpSectionLayout
          typeBanner={TYPES_BANNER.rrss}
          titles={bannerRRSS.titles}
          stylesContentBanner={containBannerRRSS}
          stylesBannerCaption={containtCaptionAndRRSS}
          stylesIconRRSS={containFlexIconRRSS}
          variantTitle={variantTypography.h5}
        />
      </LayoutGeneral>
      <Box sx={contentBannerAndYouMightInterestedLayout}>
        <LayoutGeneral style={layoutBlogPost}>
          <HelpSectionLayout
            typeBanner={TYPES_BANNER.download}
            titles={bannerDownload.titles}
            stylesContentBanner={bannerDownLoad}
            styleDownload={{ styleAppStore, styleAppPlayStore }}
            stylesBannerImage={stylesBannerImageDownload}
            variantTitle={variantTypography.h3}
            variantSubTitle={variantTypography.h5}
            nameImage={bannerDownload.nameImage}
            imageBanner={ImageBannerDownload}
          />
          {
          postsForCategory?.cardsBlogPost?.length > 0
            ? <YouMightInterestedLayout cardItemBlogPost={postsForCategory} />
            : null
          }
          <HelpSectionLayout
            typeBanner={TYPES_BANNER.subscribe}
            titles={bannerSuscribe.titles}
            textLabel={bannerSuscribe.placeHolder}
            textButton={bannerSuscribe.textButton}
            stylesContentBanner={contentBannerSubscribe}
            stylesBannerCaption={stylesBannerCaptionSubscribe}
            stylesBannerImage={stylesBannerImageSubscribe}
            variantTitle={variantTypography.h3}
            variantSubTitle={variantTypography.h6}
            nameImage={bannerSuscribe.nameImage}
            imageBanner={toggleBreakpoint ? ImageSubscribeMobile : ImageSubscribeDesktop}
          />
        </LayoutGeneral>
      </Box>
    </Box>
  );
};

export default BlogPost;
