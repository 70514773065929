export const linkItemStyles = {
  display: {
    xs: 'none',
    sm: 'none',
    md: 'flex',
    lg: 'flex',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  flexBasis: '62%',
  fontSize: '1.13rem',
  fontWeight: '900',
  '& li': {
    padding: '0px',
    display: 'block',
    width: 'max-content',
  },
  '& li:nth-of-type(4)': {
    display: 'none',
  },
  '& li a': {
    display: 'block',
    color: 'primary.main',
    width: '100%',
  },
  '& li:last-of-type': {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '15px',
    backgroundColor: 'primary.main',
    width: '219px',
    fontSize: '1.19rem',
    lineHeight: 'initial',
  },
  '& li:last-of-type a': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'initial',
    padding: '8px 25px 8px 24px',
    color: 'secondary.main',
    textAlign: 'center',
    textDecoration: 'none',
  },
};
export const linkItemStylesBlog = {
  flexBasis: '86.5%',
  justifyContent: 'flex-end',
  gap: '1rem',
  '& > li:first-of-type': {
    margin: '0px',
  },
  '& > li': {
    display: 'block',
    width: 'auto',
    padding: '0px',
    margin: '0px',
    '& > a': {
      textDecoration: 'none',
      fontSize: '1rem',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
  '& li:nth-of-type(4)': {
    width: 'auto',
  },
  '& > li:last-of-type': {
    maxWidth: '180px',
    width: '100%',
    marginRight: '0px',
    marginLeft: '0px',
    fontSize: '1.125rem',
    borderRadius: '10px',
    '& > a': {
      fontWeight: '900',
      textDecoration: 'none',
    },
  },
  '& > li:nth-of-type(8)': {
    display: 'none',
    width: '41px',
    height: '41px',
    '& > a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F0F1F5',
      borderRadius: '10px',
      height: '100%',
    },
    '& > a > svg': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
      width: '60%',
    },
  },
};
