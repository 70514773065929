export const headerStyle = {
  display: {
    xs: 'block', sm: 'block', md: 'flex', lg: 'flex',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: {
    xs: '0px', sm: '0px', md: '80px', lg: '80px',
  },
  padding: { xs: '0px' },
  '& > h3': {
    fontSize: {
      xs: '1.75rem', sm: '1.75rem', md: '2.5rem', lg: '2.5rem',
    },
    widht: '100%',
    maxWidth: {
      xs: 'auto', sm: 'auto', md: '462x', lg: '462px',
    },
    width: {
      xs: '91%', sm: '91%',
    },
    margin: {
      xs: '0 auto', sm: '0 auto', md: '0', lg: '0',
    },
    fontWeight: '800',
    color: 'primary.main',
  },
  '& > div': {
    flexBasis: '28.5%',
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      borderRadius: '15px',
      borderWidth: '2px',
      fontSize: '22px',
      fontWeight: '900',
      textTransform: 'capitalize',
    },
    '& button:hover': {
      borderWidth: '2px',
    },
  },
};

export const contentCards = {
  height: {
    xs: '80%', sm: '80%', md: '312px', lg: '312px',
  },
  paddingLeft: {
    xs: '16px', sm: '16px', md: '353px', lg: '353px',
  },
  overflowX: 'hidden',
  '& > div:first-of-type': {
    display: 'flex',
    width: {
      xs: '443vw', sm: '443vw', md: '120vw', lg: '120vw',
    },
    marginLeft: 'auto',
    gap: '34px  ',
    marginTop: '20px',
    height: {
      xs: '413px', sm: '413px', md: '263px', lg: '263px',
    },
    '& > div:last-of-type': {
      marginRight: { lg: '125px' },
    },
    transition: 'all .5s ease',
    transform: 'translateX(0vw)',
  },
};

export const contentButtonCarousel = {
  width: '85%',
  display: 'flex',
  margin: '0 auto',
  justifyContent: 'space-between',
  marginTop: '32px',
  '& button': {
    borderRadius: '15px',
    borderWidth: '2px',
    fontSize: '22px',
    fontWeight: '900',
    textTransform: 'capitalize',
  },
  '& button:hover': {
    borderWidth: '2px',
  },
};

export const contentPointCarouselStyle = {
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  width: '80%',
  position: {
    xs: 'relative', sm: 'relative', md: 'static', lg: 'static',
  },
  top: {
    xs: '-14px', sm: '-14px',
  },
};

export const pointCarouselStyle = {
  display: 'block',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'primary.main',
  marginRight: '24px',
  width: '15px',
  minWidth: '15px',
  height: '15px',
  padding: '0px',
};
