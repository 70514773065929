export const titleStyle = {
  marginBottom: '2.2rem',
  fontSize: {
    xs: '3rem',
    md: '3rem',
  },
  fontWeight: '900',
  lineHeight: '3rem',
};

export const descriptionStyle = {
  width: '100%',
  maxWidth: {
    xs: '327px',
    md: 'initial',
  },
  '& p': {
    fontSize: {
      xs: '1rem',
      sm: '1rem',
      md: '1.125rem',
    },
    color: 'text.secondary',
  },
};
