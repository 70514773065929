import { Button, styled, TextField } from '@mui/material';

export const stylesLabels = {
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.1875rem',
  color: 'text.secondary',
  marginBottom: '1rem',
};

export const PreOrderInputStyles = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '45px',
  marginBottom: '1rem',
  borderRadius: '15px',
  backgroundColor: theme.palette.background.secondary,
  '& fieldset': {
    border: '1px solid rgba(121, 121, 122, 0.1)',
    transition: 'all .3s ease',
    borderRadius: '15px',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    height: '100%',
    '& .MuiInputBase-input': {
      padding: '0.625rem 1rem 0.8125rem 1.55375rem',
    },
  },
}));

export const PayButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  width: '168px',
  height: '45px',
  background: '#F0F1F5',
  border: '1px solid rgba(121, 121, 122, 0.2)',
  borderRadius: '15px',
  color: '#202022',
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  transition: 'border .3s ease',
}));
