import { createContext, useMemo, useState } from 'react';

export const LadingIdContext = createContext('');

const LandingIdProvider = ({ children }) => {
  const [dataLandingId, setDataLandingId] = useState({
    idName: '',
    path: '',
  });
  const dataIdLandingMemo = useMemo(() => ({ dataLandingId, setDataLandingId }), [dataLandingId]);
  return (
    <LadingIdContext.Provider value={dataIdLandingMemo}>
      { children }
    </LadingIdContext.Provider>
  );
};

export default LandingIdProvider;
