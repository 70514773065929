/* eslint-disable no-undef */
import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { TYPES_CARDS, variantTypography } from '../../Common';
import { pathViews } from '../../Common/messages';
import { Captions, CardItem } from '../../Components';
import {
  captionYouMightInterested,
  cardBlogPost,
  contentCardsBlogPost,
  contentTextCardBlogPost,
  directionCards,
  spacingCards,
} from './YouMightInterestedLayoutStyles';

const YouMightInterestedLayout = ({ cardItemBlogPost }) => {
  const { blog: { app } } = pathViews;
  const pathPost = 'post/';

  return (
    <>
      <Captions
        variant={variantTypography.h4}
        style={captionYouMightInterested}
      >
        { cardItemBlogPost?.texTitle }
      </Captions>
      <Stack direction={directionCards} spacing={spacingCards} sx={contentCardsBlogPost}>
        {cardItemBlogPost?.cardsBlogPost?.slice(0, 3).map((card) => (
          <NavLink key={card.id} to={`${app}${pathPost}${card.title.split(' ').join('-')}`}>
            <CardItem
              typeCard={TYPES_CARDS.blogPost}
              cardStyle={cardBlogPost}
              contentTexts={contentTextCardBlogPost}
              title={card.title}
              authorBlogPost={card.author}
              dateBlogPost={card.creationDate}
              timeReadBlogPost={card.readDuration}
              img={card.img}
            />
          </NavLink>
        ))}
      </Stack>
    </>
  );
};

export default YouMightInterestedLayout;
