import {
  Box,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

export const conatainerTitleAndBadge = {
  position: 'relative',
  zIndex: '10',
};

export const contentBagdeStyle = {
  width: {
    xs: '115%',
    md: '35%',
  },
  padding: {
    xs: '0rem 1rem',
    md: '0rem 0rem',
  },
  position: {
    xs: 'relative',
    md: 'static',
  },
  left: '-9%',
};

export const Title = styled(Typography)(({ theme }) => ({
  width: '50%',
  marginBottom: '1.8rem',
  fontWeight: '900',
  fontSize: '2rem',
  lineHeight: '2.731875rem',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

export const Titleskeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const ContainerBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '98%',
  height: '71px',
  padding: '0.3125rem 1rem 0.6875rem',
  margin: {
    xs: '0 auto',
    md: 'initial',
  },
  borderRadius: '1.25rem',
  backgroundColor: theme.palette.background.textFieldSubscribe,
}));

export const ContainImage = styled(Box)(() => ({
  flexBasis: '19%',
  height: '60px',
}));

export const badgeImage = {
  width: '100%',
  height: '100%',
};

export const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
  display: 'block',
  widht: '100%',
  height: '100%',
  transform: 'scale(1, 1)',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
}));

export const ContainText = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexBasis: '74%',
}));

export const TitleBadge = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: '900',
  fontSize: '1.25rem',
  lineHeight: '1.7075rem',
  color: theme.palette.primary.main,
}));

export const SkeletonTitleBadge = styled(Skeleton)(({ theme }) => ({
  width: '56%',
  backgroundColor: theme.palette.primary.main,
}));

export const SubTitleBadge = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.36625rem',
  color: theme.palette.text.secondary,
}));

export const SkeletonSubTitleBadge = styled(Skeleton)(({ theme }) => ({
  width: '72%',
  backgroundColor: theme.palette.text.secondary,
}));
