import { Tooltip } from '@mui/material';
import { variantTypography } from '../../Common';
import { formatMoney } from '../../utils/service-tracking';
import {
  CardBage as LocationBadge,
  loactionBadgeStyles,
  spanReset,
} from '../ServiceSearch/styles';

import {
  BadgeContainer,
  HeaderContainer,
  PriceText,
  ProductCaption,
} from './styles';

const ProductHeader = ({
  title,
  priceText,
  locationItems,
}) => {
  return (
    <HeaderContainer>
      <Tooltip title={title || ''} arrow placement='bottom'>
        <ProductCaption
          variant={variantTypography.h1}
        >
          { title }
        </ProductCaption>
      </Tooltip>
      <PriceText>{ `$${formatMoney(priceText)} c/u` }</PriceText>
      <BadgeContainer>
        {
            locationItems?.map(item => {
              return (
                <LocationBadge
                  key={item.id}
                  sx={{ ...spanReset, ...loactionBadgeStyles }}
                  label={item.state}
                />
              );
            })
        }
      </BadgeContainer>
    </HeaderContainer>
  );
};

export default ProductHeader;
