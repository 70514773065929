/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import {
  initialScroll,
  TYPES_BANNER, TYPES_COMPONENTS, TYPES_PARAMS_STRAPI, TYPE_SCROLL, variantTypography,
} from '../../../Common';
import { BannerBlogPost, BlogPosts } from '../../../Common/messages';
import {
  ContentPostsLayout,
  HelpSectionLayout,
  LayoutGeneral,
  SkeletonCardLayout,
} from '../../../Layouts';
import {
  bannerDownLoad,
  styleAppStore,
  styleAppPlayStore,
  stylesBannerImageDownload,
} from '../BlogAppStyles';
import {
  containBannerDownloadCategories,
  containCaptionAndCards,
  containerCaptionAndCards,
  contentCaptionAndDescription,
  contentColumnThreeStyle,
  cotainerBannerDownloadCategories,
  styleCaptionSkeleton,
  styleContentSkeletonCategories,
  styleDescriptionSkeleton,
} from './BlogCategoriesStyles';
import ImageBannerDownload from '../../../../assets/img/Blog/imageBanner/imageBannerDownload.png';
import {
  styleColumnThree,
  styleContentPosts,
} from '../BlogHome/BlogHomeStyles';
import { Captions, SkeletonCard } from '../../../Components';
import axiosStrapi from '../../../Configs/axios';
import { useResetScrollTop } from '../../../hooks';
import { DataLading } from '../../../api';

const BlogCategories = () => {
  const [blogPostAndCategories, setBlogPostAndCategories] = useState({
    messageCategories: {
      tittle: '',
      description: '',
    },
    blogPosts: [],
    dataImageDownload: {
      imageIOS: {
        image: '',
        alternativeText: '',
      },
      imageAndroid: {
        image: '',
        alternativeText: '',
      },
    },
  });
  const [loading, setLoading] = useState(true);
  const { id: idCategory } = useParams();
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const { state: { idStrapi } } = useLocation();

  const { populates, category: { fields } } = TYPES_PARAMS_STRAPI;
  const columnOfThree = 3;
  const lengthCards = 12;

  useEffect(() => {
    setLoading(true);
    const getBlogPostsAndCategories = async (id) => {
      const { data: { data } } = await axiosStrapi.get(`categories/${id}?fields=${fields.name},${fields.description}&populate[blog_posts][populate]=${populates.img}`);
      const category = data.attributes;
      const { name, description } = category;
      const { data: dataPosts } = category.blog_posts;
      return {
        messageCategories: {
          title: name,
          description,
        },
        blogPosts: dataPosts.map(post => {
          const { id: idPost, attributes } = post;
          const dataImage = attributes.image.data[0] || {};
          return {
            id: idPost,
            title: attributes.title,
            author: attributes.author,
            creationDate: attributes.creationDate,
            readDuration: attributes.readDuration,
            img: dataImage.attributes.url,
            alternativeText: dataImage.attributes.alternativeText,
            column: columnOfThree,
            description: attributes.description,
          };
        }),
      };
    };
    getBlogPostsAndCategories(idStrapi).then(dataResponseBlogPostAndCategories => {
      setBlogPostAndCategories(prevDataBlogPostAndCategories => ({
        ...prevDataBlogPostAndCategories,
        messageCategories: dataResponseBlogPostAndCategories.messageCategories,
        blogPosts: dataResponseBlogPostAndCategories.blogPosts,
      }));
      setLoading(false);
    });
    if (scrollTop > initialScroll) {
      window.scroll({
        top: initialScroll,
        behavior: TYPE_SCROLL,
      });
    }
  }, [idCategory]);

  useEffect(() => {
    setScroll(idStrapi);
  }, []);

  const { getSaveTime } = DataLading;

  useEffect(() => {
    getSaveTime().then(dataResponseSaveTime => {
      setBlogPostAndCategories(prevDataBlogPostAndCategories => ({
        ...prevDataBlogPostAndCategories,
        dataImageDownload: dataResponseSaveTime.imageDownloadApp,
      }));
    });
  }, []);

  const { bannerDownload } = BannerBlogPost;
  const { propsSkeleton } = BlogPosts;
  return (
    <>
      <Box sx={containerCaptionAndCards}>
        <LayoutGeneral style={containCaptionAndCards}>
          <Box sx={contentCaptionAndDescription}>
            <Captions variant={variantTypography.h1}>
              {
              loading
                ? <Skeleton sx={styleCaptionSkeleton} />
                : blogPostAndCategories.messageCategories.title
              }
            </Captions>
            <Box component={TYPES_COMPONENTS.section}>
              <Captions variant={variantTypography.body1} style={styleDescriptionSkeleton}>
                {loading ? <Skeleton width='50%' /> : blogPostAndCategories.messageCategories.description}
              </Captions>
            </Box>
          </Box>
          {loading ? (
            <SkeletonCardLayout styleContentSkeleton={styleContentSkeletonCategories}>
              <SkeletonCard
                styleContentColumnThree={contentColumnThreeStyle}
                styleThreeColumn={propsSkeleton[2].styleThreeColumn}
                typeColumn={propsSkeleton[2].typeColumn}
                lengthCard={lengthCards}
              />
            </SkeletonCardLayout>
          ) : (
            <ContentPostsLayout
              styleColumnThree={styleColumnThree}
              cardsBlogPost={blogPostAndCategories?.blogPosts}
              styleContentPosts={styleContentPosts}
            />
          )}
        </LayoutGeneral>
      </Box>
      <Box sx={cotainerBannerDownloadCategories}>
        <LayoutGeneral style={containBannerDownloadCategories}>
          <HelpSectionLayout
            typeBanner={TYPES_BANNER.download}
            titles={bannerDownload.titles}
            stylesContentBanner={bannerDownLoad}
            styleDownload={{ styleAppStore, styleAppPlayStore }}
            stylesBannerImage={stylesBannerImageDownload}
            variantTitle={variantTypography.h3}
            variantSubTitle={variantTypography.h5}
            nameImage={bannerDownload.nameImage}
            imageBanner={ImageBannerDownload}
            imageIOS={blogPostAndCategories.dataImageDownload.imageIOS}
            imageAndroid={blogPostAndCategories.dataImageDownload.imageAndroid}
          />
        </LayoutGeneral>
      </Box>
    </>
  );
};

export default BlogCategories;
