import { LOCATION_TYPES } from '../Common';

const {
  streetNumber,
  route,
  sublocalityLevelOne,
  locality,
  administrativeAreaLevelOne,
  postalCode,
} = LOCATION_TYPES;
export const setLocation = (address) => {
  if (!address) return;
  const location = {};
  address.forEach((addressItem, i) => {
    addressItem.types.forEach((type, j) => {
      switch (address[i].types[j]) {
        case streetNumber: {
          location.numExt = address[i].long_name;
          break;
        }
        case route: {
          location.street = address[i].long_name;
          break;
        }
        case sublocalityLevelOne: {
          location.suburb = address[i].long_name;
          break;
        }
        case locality: {
          location.municipality = address[i].long_name;
          break;
        }
        case administrativeAreaLevelOne: {
          location.state = address[i].long_name;
          break;
        }
        case postalCode: {
          location.zipcode = address[i].long_name;
          break;
        }
        default: {
          break;
        }
      }
    });
  });
  // eslint-disable-next-line consistent-return
  return location;
};
