import { createContext, useMemo, useState } from 'react';

export const ContextScore = createContext(null);

const ScoreProvider = ({ children }) => {
  const [showScore, setShowScore] = useState(false);
  const showScoreMemo = useMemo(() => ({ showScore, setShowScore }), [showScore, setShowScore]);
  return (
    <ContextScore.Provider value={showScoreMemo}>
      { children }
    </ContextScore.Provider>
  );
};

export default ScoreProvider;
