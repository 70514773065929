import { memo } from 'react';
import { Box } from '@mui/material';
import ButtonGeneral from '../Button/Button';
import Captions from '../Captions/Captions';
import { contentPersonalizedHelp, titleDisplayStyles } from './PersonalizedHelpStyles';
import WhatsAppLogo from '../../../assets/img/WhatsAppLogo.png';
import { textPersonalizedHelp } from '../../Common/messages';

const PersonalizedHelp = ({
  titles, textUrl, backgroundImages,
}) => {
  const { colorTitle, url, variantsTitle } = textPersonalizedHelp;
  return (
    <Box sx={contentPersonalizedHelp(backgroundImages.desktop, backgroundImages.mobile)}>
      <Box>
        <Box>
          <Captions style={titleDisplayStyles('none', 'block')} variant={variantsTitle.main} color={colorTitle}>
            {titles.titleMain}
          </Captions>
          <Captions style={titleDisplayStyles('block', 'none')} variant={variantsTitle.main} color={colorTitle}>
            {titles.titleMain}
          </Captions>
          <Captions style={titleDisplayStyles('none', 'block')} variant={variantsTitle.secondary} color={colorTitle}>
            {titles.titleSecondaryDesktop}
          </Captions>
          <Captions style={titleDisplayStyles('block', 'none')} variant={variantsTitle.secondary} color={colorTitle}>
            {titles.titleSecondaryMobile}
          </Captions>
          <ButtonGeneral icon={WhatsAppLogo} textUrl={textUrl} url={url} />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PersonalizedHelp, (prev, next) => {
  return prev.titles.titleMain === next.titles.titleMain;
});
