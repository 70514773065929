import ImageTestOne from '../../../assets/img/Blog/content/img1.png';
import ImageTestTwo from '../../../assets/img/Blog/content/img2.png';

const contentBlogPost = `Una parte importante del SEO implica posicionar webs basándose en palabras clave. Los profesionales de SEO realizan investigaciones de palabras clave para encontrar búsquedas relacionadas con el objetivo de incluirlas en su contenido, y después analizar la posición de las palabras clave en buscadores. <br /><br /> Si quieres seguir tus <a href="#">posiciones</a>, hay muchas maneras de empezar. Puedes usar datos de posicionamiento para medir el éxito de tus campañas de SEO o compartir el progreso con tus clientes. En esta guía, compartiremos algunas de nuestras herramientas que pueden ayudarte a seguir el posicionamiento de palabras clave. <ol><li>La técnica de Skyscraper</li><li>El método Moving Man</li><li>Técnica Guest Posting</li><li>Relaciones públicas digitales</li></ol> <img src=${ImageTestOne} alt="imageTestOne" /> <img src=${ImageTestTwo} alt="imageTestOne" /> <h2>¿Qué es una difusión? — Estrategias y técnicas de generación de backlinks</h2> Una parte importante del SEO implica posicionar webs basándose en palabras clave. Los profesionales de SEO realizan investigaciones de palabras clave para encontrar búsquedas relacionadas con el objetivo de incluirlas en su contenido, y después analizar la posición de las palabras clave en buscadores. Si quieres seguir tus posiciones, hay muchas maneras de empezar. Puedes usar datos de posicionamiento para medir el éxito de tus campañas de SEO o compartir el progreso con tus clientes. En esta guía, compartiremos algunas de nuestras herramientas que pueden ayudarte a seguir el posicionamiento de palabras clave. La técnica de Skyscraper incluye encontrar contenido al que las webs ya están enlazando y, a continuación, crear tu propio contenido de calidad sobre el mismo tema o nicho. Promocionarás tu contenido a esas webs y les convencerás de que enlacen, en su lugar, a tu contenido.`;

const contentTable = {
  title: 'Tabla de contenidos',
  links: [
    {
      text: 'Cuales son las 5 formas principales en las que te roban Gas',
      pathName: '#',
      navLink: true,
    },
    {
      text: '¿Qué es una difusión? — Estrategias y técnicas de generación de backlinks',
      pathName: '#',
      navLink: true,
    },
    {
      text: 'Mejores backlinks con las estrategias de difusión',
      pathName: '#',
      navLink: true,
    },
  ],
};

export { contentBlogPost, contentTable };
