import { ServiceSearchProvider } from '../../Contexts';
import { LayoutGeneral, ServicesSearchLayout } from '../../Layouts';
import { serviceSearchContainer } from './styles';

const ServicesSearch = () => {
  return (
    <ServiceSearchProvider>
      <LayoutGeneral style={serviceSearchContainer}>
        <ServicesSearchLayout />
      </LayoutGeneral>
    </ServiceSearchProvider>
  );
};

export default ServicesSearch;
