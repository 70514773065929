export const titleServices = {
  position: 'relative',
  zIndex: '10',
  fontSize: '1.6875rem',
  fontWeight: '900',
  width: '100%',
  lineHeight: '2.731875rem',
  marginBottom: '0.8rem',
};

export const contentServices = {
  height: '500px',
  marginTop: '2rem',
  marginBottom: {
    xs: '2rem',
    md: '-2rem',
  },
};

export const titleListSercieSkeleton = {
  backgroundColor: 'primary.main',
  width: {
    xs: '100%',
    md: '50%',
  },
};
