const LightWaddiLogo = (props) => (
  <svg
    width='119'
    height='26'
    viewBox='0 0 119 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M17.2897 23.7048L24.7554 13.6075C25.5215 12.5768 27.0687 12.5768 27.8348 13.6075L34.7146 22.8833L35.1953 23.5255C36.9678 25.9453 40.6931 25.6316 42.0301 22.943L52.6502 1.77749L49.2253 0L39.6266 19.1042C39.0858 20.1797 37.6137 20.2992 36.8927 19.3432L25.0107 3.30105L24.6652 2.838C23.3584 1.07545 20.7747 0.881274 19.2275 2.41977L10.485 11.4864C9.4485 12.5619 7.69099 12.4424 6.80472 11.2475L3.16953 6.31829L0 8.54388L11.221 23.7197C12.7232 25.7511 15.7876 25.7362 17.2897 23.7048ZM15.4721 19.6718C14.8712 20.4784 13.6545 20.4784 13.0536 19.6718L10.8305 16.6695L20.8047 6.3631C21.3155 5.85525 22.1567 5.92993 22.5773 6.51247L23.8991 8.26008L15.4721 19.6718Z' fill='white' />
    <path d='M82.3778 0.0447998V10.4409C81.8821 10.0674 81.4014 9.72388 80.8607 9.46996C79.8843 8.97704 78.7877 8.73805 77.601 8.73805C76.3542 8.73805 75.1826 8.9621 74.1461 9.38033C73.1096 9.79857 72.2083 10.3811 71.4723 11.1429C70.7362 11.9047 70.1504 12.8009 69.7448 13.8166C69.3392 14.8323 69.1289 15.9675 69.1289 17.1624C69.1289 18.3574 69.3392 19.4776 69.7448 20.5083C70.1504 21.524 70.7212 22.4351 71.4422 23.182C72.1783 23.9288 73.0495 24.5412 74.0409 24.9744C75.0323 25.3926 76.0989 25.5868 77.2255 25.5868C78.6826 25.5868 79.9744 25.2731 81.071 24.6458C81.5517 24.362 81.9873 24.0483 82.3929 23.6898V25.1387H86.1482V0.0447998H82.3778ZM81.9873 15.2206C82.1976 15.8032 82.3328 16.4604 82.3328 17.1624C82.3328 17.8645 82.2126 18.5217 81.9873 19.1192C81.792 19.6868 81.4766 20.1946 81.086 20.6278C80.6804 21.046 80.1847 21.3896 79.6139 21.6435C79.0581 21.8825 78.4272 22.002 77.7062 22.002C77.0002 22.002 76.3392 21.8825 75.7834 21.6435C75.2276 21.3896 74.7469 21.046 74.3414 20.6278C73.9358 20.1797 73.6203 19.6718 73.395 19.1192C73.1847 18.5217 73.0795 17.8645 73.0795 17.1624C73.0795 16.4604 73.1847 15.8181 73.395 15.2356C73.6203 14.653 73.9358 14.1303 74.3414 13.712C74.7469 13.2789 75.2276 12.9353 75.7834 12.6963C76.3542 12.4424 77.0002 12.308 77.7062 12.308C78.4122 12.308 79.0581 12.4424 79.6139 12.6963C80.1847 12.9353 80.6654 13.2789 81.086 13.712C81.4916 14.1303 81.792 14.6232 81.9873 15.2206Z' fill='white' />
    <path d='M101.74 0.0447998V10.4409C101.245 10.0674 100.764 9.72388 100.223 9.46996C99.2468 8.97704 98.1503 8.73805 96.9636 8.73805C95.7168 8.73805 94.5451 8.9621 93.5086 9.38033C92.4721 9.79857 91.5709 10.3811 90.8348 11.1429C90.0987 11.9047 89.5129 12.8009 89.1073 13.8166C88.7018 14.8323 88.4915 15.9675 88.4915 17.1624C88.4915 18.3574 88.7018 19.4776 89.1073 20.5083C89.5129 21.524 90.0837 22.4351 90.8048 23.182C91.5408 23.9288 92.4121 24.5412 93.4035 24.9744C94.3949 25.3926 95.4614 25.5868 96.588 25.5868C98.0451 25.5868 99.337 25.2731 100.434 24.6458C100.914 24.362 101.35 24.0483 101.755 23.6898V25.1387H105.511V0.0447998H101.74ZM101.35 15.2206C101.56 15.8032 101.695 16.4604 101.695 17.1624C101.695 17.8645 101.575 18.5217 101.35 19.1192C101.155 19.6868 100.839 20.1946 100.449 20.6278C100.043 21.046 99.5472 21.3896 98.9764 21.6435C98.4206 21.8825 97.7897 22.002 97.0687 22.002C96.3627 22.002 95.7018 21.8825 95.146 21.6435C94.5902 21.3896 94.1095 21.046 93.7039 20.6278C93.2983 20.1797 92.9829 19.6718 92.7575 19.1192C92.5472 18.5217 92.4421 17.8645 92.4421 17.1624C92.4421 16.4604 92.5472 15.8181 92.7575 15.2356C92.9829 14.653 93.2983 14.1303 93.7039 13.712C94.1095 13.2789 94.5902 12.9353 95.146 12.6963C95.7168 12.4424 96.3627 12.308 97.0687 12.308C97.7747 12.308 98.4206 12.4424 98.9764 12.6963C99.5472 12.9353 100.028 13.2789 100.449 13.712C100.854 14.1303 101.155 14.6232 101.35 15.2206Z' fill='white' />
    <path d='M112.871 9.15625H109.116V25.1387H112.871V9.15625Z' fill='white' />
    <path d='M63.9014 8.85749V10.4856C63.4057 10.0674 62.91 9.73876 62.3692 9.48484C61.3928 9.00686 60.3112 8.75293 59.1396 8.75293C57.9078 8.75293 56.7361 8.97698 55.6997 9.39521C54.6782 9.79851 53.7769 10.396 53.0409 11.1578C52.3048 11.9046 51.734 12.8008 51.3134 13.8315C50.9078 14.8472 50.6975 15.9674 50.6975 17.1624C50.6975 18.3573 50.9078 19.4776 51.3134 20.4933C51.719 21.509 52.2898 22.4052 52.9958 23.167C53.7318 23.9138 54.6031 24.5113 55.5945 24.9445C56.5709 25.3478 57.6374 25.5569 58.764 25.5569C60.1911 25.5569 61.4679 25.2432 62.5494 24.6159C63.0602 24.3171 63.4958 23.9885 63.8864 23.63V25.1387H67.4915V8.85749H63.9014ZM63.616 15.1758C63.8413 15.7583 63.9615 16.4155 63.9615 17.1176C63.9615 17.8196 63.8413 18.4768 63.616 19.0892C63.4057 19.6568 63.1052 20.1647 62.6997 20.6128C62.2941 21.031 61.7984 21.3895 61.2125 21.6434C60.6417 21.9123 60.0108 22.0318 59.2898 22.0318C58.5688 22.0318 57.9228 21.9123 57.352 21.6733C56.7962 21.4194 56.3005 21.0609 55.8949 20.6427C55.4894 20.1946 55.1589 19.6867 54.9486 19.1191C54.7383 18.5216 54.6331 17.8495 54.6331 17.1474C54.6331 16.4454 54.7383 15.7882 54.9486 15.2057C55.1739 14.6082 55.4894 14.1003 55.91 13.6672C56.3155 13.234 56.7962 12.8904 57.367 12.6365C57.9379 12.3826 58.5988 12.2482 59.3048 12.2482C60.0108 12.2482 60.6718 12.3826 61.2276 12.6365C61.7984 12.8755 62.2941 13.2191 62.6997 13.6672C63.1052 14.0854 63.4057 14.5932 63.616 15.1758Z' fill='white' />
    <path d='M117.242 22.1514C116.266 22.1514 115.485 22.943 115.485 23.899C115.485 24.8549 116.281 25.6466 117.242 25.6466C118.204 25.6466 119 24.8549 119 23.899C119 22.943 118.219 22.1514 117.242 22.1514Z' fill='white' />
  </svg>
);
export default LightWaddiLogo;
