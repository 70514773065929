import { directionButttons } from '../../Common/messages';

const initialIndex = 0;
const carouselOffset = 1;
const leftForEachCardDesktop = -520;
const leftForEchCardMobile = -320;
const initialCardMobile = 37;

const eventCarousel = (
  direction,
  mobile,
  textSuggestions,
  currentIndex,
  setCurrentIndex,
  contentCardsRef,
  contentCardsMobileRef,
  buttonsRef,
) => {
  const { next, previous } = directionButttons;
  let index = currentIndex;
  const cardsMobile = contentCardsMobileRef;
  const cardsDesktop = contentCardsRef;
  const pointCarousel = buttonsRef;
  if (direction === next) {
    index = index < textSuggestions.length - carouselOffset
      ? currentIndex + carouselOffset
      : initialIndex;
  } else if (direction === previous) {
    index = index > initialIndex
      ? currentIndex - carouselOffset
      : textSuggestions.length - carouselOffset;
  }
  if (mobile) cardsMobile.current.style.left = `${index * leftForEchCardMobile === initialIndex ? initialCardMobile : index * leftForEchCardMobile}px`;
  else cardsDesktop.current.style.left = `${index * leftForEachCardDesktop}px`;
  setCurrentIndex(index);
  pointCarousel.current.forEach((el) => {
    el.classList.remove('activePoint');
  });
};

let movementStart;
let isPressed = false;
let movementX;
const classTransition = 'transitionMovement';

const startMovement = (e, contentCardsRef) => {
  movementStart = e.pageX - contentCardsRef.current.offsetLeft;
  contentCardsRef.current.classList.add(classTransition);
  isPressed = true;
};
const endMovement = (contentCardsRef) => {
  isPressed = false;
  contentCardsRef.current.classList.remove(classTransition);
};
const carouselMovement = (e, contentCardsRef, xPositionDefault, xPositiondEnd) => {
  if (!isPressed) return;
  const contentCards = contentCardsRef;
  e.preventDefault();
  const cursosX = e.pageX;
  movementX = cursosX - movementStart;
  if (movementX >= xPositionDefault) {
    contentCards.current.style.left = `${xPositionDefault}px`;
    return;
  }
  if (movementX <= xPositiondEnd) {
    contentCards.current.style.left = `${xPositiondEnd}px`;
    return;
  }
  contentCards.current.style.left = `${movementX}px`;
};

const startMovementMobile = (
  e,
  contentCardsMobileRefMobile,
) => {
  const cardMobile = contentCardsMobileRefMobile;
  const touches = e.touches[0] || {};
  movementStart = touches.pageX - cardMobile.current.offsetLeft;
  cardMobile.current.classList.add(classTransition);
  isPressed = true;
};
const endMovementMobile = (contentCardsMobileRefMobile) => {
  contentCardsMobileRefMobile.current.classList.remove(classTransition);
  isPressed = false;
};
const carouselMovementMobile = (
  e,
  contentCardsMobileRefMobile,
  xPositionDefaultMobile,
  xPositiondEndMobile,
) => {
  if (!isPressed) return;
  const cardMobile = contentCardsMobileRefMobile;
  e.preventDefault();
  const touches = e.touches[0] || {};
  const touchX = touches.pageX;
  movementX = touchX - movementStart;
  if (movementX >= xPositionDefaultMobile) {
    cardMobile.current.style.left = `${xPositionDefaultMobile}px`;
    return;
  }
  if (movementX <= xPositiondEndMobile) {
    cardMobile.current.style.left = `${xPositiondEndMobile}px`;
    return;
  }
  cardMobile.current.style.left = `${movementX}px`;
};

export {
  startMovement,
  endMovement,
  carouselMovement,
  startMovementMobile,
  endMovementMobile,
  carouselMovementMobile,
  eventCarousel,
};
