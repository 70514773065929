export const bgImage = (SaveTimeImageDesktop, SaveTimeImageMobile) => {
  return {
    display: 'flex',
    justifyContent: { xs: 'center' },
    width: '100%',
    height: {
      xs: '755px', sm: '755px', md: '100%', lg: '100%',
    },
    minHeight: {
      xs: 'initial',
      md: '618px',
    },
    borderRadius: {
      xs: 'initial',
      md: '40px',
    },
    position: {
      xs: 'relative', sm: 'relative', md: 'static', lg: 'static',
    },
    top: { xs: '69px', sm: '69px' },
    overflow: 'hidden',
    backgroundImage: {
      xs: `url(${SaveTimeImageMobile})`,
      sm: `url(${SaveTimeImageMobile})`,
      md: `url(${SaveTimeImageDesktop})`,
      lg: `url(${SaveTimeImageDesktop})`,
    },
    backgroundSize: {
      xs: '100% 96%', sm: '100% 96%', md: '100%', lg: '100%',
    },
    backgroundPosition: {
      xs: 'center -8px', sm: 'center -8px', md: 'center center', lg: 'center center',
    },
    backgroundRepeat: 'no-repeat',
  };
};

export const contentCaption = {
  display: 'flex',
  flexBasis: {
    xs: '100%', sm: '100%', md: '50%', lg: '50%',
  },
  justifyContent: {
    xs: 'start', phoneMd: 'start', sm: 'start', md: 'center', lg: 'center',
  },
  alignItems: {
    xs: 'flex-end', phoneMd: 'flex-end', sm: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end',
  },
  overflow: 'hidden',
  '& > div': {
    display: {
      xs: 'flex', sm: 'flex', md: 'block', lg: 'block',
    },
    flexDirection: { xs: 'column', md: 'column' },
    alignItems: { xs: 'center', md: 'center' },
    flexBasis: {
      xs: '100%', sm: '100%', md: '73%', lg: '73%',
    },
    height: {
      xs: '53%', phoneMd: '53%', sm: '62%', md: '50%',
    },
    marginBottom: {
      xs: '0', sm: '0', md: '103px', laptop: '100px', lg: '60px',
    },
  },
};

export const headingOne = {
  width: '100%',
  maxWidth: {
    xs: '277px', sm: '277px', md: '445px', lg: '445px',
  },
  margin: {
    xs: '0 32px', sm: '0 32px', md: '0', lg: '0',
  },
  marginBottom: {
    xs: '8px', sm: '8px', md: '16px', lg: '16px',
  },
  fontSize: {
    xs: '2.5rem', sm: '2.5rem', md: '3.125rem', lg: '3.125rem',
  },
  fontWeight: '900',
  lineHeight: {
    xs: '2.5rem', sm: '2.5rem', md: '3.125rem', lg: '3.125rem',
  },
};

export const headingTwo = {
  width: {
    xs: '100%', sm: '100%', md: '100%', lg: '100%',
  },
  maxWidth: {
    xs: '275px', sm: '275px', md: '540px', lg: '540px',
  },
  margin: {
    xs: '0 32px', sm: '0 32px', md: '0', lg: '0',
  },
  marginBottom: {
    xs: '16px', sm: '16px', md: '40px', lg: '40px',
  },
  fontSize: {
    xs: '1rem', sm: '1rem', md: '1.375rem', lg: '1.375rem',
  },
  lineHeight: {
    xs: '1.36625rem', sm: '1.36625rem', md: '1.878125rem', lg: '1.878125rem',
  },
};

export const contentImageCaption = {
  display: {
    xs: 'none', sm: 'none', md: 'flex', lg: 'flex',
  },
  flexBasis: '50%',
  justifyContent: 'end',
  '& img': {
    objectFit: 'cover',
    width: '83%',
  },
};

export const contentIconApps = {
  maxWidth: {
    xs: '292px', sm: '292px', md: 'initial', lg: 'initial',
  },
  width: '100%',
  margin: {
    xs: '0 auto', ms: '0 auto', md: '0', lg: '0',
  },
};

export const styleAppStore = {
  width: {
    xs: '9.25rem', sm: '9.25rem', md: '10.375rem', lg: '10.375rem',
  },
  marginRight: {
    xs: '0px', sm: '0px', md: '16px', lg: '16px',
  },
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%', sm: '88%', md: '100%', lg: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: {
    xs: '9.25rem', sm: '9.25rem', md: '10.375rem', lg: '10.375rem',
  },
  textAlign: 'center',
  '& img': {
    width: {
      xs: '88%', sm: '88%', md: '100%', lg: '100%',
    },
  },
};

export const progressContainer = {
  display: 'grid',
  placeItems: 'center',
  backgroundImage: 'none',
};
