export const containerContetAndBannerRRSS = {
  padding: '0px !important',
  width: '100%',
  maxWidth: {
    xs: '343px !important',
    sm: '343px !important',
    tablet: '88% !important',
    md: '883px !important',
    lg: '883px !important',
  },
};

export const containBannerRRSS = {
  backgroundColor: 'secondary.main',
  width: '100%',
  height: {
    xs: '190px',
    phoneMd: '190px',
    tablet: '135px',
    md: '135px',
    lg: '135px',
  },
  margin: {
    xs: '2rem auto 3.1rem auto !important',
    phoneMd: '2rem auto 3.1rem auto !important',
    sm: '2rem auto 3.1rem auto !important',
    md: '2rem 0rem 6.5rem 0rem !important',
    lg: '2rem 0rem 6.5rem 0rem !important',
    xl: '2rem 0rem 6.5rem 0rem !important',
  },
};

export const containFlexIconRRSS = {
  spacing: {
    xs: 2,
    phoneMd: 2,
    sm: 2,
    md: 2.6,
    lg: 2.6,
    xl: 2.6,
  },
  style: {
    width: {
      xs: '90%',
      phoneMd: '90%',
      md: 'auto',
    },
    '& > a:nth-of-type(1)': {
      order: {
        xs: '1',
        phoneMd: '1',
        md: 'initial',
        lg: 'initial',
      },
    },
    '& > a:nth-of-type(2)': {
      order: {
        xs: '3',
        phoneMd: '3',
        md: 'initial',
        lg: 'initial',
      },
    },
    '& > a:nth-of-type(3)': {
      marginLeft: {
        xs: '-15px',
        phoneMd: '-15px',
        md: '20.8px',
        lg: '20.8px',
      },
      order: {
        xs: '2',
        phoneMd: '2',
        md: 'initial',
        lg: 'initial',
      },
    },
    '& > a:nth-of-type(4)': {
      order: {
        xs: '4',
        phoneMd: '4',
        md: 'initial',
        lg: 'initial',
      },
    },
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
};

export const containtCaptionAndRRSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
  marginLeft: {
    xs: '0rem',
    phoneMd: '0rem',
    md: '2rem',
    lg: '2rem',
  },
  '& > h5': {
    width: '100%',
    maxWidth: {
      xs: '267px',
      phoneMd: '267px',
      tablet: 'initial',
      md: 'initial',
      lg: 'initial',
    },
    marginLeft: {
      xs: '2rem',
      phoneMd: '2rem',
      md: 'initial',
      lg: 'initial',
    },
    color: 'text.secondary',
    fontSize: '1.375rem',
    fontWeight: '900',
    lineHeight: '1.878125rem',
  },
  '& > div': {
    margin: '0rem',
  },
  '& > div > a': {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.625rem',
    fontSize: '1.125rem',
    fontWeight: '900',
    '& > span:first-of-type': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > span:last-of-type': {
      fontSize: 'inherit',
      color: 'primary.main',
    },
  },
};

export const contentBannerAndYouMightInterestedLayout = {
  backgroundColor: 'secondary.main',
  paddingTop: {
    xs: '2.5rem',
    phoneMd: '2.5rem',
    sm: '2.5rem',
    md: '4rem',
    lg: '4rem',
  },
  paddingBottom: '4rem',
};

export const layoutBlogPost = {
  maxWidth: {
    xs: '343px',
    sm: '343px',
    tablet: '1190px',
    md: '1190px',
    lg: '1190px',
  },
  padding: '0px',
};
