import { downloads, pathViews } from './paths';

const { blog: { app, childrens } } = pathViews;

const listLinks = [
  {
    text: 'Beneficios',
    pathName: '#beneficios',
  },
  {
    text: 'Servicios',
    pathName: '#servicios',
  },
  {
    text: 'Cobertura',
    pathName: '#cobertura',
  },
  {
    text: 'Blog',
    pathName: `${app}${childrens.home}`,
    navLink: true,
  },
  {
    text: 'Descarga la App',
    pathName: downloads.downLoadApp,
    prevent: (e) => { e.preventDefault(e); },
  },
];

export {
  listLinks,
};
