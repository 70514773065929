export const contentPostHome = {
  bacckgroundColor: 'menu.primary',
};

export const containerPost = {
  maxWidth: {
    xs: '345px !important',
    sm: '345px !important',
    md: '1190px !important',
    lg: '1190px !important',
  },
  marginTop: '124px',
  padding: '0px !important',
};

export const styleContentPosts = {
  spacing: {
    row: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
    column: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
  },
  style: {
    width: '100% !important',
    margin: '0px !important',
    '& a': {
      textDecoration: 'none',
    },
    '& > div': {
      padding: '0 !important',
    },
  },
};

export const styleContentLastPosts = {
  style: {
    width: '100% !important',
    marginLeft: '0px !important',
    '& a': {
      textDecoration: 'none',
    },
    '& > div': {
      padding: '0px !important',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  spacing: {
    row: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
    column: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
  },
};

export const styleColumnOne = {
  card: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'column',
      md: 'row',
      lg: 'row',
    },
    borderRadius: '30px',
    boxShadow: 'none',
    height: {
      xs: '500px',
      phoneMd: '500px',
      md: '394px',
      lg: '394px',
    },
    backgroundColor: 'primary.main',
    marginBottom: '2.8125rem',
    color: 'secondary.main',
    '& > img': {
      flexBasis: '48.6%',
      borderRadius: '30px',
      objectFit: 'cover',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '51.4%',
      margin: 'auto',
      width: {
        xs: '88%',
        sm: '88%',
        md: '100%',
        lg: '100%',
      },
      height: '100%',
      maxWidth: '409px !important',
      maxHeight: '247px !important',
      '& > div:first-of-type': {
        fontSize: '1rem !important',
        color: 'background.textFieldSubscribe',
        '& > div > span': {
          color: 'inherit',
        },
      },
      '& > div:nth-of-type(2)': {
        width: '91%',
        '& > div > span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1.75rem !important',
          lineHeight: '2.25rem',
        },
      },
      '& > div:first-of-type, & > div:nth-of-type(2)': {
        marginBottom: '.5rem !important',
      },
      '& > div:nth-of-type(3)': {
        '& > p': {
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '1rem',
          lineHeight: '1.375rem',
        },
      },
      '& div': {
        padding: '0px !important',
      },
    },
  },
};
export const styleColumnTwo = {
  contain: {
    '&:nth-of-type(3)': {
      height: 'auto !important',
      padding: '0 !important',
      marginLeft: {
        xs: '0px',
        sm: '0px',
        md: '2rem',
        lg: '2rem',
      },
    },
  },
  card: {
    height: {
      xs: '500px',
      phoneMd: '500px',
      md: '424px',
      lg: '424px',
    },
    borderRadius: '30px',
    margin: '0px !important',
    marginBottom: '2.5rem !important',
    boxShadow: '20px 30px 60px rgba(0, 0, 0, 0.05)',
    '& > img': {
      width: '100%',
      height: '47.4%',
      objectFit: 'cover',
      objectPosition: 'center center',
    },
    '& > div': {
      margin: '2.5rem auto 2rem',
      width: {
        xs: '88%',
        sm: '88%',
        md: '100%',
        lg: '100%',
      },
      height: '100%',
      maxWidth: '464px',
      maxHeight: '144px',
      '& > div:first-of-type': {
        '& > div > span': {
          color: 'primary.timeRead',
          fontWeight: '500',
        },
        marginBottom: '.5rem',
      },
      '& > div:nth-of-type(2)': {
        marginBottom: '1rem',
        '& > div > span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: {
            xs: 3,
            md: 2,
          },
          WebkitBoxOrient: 'vertical',
          color: 'text.secondary',
          fontSize: '1.75rem !important',
          fontWeight: '900',
          lineHeight: '2.375rem',
        },
      },
      '& > div:nth-of-type(3)': {
        lineHeight: '1rem',
        '& > div': {
          color: 'text.authorBlogPost',
        },
      },
    },
    '& div': {
      padding: '0px !important',
    },
  },
};

export const styleColumnThree = {
  contain: {
    display: 'flex',
    marginBottom: '2.5rem',
    '&:nth-of-type(3n+2)': {
      display: 'flex',
      justifyContent: 'center',
    },
    '&:nth-of-type(3n+3)': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  card: {
    marginBottom: '0rem',
  },
};

export const containerPagination = {
  width: '100%',
  maxWidth: '406px',
  margin: '0 auto',
  paddingTop: '2.64rem',
  paddingBottom: '5rem',
};

export const pagination = {
  display: 'flex',
  justifyContent: 'center',
  color: 'primary.normal',
  '& > ul': {
    '& > li > button': {
      width: '46px',
      height: '46px',
      color: 'primary.normal',
      fontWeight: '800',
      fontSize: '1.125rem',
      borderRadius: '15px',
      '& > span': {
        color: 'secondary.main',
      },
    },
    '& > li:first-of-type, & > li:last-of-type': {
      display: 'none',
    },
  },
};

export const contentBannerSubscribeHome = {
  backgroundColor: 'secondary.main',
  paddingTop: '4rem',
  paddingBottom: '4rem',
  '& > div > div': {
    marginBottom: '0px',
  },
};
