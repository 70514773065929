/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  breakPoints,
  FORMAT_DATE_CALENDAR,
  LOCALSTORAGE_NAME_TYPES,
  SEARCH_SELECT_TYPES,
  SELECTORS_TYPES,
  TYPES_BUTTONS,
} from '../../Common';
import { useBreakpoint } from '../../hooks';
import {
  arrowIcon,
  ContentSelect,
  locationSelectorsStyles,
  SelectContainer,
  SelectLocation,
  SelectService,
  DesktopContentSelect,
  locationButton,
  TextButtonSelect,
  SelectDate,
  dateCustomStyles,
  dateSelectCustomStyles,
  buttonSearchMobile,
  arrowDateIcon,
  buttonSearchDescktop,
  modalLocationStyles,
  servicesSelectorsStyles,
  labelShow,
  customServiceInput,
  customLocationInput,
} from './styles';
import { pathViews, ServicesSearchMessage } from '../../Common/messages';
import SelectsFilters from './SelectsFilters';
import { ServiceSearchContext } from '../../Contexts';
import ModalServices from './ModalServices';
import {
  serviceFilter,
  locationFilter,
  dateFilter,
  getSelectorsData,
} from '../../store/action';
import ModalDate from './ModalDate';
import ButtonPrimary from '../Button/ButtonPrimary';
import { ServiceSearchUtils } from '../../utils';
import { DataServicesSearch } from '../../api';

const {
  filterServices,
  handleModal,
  iconRotate,
  selectButtonChangeColor,
} = ServiceSearchUtils;

const { dayMonthYear } = FORMAT_DATE_CALENDAR;

const {
  titles,
  textModals,
  titlesSelectsMobile,
  customLabel,
  customSelect,
} = ServicesSearchMessage;

const {
  getServicesType,
} = DataServicesSearch;

const {
  serviceTypeData,
} = LOCALSTORAGE_NAME_TYPES;

const ContentSelectors = () => {
  const [toggleBreakpoint] = useBreakpoint(breakPoints.mobile);
  const [searchServices, dispatch] = useContext(ServiceSearchContext);
  const [openModals, setOpenModals] = useState({
    modalService: false,
    modalLocation: false,
    modalDate: false,
    valueService: searchServices.service,
    valueLocation: searchServices.location,
    serviceType: '',
  });
  const refIcon = useRef(null);
  const refLocationIcon = useRef(null);
  const refDateIcon = useRef(null);
  const servicesRef = useRef(null);
  servicesRef.current = [];
  const locationsRef = useRef(null);
  locationsRef.current = [];

  const { service: nameServiceModal, location, date } = SEARCH_SELECT_TYPES;

  useEffect(() => {
    if (toggleBreakpoint) {
      const icon = refIcon.current;
      iconRotate(openModals.modalService, icon);
    }
  }, [openModals.modalService]);

  useEffect(() => {
    if (toggleBreakpoint) {
      const icon = refLocationIcon.current;
      iconRotate(openModals.modalLocation, icon);
    }
  }, [openModals.modalLocation]);

  useEffect(() => {
    const icon = refDateIcon.current;
    iconRotate(openModals.modalDate, icon);
  }, [openModals.modalDate]);

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem(LOCALSTORAGE_NAME_TYPES.services, JSON.stringify(searchServices));
  }, [searchServices.service, searchServices.location, searchServices.date]);

  const servicesSearch = searchServices.service;
  const locationSearch = searchServices.location;

  useEffect(() => {
    setOpenModals(prevModal => ({
      ...prevModal,
      modalService: false,
      modalLocation: false,
    }));
  }, [toggleBreakpoint]);

  useEffect(() => {
    if (toggleBreakpoint) {
      servicesRef.current = servicesRef.current.filter(service => service);
      servicesRef.current.forEach(serviceRef => {
        if (servicesSearch === serviceRef.value) {
          serviceRef.style.backgroundColor = 'rgba(68, 51, 224, 0.2)';
        } else {
          serviceRef.style.backgroundColor = 'transparent';
        }
      });

      locationsRef.current = locationsRef.current.filter(service => service);
      locationsRef.current.forEach(locationRef => {
        if (locationSearch === locationRef.value) {
          locationRef.style.backgroundColor = 'rgba(68, 51, 224, 0.2)';
        } else {
          locationRef.style.backgroundColor = 'transparent';
        }
      });
    }
  }, [toggleBreakpoint, openModals.modalService, openModals.modalLocation]);

  useEffect(() => {
    getServicesType().then(servicesTypeResponse => {
      const { data } = servicesTypeResponse;
      dispatch(getSelectorsData(data));
    });
  }, []);

  const handleOpen = (e, modalName, openOrClose) => {
    handleModal(true, false, setOpenModals, modalName, openOrClose);
  };

  const handleClose = (e, modalName, openOrClose) => {
    handleModal(false, true, setOpenModals, modalName, openOrClose);
  };

  const valueService = 'valueService';
  const valueLocation = 'valueLocation';
  const handleSelect = (e, modalName) => {
    const { target } = e;
    const serviceTypeTargetData = {
      description: target?.value?.split(' ').join('-'),
      registryUuid: target?.name,
    };

    if (modalName === nameServiceModal) {
      if (toggleBreakpoint) {
        // eslint-disable-next-line no-undef
        sessionStorage.setItem(serviceTypeData, JSON.stringify(serviceTypeTargetData));
        selectButtonChangeColor(target, valueService, setOpenModals, servicesRef);
      }
    }
    if (modalName === location) {
      if (toggleBreakpoint) {
        selectButtonChangeColor(target, valueLocation, setOpenModals, locationsRef);
      }
    }
    setOpenModals(prevModal => ({
      ...prevModal,
      serviceType: target.name,
    }));
  };

  const handleServiceSelectDesktop = (e, selectType, items) => {
    const serviceType = items.find(item => e.target.value === item.description);
    if (SELECTORS_TYPES.services === selectType) {
      const serviceTypeTargetData = {
        description: serviceType?.description?.split(' ').join('-'),
        registryUuid: serviceType?.registryUuid,
      };
      // eslint-disable-next-line no-undef
      sessionStorage.setItem(serviceTypeData, JSON.stringify(serviceTypeTargetData));
    }
    filterServices(
      e,
      selectType,
      dispatch,
      serviceFilter,
      locationFilter,
      serviceType.registryUuid,
    );
  };

  const handleSearch = (e, { modalName, openModal }) => {
    setOpenModals(prevModal => ({
      ...prevModal,
      [modalName]: !openModal,
    }));
    if (toggleBreakpoint && modalName === nameServiceModal) {
      dispatch(serviceFilter({
        service: openModals.valueService, serviceType: openModals.serviceType,
      }));
    }
    if (toggleBreakpoint && modalName === location) {
      dispatch(locationFilter(openModals.valueLocation));
    }
  };

  const handleDate = (dateValue, typeModal, modalDate) => {
    const { _d: day } = dateValue;
    setOpenModals(prevModal => ({
      ...prevModal,
      [typeModal]: !modalDate,
    }));
    dispatch(dateFilter(day));
  };

  const handleSearchButton = () => {
    navigate(pathViews.servicesSearch.app);
  };

  const serviceLabel = searchServices.service ? labelShow : customServiceInput;
  const locationLabel = searchServices.location ? labelShow : customLocationInput;

  return (
    toggleBreakpoint ? (
      <>
        <ContentSelect>
          <SelectService
            onClick={(e) => handleOpen(e, nameServiceModal, openModals.modalService)}
          >
            <Box sx={TextButtonSelect}>
              { searchServices.service ? searchServices.service : titlesSelectsMobile.service }
            </Box>
            <KeyboardArrowDownIcon ref={(el) => refIcon.current = el} sx={arrowIcon} />
          </SelectService>

          <SelectLocation
            onClick={(e) => handleOpen(e, location, openModals.modalLocation)}
          >
            <Box sx={TextButtonSelect}>
              { searchServices.location ? searchServices.location : titlesSelectsMobile.location }
            </Box>
            <KeyboardArrowDownIcon ref={(el) => refLocationIcon.current = el} sx={arrowIcon} />
          </SelectLocation>
          <SelectDate
            onClick={(e) => handleOpen(e, date, openModals.modalDate)}
          >
            <Box sx={TextButtonSelect}>
              {
                  searchServices.date
                    ? moment(searchServices.date).format(dayMonthYear)
                    : titlesSelectsMobile.date
                  }
            </Box>
            <KeyboardArrowDownIcon ref={(el) => refDateIcon.current = el} sx={arrowIcon} />
          </SelectDate>

          <ButtonPrimary
            handleToggle={handleSearchButton}
            styles={buttonSearchMobile}
            typeButton={TYPES_BUTTONS.button}
          >
            { titles.titleSerachButton }
          </ButtonPrimary>
        </ContentSelect>

        <ModalServices
          handleClose={handleClose}
          handleSearch={handleSearch}
          handleSelect={handleSelect}
          modal={openModals.modalService}
          nameServiceModal={nameServiceModal}
          searchServices={openModals.valueService}
          items={searchServices.servicesSelectors}
          itemsRef={servicesRef}
          textModals={textModals}
          titleModal={titles.titleServiceModal}
        />
        <ModalServices
          handleClose={handleClose}
          handleSearch={handleSearch}
          handleSelect={handleSelect}
          modal={openModals.modalLocation}
          nameServiceModal={location}
          searchServices={openModals.valueLocation}
          items={searchServices.locationSelectors}
          itemsRef={locationsRef}
          textModals={textModals}
          titleModal={titles.titleLocationModal}
          styles={{ button: locationButton, modal: modalLocationStyles }}
        />

        <ModalDate
          modalName={date}
          dateValue={searchServices.date}
          handleClose={handleClose}
          handleDate={handleDate}
          openModal={openModals.modalDate}
        />
      </>
    ) : (
      <DesktopContentSelect>
        <SelectContainer>
          <SelectsFilters
            items={searchServices.servicesSelectors}
            label={customLabel}
            selectId={customSelect}
            title={titles.titleServiceLabel}
            placeholder={titles.titleServiceLabel}
            handleSelectors={handleServiceSelectDesktop}
            value={searchServices.service}
            refElements={servicesRef.current}
            selectType={SELECTORS_TYPES.services}
            selectorsStyles={servicesSelectorsStyles}
            inputLabelStyle={serviceLabel}
          />
        </SelectContainer>

        <SelectContainer>
          <SelectsFilters
            items={searchServices.locationSelectors}
            label={customLabel}
            selectId={customSelect}
            title={titles.titleLocationLabel}
            placeholder={titles.titleLocationLabel}
            handleSelectors={handleServiceSelectDesktop}
            value={searchServices.location}
            refElements={locationsRef.current}
            selectorsStyles={locationSelectorsStyles}
            inputLabelStyle={locationLabel}
            selectType={SELECTORS_TYPES.locations}
          />
        </SelectContainer>

        <SelectContainer sx={dateSelectCustomStyles}>
          <SelectDate
            onClick={(e) => handleOpen(e, date, openModals.modalDate)}
            sx={dateCustomStyles}
          >
            <Box sx={TextButtonSelect}>
              {
                  searchServices.date
                    ? moment(searchServices.date).format(dayMonthYear)
                    : titlesSelectsMobile.date
                  }
            </Box>
            <KeyboardArrowDownIcon
              ref={(el) => refDateIcon.current = el}
              sx={{ ...arrowIcon, ...arrowDateIcon }}
            />
          </SelectDate>
        </SelectContainer>

        <ModalDate
          modalName={date}
          dateValue={searchServices.date}
          handleClose={handleClose}
          handleDate={handleDate}
          openModal={openModals.modalDate}
        />

        <ButtonPrimary
          handleToggle={handleSearchButton}
          styles={{ ...buttonSearchMobile, ...buttonSearchDescktop }}
          typeButton={TYPES_BUTTONS.button}
        >
          { titles.titleSerachButton }
        </ButtonPrimary>
      </DesktopContentSelect>
    )
  );
};

export default ContentSelectors;
