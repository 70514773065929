import { ServicePreOrder } from '../../Common/messages';

const {
  ACTION_TYPES,
} = ServicePreOrder;

export const setConfigurationData = (payload) => {
  return {
    type: ACTION_TYPES.setConfigurationData,
    payload,
  };
};

export const getCompanyId = (payload) => {
  return {
    type: ACTION_TYPES.getCompanyId,
    payload,
  };
};

export const getProductShowCase = (payload) => {
  return {
    type: ACTION_TYPES.getProductShowCase,
    payload,
  };
};

export const setDateData = (payload) => {
  return {
    type: ACTION_TYPES.setDate,
    payload,
  };
};

export const getPreOrderSummaryData = (payload) => {
  return {
    type: ACTION_TYPES.getPreOrderSummary,
    payload,
  };
};

export const getCheckoutData = (payload) => {
  return {
    type: ACTION_TYPES.getCheckout,
    payload,
  };
};

export const setCheckoutParamsData = (payload) => {
  return {
    type: ACTION_TYPES.setCheckoutParamsData,
    payload,
  };
};
