import { Alert, FormControl, styled } from '@mui/material';

export const ProductsContainer = styled('section')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2.5rem',
  height: 'max-content',
}));

export const loaderContainer = {
  justifyContent: 'center',
  alignItems: 'center',
  height: '230px',
  width: {
    xs: '100%',
    md: '522px',
  },
};

export const PreOrderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '3.4375rem',
    justifyContent: 'flex-start',
  },
}));

export const CaptionAndAddressContainer = styled('div')(() => ({
  width: '100%',
}));

export const captionStyles = {
  fontSize: {
    xs: '2rem',
    md: '2.1875rem',
  },
  lineHeight: {
    xs: '2.8125rem',
    md: '2.5rem',
  },
  fontWeight: '900',
  color: 'primary.main',
  marginBottom: '1rem',
};

export const titleAddressStyles = {
  color: 'text.secondary',
  fontSize: '1.25rem',
  fontWeight: '900',
  lineHeight: '2.5rem',
  marginBottom: '0.4375rem',
};

export const AddressContianer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.25rem',
  alignItems: 'center',
  width: 'max-content',
  height: '56px',
  fontWegith: '500',
  fontSize: '0.875rem',
  lineHeight: {
    xs: '0.875rem',
    md: '2.5rem',
  },
  padding: '.5rem 2rem',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '343px',
    height: 'max-content',
  },
}));

export const AddressIcon = styled('img')(() => ({
  width: 'max-content',
  height: 'max-content',
  objectFit: 'cover',
}));

export const TitleOrder = styled('p')(({ theme }) => ({
  fontWeight: 900,
  fontSize: '1.25rem',
  lineHeight: '2.5rem',
  marginTop: '1.5rem',
  color: theme.palette.text.secondary,
}));

export const FormContainer = styled(FormControl)(({ theme }) => ({
  marginTop: '-7.3rem',
  width: '36.3%',
  height: 'max-content',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: 'initial',
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '.5rem',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
  '& > button': {
    paddingLeft: '1.5rem',
    flexBasis: '49%',
    textTransform: 'initial',
  },
  [theme.breakpoints.down('sm')]: {
    '& > button': {
      flexBasis: '48%',
    },
  },
}));

export const IconButton = styled('img')(() => ({
  objectFit: 'contain',
  width: '20px',
  pointerEvents: 'none',
}));

export const TextButton = styled('span')(() => ({
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.375',
}));

export const PayFooter = styled('p')(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1.5rem',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  color: theme.palette.text.select,
}));

export const summaryTitleStyles = {
  fontWeight: '900',
  fontSize: {
    xs: '2rem',
    md: '2.1875rem',
  },
  lineHeight: {
    xs: '2rem',
    md: '2.5rem',
  },
  color: 'primary.main',
};

export const summaryContainerStyles = {
  display: 'flex',
};

export const summaryHidden = {
  display: 'none',
};

export const pricesTextCustom = {
  regularText: {
    '& > p': {
      fontSize: {
        xs: '0.875rem',
        md: '1rem',
      },
    },
  },
  totalText: {
    '& > p': {
      fontSize: {
        xs: '1rem',
        md: '1.5rem',
      },
      fontWeight: '900',
      color: 'primary.main',
    },
  },
};

export const preorderButton = {
  width: '100%',
  maxWidth: 'initial',
  marginTop: '.5rem',
  marginBottom: {
    xs: '1rem',
    md: '.5rem',
  },
  '&.Mui-disabled': {
    color: 'secondary.main',
    backgroundColor: '#C4C4C4',
  },
  '& .MuiCircularProgress-root': {
    width: '24px !important',
    height: '29px !important',
  },
  '& svg': {
    width: '24px !important',
    height: '29px !important',
  },
  '&:hover svg': {
    color: 'primary.main',
  },
};

export const ConfigurationPreOrderFooter = styled('p')(() => ({
  fontSize: '1rem',
  lineHeight: '1.1875rem',
  fontWeight: '500',
  color: 'text.authorBlogPost',
}));

export const labelCustom = {
  marginTop: '.3rem',
};

export const emailInputCustom = {
  marginBottom: '1.5rem',
};

export const customLoader = {
  display: 'grid',
  placeItems: 'center',
  marginTop: '2rem',
};

export const customPaymentLoader = {
  display: 'grid',
  placeItems: 'center',
};

export const PreOrderAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '.8rem',
  },
}));
