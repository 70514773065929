import { RadioGroup } from '@mui/material';
import Proptypes from 'prop-types';
import { useState } from 'react';
import { LabelRadio, StyledRadio, styledRadioContainer } from './styles';

const ServiceBillingGroupRadio = ({
  defaultValue,
  onChange,
  valueFirst,
  valueSecond,
  name,
  labelFirst,
  labelSecond,
  styledRadioGroup,
  radioSize,
}) => {
  const [value, setValue] = useState(defaultValue);

  const changeValue = ({ target }) => {
    setValue(target.value);
    onChange({ value: target.value, name: target.name });
  };
  return (
    <RadioGroup
      value={value}
      onChange={changeValue}
      name={name}
      sx={{ ...styledRadioContainer, ...styledRadioGroup }}
    >
      <LabelRadio
        value={valueFirst}
        control={<StyledRadio size={radioSize} />}
        label={labelFirst}
      />
      <LabelRadio
        value={valueSecond}
        control={<StyledRadio size={radioSize} />}
        label={labelSecond}
      />
    </RadioGroup>
  );
};

export default ServiceBillingGroupRadio;

ServiceBillingGroupRadio.prototypes = {
  defaultValue: Proptypes.string.isRequired,
};
