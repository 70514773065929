import { LayoutGeneral, SuccesPreOrderLayout } from '../../Layouts';
import { containerSucces, serviceSearchContainer } from './styles';

const SuccesPreOrder = () => {
  return (
    <LayoutGeneral style={{ ...serviceSearchContainer, ...containerSucces }}>
      <SuccesPreOrderLayout />
    </LayoutGeneral>
  );
};

export default SuccesPreOrder;
