const linkDownloadApp = 'Descarga la App';
const linkOrderGas = 'Pedir Gas en Web';

const noBar = 6;
const pathBlogpost = 11;

const getTopSections = (
  firstSection,
  firstSectionTop,
  scrollCalcFirstSection,
  secondSectionTop,
  scrollCalc,
) => ({
  firstSection,
  firstSectionTop,
  scrollCalcFirstSection,
  secondSectionTop,
  scrollCalc,
});

const scrollTopMozzilla = {
  suggestionsSection: {
    mobile: 0,
    desktop: 698,
  },
  ourServicesSection: {
    mobile: 1255,
    desktop: 1463,
  },
  coverageSection: {
    mobile: 3561,
    mobileXs: 3992,
    mobileLg: 3513,
    desktop: 2659,
  },
};

const scrollTopChrome = {
  suggestionsSection: {
    desktop: 410,
  },
  ourServicesSection: {
    desktop: 967,
  },
  coverageSection: {
    desktop: 2371,
  },
};

const TYPE_STYLE_TEXT = {
  weight: {
    bold: '800',
    normal: '500',
  },
};

const numberListSection = (number) => {
  return number + 1;
};

const columns = {
  one: 1,
  two: 2,
  three: 3,
};

const breakPoints = {
  mobile: '(max-width: 600px)',
  md: '(min-width: 931px)',
  lg: '(min-width: 1200px)',
};

const initialScroll = 0;

const lengthCardsSwiper = {
  oneCard: 1,
  forCard: 4,
};

const scores = {
  starsFive: 5,
  starsFour: 4,
};

const rowFourForm = 4;

const coverageEnums = {
  sectionFirst: 0,
  sectionSecond: 1,
  sectionThird: 2,
};

const tabValues = {
  includeTitle: 0,
  notIncludeTitle: 1,
};

const badgeLength = {
  one: 1,
  two: 2,
};

const serviceNumber = 8;

const scheduleFormat = {
  hours: 0,
  minutes: 2,
};

const mxnCurrency = 'MXN';

const zoom = 9;

const STATUS_SERVICES = {
  CREATED: { NAME: 'Creado', TYPE: 'primary', ID: 1 },
  ACTIVE: { NAME: 'Validado', TYPE: 'warning', ID: 2 },
  ACCEPTED: { NAME: 'Aceptado', TYPE: 'info', ID: 3 },
  IN_ROUTE: { NAME: 'En camino', TYPE: 'info', ID: 3 },
  ARRIVED: { NAME: 'Llegó', TYPE: 'success', ID: 4 },
  FINISHED: { NAME: 'Terminado', TYPE: 'dark', ID: 5 },
  CANCEL_BY_CLIENT: { NAME: 'Cancelado por cliente', TYPE: 'dark', ID: 6 },
  CANCEL_BY_PROVIDER: { NAME: 'Cancelado por colaborador', TYPE: 'dark', ID: 7 },
  CANCEL_BY_ADMIN_FREE: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  CANCEL_BY_ADMIN_COST: { NAME: 'Cancelado por administrador', TYPE: 'dark', ID: 8 },
  ASSIGNED: { NAME: 'Asignado', TYPE: 'success', ID: 9 },
  TAKEN: { NAME: 'Aceptado', TYPE: 'success', ID: 10 },
  PAYMENT_PENDING: { NAME: 'Por pagar', TYPE: 'warning', ID: 11 },
  BUDGET_AWAIT: { NAME: 'En espera de cotización', TYPE: 'primary', ID: 12 },

  1: { TYPE: 'primary' },
  2: { TYPE: 'warning' },
  3: { TYPE: 'info' },
  4: { TYPE: 'success' },
  5: { TYPE: 'dark' },
  6: { TYPE: 'dark' },
  7: { TYPE: 'dark' },
  8: { TYPE: 'dark' },
  9: { TYPE: 'success' },
  10: { TYPE: 'success' },
  11: { TYPE: 'warning' },
};

const STATUS_SERVICES_FINISHED_SERVICE = [
  STATUS_SERVICES.FINISHED.ID,
  STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
  STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_FREE.ID,
  STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID,
];

const paymentTypes = [
  {
    id: 7,
    name: 'CoDi',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/codi.png',
    minPurchase: null,
    maxPurchase: null,
  },
  {
    id: 4,
    name: 'Link de Pago',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/link.png',
    minPurchase: null,
    maxPurchase: null,
  },
  {
    id: 1,
    name: 'Efectivo',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/cash.png',
    minPurchase: null,
    maxPurchase: null,
  },
  {
    id: 2,
    name: 'Terminal',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/terminal.png',
    minPurchase: null,
    maxPurchase: null,
  },
  {
    id: 3,
    name: 'Tarjeta',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/card.png',
    minPurchase: null,
    maxPurchase: null,
  },
  {
    id: 5,
    name: 'Transferencia',
    icon: 'https://okboy.s3.us-west-2.amazonaws.com/payment_methods/transfer.png',
    minPurchase: null,
    maxPurchase: null,
  },
];

export {
  linkDownloadApp,
  linkOrderGas,
  getTopSections,
  scrollTopMozzilla,
  scrollTopChrome,
  TYPE_STYLE_TEXT,
  numberListSection,
  noBar,
  pathBlogpost,
  columns,
  breakPoints,
  initialScroll,
  lengthCardsSwiper,
  scores,
  rowFourForm,
  coverageEnums,
  tabValues,
  badgeLength,
  serviceNumber,
  scheduleFormat,
  mxnCurrency,
  zoom,
  STATUS_SERVICES_FINISHED_SERVICE,
  paymentTypes,
};
