import { Grid } from '@mui/material';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { columns, TYPES_CARDS } from '../../Common';
import { pathViews } from '../../Common/messages';
import { CardItem } from '../../Components';
import {
  cardBlogPost,
  contentTextCardBlogPost,
} from '../YouMightInterestedLayout/YouMightInterestedLayoutStyles';

const ContentPostsLayout = ({
  cardsBlogPost,
  fromIndex,
  toIndex,
  styleContentPosts,
  styleColumnOne,
  styleColumnTwo,
  styleColumnThree,
}) => {
  const { blog: { app } } = pathViews;
  const pathPost = 'post/';
  return (
    <Grid
      container
      direction={styleContentPosts.direction}
      columnSpacing={styleContentPosts.spacing?.column}
      rowSpacing={styleContentPosts.spacing?.row}
      sx={styleContentPosts.style}
    >
      {cardsBlogPost?.slice(fromIndex, toIndex).map((card) => (
        <Fragment key={card.id}>
          {
              card.column === columns.one ? (
                <Grid
                  item
                  lg={12}
                >
                  <NavLink to={`${app}${pathPost}${card.title.split(' ').join('-')}`}>
                    <CardItem
                      typeCard={TYPES_CARDS.blogPost}
                      cardStyle={styleColumnOne?.card}
                      contentTexts={styleColumnOne?.contentText}
                      title={card.title}
                      authorBlogPost={card.author}
                      dateBlogPost={card.creationDate}
                      timeReadBlogPost={card.readDuration}
                      img={card.img}
                      alternativeText={card.alternativeText}
                      description={card.description}
                    />
                  </NavLink>
                </Grid>
              ) : card.column === columns.two ? (
                <Grid
                  item
                  lg={5.83}
                  sx={styleColumnTwo.contain}
                >
                  <NavLink to={`${app}${pathPost}${card.title.split(' ').join('-')}`}>
                    <CardItem
                      typeCard={TYPES_CARDS.blogPost}
                      cardStyle={styleColumnTwo?.card}
                      contentTexts={styleColumnTwo?.contentText}
                      title={card.title}
                      authorBlogPost={card.author}
                      dateBlogPost={card.creationDate}
                      timeReadBlogPost={card.readDuration}
                      img={card.img}
                      alternativeText={card.alternativeText}
                    />
                  </NavLink>
                </Grid>
              ) : card.column === columns.three ? (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={styleColumnThree.contain}
                >
                  <NavLink to={`${app}${pathPost}${card.title.split(' ').join('-')}`}>
                    <CardItem
                      typeCard={TYPES_CARDS.blogPost}
                      cardStyle={{ ...cardBlogPost, ...styleColumnThree?.card }}
                      contentTexts={{
                        ...contentTextCardBlogPost,
                        ...styleColumnThree?.contentText,
                      }}
                      title={card.title}
                      authorBlogPost={card.author}
                      dateBlogPost={card.creationDate}
                      timeReadBlogPost={card.readDuration}
                      img={card.img}
                      alternativeText={card.alternativeText}
                    />
                  </NavLink>
                </Grid>
              ) : null
            }
        </Fragment>
      ))}
    </Grid>
  );
};

export default ContentPostsLayout;
