import { SaveTime } from '../../Components';
import { LayoutGeneral } from '../../Layouts';
import { downloadContainer } from './styles';
// import { DownloadContainer } from './styles';

const Download = () => {
  return (
    <LayoutGeneral style={downloadContainer}>
      <SaveTime isDownloadView={true} />
    </LayoutGeneral>
  );
};

export default Download;
