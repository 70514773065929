import { Box } from '@mui/material';
import { Circles } from './Styles';

const IconStatus = ({ active, completed, className }) => {
  const defaultClass = 'circle';
  const completedClass = 'circle-completed';
  return (
    <Circles ownerState={{ active }} className={className}>
      {completed ? (
        <Box className={completedClass} />
      ) : (
        <Box className={defaultClass} />
      )}
    </Circles>
  );
};

export default IconStatus;
