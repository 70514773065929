import {
  Button,
  TextField,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import {
  TYPES_REGEX,
  VARIANT_BUTTONS_TYPES,
  VARIANT_TEXTFILEDS_TYPES,
} from '../../Common';
import axiosStrapi from '../../Configs/axios';
import { useForm } from '../../hooks';
import {
  contentForm,
  stylesButtonDefault,
  styleTextFieldDefault,
} from './SubscribeStyles';

const nameTextFiel = 'email';
const Subscribe = ({
  textLabel,
  textButton,
  stylesTextField,
  stylesButton,
  stylesContentForm,
}) => {
  const [mail, handleChange, resetForm, error, setError] = useForm({
    data: { email: '' },
  });
  const [messageEmail, setMessageEmail] = useState(textButton.send);
  const [toggleLoad, setToogleLoad] = useState(false);
  const handleSubmit = () => {
    if (!TYPES_REGEX.email.test(mail.data.email)) {
      return;
    }
    setToogleLoad(true);
    setError({ ...error, errorSubmit: true });
    axiosStrapi
      .post('subscribes', mail)
      .then(({ data: { data } }) => {
        setError(data?.attributes.email && { ...error, errorSubmit: true });
      })
      .finally(() => {
        setMessageEmail(textButton.success);
        setToogleLoad(false);
      });
    resetForm();
  };
  return (
    <FormControl sx={stylesContentForm || contentForm}>
      <TextField
        error={error.errorInput}
        id='standard-basic'
        label={error.errorInput ? textLabel.error : textLabel.text}
        variant={VARIANT_TEXTFILEDS_TYPES.outlined}
        sx={
          { ...stylesTextField, ...styleTextFieldDefault(error.errorInput) }
          || styleTextFieldDefault(error.errorInput)
        }
        name={nameTextFiel}
        value={mail.data.email}
        onChange={handleChange}
      />
      <Button
        sx={stylesButton || stylesButtonDefault}
        variant={VARIANT_BUTTONS_TYPES.contained}
        fullWidth
        onClick={handleSubmit}
        disabled={error.errorSubmit}
      >
        {toggleLoad ? <CircularProgress /> : messageEmail}
      </Button>
    </FormControl>
  );
};

export default Subscribe;

Subscribe.defaultProps = {
  stylesTextField: null,
  stylesButton: null,
  stylesContentForm: null,
};
