export const titleMain = {
  fontSize: '2.5rem',
  fontWeight: '900',
  marginBottom: '16px',
};

export const subTitle = {
  fontSize: '1.375rem',
  fontWeight: '800',
  lineHeight: '1.878125rem',
  marginBottom: '16px',
};

export const subTitleSubList = (mt) => {
  return {
    fontSize: '1.15rem',
    fontWeight: '800',
    lineHeight: '1.878125rem',
    marginBottom: '5px',
    marginTop: mt,
  };
};

export const sectionGlobal = {
  marginBottom: '16px',
  fontSize: '1.375rem',
  fontWeight: '500',
  color: 'text.secondary',
  '& > p': {
    marginBottom: '16px',
    fontSize: 'inherit',
  },
  '& a': {
    color: 'primary.main',
  },
};

export const sectionGlobalListAndParagrapgh = {
  '& > ol:first-of-type > p': {
    marginBottom: '16px',
  },
};

export const modifiedParagraph = {
  '& > ol > p:last-of-type': {
    marginBottom: '0px',
  },
};

export const firstSection = {
  color: 'text.secondary',
  '& > p:last-child a': {
    color: 'primary.main',
  },
};

export const listGlobal = (typeListStyle, display) => {
  return {
    color: 'text.secondary',
    '& > li, & > span': {
      fontWeight: '800',
      display: display || 'list-item',
    },
    '& > span': {
      marginRight: '6px',
    },
    '& > p': {
      fontSize: 'inherit',
    },
    listStyle: typeListStyle,
  };
};

export const subList = (weight, typeListStyle, pl, mg) => {
  return {
    listStyle: typeListStyle,
    color: 'text.secondary',
    fontSize: '1rem',
    paddingLeft: pl || '23px',
    '& > p': {
      fontSize: 'inherit',
    },
    '& > li': {
      fontWeight: weight,
      margin: mg,
    },
  };
};

export const listTypeSecondList = {
  marginRight: '10px',
};

export const sectionParagraph = {
  '& > p': {
    fontSize: '1rem',
  },
};

export const contentThirdListSection = {
  color: 'text.secondary',
  '& > ul > li, & > ul > span': {
    fontWeight: '800',
  },
  '& > ul > li': {
    display: 'inline-block',
  },
  '& > ul > span': {
    marginRight: '6px',
  },
};

export const subListThirdListSectionSecond = {
  listStyle: 'disc',
  fontSize: '1.1rem',
  paddingLeft: '32px',
  marginTop: '-14px',
};

export const contentSubListThirdListSection = {
  color: 'text.secondary',
  '& > p': {
    fontSize: '1.3rem',
  },
  '& > ol ': {
    paddingLeft: '16px',
  },
  '& > ol > div': {
    display: 'flex',
  },
  '& > ol > div > span': {
    marginRight: '6px',
  },
  '& > ol > div > li': {
    display: 'block',
  },
  '& > ol > div > li, & > ol > div > span': {
    fontSize: '1rem',
  },
};

export const contentFourthListSection = {
  '& > li:first-of-type': {
    display: 'inline',
  },
  '& > ol': {
    paddingLeft: '16px',
  },
  '& > ol > div:first-of-type, & > ol > div:nth-of-type(2), & > ol > div:nth-of-type(3), & > ol > div:nth-of-type(4)': {
    display: 'flex',
    fontSize: '1.2rem',
    '& > span': {
      fontWeight: '900',
      marginRight: '6px',
    },
  },
  '& > p': {
    marginBottom: '16px',
    fontSize: '1.375rem',
  },
  '& > p:last-of-type': {
    marginBottom: '0px',
  },
};

export const contentFourthSubListSection = {
  paddingLeft: '42px',
  listStyle: 'disc',
  fontSize: '1rem',
  color: 'text.secondary',
};

export const contentLastListSection = {
  '& p': {
    fontSize: '1.375rem',
  },
  '& > div:first-of-type': {
    display: 'flex',
    '& > span': {
      marginRight: '6px',
      fontWeight: '800',
    },
  },
  '& > div:first-of-type, & > div:nth-of-type(2), & > div:nth-of-type(3), & > div:last-of-type': {
    '& > li': {
      fontWeight: '800',
    },
    '& > p': {
      marginBottom: '16px',
    },
  },
  '& > div:nth-of-type(2) > li, & > div:nth-of-type(3) > li, & > div:last-of-type > li': {
    display: 'inline',
  },
  '& > div:nth-of-type(2) > span, & > div:nth-of-type(3) > span, & > div:last-of-type > span': {
    marginRight: '6px',
    fontWeight: '800',
  },
};

export const contentLastSubListSection = {
  paddingLeft: '16px',
  '& > ul': {
    paddingLeft: '32px',
    listStyle: 'disc',
    fontSize: '1rem',
  },
  '& > li': {
    fontSize: '1rem',
  },
  '& > div:nth-of-type(1), & > div:nth-of-type(2), & > div:nth-of-type(3), & > div:nth-of-type(4)': {
    display: 'flex',
    fontSize: '1.2rem',
    '& > span': {
      marginRight: '6px',
    },
  },
};
