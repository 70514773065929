import CloseIcon from '@mui/icons-material/Close';
import {
  AcceptButton,
  ChasImage,
  ChasImageContainer,
  IconContainer,
  ModalText,
  styledClose,
  StyledModal,
  styledSuccess,
} from './styles';
import ChasImageSrc from '../../../../../assets/img/WebView/mano-chas.png';

const WebViewModal = ({
  label,
  buttonText,
  styles,
  open,
  onClose,
}) => {
  const closeHandler = () => {
    onClose(false);
  };

  return (
    <StyledModal sx={open ? { ...styledSuccess, ...styles } : styledClose}>
      <IconContainer disableRipple={true} onClick={closeHandler}>
        <CloseIcon sx={{ color: 'webView.primary', fontSize: '2rem' }} />
      </IconContainer>
      <ChasImageContainer>
        <ChasImage src={ChasImageSrc} />
      </ChasImageContainer>
      <ModalText>
        { label }
      </ModalText>
      <AcceptButton onClick={closeHandler} disableRipple={true}>
        { buttonText }
      </AcceptButton>
    </StyledModal>
  );
};

export default WebViewModal;
