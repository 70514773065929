import { Box, Skeleton, styled } from '@mui/material';

export const ContainerPointsService = styled(Box)(() => ({
  display: 'flex',
  margin: '0 auto',
  maxWidth: '171px',
  width: '100%',
  marginTop: '1rem',
  marginBottom: '1.6rem',
}));

export const PointServiceSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
}));
