const inputData = (changeHandler) => {
  return [
    {
      id: 1,
      cb: changeHandler,
      name: 'invoice',
      placeholder: 'Aparece en el detalle del pedido',
      labelText: 'Folio del pedido',
    },
    {
      id: 2,
      cb: changeHandler,
      name: 'businessName',
      placeholder: 'Nombre completo de la persona física o moral',
      labelText: 'Razón social',
    },
    {
      id: 3,
      cb: changeHandler,
      name: 'rfc',
      placeholder: 'XAXX010101000',
      labelText: 'RFC',
    },
    {
      id: 4,
      cb: changeHandler,
      name: 'postalCode',
      placeholder: 'Código postal',
      labelText: 'Código postal',
    },
    {
      id: 5,
      cb: changeHandler,
      name: 'email',
      placeholder: 'correo@ejemplo.com',
      labelText: 'Correo electrónico',
    },
  ];
};

const selectors = {
  cfdi: {
    name: 'cfdi',
    placeholder: 'Seleccionar',
  },
  fiscalregimen: {
    name: 'fiscalRegimen',
    placeholder: 'Seleccionar',
  },
};

const billingCompletedTitle = 'Completa los datos que faltan para procesar tu factura';

export {
  inputData,
  selectors,
  billingCompletedTitle,
};
