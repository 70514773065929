import { Box } from '@mui/material';
import { useEffect } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { LOCATION_PROPS_TYPES } from '../../Common';
import { locationInputStyles } from './styles';

const {
  componentRestrictions,
  types,
  fields,
} = LOCATION_PROPS_TYPES;

const LocationInput = ({
  inputRef,
  styles,
  autoCompleteHandle,
  locationToggle,
}) => {
  useEffect(() => {
    const root = document.getElementById('root');
    const rootChildStyles = root?.childNodes[0]?.style || {};
    if (!locationToggle) {
      rootChildStyles.position = 'absolute';
      rootChildStyles.width = '100%';
    }
    return () => {
      rootChildStyles.position = 'static';
      rootChildStyles.width = 'initial';
    };
  }, [locationToggle]);

  return (
    <Box ref={inputRef} sx={{ ...locationInputStyles, ...styles }}>
      <ReactGoogleAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        onPlaceSelected={autoCompleteHandle}
        options={{
          types: types.address,
          componentRestrictions: { country: componentRestrictions.mx },
          fields: [fields.all],
          strictBounds: true,
        }}
      />
    </Box>
  );
};

export default LocationInput;
