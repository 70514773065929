export const boxCardsCarousel = (desktop, mobile) => {
  return {
    display: {
      xs: mobile, sm: mobile, md: desktop, lg: desktop,
    },
    position: {
      xs: 'relative', sm: 'relative', md: 'static', lg: 'static',
    },
    top: {
      xs: '-40px', sm: '-40px', md: '0', lg: '0',
    },
    backgroundColor: {
      xs: 'background.primary', sm: 'background.primary', md: 'transparent', lg: 'transparent',
    },
    paddingTop: {
      xs: '61px', sm: '61px', md: '0', lg: '0',
    },
    marginBottom: {
      xs: '-92px', sm: '-92px', md: '0px', lg: '0px',
    },
    borderTopLeftRadius: {
      xs: '30px', sm: '30px', md: '0', lg: '0',
    },
    borderTopRightRadius: {
      xs: '30px', sm: '30px', md: '0', lg: '0',
    },
    height: {
      xs: '785px', sm: '785px', md: '457px', lg: '457px',
    },
  };
};

export const CardStyle = {
  display: 'flex',
  backgroundColor: 'background.primary',
  height: '100%',
  flexDirection: {
    xs: 'column', sm: 'column', md: 'row', lg: 'row',
  },
  borderRadius: '30px',
  boxShadow: '20px 30px 100px rgba(0, 0, 0, 0.1)',
  width: {
    xs: '300px', phoneMd: '300px', sm: '300px', md: '500px', lg: '500px',
  },
  zIndex: {
    xs: 'initial',
    md: '-1',
  },
};

export const contentImage = (img, imgMobile) => {
  return {
    flexBasis: '100%',
    backgroundImage: {
      xs: `url(${imgMobile})`, sm: `url(${imgMobile})`, md: `url(${img})`, lg: `url(${img})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: {
      xs: '100%', sm: '100%', md: 'cover', lg: 'cover',
    },
    backgroundPosition: {
      xs: 'center 0px', phoneMd: 'center 0px', sm: 'center 0px', md: 'center center', lg: 'center center', xl: 'center center',
    },
  };
};

export const contentTexts = {
  display: 'flex',
  flexDirection: 'column',
  '& > div:first-of-type': {
    marginTop: '34px',
    width: '100%',
  },
  '& > div:first-of-type > div > span': {
    display: 'block',
    fontSize: {
      xs: '1.75rem', ms: '1.75rem', md: '1.45rem', lg: '1.45rem',
    },
    lineHeight: '2.390625rem',
    fontWeight: '800',
    '&::selection': {
      color: 'text.primary',
    },
  },
  '& > div:last-child': {
    paddingTop: '3px',
    width: '268px',
    '&::selection': {
      color: 'text.primary',
    },
  },
  '& > div:last-child > div > span': {
    display: 'block',
    fontSize: '1.125rem',
    fontWeight: '800',
    lineHeight: '2.390625rem',
    color: 'text.primary',
  },
};

export const contentButtonCarousel = {
  width: '85%',
  display: 'flex',
  margin: '0 auto',
  justifyContent: 'space-between',
  marginTop: '32px',
  '& button': {
    borderRadius: '15px',
    borderWidth: '2px',
    fontSize: '22px',
    fontWeight: '900',
    textTransform: 'capitalize',
  },
  '& button:hover': {
    borderWidth: '2px',
  },
};

export const contentCards = {
  position: 'relative',
  height: {
    xs: '80%', sm: '80%', md: '312px', lg: '312px',
  },
  paddingLeft: {
    xs: '16px', sm: '16px', md: '0px', lg: '0px',
  },
  '& > div:first-of-type': {
    position: 'absolute',
    left: {
      xs: '37px',
      md: '0px',
    },
    top: {
      xs: '70px',
      md: '0px',
    },
    display: 'flex',
    width: {
      xs: '350vw', sm: '443vw', md: '200%', lg: '200%',
    },
    gap: '34px  ',
    marginTop: '20px',
    height: {
      xs: '413px', sm: '413px', md: '263px', lg: '263px',
    },
    '& > div:last-of-type': {
      marginRight: { laptopLg: '155px', lg: '125px' },
    },
    transition: 'left .4s ease',
    '&.transitionMovement': {
      scrollSnapType: 'unset',
      transition: {
        xs: 'left .06s linear',
        md: 'left .08s cubic-bezier(.7,.72,.99,1)',
      },
    },
  },
};
