import { Box, List, Typography } from '@mui/material';

import { variantTypography } from '../../Common';
import ListItems from '../../Components/ListLinks/ListItems';
import { contentList, contentListItem } from './InstructionsLayoutStyles';

const InstructionsLayout = ({
  listInstructions,
  backgroundImageDesktop,
  backgroundImageMobile,
}) => {
  const listId = (Math.random() / Math.random()).toString();
  return (
    <Box sx={contentList(backgroundImageDesktop, backgroundImageMobile)}>
      <List sx={contentListItem}>
        {listInstructions.map((list, i) => (
          <Box key={`${listId}${list.text}`}>
            <Typography variant={variantTypography.body2}>
              {i + 1}
            </Typography>
            <Typography variant={variantTypography.h3}>
              <ListItems {...list} />
            </Typography>
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default InstructionsLayout;
