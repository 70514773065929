import { Avatar, Rating, Tooltip } from '@mui/material';
import { useEffect, useRef } from 'react';
import DeleteIcon from '../../../../assets/icons/Delete';
import { COLOR_TYPES, mxnCurrency, VALUES_PROPERTYS_CSS } from '../../../Common';
import { formatMoney } from '../../../utils/service-tracking';
import { spanReset } from '../../ServiceSearch/styles';
import {
  avatarStyle,
  ButtonIcon,
  CalificationText,
  ComplementContainer,
  ContentDate,
  DataProductDetailContainer,
  DateAndDeliveryContainer,
  dateStyleCustom,
  DetailProductContainer,
  DetailProductPriceText,
  DetailProductText,
  DetailServiceContainer,
  Image,
  ImageContainer,
  NameAndRatingContainer,
  Price,
  PriceAndTitleContainer,
  ProductContainer,
  ProductTitle,
  ProviderDetail,
  ProviderName,
  ratingStyles,
  ServiceName,
  textProduct,
} from './styles';

const {
  ratingWidthOutColor,
} = COLOR_TYPES;

const {
  color,
} = VALUES_PROPERTYS_CSS;

const Product = ({
  image,
  serviceTitle,
  price,
  avatar,
  nameProvider,
  rating,
  califications,
  productTitle,
  units,
  unitsPriceTotal,
  complements,
  dateTitle,
  date,
  deliveryTitle,
  delivery,
}) => {
  const ratingRef = useRef(null);
  useEffect(() => {
    ratingRef.current?.childNodes.forEach(element => {
      const childElement = element.childNodes[0] || {};
      // eslint-disable-next-line no-undef
      const valuesPropertys = window.getComputedStyle(childElement);
      const colorValue = valuesPropertys.getPropertyValue(color);
      if (colorValue === ratingWidthOutColor) {
        // eslint-disable-next-line no-param-reassign
        element.childNodes[0].style.display = 'none';
      }
    });
  }, [rating]);

  return (
    <ProductContainer>
      <ImageContainer>
        <Image src={image} alt={serviceTitle} />
      </ImageContainer>
      <DetailServiceContainer>
        <PriceAndTitleContainer>
          <ServiceName sx={spanReset} label={serviceTitle} />
          <Price sx={textProduct}>{`$${formatMoney(price)} ${mxnCurrency}`}</Price>
          <ButtonIcon>
            <DeleteIcon />
          </ButtonIcon>
        </PriceAndTitleContainer>
        <ProviderDetail>
          <Avatar sx={avatarStyle} alt={nameProvider} src={avatar} />
          <NameAndRatingContainer>
            <ProviderName sx={textProduct}>{ nameProvider }</ProviderName>
            {rating && (
            <Rating
              ref={(e) => { ratingRef.current = e; }}
              sx={ratingStyles}
              value={rating}
              readOnly
            />
            )}
          </NameAndRatingContainer>
          {califications && <CalificationText sx={textProduct}>{califications}</CalificationText>}
        </ProviderDetail>
      </DetailServiceContainer>
      <DetailProductContainer>
        <ProductTitle>
          {productTitle}
        </ProductTitle>
        <DataProductDetailContainer>
          <DetailProductText>{`${units} unidades`}</DetailProductText>
          <Tooltip title={unitsPriceTotal ? `$${formatMoney(unitsPriceTotal)} ${mxnCurrency}` : ''} arrow>
            <DetailProductPriceText>{`$${formatMoney(unitsPriceTotal)} ${mxnCurrency}`}</DetailProductPriceText>
          </Tooltip>
        </DataProductDetailContainer>
        {
                complements?.map((complement) => {
                  return (
                    <ComplementContainer key={complement.id}>
                      <DetailProductText sx={textProduct}>{ complement?.name }</DetailProductText>
                      {
                        complement.priceList.map((listItem) => {
                          return (
                            <Tooltip arrow key={complement.id} title={listItem?.price ? `$${formatMoney(listItem?.price)} ${mxnCurrency}` : ''}>
                              <DetailProductPriceText sx={textProduct}>
                                {`$${formatMoney(listItem?.price)} ${mxnCurrency}`}
                              </DetailProductPriceText>
                            </Tooltip>
                          );
                        })
                      }
                    </ComplementContainer>
                  );
                })
            }
        <DataProductDetailContainer sx={dateStyleCustom}>
          <DateAndDeliveryContainer>
            <ContentDate>{ dateTitle }</ContentDate>
            <ContentDate>{ date }</ContentDate>
          </DateAndDeliveryContainer>
          <DateAndDeliveryContainer>
            <ContentDate>{ deliveryTitle }</ContentDate>
            <ContentDate>{ delivery }</ContentDate>
          </DateAndDeliveryContainer>
        </DataProductDetailContainer>
      </DetailProductContainer>
    </ProductContainer>
  );
};

export default Product;
