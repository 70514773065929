import { ServiceProduct } from '../../Common/messages';

const {
  ACTION_TYPES,
} = ServiceProduct;

export const selectProduct = (payload) => {
  return {
    type: ACTION_TYPES.selectProduct,
    payload,
  };
};
export const selectProvider = (payload) => {
  return {
    type: ACTION_TYPES.selectProvider,
    payload,
  };
};

export const selectDate = (payload) => {
  return {
    type: ACTION_TYPES.selectDate,
    payload,
  };
};

export const selectSchedule = (payload) => {
  return {
    type: ACTION_TYPES.selectSchedule,
    payload,
  };
};

export const getLocation = (payload) => {
  return {
    type: ACTION_TYPES.selectLocation,
    payload,
  };
};

export const setproduct = (payload) => {
  return {
    type: ACTION_TYPES.productDetail,
    payload,
  };
};

export const getCompaniesData = (payload) => {
  return {
    type: ACTION_TYPES.getCompanies,
    payload,
  };
};

export const setCompanieId = (payload) => {
  return {
    type: ACTION_TYPES.setCompanieId,
    payload,
  };
};

export const getCompanieProductsData = (payload) => {
  return {
    type: ACTION_TYPES.getCompanieProducts,
    payload,
  };
};

export const getCompanieSchedules = (payload) => {
  return {
    type: ACTION_TYPES.getCompanieSchedules,
    payload,
  };
};

export const getShowcaseProduct = (payload) => {
  return {
    type: ACTION_TYPES.getShowCaseProduct,
    payload,
  };
};

export const setShowcaseProductId = (payload) => {
  return {
    type: ACTION_TYPES.setShowcaseProductId,
    payload,
  };
};
