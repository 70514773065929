import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Stack } from '@mui/material';
import {
  StatusDateContainer,
  StatusDateLabel,
  styledDateIcon,
  stylesDefault,
} from './styles';
import { TYPES_DIRECTIONS } from '../../Common';
import { messagesTracking } from '../../Common/messages/ServiceTracking';

const {
  statusDate,
} = messagesTracking;

const StatusDate = ({
  dateLabel,
  timeLabel,
  styledDate,
  styledTime,
}) => {
  const date = (
    <Stack sx={{ ...stylesDefault, ...styledDate }} direction={TYPES_DIRECTIONS.row} alignItems='center' gap='0.5rem'>
      <CalendarTodayIcon sx={styledDateIcon} />
      <StatusDateLabel>
        { `${statusDate.date} ${dateLabel || '-'}` }
      </StatusDateLabel>
    </Stack>
  );
  const time = (
    <Stack sx={{ ...stylesDefault, ...styledTime }} direction={TYPES_DIRECTIONS.row} alignItems='center' gap='0.5rem'>
      <ScheduleIcon sx={styledDateIcon} />
      <StatusDateLabel>
        { `${statusDate.time} ${timeLabel ? `${timeLabel} hrs` : '-'}` }
      </StatusDateLabel>
    </Stack>
  );
  return (
    <StatusDateContainer>
      { date }
      { time }
    </StatusDateContainer>
  );
};

export default StatusDate;
