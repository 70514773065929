import { FormControlLabel, Radio, styled } from '@mui/material';

export const StyledRadio = styled(Radio)(({ theme }) => ({
  fontSize: '1rem',
  padding: '0',
  '&, &.Mui-checked': {
    color: theme.palette.webView.radio,
  },
}));

export const LabelRadio = styled(FormControlLabel)(() => ({
  margin: '0',
  width: 'max-content',
  height: 'max-content',
  '& > .MuiTypography-root': {
    fontSize: '.75rem',
    marginLeft: '.5rem',
  },
}));

export const styledRadioContainer = {
  display: 'flex',
  width: {
    xs: '100%',
    md: '425px',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  gap: {
    xs: '1rem',
    md: '2.5rem',
  },
  marginBottom: '1.4rem',
};
