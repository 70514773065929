import {
  CardImageSelectionOrderSkeleton,
  CardServiceSelectionOrder,
  CardTextSelectionOrderSkeleton,
} from '../../../CardServiceOrBenefits/styles';

const CardServiceSelectionSkeleton = ({ length }) => {
  return (
    Array.from(new Array(length)).map(() => (
      <CardServiceSelectionOrder key={Math.random()}>
        <CardImageSelectionOrderSkeleton />
        <CardTextSelectionOrderSkeleton />
      </CardServiceSelectionOrder>
    ))
  );
};

export default CardServiceSelectionSkeleton;
