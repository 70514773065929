export const bannerDownLoad = {
  backgroundColor: 'secondary.bannerDownload',
  flexDirection: {
    xs: 'column',
    phoneMd: 'column',
    tablet: 'row',
    md: 'row',
    lg: 'row',
  },
  width: '100%',
  height: {
    xs: '633px',
    phoneMd: '633px',
    tablet: '281px',
    sm: '281px',
    md: '281px',
    lg: '281px',
  },
  paddingLeft: {
    xs: '0rem',
    phoneMd: '0rem',
    sm: '0rem',
    md: '5rem',
    lg: '5rem',
  },
  overflow: 'hidden',
  '& > div:first-of-type': {
    order: {
      xs: '2',
      phoneMd: '2',
      tablet: '1',
      md: '1',
      lg: '1',
    },
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    flexBasis: '50%',
    margin: {
      xs: '0px auto',
      phoneMd: '0px auto',
      md: '0px auto',
      ñg: '0px auto',
    },
    width: {
      xs: '100%',
      phoneMd: '100%',
      md: 'auto',
      lg: 'auto',
    },
    maxWidth: {
      xs: '311px',
      phoneMd: '311px',
      md: 'initial',
      lg: 'initial',
    },
    paddingTop: {
      xs: '0.1875rem',
      phoneMd: '0.1875rem',
      md: 'initial',
      lg: 'initial',
    },
    '& > h3': {
      color: 'primary.main',
      fontSize: {
        xs: '1.75rem',
        phoneMd: '1.75rem',
        md: '2.5rem',
        lg: '2.5rem',
      },
      fontWeight: '900',
      position: 'relative',
      top: {
        xs: '2%',
        phoneMd: '2%',
        md: '4%',
        lg: '4%',
      },
    },
    '& > h5': {
      width: '100%',
      maxWidth: '390px',
      position: {
        xs: 'relative',
        phoneMd: 'relative',
        md: 'static',
        lg: 'static',
      },
      bottom: '-1.8%',
      color: 'text.secondary',
      fontSize: {
        xs: '1.125rem',
        phoneMd: '1.125rem',
        md: '1.375rem',
        lg: '1.375rem',
      },
      lineHeight: {
        xs: '1.536875rem',
        phoneMd: '1.536875rem',
        md: '1.878125rem',
        lg: '1.878125rem',
      },
      fontWeight: '900',
    },
    '& > div': {
      width: '100%',
      position: {
        xs: 'relative',
        phoneMd: 'relative',
        md: 'static',
        lg: 'static',
      },
      top: '-2.1%',
      margin: '0rem',
      height: 'auto',
    },
  },
};

export const styleAppStore = {
  width: {
    xs: '9.25rem',
    phoneMd: '9.25rem',
    md: '10.375rem',
    lg: '10.375rem',
  },
  marginRight: '16px',
  '& img': {
    width: '100%',
  },
};

export const styleAppPlayStore = {
  width: {
    xs: '9.25rem',
    phoneMd: '9.25rem',
    md: '10.375rem',
    lg: '10.375rem',
  },
  '& img': {
    width: '100%',
  },
};

export const stylesBannerImageDownload = {
  display: 'flex',
  justifyContent: 'center !important',
  flexDirection: 'row !important',
  alignItems: 'center !important',
  flexBasis: '50% !important',
  width: {
    xs: '100%',
    phoneMd: '100%',
    md: 'auto',
    lg: 'auto',
  },
  maxWidth: {
    xs: '311px',
    phoneMd: '311px',
    md: 'initial',
    lg: 'initial',
  },
  order: {
    xs: '1',
    phoneMd: '1',
    md: '2',
    lg: '2',
  },
  position: {
    xs: 'static',
    phoneMd: 'static',
    md: 'relative',
    lg: 'relative',
  },
  '& > img': {
    width: {
      xs: '96%',
      phoneMd: '96%',
      md: '54%',
      lg: '54%',
    },
    objectFit: 'cover',
    position: 'relative',
    top: '6%',
    right: {
      xs: '-4%',
      phoneMd: '-4%',
      md: '-8.6%',
      lg: '-8.6%',
    },
  },
};

export const contentBannerSubscribe = {
  flexDirection: {
    xs: 'column',
    phoneMd: 'column',
    tablet: 'row',
    md: 'row',
    lg: 'row',
  },
  backgroundColor: 'secondary.bannerSubscribe',
  width: '100%',
  paddingLeft: { tablet: '2rem' },
  height: {
    xs: '545px',
    phoneMd: '545px',
    tablet: '321px',
    md: '321px',
    lg: '321px',
  },
  overflow: 'hidden',
};

export const stylesBannerCaptionSubscribe = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'space-evenly',
  height: { tablet: '297px' },
  flex: {
    xs: '0 0 238px',
    tablet: '0 0 50%',
    md: '0 0 238px',
  },
  order: {
    xs: '2',
    phoneMd: '2',
    tablet: '1',
    md: '1',
    lg: '1',
  },
  padding: '2.375rem',
  paddingTop: {
    xs: '0.3rem',
    phoneMd: '0.3rem',
    md: '2.375rem',
    lg: '2.375rem',
  },
  paddingBottom: {
    xs: '2.8rem;',
    phoneMd: '2.8rem;',
    tablet: '0.3rem',
    md: '2.375rem',
    lg: '2.375rem',
  },
  paddingRight: {
    xs: '0rem',
    phoneMd: '0rem',
    md: '2.375rem',
    lg: '2.375rem',
  },
  maxWidth: {
    xs: '311px',
    phoneMd: '311px',
    tablet: '511px',
    md: 'initial',
    lg: 'initial',
  },
  width: {
    xs: '100%',
    phoneMd: '100%',
    md: 'auto',
    lg: 'auto',
  },
  margin: {
    xs: '0 auto',
    phoneMd: '0 auto',
    tablet: 'auto 0',
    md: '0',
    lg: '0',
  },
  paddingLeft: {
    xs: '0rem',
    phoneMd: '0rem',
    md: '5rem',
    lg: '5rem',
  },
  '& > *': {
    margin: '0px',
    marginLeft: '0px !important',
    marginTop: '0px !important',
  },
  '& > h3': {
    color: 'secondary.main',
    lineHeight: {
      xs: '2.389375rem',
      phoneMd: '2.389375rem',
      md: '1.36625rem',
      lg: '1.36625rem',
    },
    fontSize: {
      xs: '1.75rem',
      phoneMd: '1.75rem',
      md: '2rem',
      lg: '2rem',
    },
    fontWeight: '900',
    marginBottom: '0.3rem',
  },
  '& > h6': {
    width: '100%',
    marginTop: {
      xs: '-6px !important;',
      phoneMd: '-6px !important;',
      md: '0px !important;',
      lg: '0px !important;',
    },
    color: '#F0F1F5',
    fontSize: '1rem',
    fontWeight: '400',
    maxWidth: '498px',
    lineHeight: '1.36625rem',
  },
};

export const stylesBannerImageSubscribe = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '0px',
  order: {
    xs: '1',
    phoneMd: '1',
    tablet: '2',
    md: '2',
    lg: '2',
  },
  '& > img': {
    position: {
      xs: 'relative',
      phoneMd: 'relative',
      md: 'static',
      lg: 'static',
    },
    top: '3%',
    width: {
      xs: '91%',
      phoneMd: '91%',
      tablet: '90.8%',
      md: '77.8%',
      lg: '77.8%',
    },
    marginLeft: {
      xs: '0rem',
      phoneMd: '0rem',
      md: '1.5rem',
      lg: '1.5rem',
    },
    objectFit: 'cover',
  },
};

export const styleContentSkeleton = {
  spacing: {
    row: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
    column: {
      xs: 4,
      phoneMd: 4,
      md: 3,
      lg: 3,
    },
  },
  direction: {
    xs: 'column',
    phoneMd: 'column',
    md: 'row',
    lg: 'row',
  },
  style: {
    width: '100% !important',
    margin: '0px !important',
    '& a': {
      textDecoration: 'none',
    },
    '& > div': {
      padding: '0 !important',
    },
  },
};

export const styleColumnOneSkeleton = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
  },
  borderRadius: '30px',
  boxShadow: 'none',
  height: {
    xs: '500px',
    phoneMd: '500px',
    md: '394px',
    lg: '394px',
  },
  backgroundColor: 'primary.main',
  marginBottom: '2.8125rem',
  '& span': {
    display: 'block',
  },
  '& > span:first-of-type': {
    flexBasis: {
      xs: '450px',
      sm: '450px',
      md: '578px',
      lg: '578px',
    },
    width: {
      xs: '100%',
      sm: '100%',
      md: 'initial',
      lg: 'initial',
    },
    height: '100%',
    borderRadius: '30px !important',
    backgroundColor: 'rgba(255, 255, 255, 0.13)',
  },
  '& > div': {
    flexBasis: {
      xs: '60%',
      sm: '60%',
      md: '46%',
      lg: '46%',
    },
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      xs: 'center',
      sm: 'center',
      md: 'space-evenly',
      lg: 'space-evenly',
    },
    width: {
      xs: '85%',
      sm: '85%',
      md: 'initial',
      lg: 'initial',
    },
    '& > span:first-of-type': {
      width: '50%',
      height: {
        xs: '18px',
        phoneMd: '18px',
        md: '24px',
        lg: '24px',
      },
      backgroundColor: 'background.textFieldSubscribe',
    },
    '& > span:nth-of-type(2)': {
      background: 'rgba(255, 255, 255, 0.13)',
      width: '80%',
      height: {
        xs: '100px',
        phoneMd: '100px',
        md: '120px',
        lg: '120px',
      },
    },
    '& > span:nth-of-type(3)': {
      background: 'rgba(255, 255, 255, 0.13)',
      width: '80%',
      height: {
        xs: '40px',
        phoneMd: '40px',
        md: '60px',
        lg: '60px',
      },
    },
    '& > span:last-of-type': {
      background: 'rgba(255, 255, 255, 0.13)',
      width: '20%',
      height: {
        xs: '10px',
        phoneMd: '10px',
        md: '20px',
        lg: '20px',
      },
    },
  },
};
export const styleColumnTwoSkeleton = {
  boxShadow: '20px 30px 60px rgba(0, 0, 0, 0.05)',
  borderRadius: '30px',
  minHeight: {
    xs: '500px',
    sm: '500px',
    md: '424px',
    lg: '424px',
  },
  height: '100%',
  flexBasis: '578px !important',
  marginBottom: '2.8125rem',
  '&:nth-of-type(3)': {
    marginLeft: '2.1rem',
  },
  '& > span:first-of-type': {
    height: '200px',
    borderTopRightRadius: '30px',
    borderTopLeftRadius: '30px',
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    margin: '2.5rem auto 2rem',
    width: {
      xs: '88%',
      sm: '88%',
      md: '100%',
      lg: '100%',
    },
    maxWidth: '464px',
    maxHeight: '144px',
    minHeight: '144px',
    '& > span:first-of-type': {
      width: '50%',
    },
  },
};

export const styleContentColumnThreeSkeleton = {
  width: '100%',
  height: {
    xs: 'auto',
    sm: 'auto',
    md: '424px',
    lg: '424px',
  },
  display: 'flex',
  justifyContent: 'space-between !important',
  flexDirection: {
    xs: 'column !important',
    sm: 'column !important',
    md: 'row !important',
    lg: 'row !important',
  },
  marginBottom: '2.5rem',
};

export const styleContentColumnThreeSkeletonAfterPageOne = {
  flexWrap: 'wrap',
  height: 'auto',
  '& > div': {
    height: '424px',
  },
};

export const styleColumnThreeSkeleton = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start !important',
  minHeight: '424px',
  height: '100%',
  marginBottom: '2.8125rem',
  boxShadow: '20px 30px 60px rgba(0, 0, 0, 0.05)',
  borderRadius: '30px',
  width: '369px',
  '& span': {
    display: 'block',
  },
  '& > span': {
    display: 'block !important',
    width: '100%',
    height: '200px',
    borderTopRightRadius: '30px',
    borderTopLeftRadius: '30px',
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    margin: '2.5rem auto 2rem',
    width: {
      xs: '88%',
      sm: '88%',
      md: '75%',
      lg: '75%',
    },
    maxWidth: '464px',
    maxHeight: '144px',
    minHeight: '144px',
    '& > span:first-of-type': {
      width: '50%',
    },
  },
};
