import { Box } from '@mui/material';
import {
  badgeLength,
  LOCALSTORAGE_NAME_TYPES,
  TYPES_COMPONENTS,
  variantTypography,
} from '../../Common';
import { ServiceTracking } from '../../utils';
import {
  badgeOne,
  badgeTwo,
  CardBage,
  CardServicesContent,
  hiddenText,
  imageBadge,
  ImageCard,
  ImageContainer,
  loactionBadgeStyles,
  LocationsBadgeContainer,
  nameContainerBadge,
  priceBadgeStyles,
  serviceBadgeStyles,
  serviceCardStyles,
  ServiceCardStyles,
  ServicesBadgeContainer,
  spanReset,
  SubTitle,
  Title,
} from './styles';

import DummyImage from '../../../assets/img/okboy_default.png';
import { ServicesSearchMessage } from '../../Common/messages';

const {
  formatMoney,
} = ServiceTracking;

const {
  nameRegistryUUid,
} = ServicesSearchMessage;

const {
  serviceTypeData,
} = LOCALSTORAGE_NAME_TYPES;

const ServiceCard = ({
  icon,
  imageBadgeText,
  minPrice,
  description,
  companies,
  states,
  about,
  name,
}) => {
  const numberBadgeValid = companies?.length === badgeLength.one
    ? badgeOne
    : companies?.length === badgeLength.two ? badgeTwo : null;

  const containerNumberBadgeValid = companies?.length === badgeLength.one
    ? { ...hiddenText, ...nameContainerBadge }
    : companies?.length === badgeLength.two ? { ...hiddenText, ...nameContainerBadge } : hiddenText;

  const unduplicatedState = states && states.filter(
    (state, index) => states.indexOf(state) === index,
  );

  // eslint-disable-next-line no-undef
  const registryUuidValue = JSON.parse(sessionStorage.getItem(nameRegistryUUid));
  // eslint-disable-next-line no-undef
  const serviceTypeStorage = JSON.parse(sessionStorage.getItem(serviceTypeData));
  const registryUuidValid = registryUuidValue || serviceTypeStorage?.registryUuid;

  const cardTitle = !registryUuidValid ? description : name;
  const cardDescription = !registryUuidValid ? about : description;

  return (
    <>
      <ServiceCardStyles
        sx={serviceCardStyles}
      >
        <ImageContainer sx={spanReset}>
          {
            imageBadgeText
            && (
            <CardBage
              sx={{ ...imageBadge, ...spanReset }}
              label={imageBadgeText}
            />
            )
          }
          <ImageCard
            component={TYPES_COMPONENTS.img}
            image={icon || DummyImage}
            alt={icon}
          />
        </ImageContainer>
        <CardServicesContent>
          <Title
            variant={variantTypography.h4}
          >
            <Box component={TYPES_COMPONENTS.span}>
              { cardTitle || null }
            </Box>
            <CardBage label={`Desde $${formatMoney(minPrice)}`} sx={{ ...spanReset, ...priceBadgeStyles }} />
          </Title>
          <SubTitle sx={hiddenText} variant={variantTypography.h5}>
            { cardDescription || null }
          </SubTitle>

          <ServicesBadgeContainer sx={containerNumberBadgeValid}>
            {
              companies?.map(item => {
                return (
                  <CardBage
                    key={item.registryUuid}
                    label={item?.name}
                    sx={{ ...spanReset, ...serviceBadgeStyles, ...numberBadgeValid }}
                  />
                );
              })
            }
          </ServicesBadgeContainer>

          <LocationsBadgeContainer sx={hiddenText}>
            {
              unduplicatedState?.map((state) => {
                return (
                  <CardBage
                    key={Math.random()}
                    label={state}
                    sx={{ ...spanReset, ...loactionBadgeStyles }}
                  />
                );
              })
            }
          </LocationsBadgeContainer>
        </CardServicesContent>
      </ServiceCardStyles>
    </>
  );
};

export default ServiceCard;
