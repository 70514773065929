import { CategoriesContextData } from '../../Contexts';
import { LayoutGeneral, NotFoundLayout } from '../../Layouts';
import { contentNodFound } from './NotFoundStyles';

const NotFound = () => {
  return (
    <>
      <CategoriesContextData />
      <LayoutGeneral style={contentNodFound}>
        <NotFoundLayout />
      </LayoutGeneral>
    </>
  );
};

export default NotFound;
