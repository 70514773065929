/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { PersonalizedHelp, SEO } from '../../Components';
import { HelpSectionLayout, LayoutGeneral, LayoutQA } from '../../Layouts';
import { layoutGeneralStyles } from './FrequentQuestionsStyles';
import { useResetScrollTop } from '../../hooks';
import { DataLading } from '../../api';

const FrequentQuestions = () => {
  const [dataFrecuentQuestions, setDataFrecuentQuestions] = useState({
    bannerHeader: {
      titles: {
        titleMain: '',
        titleSecondaryDesktop: '',
        titleSecondaryMobile: '',
      },
      textUrl: '',
      backgroundImages: {
        desktop: '',
        mobile: '',
      },
    },
    textsQa: [],
    sectionPersonalizedHelp: {
      titles: {
        titleMain: '',
        titleSecondary: '',
      },
      textsUrls: {
        watsApp: '',
      },
      url: '',
    },
  });
  const { scrollTop } = document.documentElement;
  const [setScroll] = useResetScrollTop(scrollTop);
  const initialScroll = 0;
  useEffect(() => {
    setScroll(initialScroll);
  }, []);

  const {
    getDataSeoFreceuntQuestions,
  } = DataLading;

  useEffect(() => {
    const dataFrecuentQuestionsResponse = {
      dataBannerHeader: {
        titles: {
          titleMain: '¿Necesitas ayuda?',
          titleSecondaryDesktop: 'Escríbenos por WhatsApp y un asesor te ayudara.',
          titleSecondaryMobile: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
        },
        backgroundImages: {
          desktop: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Banner_Desktop_3f0c33b8e9.png',
          mobile: 'https://okboy-s3-strapi-artifacts-228826367249.s3.us-west-2.amazonaws.com/Banner_Mobile_12b09f8cbe.png',
        },
        textUrl: 'Contactar',
      },
      dataQa: [
        {
          title: '¿Qué servicios ofrece Okboy?',
          textContent: 'En Okboy ofrecemos servicios para tu hogar. Actualmente contamos con lavandería, gas, pago de servicios online, mudanzas, pinturería, servicio de limpieza y servicios de handyman (plomería, electricista, jardinería).',
        },
        {
          title: '¿Quién va a entregar mis servicios?',
          textContent: 'Nuestros profesionales aliados te alcanzarán los servicios que necesitas hasta tu hogar. Trabajamos con transparencia en el precio y con personal de confianza. Okboy está para cuidarte a ti y a tu hogar.',
        },
        {
          title: '¿Cuándo me entero del valor del servicio?',
          textContent: 'El valor del servicio lo conoces antes de pagarlo a través de nuestra app.',
        },
        {
          title: '¿Qué pasa si hay algún problema con el servicio?',
          textContent: 'Nuestro equipo de servicio al cliente está disponible para ti de Lunes a Viernes de 9 am a 6 pm para solucionarte cualquier inconveniente que tengas con los servicios.',
        },
        {
          title: '¿Cómo puedo pagar el servicio?',
          textContent: 'Encuentra todos los servicios que necesitas y pídelos en un clic. Encuentra todos los servicios que necesitas ',
        },
        {
          title: '¿Okboy es una gasera?',
          textContent: 'Puedes pagarlo por medio de la app con tarjeta de débito y crédito o en efectivo cuando el profesional llega a tu hogar.',
        },
        {
          title: '¿En qué ciudades funciona Okboy?',
          textContent: 'Okboy funciona en Guadalajara, Ciudad de México, Toluca, San Luis Potosí, Aguascalientes y León. Esperamos llegar a más ciudades muy pronto.',
        },
        {
          title: '¿Qué puedo ver en la sección de análisis?',
          textContent: 'En nuestra sección de análisis podrás organizar tus finanzas personales. Estarás al tanto de cuánto gastas por mes en tus servicios y cuánto pagan las personas por ese mismo servicio en tu ciudad. Esta sección te ayuda a darte cuenta si estás pagando más por alguno de tus servicios.',
        },
        {
          title: '¿Okboy funciona los fines de semana?',
          textContent: 'Sí, estos son nuestros horarios Sábados de 9 am a 3 pm y Domingos de 9 am a 2 pm.',
        },
        {
          title: '¿Puedo programar el día y la hora de mi servicio?',
          textContent: '¡Claro! Puedes programar el día y la hora en que requieras tu servicio.',
        },
        {
          title: '¿Okboy es una gasera?',
          textContent: 'Okboy no es una gasera, Okboy está para cuidarte a ti y a tu hogar, ofreciendo diversos servicios como lavandería, gas, limpieza entre muchos otros.',
        },
      ],
      dataSectionPersonalizedHelp: {
        titles: {
          titleMain: '¿Necesitas ayuda?',
          titleSecondary: 'Escríbenos por WhatsApp o consulta las preguntas frecuentes.',
        },
        textsUrls: {
          watsApp: 'Escríbenos',
        },
        url: 'https://api.whatsapp.com/send?phone=+525610109864&text=%C2%A1Hola!%20Necesito%20ayuda%20en',
      },
    };
    const {
      dataBannerHeader,
      dataQa,
      dataSectionPersonalizedHelp,
    } = dataFrecuentQuestionsResponse;
    setDataFrecuentQuestions(prevDataFrecuentQuestions => ({
      ...prevDataFrecuentQuestions,
      bannerHeader: {
        titles: {
          titleMain: dataBannerHeader.titles.titleMain,
          titleSecondaryDesktop: dataBannerHeader.titles.titleSecondaryDesktop,
          titleSecondaryMobile: dataBannerHeader.titles.titleSecondaryMobile,
        },
        textUrl: dataBannerHeader.textUrl,
        backgroundImages: {
          desktop: dataBannerHeader.backgroundImages.desktop,
          mobile: dataBannerHeader.backgroundImages.mobile,
        },
      },
      textsQa: dataQa,
      sectionPersonalizedHelp: {
        titles: {
          titleMain: dataSectionPersonalizedHelp.titles.titleMain,
          titleSecondary: dataSectionPersonalizedHelp.titles.titleSecondary,
        },
        textsUrls: {
          whatsApp: dataSectionPersonalizedHelp.textsUrls.watsApp,
        },
        url: dataSectionPersonalizedHelp.url,
      },
    }));
  }, []);

  const {
    bannerHeader,
    textsQa,
    sectionPersonalizedHelp,
  } = dataFrecuentQuestions;
  return (
    <LayoutGeneral style={layoutGeneralStyles}>
      <SEO getDataSeo={getDataSeoFreceuntQuestions} />
      <PersonalizedHelp {...bannerHeader} />
      <LayoutQA textsQa={textsQa} />
      <HelpSectionLayout {...sectionPersonalizedHelp} />
    </LayoutGeneral>
  );
};

export default FrequentQuestions;
