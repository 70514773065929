/* eslint-disable no-undef */
import { LayoutGeneral } from '../../Layouts';
import TermsConditionsLayouts from '../../Layouts/TermsConditionsLayouts/TermsConditionsLayouts';
import { contentTermsCondition } from './TermsConditionsStyles';
import { captions, sectionsTermsConditions } from '../../Common/messages';

const TermsConditions = () => {
  return (
    <LayoutGeneral style={contentTermsCondition}>
      <TermsConditionsLayouts captions={captions} sections={sectionsTermsConditions} />
    </LayoutGeneral>
  );
};

export default TermsConditions;
