import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  InputBase,
  List,
  Radio,
  RadioGroup,
  Skeleton,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(2, max-content)',
  width: '100%',
  marginBottom: '1.75rem',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridTemplateRows: 'repeat(3, max-content)',
  },
}));

export const ProductCaption = styled('h1')(({ theme }) => ({
  gridColumn: '1 / 2',
  gridRow: '1 / 2',
  fontWeight: '900',
  fontSize: '2.1875rem',
  lineHeight: '2.5rem',
  marginBottom: '1rem',
  color: theme.palette.primary.main,
  width: '343px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '2.8125rem',
    fontSize: '2rem',
    marginBottom: '0rem',
  },
}));

export const PriceText = styled(Typography)(({ theme }) => ({
  gridColumn: '2 / 4',
  gridRow: '1 / 3',
  fontWeight: '900',
  fontSize: '2.1875rem',
  lineHeight: '2.5rem',
  justifySelf: 'center',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    justifySelf: 'start',
    alignSelf: 'start',
    fontSize: '1.25rem',
    lineHeight: '2.5rem',
    color: theme.palette.text.select,
  },
  [theme.breakpoints.up('tablet')]: {
    width: 'auto',
    color: theme.palette.primary.main,
  },
}));

export const BadgeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '.5rem',
  gridColumn: '1 / 2',
  gridRow: '2 / 3',
  alignSelf: 'center',
  width: '587px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    gridColumn: '1 / 2',
    gridRow: '3 / 4',
    width: '94%',
    flexFlow: 'row wrap',
  },
}));

/* Product Description */

export const ImageAndDescriptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  height: '524px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '270px',
  },
  [theme.breakpoints.up('tablet')]: {
    height: '470px',
  },
}));

export const Imagecontent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '378px',
  minHeight: '378px',
  maxHeight: '378px',
  marginBottom: '1.5rem',
  flexBasis: 'max-content',
  [theme.breakpoints.down('sm')]: {
    height: '176px',
    minHeight: '176px',
    maxHeight: '176px',
    marginBottom: '1rem',
  },
  [theme.breakpoints.up('tablet')]: {
    marginBottom: '1rem',
  },
}));

export const Image = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '30px',
}));

export const Descriptioncontent = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  paddingBottom: '1.5rem',
  borderBottom: '1px solid #C4C4C4',
  color: theme.palette.text.secondary,
  flexBasis: 'max-content',
  marginBottom: '3rem',
}));

/* Service Included */

export const ListContainerDesktop = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  padding: '1.4rem',
  backgroundColor: theme.palette.secondary.main,
  display: 'none',
  borderRadius: '30px',
  marginBottom: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const TextsContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  width: '100%',
}));

export const ServiceIncludedTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '92%',
  paddingBottom: '0.2625rem',
  borderBottom: '1px solid rgba(121, 121, 122, 0.5)',
  gap: '1rem',
  '& > span': {
    fontWeight: '800',
    fontSize: '1.375rem',
    lineHeight: '1m875rem',
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    '& > span:first-of-type': {
      fontSize: '1.25rem',
      lineHeight: '1.7075rem',
    },
  },
}));

export const includeList = {
  width: '92%',
  gridColumn: '1 / 2',
  gridRow: '2 / 3',
  paddingLeft: '1.3rem',
};

export const notIncludeList = {
  width: '92%',
  gridColumn: '2 / 3',
  gridRow: '2 / 3',
  paddingLeft: '1.3rem',
};

export const ListContainerMobile = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    width: '100%',
    height: 'max-content',
    padding: '1rem',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '30px',
    marginBottom: '2.5rem',
  },
}));

export const ContainerTitles = styled(Tabs)(() => ({
  width: '100%',
  backgroundColor: 'transparent',
}));

export const ItemTitle = styled(Tab)(({ theme }) => ({
  width: '50%',
  padding: '0px',
  textTransform: 'initial',
  backgroundColor: 'transparent',
  '&.MuiButtonBase-root': {
    fontSize: '1.07rem',
    fontWeight: '800',
    lineHeight: '1.6875rem',
    color: theme.palette.text.secondary,
  },
}));

export const includeIcon = {
  fill: '#78E25E',
};

export const includeNotIcon = {
  fill: '#EC225E',
};

export const ListContent = styled(List)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  gap: '.4rem',
  listStyle: 'disc',
  paddingLeft: '2rem',
  '& li': {
    position: 'relative',
    fontSize: '0.875rem',
    lineHeight: '1.195rem',
    color: theme.palette.text.secondary,
  },
}));

/* Does It Work Card */

export const ContainerCard = styled(Box)(({ theme }) => ({
  width: '172px',
  height: '236px',
  backgroundColor: theme.palette.secondary.main,
  padding: '1rem',
  borderRadius: '30px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    width: '234px',
    height: '178px',
    padding: '1rem 2.3125rem',
  },
}));

export const NumberContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export const CardNumber = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  textAlign: 'center',
  padding: '0.5rem 1rem',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  marginBottom: '2rem',
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    marginBottom: '1rem',
  },
}));

export const CardText = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  width: '100%',
  height: '130px',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '6',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    height: '91px',
    textAlign: 'center',
    WebkitLineClamp: '4',
  },
}));

/* Product Recommendations  */

export const productRecommendationsCaption = {
  width: 'max-content',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  borderRadius: '15px',
  marginBottom: '.5rem',
  padding: '.5rem 2rem',
  backgroundColor: 'rgba(255, 131, 0, 0.1)',
  color: 'text.secondary',
};

export const ListContainerRecommendations = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: '580px',
  marginBottom: '1rem ',
  listStyle: 'disc',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  paddingLeft: '.8rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '333px',
  },
}));

/* Warranty  */

export const WarrantyListsContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.text.secondary,
    '& > div': {
      width: 'max-content',
      minWidth: 'initial',
    },
  },
  '& .MuiListItemText-root': {
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiTypography-root': {
      fontWeight: '900',
      fontSize: '0.875rem',
      lineHeight: '2.5rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: '.5rem',
    padding: '0px',
    marginTop: '4px',
    marginBottom: '4px',
    '& .MuiListItemText-root .MuiTypography-root': {
      lineHeight: '0.875rem',
    },
  },
}));

export const WarrantyIcon = styled('img')(() => ({
  width: 'auto',
}));

/* Billing Section */
export const BillingSectionStyles = styled('div')(({ theme }) => ({
  '& > p': {
    width: '99%',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.1875rem',
    color: theme.palette.text.secondary,
    '& > a': {
      display: 'inline-block',
      textDecoration: 'underline',
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: '1.1875rem',
      marginLeft: '.7rem',
      color: theme.palette.primary.main,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > p': {
      width: '100%',
      display: 'flex',
      flexFlow: 'wrap column',
      '& > a': {
        display: 'inline',
        marginLeft: '0rem',
      },
    },
  },
}));

/* App Banner */

export const AppBannerContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  gridTemplateRows: '1fr',
  width: '100%',
  backgroundColor: 'rgba(120, 114, 242, 0.8);',
  borderRadius: '30px',
  padding: '2.5rem 1.4375rem',
  paddingRight: '2rem',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'repeat(2, 1fr)',
    width: '340px',
    height: '689px',
    paddingRight: '0rem',
    padding: '2.5rem 1rem',
  },
}));

export const TextAppBannerContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: 'repeat(4, max-content)',
  width: '100%',
  gridColumn: '1 / 2',
  placeItems: 'center',
  gap: '.5rem',
}));

export const AppBannerSubTitle = styled(Chip)(({ theme }) => ({
  fontWeight: '800',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  padding: '.5rem 1rem',
  background: 'rgba(68, 51, 224, 0.5)',
  borderRadius: '15px',
  color: theme.palette.secondary.main,
}));

export const AppBannerTitle = {
  width: '101%',
  fontWeight: '900',
  fontSize: {
    xs: '2.25rem',
    md: '2.5rem',
  },
  lineHeight: '2.8125rem',
  textAlign: 'center',
  color: 'secondary.main',
};

export const TextContent = styled(Typography)(({ theme }) => ({
  width: 'auto',
  fontWeight: '500',
  fontSize: '1.375rem',
  lineHeight: '27px',
  textAlign: 'center',
  marginBottom: '2rem',
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    marginBottom: '.5rem',
    fontSize: '1.25rem',
  },
}));

export const AppDownloadContainer = styled(Box)(({ theme }) => ({
  width: '98%',
  '& > div > div:first-of-type': {
    marginRight: '0.8125rem',
  },
  '& > div > div': {
    width: '11rem',
  },
  '& div, & div a': {
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '95%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > div > div': {
      width: '10.375rem',
    },
  },
}));

export const styleAppStore = {
  width: '10.375rem',
  marginRight: '16px',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};

export const styleAppPlayStore = {
  width: '10.375rem',
  '& img': {
    width: {
      xs: '84%',
      md: '100%',
    },
  },
};

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '309px',
  justifySelf: 'end',
  gridColumn: '2 / 3',
  marginTop: '-.6rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifySelf: 'start',
    gridColumn: '1 / 2',
    marginTop: '0rem',
  },
}));

export const AppBannerImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    objectFit: 'contain',
  },
}));

/* ProductPrices */

export const PricesContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const PriceLabel = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  color: theme.palette.primary.normal,
}));

export const PriceProductText = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  color: theme.palette.primary.normal,
}));

export const priceTextStyled = {
  fontSize: '0.875rem',
  lineHeight: '1.1875rem',
  fontWeight: '500',
  color: 'primary.normal',
};

/* OrderButton */

export const ContainerOrderButton = styled('div')(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  gap: '.25rem',
  width: '100vw',
  height: '104px',
  padding: '1rem 0rem',
  backgroundColor: '#DED2FD',
  borderRadius: '15px 15px 0px 0px',
  position: 'fixed',
  bottom: '0',
  left: '0',
  zIndex: '10',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
}));

export const TitleOrderButton = styled('p')(({ theme }) => ({
  fontWeight: '900',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  margin: '0 auto',
  color: theme.palette.primary.main,
}));

export const TitleOrderButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'scale(1)',
  backgroundColor: theme.palette.primary.main,
  width: '200px',
}));

export const TextOrderButton = styled(Button)(({ theme }) => ({
  fontWeight: '900',
  fontSize: '1.375rem',
  lineHeight: '1.875rem',
  width: '70.3%',
  margin: '0 auto',
  borderRadius: '15px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  textTransform: 'initial',
  '&:hover': {
    backgroundColor: '#4433E0',
    color: 'white',
  },
}));

export const TextOrderButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  transform: 'scale(1)',
  backgroundColor: theme.palette.secondary.main,
  width: '130px',
}));

/* Selects */

export const InputSelect = styled(InputBase)(() => ({
  gridColumn: '1 / 2',
  width: '122px',
  padding: '0px',
  height: '100%',
  borderRight: '1px solid rgba(77, 77, 78, 0.5);',
  '& .MuiInputBase-input': {
    width: '68%',
    paddingRight: '2rem !important',
    paddingTop: '0.3rem',
    position: 'relative',
    fontSize: '1rem',
    borderRadius: 'initial',
    '&:focus': {
      '& + .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      },
    },
  },
}));

/* Custom Radio */

export const ContainerRadio = styled(RadioGroup)(() => ({
  width: '100%',
  height: 'max-content',
  padding: '0rem 1rem',
  gap: '1rem',
  marginBottom: '1.441875rem',
}));

export const RadioLabel = styled(FormControlLabel)(() => ({
  width: '100%',
  height: '46px',
  background: '#F0F1F5',
  border: '1px solid rgba(121, 121, 122, 0.1)',
  borderRadius: '15px',
  padding: '0.75rem 0rem 0.875rem 0.7rem',
  boxSizing: 'border-box',
  marginRight: '0rem',
  marginLeft: '0px',
}));

export const RadioCustom = styled(Radio)(() => ({
  fontSize: '1.3rem',
  color: '#79797A',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const locationInputStyles = {
  width: '100%',
  height: '45px',
  fontSize: '1rem',
  lineHeight: '1.375rem',
  '& input': {
    border: '1px solid transparent',
    outline: 'none',
    padding: '0.625rem 1rem 0.8125rem 1.55375rem',
    height: '100%',
    borderRadius: '15px',
    width: '100%',
    backgroundColor: 'background.secondary',
  },
  '& input:focus': {
    border: '1px solid',
    borderColor: 'primary.main',
  },
  '&:focus-visible': {
    border: '1px solid',
    borderColor: 'primary.main',
  },
};

/* Loader */

export const LoaderContainer = styled('div')(() => ({
  height: 'max-content',
}));
