export const downloadContainer = {
  paddingLeft: {
    xs: '0px',
    md: '0px',
  },
  paddingRight: {
    xs: '0px',
    md: '0px',
  },
  marginTop: {
    xs: 'initial',
    md: '4.875rem',
  },
  marginBottom: '1rem',
  '& > div:first-of-type': {
    top: {
      xs: '0',
      md: 'initial',
    },
  },
  '& > .MuiBox-root > .MuiBox-root': {
    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: {
        xs: 'initial',
        md: 'space-evenly',
      },
      gap: {
        xs: '2rem',
        md: '0rem',
      },
      '& > h1, & > h2': {
        marginBottom: '0px',
      },
    },
  },
};
