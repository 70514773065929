const TwitterCircle = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M18.4683 4.71327C17.8321 4.99468 17.1574 5.1795 16.4666 5.26161C17.1947 4.82613 17.7397 4.14078 17.9999 3.33327C17.3166 3.73994 16.5674 4.02494 15.7866 4.17911C15.2621 3.61792 14.5669 3.24574 13.8091 3.12043C13.0512 2.99511 12.2732 3.12368 11.596 3.48615C10.9187 3.84862 10.3802 4.42467 10.0642 5.12477C9.74812 5.82486 9.67221 6.60976 9.84826 7.35744C8.46251 7.28798 7.10687 6.92787 5.86933 6.30049C4.63179 5.67311 3.54003 4.79248 2.66492 3.71577C2.35516 4.24781 2.19238 4.85263 2.19326 5.46827C2.19326 6.6766 2.80826 7.7441 3.74326 8.3691C3.18993 8.35169 2.64878 8.20226 2.16492 7.93327V7.9766C2.16509 8.78135 2.44356 9.56128 2.95313 10.1842C3.46269 10.807 4.17199 11.2345 4.96076 11.3941C4.4471 11.5333 3.90851 11.5538 3.38576 11.4541C3.60814 12.1468 4.04159 12.7526 4.62542 13.1867C5.20924 13.6208 5.9142 13.8614 6.64159 13.8749C5.91866 14.4427 5.0909 14.8624 4.20566 15.1101C3.32041 15.3577 2.39503 15.4285 1.48242 15.3183C3.0755 16.3428 4.93 16.8867 6.82409 16.8849C13.2349 16.8849 16.7408 11.5741 16.7408 6.96827C16.7408 6.81827 16.7366 6.6666 16.7299 6.51827C17.4123 6.02508 18.0013 5.41412 18.4691 4.71411L18.4683 4.71327Z' fill='white' />
    </svg>
  );
};

export default TwitterCircle;
