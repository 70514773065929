const Tiktok = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.619 3.429H4.381a.955.955 0 0 0-.952.952v15.238c0 .524.428.952.952.952h15.238a.955.955 0 0 0 .952-.952V4.381a.955.955 0 0 0-.952-.952Zm0-1.429H4.381A2.384 2.384 0 0 0 2 4.381v15.238A2.384 2.384 0 0 0 4.381 22h15.238A2.384 2.384 0 0 0 22 19.619V4.381A2.384 2.384 0 0 0 19.619 2Z'
        fill='#4433E0'
      />
      <path
        d='M17 10.855c-.095.009-.19.015-.287.015a3.1 3.1 0 0 1-2.611-1.449v4.933c0 2.014-1.59 3.646-3.551 3.646S7 16.368 7 14.354c0-2.013 1.59-3.646 3.551-3.646.074 0 .147.007.22.012v1.796c-.073-.008-.145-.022-.22-.022-1.001 0-1.812.833-1.812 1.86 0 1.029.81 1.861 1.812 1.861 1.001 0 1.885-.81 1.885-1.837L12.454 6h1.672c.158 1.535 1.365 2.747 2.874 2.858v1.997Z'
        fill='#4433E0'
      />
    </svg>
  );
};

export default Tiktok;
